import { useState, useEffect } from 'react';
import {
  Timeline,
  Button,
  Space,
  Modal,
  Form,
  Input,
  Spin,
  message,
  Empty,
} from 'antd';
import moment from 'moment';
import { connect, useIntl } from 'umi';
import { GetThoughts, PostThoughts } from 'services/adhd';
import ParagraphWithShowLess from './ParagraphWithShowLess'

const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const Thoughts = ({
  currentUser,
  daySortOrder,
  weekSortOrder,
  TemplateId,
  WeekThemeId,
  DailyTopicId,
  ShowTitle = true,
}) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const { adminRoles, current } = currentUser;
  const isAdmin = adminRoles.length > 0 && current === null;
  const { roles = [] } = current || {} ;
  const isEnterpriseAdmin = roles.some(item => item.name === 'EnterpriseAdmin')
  const isAreaAdmin = roles.some(item => item.name === 'AreaAdmin')
  const [modalVisible, setModalVisible] = useState(false);
  const [thoughtsData, setThoughtsData] = useState([]);
  const [loading, setLoading] = useState({
    spinLoading: false,
    modalLoading: false,
  });
  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });

  const RenderTimelineItem = (item) => {
    const { thought, creationTime, userName } = item || {};
    return (
      <Space direction="vertical">
        <Space>
          {userName && <b>{userName}</b>}
          <div>{moment(creationTime).format('YYYY/MM/DD HH:mm')}</div>
        </Space>
        <ParagraphWithShowLess>{thought}</ParagraphWithShowLess>
      </Space>
    );
  };

  const initData = async () => {
    const { current, pageSize } = pagination || {};
    setLoading({
      ...loading,
      spinLoading: true,
    });

    const res = await GetThoughts({
      TemplateId,
      WeekThemeId,
      DailyTopicId,
      SkipCount: 0,
      MaxResultCount: current * pageSize,
    });

    if (res.response.ok) {
      const { items = [], totalCount } = res.data || {};
      setThoughtsData(items);
      setPagination({
        ...pagination,
        total: totalCount,
      });
    } else {
      const { error } = res.data;
      message.error(error.message);
    }

    setLoading({
      ...loading,
      spinLoading: false,
    });
  };

  useEffect(() => {
    initData();
  }, [pagination.current]);

  const addThoughts = () => {
    setModalVisible(true);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const onFinish = async (value) => {
    setLoading({
      ...loading,
      modalLoading: true,
    });
    const res = await PostThoughts({
      ...value,
      TemplateId,
      WeekThemeId,
      DailyTopicId,
    });

    if (res.response.ok) {
      message.success(intl.formatMessage({ id: 'Submitted' }));
      initData();
    } else {
      const { error } = res.data;
      message.error(error.message);
    }

    setModalVisible(false);

    setLoading({
      ...loading,
      modalLoading: false,
    });
  };

  const loadMore = async () => {
    setPagination({
      ...pagination,
      current: ++pagination.current,
    });
  };

  return (
    <Spin spinning={loading.spinLoading}>
      <Space direction="vertical" style={{ width: '100%', gap: '0px' }} size="large">
        {ShowTitle && (
          <h3>
            {`${intl.formatMessage(
              { id: 'core-weekDay' },
              {
                week: parseInt(weekSortOrder) + 1,
                day: parseInt(daySortOrder) + 1,
              },
            )} `}
          </h3>
        )}
        {!isAdmin && !isEnterpriseAdmin && !isAreaAdmin && (
          <Button
            style={{ marginLeft: '25px', marginBottom: '6px' }}
            onClick={addThoughts}
          >
            {intl.formatMessage({ id: 'Add Thoughts' })}
          </Button>
        )}
        {thoughtsData.length > 0 ? (
          <Space direction="vertical">
            <Timeline>
              {thoughtsData.map((item) => {
                const { id } = item || {};
                return (
                  <Timeline.Item key={id}>
                    {RenderTimelineItem(item)}
                  </Timeline.Item>
                );
              })}
            </Timeline>
            {pagination.total > thoughtsData.length && (
              <div style={{ textAlign: 'center' }}>
                <Button onClick={loadMore}>
                  {intl.formatMessage({ id: 'Load More' })}
                </Button>
              </div>
            )}
            {pagination.total === thoughtsData.length && (
              <div
                style={{ textAlign: 'center', color: '#ccc', fontSize: '12px' }}
              >
                {intl.formatMessage({ id: 'Loaded all' })}
              </div>
            )}
          </Space>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Space>
      <Modal
        title={intl.formatMessage({ id: 'Thoughts' })}
        open={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        confirmLoading={loading.modalLoading}
        destroyOnClose={true}
        maskClosable={false}
        okText={intl.formatMessage({ id: 'core-Submit' })}
      >
        <Form
          name="basic"
          form={form}
          onFinish={onFinish}
          {...formItemLayout}
          preserve={false}
        >
          <Form.Item
            label={intl.formatMessage({ id: 'your Thoughts' })}
            name="Thought"
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'require thought' }),
              },
            ]}
          >
            <TextArea
              rows={4}
              placeholder={intl.formatMessage({ id: 'add Thought' })}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Spin>
  );
};

export default connect(({ user }) => ({ currentUser: user.currentUser }))(
  Thoughts,
);
