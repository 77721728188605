import getRequest from 'utils/request'

const getApplyListApi = '/api/rocketAdmin/preparationTrainer/applyList'
const postReviewApi = '/api/rocketAdmin/preparationTrainer/review'

export async function GetApplyList(data) {
  return getRequest()(getApplyListApi, {
    method: 'GET',
    params: data
  })
}

export async function PostReview(data) {
  return getRequest()(postReviewApi, {
    method: 'POST',
    data,
  })
}
