import getRequest from 'utils/request'
import { getUserTenantId } from 'cognitiveleap-core-us/utils/auth'


const PostArrangeCourseApi = '/api/business/calendar/calendar'
const GetArrangeCourseApi = '/api/business/calendar/getUserCalendar'
const PutCalendarApi = '/api/business/calendar/calendar'
const GetPlanDetailInfoApi = '/api/business/calendar/planDetailInfo'
const DeleteCalendarApi = '/api/business/calendar/calendar'
const GetTeanntCourseCoseListApi = '/api/rocketAdmin/userOfflinePlan/coachCourseCostList'
const GetCoachCourseCostListApi = '/api/rocketAdmin/userOfflinePlan/tenantCourseCostList'
const GetTrainingCalendarListApi = '/api/business/calendar/linkTrainingCalendarList/{userTrainingPlanId}'

const getCurrentTenantId = () => getUserTenantId()

export async function PostArrangeCourse(data, header) {
    return getRequest(header || { 't-select': getCurrentTenantId() })(PostArrangeCourseApi, {
        method: 'POST',
        data,
    })
}

export async function GetArrangeCourse(data) {
    return getRequest()(GetArrangeCourseApi, {
        method: 'POST',
        data,
    })
}

export async function PutCalendar(data, header) {
    return getRequest(header || { 't-select': getCurrentTenantId() })(PutCalendarApi, {
        method: 'PUT',
        data
    })
}

export async function GetPlanDetailInfo(data, header) {
    return getRequest(header || { 't-select': getCurrentTenantId() })(GetPlanDetailInfoApi, {
        method: 'GET',
        params: data,
    })
}

export async function DeleteCalendar(data, header) {
    return getRequest(header || { 't-select': getCurrentTenantId() })(DeleteCalendarApi, {
        method: 'DELETE',
        params: data,
    })
}

export async function GetTeanntCourseCoseList(){
    return getRequest()(GetTeanntCourseCoseListApi, {
        method: 'GET',
    })
}

export async function GetCoachCourseCostList(data){
    return getRequest()(GetCoachCourseCostListApi, {
        method: 'GET',
    })
}

export async function GetTrainingCalendarList(data){
    return getRequest()(GetTrainingCalendarListApi.replace("{userTrainingPlanId}", data.userTrainingPlanId), {
        method: 'GET',
        params: data,
    })
}