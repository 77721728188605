import getRequest from 'cognitiveleap-core-us/utils/request'
import config from './config'
import { message } from 'antd'
import { getDvaApp } from 'umi'
import { IntlManager } from 'utils/helper'
import * as Sentry from "@sentry/react";

const { baseURL } = config

let errorTime = 0;
const logoutCode = ['Business:CenterStateInvalid', 'Business:UserInTenantRoleFrozen', 'Business:UserHasNoAvailableTenant'];

const errorHandler = error => {
  const { response = {}, data = {} } = error
  const { status, url, statusText } = response || {}
  // console.log(response);
  if (status === 401) {
    getDvaApp()._store.dispatch({
      type: 'user/returnLoginPage',
    })
  } else if (status === 403) {
    const { error } = data;
    const { code, message: errorMessage } = error || {};

    if (logoutCode.includes(code)) {
      if (errorTime++ < 1) {
        const formatMessage = IntlManager.intl.formatMessage

        errorMessage && message.error(`${errorMessage}${formatMessage({ id: 'willLogout' })}`);

        setTimeout(() => {
          getDvaApp()._store.dispatch({
            type: 'user/logout',
          })
        }, 2 * 1000);
      }
    }
  } else {
    const { error } = data;

    if (status && url) {
      Sentry.captureMessage(`Api ${status} ${url}`, {
        contexts: {
          response: {
            status,
            url
          },
          error,
        },
      }, "error");
    }
  }
  if (response && Object.keys(response).length) {
    return { response: {}, ...error }
  }
  return {
    response: { ok: false }, data: {
      "error": {
        "code": null,
        "message": "请求出问题了，请检查网络或稍后重试",
        "details": null,
        "validationErrors": null
      },
      "Error": {
        "Code": null,
        "Message": "请求出问题了，请检查网络或稍后重试",
        "Details": null,
        "ValidationErrors": null
      }
    }
  }
}

const generateRequest = (params) => getRequest()(baseURL, errorHandler, params)


export default generateRequest
