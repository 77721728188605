import React from 'react'
import { Spin } from 'antd'

const Loading = ({loading, children}) => {
    return (
        <Spin spinning={loading}>
            {children}
        </Spin>
    )
}
export default Loading