import React, { Fragment } from 'react'
import { connect, useIntl } from 'umi'
import defaultImg from 'assets/subject_default.png'
import styles from './index.less'
import { SHOWTYPE } from '../../pages/Center/Detail/SubjectDetail/models/subjectDetail'
import { Avatar, Badge, Tooltip } from 'antd'
import { CheckOutlined } from '@ant-design/icons';
import soundUrl from 'assets/sound.png'
import vratNotificationUrl from 'assets/vratNotification.png'
import { getUserImg } from 'utils/utils'
import moment from 'moment'

const titleOption = (title, intl) => {
    switch (title) {
        case intl.formatMessage({ id: 'newAuthorization' }):
        case '新增授权':
            return 'addAuth';
        case intl.formatMessage({ id: 'trainingCheckIn' }):
        case '打卡训练':
            return 'clockInTraining';
        case intl.formatMessage({ id: 'assessmentSubmission' }):
        case '提交测评':
            return 'submitAssessment';
        case intl.formatMessage({ id: 'exerciseActivated' }):
        case '开启了运动':
            return 'openExercise';
        case intl.formatMessage({ id: 'sleepActivated' }):
        case '开启了睡眠':
            return 'openSleep';
        case intl.formatMessage({ id: 'nutritionActivated' }):
        case '开启了营养':
            return 'openNutrition';
        case intl.formatMessage({ id: 'deauthorization' }):
        case '取消授权':
            return 'cancelAuth';
        case intl.formatMessage({ id: 'trainingScheduled' }):
        case '安排培训':
            return 'arrangeTraining';
        case intl.formatMessage({ id: 'Certified' }):
        case '获得证书':
            return 'getCertificate';
        case '申请注销FocusEDTx':
        case intl.formatMessage({ id: 'Apply for FocusEDTx Cancellation' }):
            return 'cancelPdt';
    }
    // return {
    //     '新增授权': 'addAuth',
    //     '打卡训练': 'clockInTraining',
    //     '提交测评': 'submitAssessment',
    //     '开启了运动': 'openExercise',
    //     '开启了睡眠': 'openSleep',
    //     '开启了营养': 'openNutrition',
    //     '取消授权': 'cancelAuth',
    //     "安排培训": 'arrangeTraining',
    //     "获得证书": 'getCertificate',
    // }
}

const dataType = (intl) => {
    return {
        todayData: intl.formatMessage({ id: 'today' }),
        yesterdayData: intl.formatMessage({ id: 'yesterday' }),
        otherdayData: intl.formatMessage({ id: 'twoDaysAgo' })
    }
}

const NotificationContent = ({ activeKey, datasource, isShowHasRead, showTimeLocation, signToRead, notification, dispatch, type, showReleaseModal }) => {
    const intl = useIntl()

    if (!datasource || (JSON.stringify(datasource) == '{}')) {
        return <div className={styles.notificationCenterContentEmpty}>{intl.formatMessage({ id: type == 'unread' ? 'noUnreadMessage' : 'thereIsNoData' })}</div>
    }

    const { visible } = notification

    const showCreateTime = (type, time) => {
        return type == 'otherdayData' ?
            moment(time).local().format('YYYY/MM/DD HH:mm')
            :
            moment(time).local().format('HH:mm')
    }

    const showNotificationContentItem = (data, index) => {
        const { extraProperties, id } = data
        let { guardianName, subjectName, assessmentTitle, num, courseName, planName, Desc } = extraProperties || {};
        const title = titleOption(data.title, intl);
        guardianName = guardianName || intl.formatMessage({ id: 'nameToEdited' })
        subjectName = subjectName || intl.formatMessage({ id: 'nameToEdited' })
        switch (title) {
            case 'addAuth':
                return (
                    <span key={id + index + 'addAuth'}>
                        <span className={styles.contentItemContentTopTitle}>{data.title}</span>
                        <span>{`${intl.formatMessage({ id: 'guardian' })}：${guardianName} | ${intl.formatMessage({ id: 'subject' })}：${subjectName.length <= 1 ? subjectName[0] : subjectName.join('，')}`}</span>
                    </span>
                )
            case 'cancelAuth':
                return (
                    <span key={id + index + 'cancelAuth'}>
                        <span className={styles.contentItemContentTopTitle}>{data.title}</span>
                        <span>{`${intl.formatMessage({ id: 'subject' })}：${subjectName.length <= 1 ? subjectName[0] : subjectName.join('，')} | ${intl.formatMessage({ id: 'guardian' })}：${guardianName}`}</span>
                    </span>
                )
            case 'submitAssessment':
                return (
                    <span key={id + index + 'submitAssessment'}>
                        <span className={styles.contentItemContentTopTitle}>{data.title}</span>
                        <span>{`${intl.formatMessage({ id: 'subject' })}：${subjectName} | ${intl.formatMessage({ id: 'assessment' })}：${assessmentTitle}`}</span>
                    </span>
                )
            case 'clockInTraining':
                return (
                    <span key={id + index + 'clockInTraining'}>
                        <span className={styles.contentItemContentTopTitle}>{data.title}</span>
                        <span>{`${intl.formatMessage({ id: 'subject' })}：${subjectName} | ${intl.formatMessage({ id: 'session' })}：${intl.formatMessage({ id: 'whichSession' }, { num })} ${courseName} | ${intl.formatMessage({ id: 'exercise' })}：${planName} | ${intl.formatMessage({ id: 'trainingActivityAllChecked' })}`}</span>
                    </span>
                )
            case 'openSleep':
            case 'openExercise':
            case 'openNutrition':
                return (
                    <span key={id + index + 'openSleep'}>
                        <span className={styles.contentItemContentTopTitle}>{data.title}</span>
                        <span>{`${intl.formatMessage({ id: 'subject' })}：${subjectName}`}</span>
                    </span>
                )
            case 'arrangeTraining':
            case 'getCertificate':
                return (
                    <span key={id + index + 'arrangeTraining'}>
                        <span className={styles.contentItemContentTopTitle}>{data.title}</span>
                        <span>{Desc}</span>
                    </span>
                )
            case 'cancelPdt':
                return (
                    <span key={id + index + 'cancelPdt'}>
                        <span className={styles.contentItemContentTopTitle}>{data.title}</span>
                        <span>{` ${intl.formatMessage({ id: 'subject' })}：${subjectName} | ${intl.formatMessage({ id: 'guardian' })}：${guardianName}`}</span>
                    </span>
                )
            default:
                let resultDesc = ""
                if (Desc) {
                    let fieldsArr = Desc.match(/{\S+}/g)
                    fieldsArr = fieldsArr ? fieldsArr.map(item => item.replace(/{|}/g, "")) : []
                    if (fieldsArr.length > 0) {
                        fieldsArr.forEach(item => {
                            const itemData = extraProperties[item]
                            const reg = new RegExp(`{${item}}`, 'g')
                            const data = moment(itemData).isValid() ? moment(itemData).local().format("YYYY/MM/DD HH:mm") : itemData
                            resultDesc = Desc.replace(reg, data || "")
                        })
                    } else {
                        resultDesc = Desc
                    }
                }

                return (
                    <span key={id + index + 'default'}>
                        <span className={styles.contentItemContentTopTitle}>{data.title}</span>
                        <span>{resultDesc}</span>
                    </span>
                )
        }
    }

    const switchToJump = (data, url) => {
        const { extraProperties, isRead, id } = data

        if (!isRead) {
            signToRead(data)
        }

        if (visible) {
            dispatch({
                type: 'notification/updateState',
                payload: { visible: false }
            })
        }

        if (url) window.open(url, '_blank')
    }

    const getUrl = (data) => {
        const { extraProperties, isRead, id } = data
        const { guardianId, subjectId } = extraProperties;
        const title = titleOption(data.title, intl);
        let url = ''
        switch (title) {
            case 'addAuth':
                url = '/system/center/detail/userDetail?from=notification&id=' + guardianId
                break;
            case 'cancelAuth':
                url = '/system/center/detail/subjectDetail?from=notification&id=' + subjectId[0]
                break;
            case 'submitAssessment':
                url = `/system/center/detail/subjectDetail?from=notification&tab=${SHOWTYPE.RocketAssessment}&id=` + subjectId
                break;
            case 'openExercise':
                url = `/system/center/detail/subjectDetail?from=notification&tab=${SHOWTYPE.Exercise}&id=` + subjectId
                break;
            case 'clockInTraining':
                url = '/system/center/detail/subjectDetail?from=notification&id=' + subjectId
                break;
            case 'openSleep':
                url = `/system/center/detail/subjectDetail?from=notification&tab=${SHOWTYPE.Sleep}&id=` + subjectId
                break
            case 'openNutrition':
                url = `/system/center/detail/subjectDetail?from=notification&tab=${SHOWTYPE.Nutrition}&id=` + subjectId
                break
            case 'getCertificate':
                url = '/system/center/menu/account/personalCenter'
                break
            case 'cancelPdt':
                url = `/system/center/detail/subjectDetail?id=${subjectId}`
                break;
        }
        return url
    }

    const onClickItem = (data) => {
        const url = getUrl(data)
        switchToJump(data, url);
    }

    const showSystemNotificationContentItem = (data, index) => {
        const { extraProperties, title, id } = data
        const { Icon, Summary, Desc } = extraProperties
        const subType = Icon || 'System'
        switch (subType) {
            case 'VratIcon':
                return (
                    <span key={id + index + 'VratIcon'}>
                        <span className={styles.contentItemContentTopTitle}>{title}</span>
                        <span>{Desc || Summary}</span>
                    </span>
                )
            case 'System':
                return <span dangerouslySetInnerHTML={{ __html: `<b>${title}</b>` + `${Summary || Desc}` }} className={styles.contentItemContentTopHTMl}></span>
        }
    }

    const onClickSystemItem = (data) => {
        const { extraProperties } = data
        const { Icon } = extraProperties
        const subType = Icon || 'System'
        switch (subType) {
            case 'VratIcon':
                const url = '/system/center/menu/analysis/vrat?tab=Indicators'
                switchToJump(data, url)
                break;
            case 'System':
                showReleaseModal(data)
                break
        }
    }

    const getSystemImg = (data) => {
        const { extraProperties } = data
        const { Icon } = extraProperties
        const subType = Icon || 'System'
        switch (subType) {
            case 'VratIcon':
                return vratNotificationUrl
                break;
            case 'System':
                return soundUrl
                break
        }
    }

    if (activeKey == 'User') {
        return (
            Object.keys(datasource).map((item, index) => {
                return (
                    <div key={index}>
                        <div className={styles.title}>{dataType(intl)[item]}</div>
                        <div className={styles.content}>
                            {
                                datasource[item].map((dataItem, dataIndex) => {
                                    const { extraProperties } = dataItem
                                    const { icon } = extraProperties;
                                    return (
                                        <div
                                            key={dataItem.id}
                                            className={styles.contentItem}
                                            onClick={() => { onClickItem(dataItem) }}
                                        >
                                            {
                                                isShowHasRead &&
                                                <div className={styles.contentItemBadge}>
                                                    {!(dataItem.isRead) && <Badge color={'#1677FF'} />}
                                                </div>
                                            }
                                            <Fragment>
                                                <div className={styles.contentItemAvatar}>
                                                    {/* <Avatar
                                                        shape="square"
                                                        src={icon || defaultImg}
                                                        style={{ width: '100%', height: '100%' }}
                                                    /> */}
                                                    {getUserImg(extraProperties, { width: '42px', height: '42px' })}
                                                </div>
                                                <div className={styles.contentItemContent}>
                                                    <div className={styles.contentItemContentTop}>
                                                        {
                                                            showNotificationContentItem(dataItem, dataIndex)
                                                        }
                                                    </div>
                                                    {
                                                        showTimeLocation == 'bottom' &&
                                                        <div className={styles.contentItemContentBottom}>
                                                            {
                                                                showCreateTime(item, dataItem.creationTime)
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </Fragment>
                                            <div className={styles.contentItemRight}>
                                                {
                                                    showTimeLocation == 'right' &&
                                                    <div className={styles.contentItemTime}>
                                                        {
                                                            showCreateTime(item, dataItem.creationTime)
                                                        }
                                                    </div>
                                                }
                                                {
                                                    !(dataItem.isRead) &&
                                                    <div className={styles.contentItemCheck}>
                                                        <Tooltip title={intl.formatMessage({ id: 'markAsRead' })} placement='bottom'>
                                                            <CheckOutlined onClick={(e) => { e.stopPropagation(); signToRead(dataItem) }} />
                                                        </Tooltip>
                                                    </div>

                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                )
            })
        )
    } else {
        return (
            Object.keys(datasource).map((item, index) => {
                return (
                    <div key={index}>
                        <div className={styles.title}>{dataType(intl)[item]}</div>
                        <div className={styles.content}>
                            {
                                datasource[item].map((dataItem, dataIndex) => {
                                    const { extraProperties, creationTime, isRead, title, id } = dataItem
                                    const { Summary } = extraProperties || {}
                                    return (
                                        <div
                                            key={dataItem.id}
                                            className={styles.contentItem}
                                            onClick={() => { onClickSystemItem(dataItem) }}
                                        >
                                            {
                                                isShowHasRead &&
                                                <div className={styles.contentItemBadge}>
                                                    {!(isRead) && <Badge color={'#1677FF'} />}
                                                </div>
                                            }
                                            <Fragment>
                                                <div className={styles.contentItemAvatar}>
                                                    <img src={getSystemImg(dataItem)} width='100%' />
                                                </div>
                                                <div className={styles.contentItemContent}>
                                                    <div className={styles.contentItemContentTop}>
                                                        {showSystemNotificationContentItem(dataItem, dataIndex)}
                                                    </div>
                                                    {
                                                        showTimeLocation == 'bottom' &&
                                                        <div className={styles.contentItemContentBottom}>
                                                            {
                                                                showCreateTime(item, creationTime)
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </Fragment>
                                            <div className={styles.contentItemRight}>
                                                {
                                                    showTimeLocation == 'right' &&
                                                    <div className={styles.contentItemTime}>
                                                        {
                                                            showCreateTime(item, dataItem.creationTime)
                                                        }
                                                    </div>
                                                }
                                                {
                                                    !(dataItem.isRead) &&
                                                    <div className={styles.contentItemCheck}>
                                                        <Tooltip title={intl.formatMessage({ id: 'markAsRead' })} placement='bottom'>
                                                            <CheckOutlined onClick={(e) => { e.stopPropagation(); signToRead(dataItem) }} />
                                                        </Tooltip>
                                                    </div>

                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                )
            })
        )
    }

}

export default connect(({ notification }) => ({ notification }))(NotificationContent)