import { Modal, Form, Input, Button } from 'antd'
import { useIntl } from 'umi'
const FormItem = Form.Item

const SendReportModal = ({ visible, onCancel, onOk, loading }) => {

  const intl = useIntl()
  const onSubmit = (values) => {
    onOk(values)
  }

  const onQuit = () => {
    onCancel()
  }

  return (
    <Modal
      maskClosable={false}
      open={visible}
      title={intl.formatMessage({ id: 'sendReport' })}
      okText={loading ? '发送中...' : intl.formatMessage({ id: 'submit' })}
      cancelText={intl.formatMessage({ id: 'cancel' })}
      onCancel={onQuit}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={onSubmit}
      >
        <FormItem
          label={intl.formatMessage({ id: 'email' })}
          name={'Email'}
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'correctEmailAddress' }),
              pattern: /^(\s+|)([a-zA-Z0-9])+(([a-zA-Z0-9])|([._-][a-zA-Z0-9])*)+@([a-zA-Z0-9-])+((\.[a-zA-Z0-9-]{2,3}){1,2})(\s+|)?$/
            },
          ]}
        >
          <Input />
        </FormItem>
        <div>
          <Button onClick={onQuit}>{intl.formatMessage({ id: 'cancel' })}</Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            {intl.formatMessage({ id: 'sure' })}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default SendReportModal
