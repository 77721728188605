import React from 'react'
import { Card, Divider } from 'antd'
import { genderLocale } from 'utils/utils'
import { useIntl } from 'umi'

const GuardianInfoCard = ({ guardianInfo }) => {
    const intl = useIntl()
    const { countryCode, phone, name, gender, email, nickName } = guardianInfo
    return <Card bordered={false} hoverable={false}>
        <p>{`${intl.formatMessage({ id: 'name' })}： ${nickName || name}`}</p>
        <p>{`${intl.formatMessage({ id: 'gender' })}： ${genderLocale(gender, intl)}`}</p>
        <p>{`${intl.formatMessage({ id: 'contactInformation' })}： ${phone.includes('+') ? phone : countryCode + '-' + phone}`}</p>
        {email && <p>{`${intl.formatMessage({ id: 'email' })}： ${email}`}</p>}
    </Card>
}

export default GuardianInfoCard
