import getRequest from 'utils/request'

const getTrainerDatabaseList = '/api/rocketAdmin/course';

const getTrainerDatabaseDetail = '/api/rocketAdmin/course/{id}';

const postCourseToUserApi = '/api/rocketAdmin/course/courseToUser'

const getUserGrantCourseApi = '/api/rocketAdmin/course/userGrant/{userId}'

const postCancelCourseToUserApi = '/api/rocketAdmin/course/cancelCourseToUser'

export async function GetTrainerDatabaseList(data) {
  return getRequest()(getTrainerDatabaseList, {
    method: 'GET',
    params: data,
  })
}

export async function GetTrainerDatabaseDetail(id) {
  return getRequest()(getTrainerDatabaseDetail.replace('{id}', id), {
    method: 'GET'
  })
}

export async function PostCourseToUser(data) {
  return getRequest()(postCourseToUserApi, {
    method: 'POST',
    data,
  })
}

export async function GetUserGrantCourse(data){
  return getRequest()(getUserGrantCourseApi.replace('{userId}', data.userId), {
    method: 'GET',
    params: data,
  })
}

export async function CancelCourseToUser(data){
  return getRequest()(postCancelCourseToUserApi, {
    method: 'POST',
    data,
  })
}