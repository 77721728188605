import { Modal, Form, InputNumber, Button } from 'antd'
import styles from './index.less'
import { IntlManager } from 'utils/helper'
const FormItem = Form.Item

const SortOrderModal = ({
  visible,
  onCancel,
  onOk,
  currentValue
}) => {
  const intl = IntlManager.MyIntl()

  const onSubmit = (values) => {
    onOk(values)
  }

  const onQuit = () => {
    onCancel()
  }

  return (
    <Modal
      title={intl('serialNumberEditing')}
      open={visible}
      destroyOnClose={true}
      maskClosable={false}
      onCancel={onQuit}
      footer={null}
    >
      <Form
        layout="horizontal"
        initialValues={{ sortOrder: currentValue }}
        onFinish={onSubmit}
      >
        <FormItem
          label={'SortOrder'}
          name={'sortOrder'}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber min={0} />
        </FormItem>
        <div className={styles.footer}>
          <Button className={styles.footerLeft} onClick={onQuit}>{intl("cancel")}</Button>
          <Button type="primary" htmlType="submit">
             {intl("submit")}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default SortOrderModal
