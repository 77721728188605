import React from 'react';
import { Modal, Progress } from 'antd';
import { useIntl } from 'umi';
import styles from './index.less';

const DownLoadModal = ({
  visible = false,
  percent = 0,
  duration = 0,
}) => {
  const intl = useIntl()

  return (
    <Modal
      title={null}
      centered
      maskClosable={false}
      closable={false}
      open={visible}
      keyboard={false}
      width={400}
      footer={null}
    >
      <div className={styles.downModal}>
        <div className={styles.progress}>
          <Progress
            type="circle"
            strokeColor={{
              '0%': '#108ee9',
              '100%': '#87d068',
            }}
            percent={percent}
          />
        </div>
        <div className={styles.progressDes}>{intl.formatMessage({ id: 'downloadSeconds' }, { time: duration })}</div>
      </div>
    </Modal>
  );
};

export default DownLoadModal;
