import getRequest from 'utils/request'
import { getUserTenantId } from 'cognitiveleap-core-us/utils/auth'

const getUserList = '/api/rocketAdmin/user/list'
const getSubjectList = '/api/rocketAdmin/user/subjectList'
const getSubjectInfo = '/api/rocketAdmin/user/subjectInfo'

const getCenterUserList = '/api/business/subject/getGuardianList'
const getCenterSubjectList = '/api/business/subject/getList'
const getCenterSubjectSimpleList = '/api/business/subject/simpleList'
const centerGetSubjectInfo = '/api/business/subject/getSubjectInfo'

const prepareSubject = '/api/business/subject/prepareForEdit'
const PostClassListApi = '/api/business/user/list'
const addUserAndSubject = '/api/business/subject/guardianAndSubject'
const sendCode = '/api/business/subject/sendRegisterCode'

const postUserHiddenContent = '/api/rocketAdmin/permission/userHiddenContent'

const postSubjectCheckIn = '/api/business/checkIn/in'
const postSubjectCheckOut = '/api/business/checkIn/out'
const getSubjectCheckInList = '/api/business/checkIn'

const postAddSubject = '/api/business/subject/subject'

const getAdminGetUserDetailInfo = '/api/rocketAdmin/user/info'

const postUser = '/api/business/user'
const getUser = '/api/business/user'
const postUserList = '/api/business/user/list'
const delUser = '/api/business/user'
const delAdminUser = '/api/business/user/byTenant'
const prepareEditUser = '/api/business/user/prepareForEdit'
const putUser = '/api/business/user'
const postSendEmail = '/api/business/user/sendEmailToUser/{userId}'
const freezeUser = '/api/business/user/freezeUser'

const postAssignCoach = '/api/business/userCoach/assign'

const postGuardianInfoApi = '/api/business/subject/getGuardianInfo/{guardianId}'

const checkSubjectApi = '/api/business/subject/check'

const getClassAndMusicApi = '/api/rocketAdmin/planUser/planUser'
const postStartClassApi = '/api/rocketAdmin/planUser/startMusic'
const getFASPlanApi = '/api/rocketAdmin/planUser/lastUserAssessment/{userId}'
const getBeforeStartMusicApi = '/api/rocketAdmin/planUser/beforeStartMusic'

const editOutpatientNumberApi = '/api/business/subject/outpatientNumber'

const getSubjectFromTenantApi = '/api/rocketAdmin/userGrant/subjectTenant/{subjectId}'
const postChangeRoleApi = '/api/business/user/changeUserRole'

const getRecordChildApi = '/api/business/subject/recentList'

const getParentInterviewApi = '/api/rocketAdmin/userQuestionnaire/parentalInterviews'

const getSubjectLinkStaffApi = '/api/business/subject/subjectLinkedStaff/{subjectId}'

const putGuardianAPi = '/api/business/subject/guardian'

const PostCheckPhoneNumberApi = '/api/business/subject/checkPhoneNumber'

const getCurrentTenantId = () => getUserTenantId()

export async function DelAdminUser(data) {
  return getRequest()(delAdminUser, {
    method: 'DELETE',
    params: data,
  })
}

export async function PostCheckPhoneNumber(data) {
  return getRequest()(PostCheckPhoneNumberApi, {
    method: 'POST',
    data,
  })
}

export async function PutGuardian(data) {
  return getRequest()(putGuardianAPi, {
    method: 'PUT',
    data
  })
}

export async function DelUser(data) {
  return getRequest()(delUser, {
    method: 'DELETE',
    params: data,
  })
}

export async function PostUserList(data) {
  return getRequest()(postUserList, {
    method: 'POST',
    data,
  })
}

export async function GetRecordChild() {
  return getRequest()(getRecordChildApi, {
    method: 'GET',
  })
}


export async function PutOutpatientNumber(data) {
  return getRequest()(editOutpatientNumberApi, {
    method: 'PUT',
    data
  })
}

export async function FreezeUser(data) {
  return getRequest()(freezeUser, {
    method: 'POST',
    data,
  })
}


export async function PostSendEmail(id) {
  return getRequest()(postSendEmail.replace('{userId}', id), {
    method: 'POST',
  })
}

export async function PostAssignCoach(data) {
  return getRequest()(postAssignCoach, {
    method: 'POST',
    data,
  })
}

export async function PrepareEditUser(data) {
  return getRequest()(prepareEditUser, {
    method: 'POST',
    data,
  })
}

export async function PostUser(data) {
  return getRequest()(postUser, {
    method: 'POST',
    data,
  })
}

export async function PutUser(data) {
  return getRequest()(putUser, {
    method: 'PUT',
    data,
  })
}

export async function GetUserList(data) {
  return getRequest()(getUserList, {
    method: 'POST',
    data,
  })
}

export async function GetSubjectSimpleList(data, header) {
  return getRequest(header || { 't-select': getCurrentTenantId() })(getCenterSubjectSimpleList, {
    method: 'GET',
    params: data,
  })
}

export async function GetSubjectCheckInList(data) {
  return getRequest()(getSubjectCheckInList, {
    method: 'GET',
    params: data,
  })
}

export async function PostSubjectCheckIn(id) {
  return getRequest()(postSubjectCheckIn, {
    method: 'POST',
    data: { subjectId: id },
  })
}

export async function AddSubject(data) {
  return getRequest()(postAddSubject, {
    method: 'POST',
    data,
  })
}

export async function PutSubject(data) {
  return getRequest()(postAddSubject, {
    method: 'PUT',
    data,
  })
}

export async function PostSubjectCheckOut(id) {
  return getRequest()(postSubjectCheckOut, {
    method: 'POST',
    data: { subjectId: id },
  })
}

export async function PostUserHiddenContent(data) {
  return getRequest()(postUserHiddenContent, {
    method: 'POST',
    data,
  })
}

export async function CreateUserAndSubject(data) {
  return getRequest()(addUserAndSubject, {
    method: 'POST',
    data,
  })
}

export async function SendRegisterCode(data) {
  return getRequest()(sendCode, {
    method: 'POST',
    data,
  })
}

export async function PrepareSubject() {
  return getRequest()(prepareSubject, {
    method: 'POST',
  })
}

export async function AdminGetSubjectInfo(id) {
  return getRequest()(getSubjectInfo, {
    method: 'GET',
    params: { id },
  })
}

export async function CenterGetSubjectInfo(id) {
  return getRequest()(centerGetSubjectInfo, {
    method: 'POST',
    data: { subjectId: id },
  })
}

export async function GetClassAndMusic(data) {
  return getRequest()(getClassAndMusicApi, {
    method: 'GET',
    params: data
  })
}

export async function GetFASPlan(data) {
  return getRequest()(getFASPlanApi.replace('{userId}', data.userId), {
    method: 'GET',
    params: data
  })
}

export async function GetBeforeStartMusic(data) {
  return getRequest()(getBeforeStartMusicApi, {
    method: 'GET',
    params: data
  })
}

export async function PostStartClass(data) {
  return getRequest()(postStartClassApi, {
    method: 'POST',
    data
  })
}

export async function GetSubjectList(data) {
  return getRequest()(getSubjectList, {
    method: 'POST',
    data,
  })
}

export async function GetCenterUserList(data) {
  return getRequest()(getCenterUserList, {
    method: 'POST',
    data,
  })
}

export async function GetCenterSubjectList(data) {
  return getRequest()(getCenterSubjectList, {
    method: 'POST',
    data,
  })
}

export async function AdminGetUserDetailInfo(id) {
  return getRequest()(getAdminGetUserDetailInfo, {
    method: 'GET',
    params: { id },
  })
}

export async function PostGuardianInfo(id) {
  return getRequest()(postGuardianInfoApi.replace('{guardianId}', id), {
    method: 'POST',
    data: {
      guardianId: id
    },
  })
}

export async function CheckSubject(data) {
  return getRequest()(checkSubjectApi, {
    method: 'POST',
    data,
  })
}

export async function GetSubjectFromTenant(data) {
  return getRequest()(getSubjectFromTenantApi.replace('{subjectId}', data.subjectId), {
    method: 'GET'
  })
}

export async function postChangeRole(data) {
  return getRequest()(postChangeRoleApi, {
    method: 'POST',
    data,
  })
}

export async function GetParentInterview(data) {
  return getRequest()(getParentInterviewApi, {
    method: 'POST',
    data,
  })
}

export async function GetSubjectLinkStaff(data) {
  return getRequest()(getSubjectLinkStaffApi.replace("{subjectId}", data.subjectId), {
    method: 'GET'
  })
}

// 分配训练师
export async function PostClassList(data) {
  return getRequest()(PostClassListApi, {
      method: 'POST',
      data,
  })
}
