import getRequest from 'utils/request'

const getAndAddSchoolListApi = '/api/rocketAdmin/school'

const updateAndGetDeleteSchoolInfoApi = '/api/rocketAdmin/school/{id}'

const grantSchoolApi = '/api/rocketAdmin/school/grant'

export async function GetSchoolList(data) {
  return getRequest()(getAndAddSchoolListApi, {
    method: 'GET',
    params: data
  })
}


export async function AddSchool(data) {
  return getRequest()(getAndAddSchoolListApi, {
    method: 'POST',
    data
  })
}

export async function updateSchoolInfo(data) {
  return getRequest()(updateAndGetDeleteSchoolInfoApi.replace('{id}', data.id), {
    method: 'PUT',
    data,
  })
}

export async function deleteSchool(id) {
  return getRequest()(updateAndGetDeleteSchoolInfoApi.replace('{id}', id), {
    method: 'DELETE',
    params: { id },
  })
}

export async function grantSchool(data) {
  return getRequest()(grantSchoolApi, {
    method: 'POST',
    data
  })
}

export async function getGrantSchool(data) {
  return getRequest()(grantSchoolApi, {
    method: 'GET',
    params: data
  })
}
