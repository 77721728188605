import getRequest from 'utils/request';

const PostCenterAllDevicesApi = '/api/vrclassroom/TestingCenter/AllDevice';
const PostAdminAllDevicesApi = '/api/vrclassroom/Admin/Device/AllDevice';
const PostExportExcelApi = '/api/vrclassroom/Admin/Device/ExportExcel';
const PostPassApi = '/api/vrclassroom/Admin/Device/Pass';
const PostRejectApi = '/api/vrclassroom/Admin/Device/Reject';
const PostCancelApi = '/api/vrclassroom/Admin/Device/Cancel';
const GetDeviceDetailApi = '/api/vrclassroom/Admin/Device/Detail';
const getDeviceDetailUseageAPi = '/api/vrclassroom/Admin/Device/GetTestRecordList';

export async function PostCenterAllDevices(data) {
  return getRequest()(PostCenterAllDevicesApi, {
    method: 'POST',
    data,
  });
}

export async function PostAdminAllDevices(data) {
  return getRequest()(PostAdminAllDevicesApi, {
    method: 'POST',
    data,
  });
}

export async function PostExportExcel(data) {
  return getRequest()(PostExportExcelApi, {
    method: 'POST',
    data,
  });
}

export async function PostPass(data) {
  return getRequest()(PostPassApi, {
    method: 'POST',
    params: data,
  });
}

export async function PostReject(data) {
  return getRequest()(PostRejectApi, {
    method: 'POST',
    params: data,
  });
}

export async function PostCancel(data) {
  return getRequest()(PostCancelApi, {
    method: 'POST',
    params: data,
  });
}

export async function GetDeviceDetail(data) {
  return getRequest()(GetDeviceDetailApi, {
    method: 'GET',
    params: data,
  });
}

export async function GetDeviceUseageDetail(data) {
  return getRequest()(getDeviceDetailUseageAPi, {
    method: 'POST',
    data,
  })
}