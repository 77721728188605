import getRequest from 'utils/request';

const getUserCredentialQuestionnairesApi =
  '/api/rocketAdmin/trainerTraining/userCredentialQuestionnairesList';
const postOralQuestionnaireApi =
  '/api/rocketAdmin/trainerTraining/oralQuestionnaire';
const postPracticalQuestionnaireApi =
  '/api/rocketAdmin/trainerTraining/practicalQuestionnaire';
const getSupervisorSSummaryListApi =
  '/api/rocketAdmin/userQuestionnaire/supervisorSSummaryList';
const getTrainingListApi =
  '/api/rocketAdmin/trainerTraining/trainingExperience';
const getStaffUserCoursesApi =
  '/api/rocketAdmin/trainerTraining/staffUsedCourse/{userId}';

export async function getStaffUserCourses(data) {
  return getRequest()(getStaffUserCoursesApi.replace('{userId}', data.id), {
    method: 'GET',
    params: data,
  });
}

export async function getTrainingList(data) {
  return getRequest()(getTrainingListApi, {
    method: 'GET',
    params: data,
  });
}

export async function getSupervisorSSummaryList(data) {
  return getRequest()(getSupervisorSSummaryListApi, {
    method: 'GET',
    params: data,
  });
}

export async function getUserCredentialQuestionnaires(data) {
  return getRequest()(getUserCredentialQuestionnairesApi, {
    method: 'GET',
    params: data,
  });
}

export async function postOralQuestionnaire(data) {
  return getRequest()(postOralQuestionnaireApi, {
    method: 'POST',
    data,
  });
}

export async function postPracticalQuestionnaire(data) {
  return getRequest()(postPracticalQuestionnaireApi, {
    method: 'POST',
    data,
  });
}
