import { history } from 'umi';
import { GetSubjectList, GetCenterSubjectList, PostSubjectCheckIn, PostSubjectCheckOut, PostAssignCoach, PrepareSubject } from 'services/userManager'
import { AddVratTest } from 'services/vrat'
import { GetCaseCode } from 'services/vrat/testCenter';
import { GetLocationList } from 'services/location'
import { addOptionsToProperties } from 'cognitiveleap-core-us/utils/utils'
import { message, Modal } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons';
import { IntlManager } from 'utils/helper'
import copy from 'copy-to-clipboard';

const listToOptions = (list, show) => {
  return list.map(item => {
    return { Value: item[show] || '', Id: item.id.toString() }
  })
}

export const gradeList = () => {
  const formatMessage = IntlManager.intl.formatMessage
  return [
    {
      key: 'Before School',
      value: formatMessage({ id: 'beforeSchool' }),
    },
    {
      key: '1',
      value: '1',
    },
    {
      key: '2',
      value: '2',
    },
    {
      key: '3',
      value: '3',
    },
    {
      key: '4',
      value: '4',
    },
    {
      key: '5',
      value: '5',
    },
    {
      key: '6',
      value: '6',
    },
    {
      key: '7',
      value: '7',
    },
    {
      key: '8',
      value: '8',
    },
    {
      key: '9',
      value: '9',
    },
    {
      key: '10',
      value: '10',
    },
    {
      key: '11',
      value: '11',
    },
    {
      key: '12',
      value: '12',
    },
    {
      key: '13',
      value: formatMessage({ id: 'other' }),
    },
    {
      key: 'Not Attending School',
      value: formatMessage({ id: 'other' }),
    },
  ]
}

const contentMenu = () => {
  const formatMessage = IntlManager.intl.formatMessage
  return {
    Properties: [
      // {
      //   EditorType: 'Dropdown',
      //   ShowTitle: formatMessage({ id: 'location' }),
      //   FormKey: 'testLocationId',
      //   AdditionalData: null,
      //   Value: null,
      //   Setting: {
      //     DropdownOptions: [],
      //     Required: true,
      //     Actions: [
      //       {
      //         key: 'add',
      //         showText: <PlusCircleOutlined style={{ fontSize: 20, marginTop: 5 }} />,
      //         action: (record, clear) => {
      //           history.push('/system/center/menu/manage/location')
      //         }
      //       }
      //     ]
      //   },
      //   Description: null,
      // },
      {
        EditorType: 'Dropdown',
        ShowTitle: formatMessage({ id: 'grade' }),
        FormKey: 'grade',
        AdditionalData: null,
        Value: null,
        Setting: {
          DropdownOptions: gradeList().map(item => {
            return { Value: item.value, Id: item.key }
          }),
          Required: true,
        },
        Description: null,
      },
    ],
  }
}

export default {
  namespace: 'subjectList',
  state: {
    list: [],
    pagination: {
      current: 1,
      pageSize: 12,
      total: 0,
    },
    content: null,
    modalVisible: false,
    selectSubjectId: null,
    searchField: '',
    outpatientNumber: '',
    assignModalVisible: false,
    coaches: [],
    checkInModalVisible: false,
    selectedSubjectInfo: null,
    filters: {},
    school: '',
    coachName: '',
    guardianName: '',
    popoverVisible: false,
    currentRecord: {},
    checkSubjectInfo: {},
    subjectInfoCheckInModalVisible: false,
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *loadPage(_, { call, put, take, select }) {
      const { pagination, searchField, filters, school, coachName, guardianName, outpatientNumber } = yield select(state => state.subjectList)
      let query = { skipCount: 0, maxResultCount: 10 }
      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      const { isCheckIn, status } = filters

      query = {
        ...query,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
        filter: searchField,
        outpatientNumber,
        isCheckIn: isCheckIn !== undefined && isCheckIn !== null ? isCheckIn[0] : null,
        status: status !== undefined && status !== null ? status[0] : null,
        // school,
        guardianName,
        coachName
      }
      const res = yield call(GetCenterSubjectList, query)
      if (res.response.ok) {
        const { items, totalCount } = res.data
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }
        yield put({
          type: 'updateState',
          payload: { pagination: newPagination, list: items },
        })
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination: currentPagination, } = yield select(state => state.subjectList)
      const { pagination, filters = {} } = data
      yield put({
        type: 'updateState',
        payload: {
          pagination: { ...currentPagination, ...pagination, },
          filters,
        },
      })
      yield put({ type: 'loadPage' })
    },

    *filterFetch({ payload }, { call, put, select, take }) {
      const defaultPagination = {
        current: 1,
        pageSize: 12,
        total: 0,
      }
      yield put({ type: 'updateState', payload: {
        ...payload,
        pagination: defaultPagination
      } })
      yield put({ type: 'loadPage' })
    },

    *checkIn({ payload: id }, { call, put, take, select }) {
      const formatMessage = IntlManager.intl.formatMessage
      const res = yield call(PostSubjectCheckIn, id)
      if (res.response.ok) {
        yield put({ type: 'updateState', payload: { checkInModalVisible: false } })
        message.success(formatMessage({ id: 'checkInSuccess' }))
        yield put({ type: 'loadPage' })
      } else {
        message.error(formatMessage({ id: 'checkInFailed' }))
      }
    },

    *assignCoach({ payload: id }, { call, put, take, select }) {
      const res = yield call(PrepareSubject)
      if (res.response.ok) {
        const { avaliableGuardianRoles, coaches } = res.data
        yield put({
          type: 'updateState',
          payload: { coaches, assignModalVisible: true, selectSubjectId: id },
        })
      }
    },

    *addTest({ payload: id }, { call, put, take, select }) {
      const query = { maxResultCount: 500, Enabled: true }
      const res = yield call(GetLocationList, query)
      if (res.response.ok) {
        const { items } = res.data
        const locationOptions = listToOptions(items, 'name')
        const emptyContent = addOptionsToProperties(
          contentMenu(),
          ['testLocationId'],
          locationOptions
        )
        yield put({
          type: 'updateState',
          payload: {
            content: emptyContent,
            modalVisible: true,
            selectSubjectId: id,
          },
        })
      } else {
        const { data: { error } } = res;
        message.warning(error.message);
      }
    },

    *submitAssignCoach({ payload: data }, { call, put, take, select }) {
      const { selectSubjectId } = yield select(state => state.subjectList)
      const { coachUserIds } = data
      const res = yield call(PostAssignCoach, { coachUserIds, userId: selectSubjectId })
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            assignModalVisible: false,
            coaches: [],
          },
        })
        yield put({ type: 'loadPage' })
      }

    },

    *checkSubject({ payload }, { call, put, take, select }) {
      yield put({
        type: 'updateState',
        payload: {
          checkSubjectInfo: payload,
          addSubjectModalVisible: false,
          subjectInfoCheckInModalVisible: true,
          addSubjectModalVisible: true
        },
      })
    },

    *submitAddSubject({ payload }, { call, put, take, select }) {
      const formatMessage = IntlManager.intl.formatMessage
      const { outpatientNumber, notes, gender, birthDay } = payload || {};
      const params = {
        name: outpatientNumber,
        registerType: "Username",
        subjects: [
          {
            birthDay,
            gender,
            outpatientNumber,
            remark: notes,
            name: outpatientNumber,
          }
        ]
      }
      const res = yield call(CreateUserAndSubject, params);

      if (res.response.ok) {
        message.success(formatMessage({ id: 'success' }))
        yield put({ type: 'loadPage' })

        yield put({
          type: 'updateState',
          payload: {
            subjectInfoCheckInModalVisible: false,
            addSubjectModalVisible: false
          },
        })
      } else {
        const { data: { error } } = res;
        message.warning(error.message);

        // 失败返回第一个信息填写弹窗
        let emptyContent = addSubjectContentMenu();

        emptyContent.Properties[0].Value = outpatientNumber
        emptyContent.Properties[1].Value = birthDay
        emptyContent.Properties[2].Value = gender
        emptyContent.Properties[3].Value = notes

        yield put({
          type: 'updateState',
          payload: {
            subjectInfoCheckInModalVisible: false,
            addSubjectModalVisible: true,
            addSubjectcontent: emptyContent,
          },
        })
      }
    },

    *submitAddTest({ payload: { id, intl } }, { call, put, take, select }) {
      const res = yield call(AddVratTest, id, {})
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            modalVisible: false,
          },
        })
        // history.push('/system/center/menu/statistic/vratList')
        // message.success('添加成功')
        const { Id } = res.data;
        const resTwo = yield call(GetCaseCode, { testRequestId: Id })
        if (resTwo.response.ok) {
          const { Code } = resTwo.data;
          Modal.info({
            title: (
              <div>
                {intl.formatMessage({ id: 'testCode' })}：{Code}
              </div>
            ),
            okText: intl.formatMessage({ id: 'copy' }),
            icon: <CheckCircleOutlined style={{ color: '#52C41A' }} />,
            content: null,
            onOk() {
              if (copy(Code)) {
                message.success(intl.formatMessage({ id: 'success' }));
                history.replace('/system/center/menu/statistic/vratList');
              } else {
                message.error(intl.formatMessage({ id: 'failure' }));
              }
            },
          });
        } else {
          const { error } = resTwo.data;
          message.error(error.message);
        }
      } else {
        const { Error } = res.data;
        message.error(Error.Message);
      }
    },

    *clearAllFilterAndSorter(_, { call, put, select }) {
      const defaultPagination = {
        current: 1,
        pageSize: 12,
        total: 0,
      }
      const payload = { pagination: defaultPagination, searchField: '', outpatientNumber: '', filters: {}, school: '', coachName: '', guardianName: '', isSeach: false, }
      yield put({ type: 'updateState', payload: payload })
      yield put({ type: 'loadPage' })
    },
  },
  subscriptions: {
    // setupHistory({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/center/menu/user/subject') {
    //       dispatch({ type: 'loadPage' })
    //     }
    //   })
    // },
  },
}
