import { UserManager, WebStorageStateStore } from 'oidc-client'
import getRequest from './request'
import { getDvaApp } from 'umi'
const RequestUrl = '/api/business/accountUser/jsConfig'

//window.location.host
let userMangerConfig = {
  ClientId: 'business-client',
  RedirectUrl: '/callback',
  PostLogoutRedirectUrl: '',
  //response_type: 'id_token token',
  //scope: 'openid profile vrclassroom',
}

const userManager = {
  userServiceUrl: null,
  manager: null,
  init: async () => {
    const userServiceConfig = await getRequest()(RequestUrl, {
      method: 'GET',
      params: userMangerConfig,
    })
    const { response, data } = userServiceConfig
    if (response.ok) {
    const { authority, clientId, postLogoutRedirectUrl, redirectUrl, responseType, scope } = data
    // {
    //   "authority": process.env.authority,
    //   "clientId": "business-client",
    //   "postLogoutRedirectUrl": window.location.origin,
    //   "redirectUrl": window.location.origin + '/callback',
    //   "responseType": "id_token token",
    //   "scope": "openid profile businessapi vrclassroom rocketapi userserviceapi offline_access realtimeapi",
    // }
    var mgr = new UserManager({
      authority,
      client_id: clientId,
      redirect_uri: redirectUrl,
      post_logout_redirect_uri: postLogoutRedirectUrl,
      response_type: responseType,
      scope: scope,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      automaticSilentRenew: true,
    })
    // mgr.events.addUserSignedOut(() => {
    //   getDvaApp()._store.dispatch({
    //     type: 'user/simpleLogout',
    //   })
    // })
    userManager.userServiceUrl = authority
    userManager.manager = mgr
    return mgr
    }
  },
}

export default userManager
