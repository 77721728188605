import React from 'react';
import style from './index.less';
import { DatePicker, Tag } from 'antd'
import { useIntl } from 'umi'
import { dateConfig, tagsData } from '../config';
const { CheckableTag } = Tag;

const AnalysisChoiceDate = ({  
    currentTag,
    dateMonthPickerData,
    dateYearPickerData,
    datePickerIsOpen,
    changeTag,
    handleOpenChange,
    handlePanelChange 
}) => {
    const intl = useIntl()
    return (
        <div className={style.trendAnalysisChoice}>
            <div className={style.trendAnalysisChoiceItem}>
                <span style={{color: '#8C8C8C'}}>{intl.formatMessage({ id: 'Period' })}</span>
                {
                    tagsData(intl).map(tag => (
                        <CheckableTag
                            key={tag.key}
                            checked={[currentTag].indexOf(tag.key) > -1}
                            onChange={checked => changeTag(tag, checked)}
                        >
                            {tag.title}
                        </CheckableTag>
                    ))
                }
            </div>
            <div>
                { dateConfig(intl)[currentTag].choice }
                <DatePicker 
                    value={ 
                        {
                            "Year": dateYearPickerData,
                            "Month": dateMonthPickerData
                        }[currentTag]
                        }
                    mode = {dateConfig(intl)[currentTag].small}
                    format={dateConfig(intl)[currentTag].format} 
                    placeholder={dateConfig(intl)[currentTag].placeholder}
                    open={datePickerIsOpen}
                    onOpenChange={handleOpenChange}
                    onPanelChange={handlePanelChange}
                    allowClear={false}
                />
            </div>
        </div>
    )
}

export default AnalysisChoiceDate;
