import getRequest from 'utils/request'

const newTenantContractApi = '/api/rocketAdmin/tenantContract'
const getContractListApi = '/api/rocketAdmin/tenantContract'
const stopContractApi = '/api/rocketAdmin/tenantContract/{id}/stop'
const deleteContractApi = '/api/rocketAdmin/tenantContract/{id}'
const postRechargeApi = '/api/rocketAdmin/tenantContract/recharge'
const postSendMessageApi = '/api/rocketAdmin/tenantContract/sendMessage'
const postCheckCodeApi = '/api/rocketAdmin/tenantContract/checkCode'
const editTenantContractApi = '/api/rocketAdmin/tenantContract/{id}'
const getSubDataByIdApi = '/api/rocketAdmin/tenantContract/{id}'
const editEvaluationApi = '/api/rocketAdmin/tenantContract/resource'
const getAssessmentResourceApi = '/api/rocketAdmin/tenantContract/prepareForAddAssessmentResource'
const postAssessmentResourceApi = '/api/rocketAdmin/tenantContract/assessmentResource'
const deleteAssessmentResourceAPI = '/api/rocketAdmin/tenantContract/resource'
const getHfsOrderListApi = '/api/rocketAdmin/hfsOrder/orderList'
const getCourseResourceApi = '/api/rocketAdmin/tenantContract/prepareForAddCourseResource'
const postCourseResourceApi = '/api/rocketAdmin/tenantContract/courseResource'
const getTrainResResourceApi = '/api/rocketAdmin/tenantContract/prepareForAddPlanResource'
const postTrainingPlanV2Api = '/api/rocketAdmin/tenantContract/prepareForAddPlanV2Resource'
const getPlanResourceApi = '/api/rocketAdmin/tenantContract/{id}/planV2Resource'
const postPlanV2ResourceApi = '/api/rocketAdmin/tenantContract/planV2Resource'
const postTrainResourceApi = '/api/rocketAdmin/tenantContract/planResource'
const postDownLoadExcelApi = '/api/rocketAdmin/HfsOrder/excelDownload'
const putRemarkApi = '/api/vrclassroom/testResource/testResourceRemark'
const postStartHfsApi = '/api/rocketAdmin/tenantContract/{id}/enable'
const PutHfsContractApi = '/api/rocketAdmin/tenantContract/contractRechargeRecord'

const xAuthenticate = () => localStorage.getItem('x-authenticate')

export async function NewTenantContract(data) {
  return getRequest({ 'x-authenticate': xAuthenticate() })(newTenantContractApi, {
    method: 'POST',
    data
  })
}

export async function PutHfsContract(data) {
  return getRequest({ 'x-authenticate': xAuthenticate() })(PutHfsContractApi, {
    method: 'PUT',
    data
  })
}

export async function EditTenantContract(data) {
  return getRequest({ 'x-authenticate': xAuthenticate() })(editTenantContractApi.replace('{id}', data.id), {
    method: 'PUT',
    data
  })
}

export async function PutRemark(data) {
  return getRequest()(putRemarkApi, {
    method: 'PUT',
    data
  })
}

export async function GetPlanResource(data) {
  return getRequest({ 'x-authenticate': xAuthenticate() })(getPlanResourceApi.replace('{id}', data.id), {
    method: 'GET',
    params: data,
  })
}

export async function GetContractList(data) {
  return getRequest({ 'x-authenticate': xAuthenticate() })(getContractListApi, {
    method: 'GET',
    params: data,
  })
}

export async function StopContract(data) {
  return getRequest({ 'x-authenticate': xAuthenticate() })(stopContractApi.replace('{id}', data.id), {
    method: 'POST',
    data
  })
}

export async function DeleteContract(data) {
  return getRequest({ 'x-authenticate': xAuthenticate() })(deleteContractApi.replace('{id}', data.id), {
    method: 'DELETE',
    params: data,
  })
}

export async function PostRecharge(data) {
  return getRequest({ 'x-authenticate': xAuthenticate() })(postRechargeApi, {
    method: 'POST',
    data
  })
}

export async function GetSubDataById(data) {
  return getRequest({ 'x-authenticate': xAuthenticate() })(getSubDataByIdApi.replace('{id}', data.id), {
    method: 'GET',
    params: data,
  })
}

export async function EditEvaluation(data) {
  return getRequest({ 'x-authenticate': xAuthenticate() })(editEvaluationApi, {
    method: 'PUT',
    data
  })
}

export async function GetAssessmentResource(data) {
  return getRequest({ 'x-authenticate': xAuthenticate() })(getAssessmentResourceApi, {
    method: 'POST',
    data
  })
}

export async function GetCourseResource(data) {
  return getRequest({ 'x-authenticate': xAuthenticate() })(getCourseResourceApi, {
    method: 'POST',
    data
  })
}

export async function PostAssessmentResource(data) {
  return getRequest({ 'x-authenticate': xAuthenticate() })(postAssessmentResourceApi, {
    method: 'POST',
    data
  })
}

export async function PostCourseResource(data) {
  return getRequest({ 'x-authenticate': xAuthenticate() })(postCourseResourceApi, {
    method: 'POST',
    data
  })
}

export async function PutPlanV2Resource(data) {
  return getRequest({ 'x-authenticate': xAuthenticate() })(postPlanV2ResourceApi, {
    method: 'PUT',
    data
  })
}

export async function PostPlanV2Resource(data) {
  return getRequest({ 'x-authenticate': xAuthenticate() })(postPlanV2ResourceApi, {
    method: 'POST',
    data
  })
}

export async function DeleteAssessmentResource(data) {
  return getRequest({ 'x-authenticate': xAuthenticate() })(deleteAssessmentResourceAPI, {
    method: 'DELETE',
    params: data,
  })
}

export async function GetTrainResResource(data) {
  return getRequest({ 'x-authenticate': xAuthenticate() })(getTrainResResourceApi, {
    method: 'POST',
    data
  })
}

export async function PostTrainingPlanV2Pre(data) {
  return getRequest({ 'x-authenticate': xAuthenticate() })(postTrainingPlanV2Api, {
    method: 'POST',
    data
  })
}

export async function PostTrainResourceApi(data) {
  return getRequest({ 'x-authenticate': xAuthenticate() })(postTrainResourceApi, {
    method: 'POST',
    data
  })
}

export async function PostStartHfs(data) {
  return getRequest({ 'x-authenticate': xAuthenticate() })(postStartHfsApi.replace('{id}', data.id), {
    method: 'POST',
    data
  })
}

export async function PostSendMessage() {
  return getRequest()(postSendMessageApi, {
    method: 'POST'
  })
}

export async function PostCheckCode(data) {
  return getRequest()(postCheckCodeApi, {
    method: 'POST',
    data
  })
}

export async function GetHfsOrderList(data) {
  return getRequest()(getHfsOrderListApi, {
    method: 'GET',
    params: data,
  })
}

export async function PostDownLoadExcel(data) {
  return getRequest()(postDownLoadExcelApi, {
    reponseType: 'blob',
    method: 'GET',
    params: data
  })
}