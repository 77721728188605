import getRequest from 'utils/request'
import { parseRes } from 'utils/utils'
const AddTest = '/api/vrclassroom/TestingCenter/TestRequest'
const TestRequestList = '/api/vrclassroom/testRequest/find'
const getSubjectVratList = '/api/rocketAdmin/UserTestRequest/Find'

const createOrGetTestResource = '/api/vrclassroom/testResource'
const getTestResourceAmount = '/api/vrclassroom/TestingCenter/TestResourceAmount'
const forbidVratAmount = '/api/vrclassroom/testResource/forbid/{resourceId}'
const GetEnoughVratNumApi = '/api/vrclassroom/testRequest/tenantTestResourcesEnough'
const GetCheckGuardianApi = '/api/business/subject/checkGuardian'
const regenerationReportApi = '/api/vrclassroom/TestingCenter/GenerateReport'
const GetMonthChildrenApi = '/api/vrclassroom/testResource/monthlyChildren'
const getReport = '/api/vrclassroom/testRequest/vratDeductAndGetReport/{caseId}'
const xAuthenticate = () => localStorage.getItem('x-authenticate-vrat');

export async function GetCheckGuardian(data) {
  return getRequest()(GetCheckGuardianApi, {
    method: 'GET',
    params: data,
  })
}

export async function GetEnoughVratNum(data) {
  return getRequest()(GetEnoughVratNumApi, {
    method: 'GET',
    params: data,
  })
}

export async function AddVratTest(subjectId, data) {
  return getRequest()(AddTest, {
    method: 'POST',
    params: { subjectId },
    data,
  })
}

export async function regenerationReport(data) {
  return getRequest()(regenerationReportApi, {
    method: 'POST',
    data,
  })
}

export async function UpdateTestRequest(queryData, bodyData) {
  return getRequest()(AddTest, {
    method: 'PUT',
    params: queryData,
    data: bodyData,
  })
}

export async function GetReport (data) {
  return getRequest()(getReport.replace('{caseId}', data.caseId), {
    method: 'GET',
    data
  })
}

export async function GetTestRequestList(data) {
  return getRequest()(TestRequestList, {
    method: 'POST',
    data,
  }).then(parseRes)
}

export async function GetSubjectVratList(data) {
  return getRequest()(getSubjectVratList, {
    method: 'GET',
    params: data,
  })
}

export async function PostTestResource(data) {
  return getRequest()(createOrGetTestResource, {
    method: 'POST',
    data,
  })
}
export async function GetMonthChildren(data) {
  return getRequest()(GetMonthChildrenApi, {
    method: 'GET',
    params: data,
  })
}

export async function GetTestResource(data) {
  return getRequest()(createOrGetTestResource, {
    method: 'GET',
    params: data,
  })
}

export async function GetTestResourceAmount(data) {
  return getRequest()(getTestResourceAmount, {
    method: 'GET',
    params: data,
  })
}

export async function ForbidVratAmount(data) {
  return getRequest()(forbidVratAmount.replace('{resourceId}', data.resourceId), {
    method: 'POST',
    data
  })
}