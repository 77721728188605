import { GetTestRequestList } from 'services/vrat'

export default {
  namespace: 'subjectVrat',

  state: {
    list: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },

  effects: {

    *loadList({ payload }, { call, put, take, select }) {
      const { subjectInfo } = yield select(state => state.subjectDetail)
      const { pagination, } = yield select(state => state.subjectVrat)
      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      const query = {
        subjectId: subjectInfo.id,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
      }
      const res = yield call(GetTestRequestList, query)
      if (res.response.ok) {
        const { items, totalCount } = res.data
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }
        yield put({
          type: 'updateState',
          payload: { pagination: newPagination, list: items, },
        })
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination: originPage } = yield select(state => state.subjectVrat)
      const { pagination } = data
      yield put({ type: 'updateState', payload: { pagination: { ...originPage, ...pagination } } })
      yield put({ type: 'loadList' })
    },

  },

  subscriptions: {
    setup({ dispatch, history }) {
      // Warning: [app.unmodel] subscription should return unlistener function, check these subscriptions setup
      return ()=>{}
    },
  },
}
