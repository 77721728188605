import ReactEcharts from 'echarts-for-react'
import { useIntl } from 'umi'

const LineWithPointsChart = ({ dataStruct }) => {
  const { Behaviour, Motion, type = 'person' } = dataStruct

  const intl = useIntl()

  let color
  let series
  let style
  if (type === 'person' || type === 'medicalChn') {
    // color = ['#3DBD7D', '#FFCE3D', '#F36E65', '#36abd3']
    // series = [
    //   {
    //     type: 'scatter',
    //     data: [],
    //   },
    //   {
    //     type: 'scatter',
    //     data: [],
    //   },
    //   {
    //     type: 'scatter',
    //     data: [],
    //   },
    //   {
    //     type: 'line',
    //     yAxisIndex: 1,
    //     showSymbol: false,
    //     hoverAnimation: false,
    //     data: [],
    //   },
    // ]
    color = ['#3DBD7D', '#FFCE3D', '#F36E65', '#36abd3']
    series = [
      {
        name: intl.formatMessage({ id: 'correct' }),
        type: 'scatter',
        data: [],
      },
      {
        name: intl.formatMessage({ id: 'miss' }),
        type: 'scatter',
        data: [],
      },
      {
        name: intl.formatMessage({ id: 'wrong' }),
        type: 'scatter',
        data: [],
      },
      {
        name: intl.formatMessage({ id: 'headMovement' }),
        type: 'line',
        yAxisIndex: 1,
        showSymbol: false,
        hoverAnimation: false,
        data: [],
      },
    ]
    style = { height: '400px', width: '800px', margin: 'auto', marginTop: -55 }
  } else if (type === 'medical') {
    color = ['#000000', '#000000', '#000000', '#7a7a7a']
    series = [
      {
        name: intl.formatMessage({ id: 'correct' }),
        type: 'scatter',
        data: [],
        symbol: "rect"
      },
      {
        name: intl.formatMessage({ id: 'miss' }),
        type: 'scatter',
        data: [],
        symbol: "path://M3,0L6,6L0,6Z"
      },
      {
        name: intl.formatMessage({ id: 'wrong' }),
        type: 'scatter',
        data: [],
        symbol: "path://M3,0C4.656854152679443,0,6,1.343145728111267,6,3C6,4.656854152679443,4.656854152679443,6,3,6C1.343145728111267,6,0,4.656854152679443,0,3C0,1.343145728111267,1.343145728111267,0,3,0ZM3,1C1.8954304456710815,1,1,1.8954304456710815,1,3C1,4.104569435119629,1.8954304456710815,5,3,5C4.104569435119629,5,5,4.104569435119629,5,3C5,1.8954304456710815,4.104569435119629,1,3,1Z"
      },
      {
        name: intl.formatMessage({ id: 'headMovement' }),
        type: 'line',
        yAxisIndex: 1,
        showSymbol: false,
        hoverAnimation: false,
        data: [],
      },
    ]
    style = { height: '350px', width: '600px', margin: 'auto', marginTop: -40 }
  } else {
    color = ['#3DBD7D', '#FFCE3D', '#F36E65', '#36abd3']
    series = [
      {
        name: intl.formatMessage({ id: 'correct' }),
        type: 'scatter',
        data: [],
      },
      {
        name: intl.formatMessage({ id: 'miss' }),
        type: 'scatter',
        data: [],
      },
      {
        name: intl.formatMessage({ id: 'wrong' }),
        type: 'scatter',
        data: [],
      },
      {
        name: intl.formatMessage({ id: 'headMovement' }),
        type: 'line',
        yAxisIndex: 1,
        showSymbol: false,
        hoverAnimation: false,
        data: [],
      },
    ]
    style = { height: '350px', width: '600px', margin: 'auto', marginTop: -40 }
  }

  const option = {
    color,

    xAxis: {
      name: intl.formatMessage({ id: 'report.realTime.chart.xAxis' }),
      type: 'value',
      nameLocation: 'center',
      nameGap: 30,
      boundaryGap: false,
      axisLine: { onZero: false },
      min: 0,
      max: 13,
      splitNumber: 13,
    },

    yAxis: [
      {
        name: intl.formatMessage({ id: 'report.realTime.chart.yAxisl' }),
        nameLocation: 'center',
        min: 0,
        max: 1600,
        interval: 400,
        nameGap: 40,
        position: 'left',
        type: 'value',
      },
      {
        name: intl.formatMessage({ id: 'report.realTime.chart.yAxisr' }),
        nameLocation: 'center',
        nameGap: 25,
        type: 'value',
        position: 'right',
        min: 0,
        max: 8,
        interval: 2,
        nameRotate: 270,
      },
    ],

    legend: type === 'medical' ? [
      {
        itemWidth: 10,
        itemHeight: 10,
        left: 100,
        bottom: 0,
        data: [{
          name: intl.formatMessage({ id: 'correct' }),
          icon: 'rect'
        }]
      },
      {
        itemWidth: 10,
        itemHeight: 10,
        left: 180,
        bottom: 0,
        data: [{
          name: intl.formatMessage({ id: 'miss' }),
        }]
      },
      {
        itemWidth: 10,
        itemHeight: 10,
        left: 240,
        bottom: 0,
        data: [{
          name: intl.formatMessage({ id: 'wrong' }),
          icon: "path://M3,0C4.656854152679443,0,6,1.343145728111267,6,3C6,4.656854152679443,4.656854152679443,6,3,6C1.343145728111267,6,0,4.656854152679443,0,3C0,1.343145728111267,1.343145728111267,0,3,0ZM3,1C1.8954304456710815,1,1,1.8954304456710815,1,3C1,4.104569435119629,1.8954304456710815,5,3,5C4.104569435119629,5,5,4.104569435119629,5,3C5,1.8954304456710815,4.104569435119629,1,3,1Z",
        }]
      },
      {
        itemWidth: 45,
        itemHeight: 5,
        left: 310,
        bottom: 0,
        data: [{
          name: intl.formatMessage({ id: 'headMovement' }),
          icon: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAAGCAYAAACMyr1NAAAAAXNSR0IArs4c6QAAACVJREFUOE9jZBhmgHGY+YeBsbW1tX44eWr4eWg4xQ7IL8MuDwEAyn4EIxzSTeAAAAAASUVORK5CYII="
        }]
      },
    ] : {
      top: 'bottom'
    },

    series
  }

  Behaviour.forEach(item => {
    switch (item.Type) {
      case 2:
        option.series[0].data.push([item.Minute, item.ReactionTime])
        break
      case 1:
        option.series[1].data.push([item.Minute, 0])
        break
      case 0:
        option.series[2].data.push([item.Minute, item.ReactionTime])
        break
      default:
        option.series[0].data.push([item.Minute, item.ReactionTime])
        break
    }
  })

  const showMotionIndex = Motion.map(item => {
    return [item.Minute, item.MotionIndex]
  })

  option.series[3].data = showMotionIndex
  return (
    <ReactEcharts
      option={option}
      style={style}
    />
  )
}

export default LineWithPointsChart
