import React from 'react'
import { Card } from 'antd'
import moment from 'moment'
import { genderLocale, handLocale } from 'utils/utils'
import { formatterSchoolNature, formatterGradeName } from 'cognitiveleap-core-us/utils/utils'
import { useIntl } from 'umi'
import { countAge } from 'utils/utils';

const SubjectInfoCard = ({ subjectInfo }) => {
    const { name, birthDay, gender, dominantHand, relationshipLocale, coachNames, city, gradeName, schoolNature, outpatientNumber } = subjectInfo
    const intl = useIntl()
    return (
        <Card bordered={false} hoverable={false}>
            {outpatientNumber && <p>{`${intl.formatMessage({ id: 'clinicNumber' })}： ${outpatientNumber}`}</p>}
            {name && <p>{`${intl.formatMessage({ id: 'name' })}： ${name}`}</p>}
            {birthDay && <p>{`${intl.formatMessage({ id: 'birthday' })}： ${moment(birthDay).format('YYYY-MM-DD')}`}</p>}
            {birthDay && <p>{`${intl.formatMessage({ id: 'age' })}： ${countAge(birthDay, intl)}`}</p>}
            {gender && <p>{`${intl.formatMessage({ id: 'gender' })}： ${genderLocale(gender, intl)}`}</p>}
            {dominantHand && <p>{`${intl.formatMessage({ id: 'dominantHand' })}： ${handLocale(dominantHand, intl)}`}</p>}
            {relationshipLocale && <p>{`${intl.formatMessage({ id: 'relationship' })}： ${relationshipLocale}`}</p>}
            {city && <p>{`${intl.formatMessage({ id: 'city' })}： ${city}`}</p>}
            {gradeName && <p>{`${intl.formatMessage({ id: 'grade' })}： ${formatterGradeName(gradeName)}`}</p>}
            {schoolNature && <p>{`${intl.formatMessage({ id: 'schoolNature' })}： ${formatterSchoolNature(schoolNature)}`}</p>}
            {coachNames && <p>{`${intl.formatMessage({ id: 'trainer' })}： ${coachNames}`}</p>}
        </Card>
    )
}

export default SubjectInfoCard
