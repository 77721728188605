import {
  ActivateAccount,
  CheckActivateToken,
  CheckRegister,
  PostTrainerActive,
} from 'services/account';
import { SetLanguage } from 'services/language';
import { setLocale, getLocale } from 'umi';
import { message } from 'antd';
import { PostSendCheckNumberCode } from 'services/trainingSystem';
import { PostActiveAccount } from 'services/roles';

export const SHOWTYPE = {
  Idle: 'idle',
  NewUser: 'newUser',
  NeedRegister: 'needRegister',
  ExistUser: 'existUser',
  UnknownError: 'error',
  ErrorLink: 'errorLink',
  UserAlreadyActive: 'userAlreadyActive',
};

const LangOptions = {
  en: 'en-US',
  'zh-Hans': 'zh-CN',
  es: 'es-ES'
};

export default {
  namespace: 'activate',

  state: {
    showType: SHOWTYPE.Idle,
    token: '',
    isNext: false,
    isRegisterNext: false,
    checkType: '',
    canSendCode: true,
    btnDisable: true,
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },

  effects: {
    *check({ payload }, { call, put, take, select }) {
      const { token, checkType = '', culture } = payload;
      let res;
      // checkType 管理员身份激活
      // trainerToSupervisor 训练师激活
      if (checkType === '') {
        // 训练师激活流程
        res = yield call(CheckActivateToken, token);
      } else if (checkType === 'trainerToSupervisor') {
        res = yield call(PostTrainerActive, token);
      } else {
        res = yield call(CheckRegister, token);
      }

      // 切换语言环境
      if (culture && getLocale() !== LangOptions[culture]) {
        const res = yield call(SetLanguage, { culture });
        if (res.response.ok) {
          setLocale(LangOptions[culture]);
        }
      }

      if (res.response.ok) {
        if (checkType === '') {
          const { isNew } = res.data;
          if (isNew) {
            yield put({
              type: 'updateState',
              payload: { showType: SHOWTYPE.NewUser, token },
            });
          } else {
            yield put({
              type: 'updateState',
              payload: { showType: SHOWTYPE.ExistUser },
            });
          }
        } else if (checkType === 'trainerToSupervisor') {
          if (res.data == '') {
            yield put({
              type: 'updateState',
              payload: { showType: SHOWTYPE.ExistUser },
            });
          }
        } else {
          const { needRegister } = res.data;
          if (needRegister) {
            yield put({
              type: 'updateState',
              payload: { showType: SHOWTYPE.NeedRegister, token },
            });
          } else {
            yield put({
              type: 'updateState',
              payload: { showType: SHOWTYPE.ExistUser },
            });
          }
        }
      } else {
        const { error } = res.data;
        const { code } = error;
        message.error(error.message);
        let showType = null;
        switch (code) {
          case 'ErrorActiveLink':
            showType = SHOWTYPE.ErrorLink;
            break;
          case ('UserAlreadyActive', 'Identity.UserAlreadyInRole'):
            showType = SHOWTYPE.UserAlreadyActive;
            break;
          default:
            showType = SHOWTYPE.UnknownError;
            break;
        }
        yield put({
          type: 'updateState',
          payload: { showType },
        });
      }
    },

    *activateUser({ payload }, { call, put, take, select }) {
      const { values, intl } = payload;
      const { token } = yield select((state) => state.activate);
      const res = yield call(ActivateAccount, { token, ...values });
      if (res.response.ok) {
        message.success(intl.formatMessage({ id: 'accountActive' }));
        yield put({
          type: 'updateState',
          payload: { showType: SHOWTYPE.ExistUser },
        });
      } else {
        const { error } = res.data;
        message.error(error.message);
      }
    },

    *registerUser({ payload }, { call, put, take, select }) {
      const { token } = yield select((state) => state.activate);
      const res = yield call(PostActiveAccount, { token, ...payload });
      if (res.response.ok) {
        message.success('账号注册成功');
        yield put({
          type: 'updateState',
          payload: { showType: SHOWTYPE.ExistUser },
        });
      } else {
        const { error } = res.data;
        message.error(error.message);
      }
    },

    *sendCode({ payload }, { call, put, take, select }) {
      const { countryCode, phoneNumber, intl } = payload;
      const sendcodeRes = yield call(PostSendCheckNumberCode, {
        phone: phoneNumber,
        countryCode: countryCode,
      });
      if (sendcodeRes.response.ok) {
        message.success(intl.formatMessage({ id: 'codeSendSucess' }));
        yield put({ type: 'updateState', payload: { canSendCode: false } });
      } else {
        const { error } = sendcodeRes.data;
        error.message && message.error(error.message);
      }
    },
  },

  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(({ pathname, query }) => {
    //     if (pathname === '/activate') {
    //       dispatch({ type: 'check', payload: query })
    //     }
    //   })
    // },
  },
};
