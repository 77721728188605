import getRequest from 'utils/request';

const GetCoachConfigApi = '/api/rocketAdmin/coachTrainingConfig';
const GetCoachConfigDetailApi = '/api/rocketAdmin/coachTrainingConfig/byUserId/{userId}'
const GetCoachConfigRecordApi = '/api/rocketAdmin/coachTrainingConfig/records'
const PostCoachConfigApi = '/api/rocketAdmin/coachTrainingConfig/changeRecord'
const PostIncrVideoPlayTimesApi = '/api/rocketAdmin/groupTrainingPlan/incrVideoPlaybackTimes'
const PostCheckVideoTimesApi = '/api/rocketAdmin/groupTrainingPlan/checkVideoPlaybackTimes'

export async function GetCoachConfig(data) {
  return getRequest()(GetCoachConfigApi, {
    method: 'GET',
    params: data,
  });
}

export async function GetCoachConfigDetail(data) {
    return getRequest()(GetCoachConfigDetailApi.replace('{userId}', data.userId), {
        method: 'GET',
        params: data,
    });
}

export async function GetCoachConfigRecord(data) {
    return getRequest()(GetCoachConfigRecordApi, {
      method: 'GET',
      params: data,
    });
}

export async function PostCoachConfig(data) {
    return getRequest()(PostCoachConfigApi, {
        method: 'POST',
        data,
    });
}

export async function PostIncrVideoPlayTimes(data) {
  return getRequest()(PostIncrVideoPlayTimesApi, {
      method: 'POST',
      data,
  });
}

export async function PostCheckVideoTimes(data) {
  return getRequest()(PostCheckVideoTimesApi, {
      method: 'POST',
      data,
  });
}