import getRequest from 'utils/request'

const ChildrenTotalData = '/api/business/subject/subjectOverview';
const ChildrenAnalysis = '/api/business/subject/subjectAnalysis';
const GuardianTotalData = '/api/business/subject/guardianOverview';
const GuardianAnalysis = '/api/business/subject/guardianAnalysis';
const ILSTotalData = '/api/rocketAdmin/userOfflinePlan/trainingStatistic';
const GetTotalDataApi = '/api/rocketAdmin/trainerTraining/staffEndClass';
const ILSAnalysis = '/api/rocketAdmin/userOfflinePlan/trainingAnalysis';
const HFSAnalysis = '/api/rocketAdmin/tenantContract/analysis';
const VRATTotalData = '/api/vrclassroom/vratReportStatistic/overview';
const VRATAnalysis = '/api/vrclassroom/vratReportStatistic/vratAnalysis';
const GetPlanListApi = '/api/rocketAdmin/userOfflinePlan/planList';
const PostDownloadExcel = '/api/rocketAdmin/UserOfflinePlan/excelDownload';
const GetFasDataApi = '/api/rocketAdmin/UserAssessment/fasAssessmentOverview'

const GetUserCourseAnalysisApi = '/api/rocketAdmin/userCourse/userCourseAnalysis'

const GetUserCourseExcelApi = '/api/rocketAdmin/UserCourse/excelDownload'

const GetQuestionAnalysisApi = '/api/rocketAdmin/userAssessment/userAssessmentOverview'
const GetDownQuestionAnalysisApi = '/api/rocketAdmin/userAssessment/userAssessmentOverviewExcel'
const GetOneToOneIndicatorApi = '/api/rocketAdmin/userOfflinePlan/trainingPlanOverview'
const GetOneToOneDownIndicatorApi = '/api/rocketAdmin/userOfflinePlan/trainingPlanOverviewExcel'
const PostOneToMoreIndicatorApi = '/api/rocketAdmin/groupTrainingPlan/trainingPlanOverview'
const PostOneToMoreDownIndicatorApi = '/api/rocketAdmin/groupTrainingPlan/downloadTrainingPlanOverview'
const GetOneToOneTrainingStatisticsApi = '/api/rocketAdmin/userOfflinePlan/trainingPlanStatistic'
const GetOneToOneTrainingAnalsisApi = '/api/rocketAdmin/userOfflinePlan/trainingPlanAnalysis'
const PostOneToMoreTrainingStatisticsApi = '/api/rocketAdmin/groupTrainingPlan/trainingPlanStatistic'
const GetOneToMoreTrainingAnalsisApi = '/api/rocketAdmin/groupTrainingPlan/trainingPlanAnalysis'
const GetHfsDataStatisticsApi = '/api/rocketAdmin/tenantContract/hfsDataStatistics'
const GetDownLoadHFSExcelApi = '/api/rocketAdmin/tenantContract/hfsDataStatisticsExcel'
const GetTrainerHfsDataApi = '/api/rocketAdmin/trainerTraining/trainerHfsData'
const GetDownLoadTrainerHfsDataExcelApi = '/api/rocketAdmin/trainerTraining/trainerHfsDataExcel'

export async function GetHfsDataStatistics(data) {
    return getRequest()(GetHfsDataStatisticsApi, {
        method: 'GET',
        params: data
    })
}

export async function GetDownLoadHFSExcel(data) {
    return getRequest()(GetDownLoadHFSExcelApi, {
        reponseType: 'blob',
        method: 'GET',
        params: data
    })
}

export async function PostOneToMoreTrainingStatistics(data) {
    return getRequest()(PostOneToMoreTrainingStatisticsApi, {
        method: 'POST',
        data
    })
}

export async function GetOneToMoreTrainingAnalsis(data) {
    return getRequest()(GetOneToMoreTrainingAnalsisApi, {
        method: 'GET',
        params: data
    })
}

export async function GetOneToOneTrainingStatistics(data) {
    return getRequest()(GetOneToOneTrainingStatisticsApi, {
        method: 'GET',
        params: data
    })
}

export async function GetOneToOneTrainingAnalsis(data) {
    return getRequest()(GetOneToOneTrainingAnalsisApi, {
        method: 'GET',
        params: data
    })
}

export async function PostOneToMoreIndicator(data) {
    return getRequest()(PostOneToMoreIndicatorApi, {
        method: 'POST',
        data
    })
}

export async function DownOneToMoreIndicator(data) {
    return getRequest()(PostOneToMoreDownIndicatorApi, {
        method: 'POST',
        data
    })
}

export async function GetOneToOneIndicator(data) {
    return getRequest()(GetOneToOneIndicatorApi, {
        method: 'GET',
        params: data
    })
}

export async function DownOneToOneIndicator(data) {
    return getRequest()(GetOneToOneDownIndicatorApi, {
        method: 'GET',
        params: data
    })
}

export async function DownQuestionAnalysis(data) {
    return getRequest()(GetDownQuestionAnalysisApi, {
        method: 'GET',
        params: data
    })
}

export async function GetQuestionAnalysis(data) {
    return getRequest()(GetQuestionAnalysisApi, {
        method: 'GET',
        params: data
    })
}

export async function GetChildrenTotalData(data) {
    return getRequest()(ChildrenTotalData, {
        method: 'GET',
        params: data
    })
}

export async function GetFasData(data) {
    return getRequest()(GetFasDataApi, {
        method: 'GET',
        params: data
    })
}

export async function GetChildrenAnalysis(data) {
    return getRequest()(ChildrenAnalysis, {
        method: 'GET',
        params: data
    })
}

export async function GetGuardianTotalData(data) {
    return getRequest()(GuardianTotalData, {
        method: 'GET',
        params: data
    })
}

export async function GetGuardianAnalysis(data) {
    return getRequest()(GuardianAnalysis, {
        method: 'GET',
        params: data
    })
}

export async function GetILSTotalData(data) {
    return getRequest()(ILSTotalData, {
        method: 'GET',
        params: data
    })
}

export async function GetTotalData(data) {
    return getRequest()(GetTotalDataApi, {
        method: 'GET',
        params: data
    })
}

export async function GetHFSAnalysis(data) {
    return getRequest()(HFSAnalysis, {
        method: 'GET',
        params: data
    })
}


export async function GetILSAnalysis(data) {
    return getRequest()(ILSAnalysis, {
        method: 'GET',
        params: data
    })
}

export async function GetVRATTotalData(data) {
    return getRequest()(VRATTotalData, {
        method: 'GET',
        params: data
    })
}

export async function GetVRATAnalysis(data) {
    return getRequest()(VRATAnalysis, {
        method: 'GET',
        params: data
    })
}

export async function GetPlanList(data) {
    return getRequest()(GetPlanListApi, {
        method: 'GET',
        params: data
    })
}

export async function DownloadExcelApi(data) {
    return getRequest()(PostDownloadExcel, {
        reponseType: 'blob',
        method: 'GET',
        params: data
    })
}

export async function GetUserCourseAnalysis(data) {
    return getRequest()(GetUserCourseAnalysisApi, {
        method: 'GET',
        params: data
    })
}

export async function DownloadUserCourseExcelApi(data) {
    return getRequest()(GetUserCourseExcelApi, {
        reponseType: 'blob',
        method: 'GET',
        params: data
    })
}

export async function GetTrainerHfsData(data) {
    return getRequest()(GetTrainerHfsDataApi, {
        method: 'GET',
        params: data
    })
}

export async function DownLoadTrainerHfsDataExcel(data) {
    return getRequest()(GetDownLoadTrainerHfsDataExcelApi, {
        responseType: 'blob',
        method: 'GET',
        params: data
    })
}
