import getRequest from 'utils/request'

const ContentAdminApi = '/api/us/contentAdmin/contentAdminList'
const PostAddRoleApi = '/api/us/contentAdmin/contentAdmin'
const PostReSendApi = '/api/us/contentAdmin/{id}/reSend'
const PostEditRoleApi = '/api/us/contentAdmin/role'
const GetNeedRegisterApi = '/api/us/contentAdmin/needRegister'
const PostActiveAccountApi = '/api/us/contentAdmin/activateAccount'
const PostAddTenantApi = '/api/us/contentAdmin/managementCenter'
const GetGrantTenantApi = '/api/us/contentAdmin/{id}/grantTenant'
const PostFreezeApi = '/api/us/contentAdmin/freeze';

export async function PostFreeze(data) {
    return getRequest()(PostFreezeApi, {
        method: 'POST',
        data
    })
}

export async function GetGrantTenant(data) {
    return getRequest()(GetGrantTenantApi.replace('{id}', data.id), {
        method: 'GET',
        params: data
    })
}

export async function GetContentAdminList(data) {
    return getRequest()(ContentAdminApi, {
        method: 'GET',
        params: data
    })
}

export async function GetNeedRegister(data) {
    return getRequest()(GetNeedRegisterApi, {
        method: 'GET',
        params: data
    })
}

export async function PostAddTenant(data) {
    return getRequest()(PostAddTenantApi, {
        method: 'POST',
        data
    })
}

export async function PostEditRole(data) {
    return getRequest()(PostEditRoleApi, {
        method: 'POST',
        data
    })
}

export async function PostActiveAccount(data) {
    return getRequest()(PostActiveAccountApi, {
        method: 'POST',
        data
    })
}

export async function PostContentAdmin(data) {
    return getRequest()(ContentAdminApi, {
        method: 'POST',
        data
    })
}

export async function PostAddRole(data) {
    return getRequest()(PostAddRoleApi, {
        method: 'POST',
        data
    })
}

export async function PostReSend(data) {
    return getRequest()(PostReSendApi.replace('{id}', data.id), {
        method: 'POST',
        data
    })
}