import React, { useState, useEffect } from 'react';
import { Button, Space, Popover } from 'antd';
import { useIntl } from 'umi';

import {
  GetTrainingAppointment,
  PostTrainingAppointmentComplete,
  DeleteTrainingAppointment,
} from 'services/trainingSystem';

import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import MakeAppointmentModal from './Modals/MakeAppointmentModal';
import FeedBackModal from './Modals/FeedBackModal';

const timeFormat = 'MM/DD/YYYY HH:mm';

const TrainingTab = ({
  roleType,
  userInfo,
  allowReloadTab,
  onChangeAllowReloadProgress,
}) => {
  const intl = useIntl();

  const { id } = userInfo || {};

  const [showList, setShowList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentTrainItem, setCurrentTrainItem] = useState({});
  const [visible, setVisible] = useState({
    makeAppointmentModalVisible: false,
    feedbackModalVisible: false,
  });

  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const initData = async () => {
    setLoading(true);

    const res = await GetTrainingAppointment({
      TrainerId: id,
      SkipCount: (pagination.current - 1) * pagination.pageSize,
      MaxResultCount: pagination.pageSize,
    });

    if (res.response.ok) {
      const { items = [], totalCount } = res.data || {};
      setShowList(items);
      setPagination({
        ...pagination,
        total: totalCount,
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, [pagination.current, pagination.pageSize, allowReloadTab]);

  const listSupport = {
    trainingModule: {
      showText: intl.formatMessage({ id: 'Module' }),
      showType: 'Text',
      render: (_, record) => {
        const { trainingModule } = record || {};
        const { displayName } = trainingModule || {};

        return displayName;
      },
    },
    creationTime: {
      showText: intl.formatMessage({ id: 'Appointment Date' }),
      showType: 'Time',
      addtional: {
        format: timeFormat,
      },
    },
    startTime: {
      showText: intl.formatMessage({ id: 'startTime' }),
      showType: 'Time',
      addtional: {
        format: timeFormat,
      },
    },
    duration: {
      showText: intl.formatMessage({ id: 'Hrs' }),
      showType: 'Text',
    },
    supervisor: {
      showText: intl.formatMessage({ id: 'Trainer' }),
      showType: 'Text',
      render: (_, record) => {
        const { supervisor } = record || {};
        const { name } = supervisor || {};

        return name;
      },
    },
    complete: {
      showText: intl.formatMessage({ id: 'status' }),
      showType: 'Text',
      render: (text) => {
        return intl.formatMessage({ id: text ? 'completed' : 'Incompleted' });
      },
    },
    note: {
      showText: intl.formatMessage({ id: 'Note' }),
      showType: 'Text',
    },
    completeTime: {
      showText: intl.formatMessage({ id: 'Complete Time' }),
      showType: 'Time',
      addtional: {
        format: timeFormat,
      },
    },
  };

  const makeAppointmentModalProps = {
    userInfo,
    currentTrainItem,
    visible: visible.makeAppointmentModalVisible,
    closeModal: () => {
      setVisible({
        ...visible,
        makeAppointmentModalVisible: false,
      });
    },
    reloadPage: () => {
      initData();
      onChangeAllowReloadProgress && onChangeAllowReloadProgress();
    },
  };

  const feedBackModalProps = {
    visible: visible.feedbackModalVisible,
    currentTrainItem,
    closeModal: () => {
      setVisible({
        ...visible,
        feedbackModalVisible: false,
      });
    },
    reloadPage: () => {
      initData();
      onChangeAllowReloadProgress && onChangeAllowReloadProgress();
    },
  };

  const onEdit = (record) => {
    setCurrentTrainItem(record);
    setVisible({
      ...visible,
      makeAppointmentModalVisible: true,
    });
  };

  const onComplete = async (record) => {
    const { id, feedback } = record || {};

    setLoading(true);
    const res = await PostTrainingAppointmentComplete({ id });

    if (res.response.ok) {
      initData();
      onChangeAllowReloadProgress && onChangeAllowReloadProgress();

      if (!feedback) {
        onFeedback(record);
        // return;
      }
    } else {
      setLoading(false);
    }
  };

  const onFeedback = (record) => {
    setCurrentTrainItem(record);
    setVisible({
      ...visible,
      feedbackModalVisible: true,
    });
  };

  const onDelete = async (record) => {
    setLoading(true);
    const { id } = record || {};
    const res = await DeleteTrainingAppointment({ id });

    if (res.response.ok) {
      initData();
      onChangeAllowReloadProgress && onChangeAllowReloadProgress();
    } else {
      setLoading(false);
    }
  };

  const getFeedBackContent = (item) => {
    const { trainerVisible, feedback, trainerTrainingModuleStatus } =
      item || {};
    return (
      <div style={{ width: '300px' }}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <div dangerouslySetInnerHTML={{ __html: feedback }}></div>
          {roleType === 'admin' && (
            <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                {intl.formatMessage({ id: 'trainer' })}
                {intl.formatMessage({
                  id: trainerVisible ? 'trainerSee' : 'trainerNotSee',
                })}
              </div>
              {trainerTrainingModuleStatus !== 'Stopped' && (
                <a onClick={() => onFeedback(item)}>
                  {intl.formatMessage({ id: 'edit' })}
                </a>
              )}
            </Space>
          )}
        </Space>
      </div>
    );
  };

  const tableProps = {
    loading,
    listData: showList,
    listSupport,
    pagination,
    onTableChange(page) {
      const { current, pageSize } = page;

      setPagination({
        ...pagination,
        current,
        pageSize,
      });
    },
    Actions: [
      roleType === 'admin'
        ? {
            showText: 'Edit',
            itemRender(record) {
              const { complete, trainerTrainingModuleStatus } = record || {};
              if (trainerTrainingModuleStatus === 'Stopped') {
                return null;
              }
              return complete ? null : (
                <a onClick={() => onEdit(record)}>
                  {intl.formatMessage({ id: 'edit' })}
                </a>
              );
            },
          }
        : null,
      roleType === 'admin'
        ? {
            showText: 'Complete',
            itemRender(record) {
              const { complete, trainerTrainingModuleStatus } = record || {};
              if (trainerTrainingModuleStatus === 'Stopped') {
                return null;
              }
              return complete ? null : (
                <a onClick={() => onComplete(record)}>
                  {intl.formatMessage({ id: 'Complete' })}
                </a>
              );
            },
          }
        : null,
      {
        showText: 'Feedback',
        itemRender(record) {
          const { feedback, trainerVisible, trainerTrainingModuleStatus } =
            record || {};
          return feedback
            ? (roleType === 'admin' || trainerVisible) && (
                <Popover
                  content={getFeedBackContent(record)}
                  title={<b>{intl.formatMessage({ id: 'feedback' })}</b>}
                >
                  <a>{intl.formatMessage({ id: 'feedback' })}</a>
                </Popover>
              )
            : trainerTrainingModuleStatus != 'Stopped' &&
                roleType === 'admin' && (
                  <a onClick={() => onFeedback(record)}>
                    {intl.formatMessage({ id: 'feedback' })}
                  </a>
                );
        },
      },
      roleType === 'admin'
        ? {
            showText: 'Delete',
            itemRender(record) {
              const { complete, trainerTrainingModuleStatus } = record || {};
              if (trainerTrainingModuleStatus === 'Stopped') {
                return null;
              }
              return complete ? null : (
                <a onClick={() => onDelete(record)}>
                  {intl.formatMessage({ id: 'delete' })}
                </a>
              );
            },
          }
        : null,
    ],
  };
  return (
    <div>
      {roleType === 'admin' && (
        <div style={{ textAlign: 'right' }}>
          <Button
            type="link"
            onClick={() => {
              setCurrentTrainItem({});
              setVisible({
                ...visible,
                makeAppointmentModalVisible: true,
              });
            }}
          >
            {intl.formatMessage({ id: 'Make an Appointment' })}
          </Button>
          <p />
        </div>
      )}

      <MagicTable {...tableProps} />
      {visible.makeAppointmentModalVisible && (
        <MakeAppointmentModal {...makeAppointmentModalProps} />
      )}
      {visible.feedbackModalVisible && (
        <FeedBackModal {...feedBackModalProps} />
      )}
    </div>
  );
};

export default TrainingTab;
