import React from 'react'
import { Avatar } from 'antd';
import { IntlManager} from 'utils/helper';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import styles from './index.less'

const listSupport = (intl)=>{
    return {
        text1: {
            showText: intl("productsName"),
            showType: 'Text'
        },
        text3: {
            showText: intl("section"),
            showType: 'Text'
        },
        text4: {
            showText: intl("productTypes"),
            showType: 'Text'
        },
        text2: {
            showText: intl("paymentAmount"),
            showType: 'Text'
        },
    }
} 

const HfsOrderInfo = () => {
    const intl = IntlManager.MyIntl()

    const tableProps = {
        listData: [],
        listSupport: listSupport(intl),
    }

    return (
        <div className={styles.orderInfo}>
            <div className={styles.orderInfoItem}>
                <div className={styles.orderInfoItemTitle}>{intl('userInformation')}</div>
                <div className={styles.orderInfoItemContent}>
                    <div>
                        <span>用户：</span>
                        <Avatar shape="square" size={48} src='https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=1577805847,323709616&fm=26&gp=0.jpg' />
                        <a style={{ marginLeft: '10px' }}>小米</a>
                    </div>
                    <span>性别：女</span>
                    <span>生日：1997/05/05</span>
                </div>
            </div>
            <div className={styles.orderInfoItem}>
                <div className={styles.orderInfoItemTitle}>订单信息</div>
                <div className={styles.orderInfoItemContent}>
                    <span>订单状态：已成交</span>
                    <span>订单号：MSI201910301405DDH</span>
                    <span>成交时间：2020-5-29 10:26</span>
                </div>
            </div>
            <div className={styles.orderInfoItem}>
                <div className={styles.orderInfoItemTitle}>商品信息</div>
                <MagicTable {...tableProps} />
            </div>
        </div>
    )
}

export default HfsOrderInfo