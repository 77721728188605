import React, { useState, useEffect } from 'react';
import { Space, Timeline } from 'antd';
import { useIntl } from 'umi';

import { GetCertificate } from 'services/trainingSystem';

import AutoGenerationCertModal from 'components/AutoGenerationCertModal';
import TrainerFeedbackDrawer from './Modals/TrainerFeedbackDrawer';
import SpinCom from './SpinCom';

import { downloadLongRangeImg } from 'cognitiveleap-core-us/utils/utils';

import styles from '../index.less';

const Certificate = ({
  progressIndex,
  selectCertData,
  userInfo,
  roleType,
  onChangeAllowReloadTab,
  allowReloadProgress
}) => {
  const intl = useIntl();

  const [certificateData, setCertificateData] = useState({});
  const [visible, setVisible] = useState({
    autoGenerationCertModalVisible: false,
    trainerFeedbackDrawerVisible: false,
  });
  const [loading, setLoading] = useState(false);

  const { trainingModule, id: trainerTrainingModuleId } = selectCertData || {};
  const { id: trainingModuleId } = trainingModule || {};

  const initData = async () => {
    setLoading(true);
    const res = await GetCertificate({ trainerTrainingModuleId });
    if (res.response.ok) {
      const { data } = res || {};
      setCertificateData(data || {});
    }
    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, [allowReloadProgress]);

  const {
    hasFeedback,
    feedback = [],
    trainerCertificate,
  } = certificateData || {};
  const { credential } = trainerCertificate || {};
  const { url } = credential || {};

  // if (Object.keys(certificateData).length === 0) {
  //   return <></>;
  // }

  const autoGenerationCertModalProps = {
    visible: visible.autoGenerationCertModalVisible,
    selectItem: {
      ...userInfo,
      trainerId: userInfo.id,
    },
    trainingModuleId,
    selectCertData,
    onCloseModal: () => {
      setVisible({
        ...visible,
        autoGenerationCertModalVisible: false,
      });
      onChangeAllowReloadTab && onChangeAllowReloadTab();
    },
  };

  const trainerFeedbackDrawerProps = {
    visible: visible.trainerFeedbackDrawerVisible,
    roleType,
    currentItem: certificateData,
    selectCertData,
    onClose: () => {
      setVisible({
        ...visible,
        trainerFeedbackDrawerVisible: false,
      });
      initData();
      onChangeAllowReloadTab && onChangeAllowReloadTab();
    },
  };

  const onIssueCertificate = () => {
    setVisible({
      ...visible,
      autoGenerationCertModalVisible: true,
    });
  };

  const onFillInFeedback = () => {
    setVisible({
      ...visible,
      trainerFeedbackDrawerVisible: true,
    });
  };

  if (loading) {
    return <SpinCom />;
  }

  return (
    <>
      <Timeline>
        <Timeline.Item>
          <div className={styles.practicalTrainingItem}>
            <div className={styles.practicalTrainingItemTitle}>
              {progressIndex}. {intl.formatMessage({ id: 'qulification' })}
            </div>
            <div className={styles.practicalTrainingItemContent}>
              {trainerCertificate ? (
                <Space>
                  <div>{intl.formatMessage({ id: 'issued' })}</div>
                  {url && roleType == 'admin' && (
                    <a onClick={() => downloadLongRangeImg(credential)}>
                      {intl.formatMessage({ id: 'Download Certificate' })}
                    </a>
                  )}
                  {hasFeedback &&
                    (feedback.some((item) => item.finishTime != null) ? (
                      <Space>
                        {url && roleType !== 'admin' && (
                          <a onClick={() => downloadLongRangeImg(credential)}>
                            {intl.formatMessage({ id: 'Download Certificate' })}
                          </a>
                        )}
                        <a onClick={onFillInFeedback}>
                          {intl.formatMessage({ id: 'View Feedback' })}
                        </a>
                      </Space>
                    ) : (
                      roleType === 'personal' && (
                        <a onClick={onFillInFeedback}>
                          {intl.formatMessage({ id: 'Fill In Feedback' })}
                        </a>
                      )
                    ))}
                </Space>
              ) : (
                <Space>
                  {roleType === 'admin' ? (
                    <a onClick={onIssueCertificate}>
                      {intl.formatMessage({ id: 'Issue Certificate' })}
                    </a>
                  ) : (
                    <div>{intl.formatMessage({ id: 'unissued' })}</div>
                  )}
                </Space>
              )}
            </div>
          </div>
        </Timeline.Item>
      </Timeline>
      {visible.autoGenerationCertModalVisible && (
        <AutoGenerationCertModal {...autoGenerationCertModalProps} />
      )}
      {visible.trainerFeedbackDrawerVisible && (
        <TrainerFeedbackDrawer {...trainerFeedbackDrawerProps} />
      )}
    </>
  );
};

export default Certificate;
