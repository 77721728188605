import React from 'react';
import styles from './ReportRichText.less';

class RichText extends React.Component {
  div = React.createRef();
  render() {
    var { content } = this.props;
    content = content ? content : '<div/>';
    content = content.replace('float:none', 'margin:0 auto').replace(/^\s+|\s+$/g,'');

    return (
      content &&
      (content.includes('<p') ? (
        <div
          className={styles.container}
          style={{ wordBreak: 'keep-all', wordWrap: 'break-word' }}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      ) : (
        <div className={styles.container} style={{ wordBreak: 'keep-all', wordWrap: 'break-word', whiteSpace: 'pre-line' }}>
          {content}
        </div>
      ))
    );
  }
}

export default RichText;
