import React, { useState, useEffect } from 'react';
import { Input, Select, InputNumber, Modal, Form, message, Spin } from 'antd';
import { useIntl } from 'umi';
import { GetCenterSubjectList } from 'services/userManager';
import { PostClassHoursSettingRecords } from 'services/userConfiguration';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const fetch = async (value, callback) => {
  if (!value) return;

  const res = await GetCenterSubjectList({
    skipCount: 0,
    maxResultCount: 999,
    status: 'Bind',
    filter: value,
  });

  if (res.response.ok) {
    const { items } = res?.data || {};
    callback(items);
  } else {
    const {
      data: { error },
    } = res;
    message.error(error.message);
  }
};

const AddTrainModal = ({
  visible = false,
  currentItem,
  onCancel,
  reloadTable,
  getRemainingTimesStyle,
}) => {
  const intl = useIntl();

  const [form] = Form.useForm();

  const { name, remainingTimes } = currentItem || {};

  const [childList, setChildList] = useState([]);
  const [selectType, setSelectType] = useState('add');
  const [selectValue, setSelectValue] = useState();
  const [loading, setLoading] = useState({
    modalLoading: false,
  });

  const onFinish = async (value) => {
    setLoading({
      ...loading,
      modalLoading: true,
    });

    const { classHours } = value;

    let props;
    if (currentItem) {
      const { subject } = currentItem || {};
      const { id: userId } = subject;
      props = {
        ...value,
        userId,
        classHours: selectType === 'reduce' ? -classHours : classHours,
      };
    } else {
      props = {
        ...value,
        classHours: selectType === 'reduce' ? -classHours : classHours,
      };
    }

    const res = await PostClassHoursSettingRecords(props);

    if (res.response.ok) {
      message.success(intl.formatMessage({ id: 'submitted' }));
      onCancel();
      reloadTable();
    } else {
      const { error } = res.data;
      message.error(error.message);
    }

    setLoading({
      ...loading,
      modalLoading: false,
    });
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleSearch = (newValue) => {
    if (newValue) {
      fetch(newValue, setChildList);
    } else {
      setChildList([]);
    }
  };

  const handleChange = (newValue) => {
    setSelectValue(newValue);
  };

  const selectBefore = (
    <Select
      value={selectType}
      onChange={(value) => setSelectType(value)}
      options={[
        {
          value: 'add',
          label: '+',
        },
        {
          value: 'reduce',
          label: '-',
        },
      ]}
    />
  );

  return (
    <Modal
      title={intl.formatMessage({ id: 'Add Training Sessions' })}
      open={visible}
      width={800}
      onOk={() => form.submit()}
      onCancel={handleCancel}
      confirmLoading={loading.modalLoading}
    >
      <Form
        name="AddTrainForm"
        form={form}
        {...formItemLayout}
        onFinish={onFinish}
      >
        {currentItem && (
          <Form.Item label={intl.formatMessage({ id: 'Remaining Sessions' })}>
            <span style={getRemainingTimesStyle(remainingTimes)}>
              {remainingTimes ?? '-'}
            </span>
          </Form.Item>
        )}
        {currentItem ? (
          <Form.Item label={intl.formatMessage({ id: 'subjectName' })}>
            <span>{name ?? '-'}</span>
          </Form.Item>
        ) : (
          <Form.Item
            label={intl.formatMessage({ id: 'Select Child' })}
            name="userId"
            rules={[
              {
                required: true,
                message: `${intl.formatMessage({
                  id: 'Select Child',
                })} ${intl.formatMessage({ id: 'isRequired' })}`,
              },
            ]}
          >
            <Select
              showSearch
              placeholder={intl.formatMessage({
                id: 'Enter and search to select',
              })}
              value={selectValue}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={handleSearch}
              onChange={handleChange}
              notFoundContent={null}
              options={childList.map((item) => {
                const { name, id } = item || {};

                return {
                  value: id.toString(),
                  label: name,
                };
              })}
            />
          </Form.Item>
        )}

        <Form.Item
          label={intl.formatMessage({ id: 'Training Sessions' })}
          name="classHours"
          rules={[
            {
              required: true,
              message: `${intl.formatMessage({
                id: 'Training Sessions',
              })} ${intl.formatMessage({ id: 'isRequired' })}`,
            },
          ]}
        >
          <InputNumber addonBefore={selectBefore} min="0" />
        </Form.Item>

        <Form.Item label={intl.formatMessage({ id: 'comments' })} name="remark">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddTrainModal;
