module.exports = {
  areaList: '/system/manage/manage/area',
  orgList: '/system/manage/manage/org',
  centerList:'/system/manage/manage/center',
  group:'/system/manage/manage/group',
  device: '/system/manage/manage/device',
  orgRole:'/system/manage/role/org',
  clRole:'/system/manage/role/admin',
  emailSetting:'/system/manage/setting/mail',
  centerListEnterprise: '/system/enterprise/manage/center/centerList',
  centerListArea: '/system/area/manage/center/centerList',
  school: '/system/manage/manage/school',
  trainingStaffList: '/system/manage/manage/trainingStaff'
}
