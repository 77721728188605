const _INTL_MSG = (props) => {
  const { id, defaultMessage, replaceData, intl } = props;
  const currentIntl = intl
  const res =
    Object.keys(replaceData).length > 0
      ? currentIntl.formatMessage(
          {
            id,
            defaultMessage,
          },
          replaceData,
        )
      : currentIntl.formatMessage({
          id,
          defaultMessage,
        });
  return res;
};

export const IntlManager = {
  intl: null,
  init(intl){
    IntlManager.intl = intl
  },
  MyIntl(prefix = ''){
    return (key, replaceData = {}, defaultString = '') => {
      let _key = key;
      if (defaultString !== '') defaultString = key;
      if (prefix !== '') _key = prefix + '.' + key;
      return _INTL_MSG({
        id: _key,
        replaceData,
        defaultMessage: defaultString,
        intl: IntlManager.intl
      })
    };
  }
}

export default {IntlManager}
