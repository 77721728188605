import { CenterGetSubjectInfo, GetClassAndMusic, GetParentInterview, PutOutpatientNumber } from 'services/userManager'
import { GetSubjectPdt, GetChildCancel } from 'services/pdt'
import { message } from 'antd'
import { IntlManager } from 'utils/helper'
import { PutQuestionnaire } from 'services/trainingSystem'


export const SHOWTYPE = {
  RocketAssessment: 'RocketAssessment',
  VratTest: 'VratTest',
  OfflinePlan: 'OfflinePlan',
  Sleep: 'Sleep',
  Nutrition: 'Nutrition',
  Exercise: 'Exercise',
  Cbt: 'Cbt',
  ExercisePdt: 'ExercisePdt',
  SleepPdt: 'SleepPdt',
  NutritionPDT: 'NutritionPDT',
  Emotion: 'Emotion',
  ParentLearning: 'ParentLearning',
  ParentEmotion: 'ParentEmotion'
}

const rocketProjectStatusTabName = [
  SHOWTYPE.OfflinePlan,
  SHOWTYPE.VratTest,
  SHOWTYPE.RocketAssessment
]

const getFilteredTab = (tabMenuList, cbtTabMeauList, grantedPolicies, changedTenant, pdtModules) => {
  const filteredMenu = []
  const rocketProjectStatus = changedTenant && changedTenant.projectStatus ? changedTenant.projectStatus.find(item => item.name == 'Project.Rocket')['status'] : true;
  const pdtProjectStatus = changedTenant && changedTenant.projectStatus ? changedTenant.projectStatus.find(item => item.name == 'Project.PDT')['status'] : true;

  tabMenuList.forEach(item => {
    const { showText, permission } = item
    if (permission === undefined || permission.every(item => grantedPolicies[item] === true)) {
      rocketProjectStatus ? filteredMenu.push(item.showText) : (rocketProjectStatusTabName.includes(showText) && filteredMenu.push(item.showText));
    }
  })
  pdtProjectStatus && cbtTabMeauList.forEach(item => {
    const { permission } = item
    if (pdtModules.includes(permission))
      filteredMenu.push(item.showText);
  })
  return filteredMenu
}

const contentMenu = (intl) => {
  return {
    Properties: [
      {
        EditorType: 'Input',
        ShowTitle: intl('clinicNumber'),
        FormKey: 'outpatientNumber',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: false,
        },
        Description: null,
      }
    ]
  }
}

const tabMenuList = [{
  showText: SHOWTYPE.OfflinePlan,
  permission: ['RocketSystem.UserOfflinePlan', 'PFE.UserManagement.SubjectDetail.UserOfflinePlanTab'],
}, {
  showText: SHOWTYPE.VratTest,
  permission: ['PFE.UserManagement.SubjectDetail.VratTestRequestTab']
}, {
  showText: SHOWTYPE.RocketAssessment,
  permission: ['RocketSystem.UserAssessment', 'PFE.UserManagement.SubjectDetail.AssessmentTab'],
}, {
  showText: SHOWTYPE.Sleep,
  permission: ['PFE.UserManagement.SubjectDetail.SleepTab']
}, {
  showText: SHOWTYPE.Nutrition,
  permission: ['PFE.UserManagement.SubjectDetail.NutritionTab']
}, {
  showText: SHOWTYPE.Exercise,
  permission: ['RocketSystem.UserTrainingPlan', 'PFE.UserManagement.SubjectDetail.SportTab'],
}]

const cbtTabMeauList = [{
  showText: SHOWTYPE.Cbt,
  permission: 'Cbt',
}, {
  showText: SHOWTYPE.Emotion,
  permission: 'Emotion',
}, {
  showText: SHOWTYPE.ParentLearning,
  permission: 'ParentLearning',
}, {
  showText: SHOWTYPE.ParentEmotion,
  permission: 'ParentEmotion',
}, {
  showText: SHOWTYPE.ExercisePdt,
  permission: 'Exercise',
}, {
  showText: SHOWTYPE.SleepPdt,
  permission: 'Sleep',
}, {
  showText: SHOWTYPE.NutritionPDT,
  permission: 'Nutrition',
}]

export const ENTRY = {
  Path: 'path',
  Drawer: 'drawer',
}

export default {
  namespace: 'subjectDetail',

  state: {
    subjectInfo: null,
    classAndMusicInfo: null,
    currentTab: SHOWTYPE.OfflinePlan,
    tabList: [],
    entry: ENTRY.path,
    subjectId: null,
    from: '',
    // 用于判断返回打不打开音乐暂停弹窗，从班级详情播放音乐进入返回则不需弹窗
    groupMusicIsOpen: false,
    content: null,
    editShow: false,
    parentInterviewDrawerVisible: false,
    parentInterviewQuestion: null,
    parentInterviewSubmitData: null,
    parentInterviewIsFininshed: false,
    reviewData: {}
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },

  effects: {
    *loadPage({ payload }, { call, put, take, select }) {
      const formatMessage = IntlManager.intl.formatMessage
      const { id, entry, tab, from, groupMusicIsOpen } = payload
      const { currentUser } = yield select(state => state.user)
      const { auth, changedTenant } = currentUser
      const { grantedPolicies } = auth

      const pdtProjectStatus = changedTenant && changedTenant.projectStatus ? changedTenant.projectStatus.find(item => item.name == 'Project.PDT')['status'] : true;

      const resList = yield [call(GetClassAndMusic, { UserId: id }), pdtProjectStatus && call(GetSubjectPdt, { subjectId: id }), pdtProjectStatus && call(GetChildCancel, { subjectId: id })]

      const { subjectInfoReturnModel } = resList[0]?.data || {};
      const { pdtModules = [], appStartTime = null } = resList[1]?.data || {};

      const showPdtModules = appStartTime ? pdtModules.map(item => item.pdtModule) : [];
      const filteredTabList = getFilteredTab(tabMenuList, cbtTabMeauList, grantedPolicies, changedTenant, showPdtModules)

      const currentTab = filteredTabList.indexOf(tab) > 0 ? tab : filteredTabList[0]

      const { isBind, isLogout } = subjectInfoReturnModel
      if (isLogout || !isBind) {
        message.warning(formatMessage({ id: 'childCannotViewed' }, { status: formatMessage({ id: isLogout ? 'cancelled' : 'noLongerAuthorized' }) }))
      }

      yield put({
        type: 'updateState',
        payload: { subjectInfo: pdtProjectStatus ? { ...subjectInfoReturnModel, ...resList[1]?.data } : subjectInfoReturnModel, classAndMusicInfo: resList[0]?.data, currentTab, tabList: filteredTabList, entry: entry, subjectId: id, from, groupMusicIsOpen, reviewData: resList[2]?.data },
      })
      yield put({ type: 'loadList', })
    },

    *changeTab({ payload }, { call, put, take, select }) {
      const { isOpen } = yield select(state => state.musicplayer)
      if (payload != SHOWTYPE.OfflinePlan && isOpen) {
        yield put({ type: 'musicplayer/changeMusicStatus', payload: { status: false } })
      }
      yield put({
        type: 'updateState',
        payload: {
          currentTab: payload
        },
      })
      yield put({ type: 'loadList', })
    },

    *getFASReport({ payload }, { call, put, take, select }) {
      const { tabList } = yield select(state => state.subjectDetail)
      yield put({ type: 'updateState', payload: { currentTab: tabList[2] } })
      yield put({ type: 'loadList', })
    },

    *loadList(_, { call, put, take, select }) {
      const { currentTab } = yield select(state => state.subjectDetail)
      switch (currentTab) {
        case SHOWTYPE.RocketAssessment:
          yield put({ type: 'subjectAssessment/loadList' })
          break
        case SHOWTYPE.VratTest:
          yield put({ type: 'subjectVrat/loadList' })
          break
        case SHOWTYPE.OfflinePlan:
          yield put({ type: 'subjectOfflinePlan/loadList' })
          break
        case SHOWTYPE.Sleep:
          yield put({ type: 'subjectSleep/loadList' })
          break
        case SHOWTYPE.Exercise:
          yield put({ type: 'subjectExercise/loadList', payload: { current: 1 } })
          break
        case SHOWTYPE.Nutrition:
          yield put({ type: 'subjectNutrition/loadList' })
          break
        default:
          break
      }
    },

    *showParentInterview({ payload }, { call, put, take, select }) {
      const { subjectId } = yield select(state => state.subjectDetail)
      const params = {
        userId: subjectId
      }
      const getParentInterviewRes = yield call(GetParentInterview, params)
      if (getParentInterviewRes.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            parentInterviewQuestion: getParentInterviewRes.data,
            parentInterviewDrawerVisible: true
          }
        })
      }
    },

    *editNum({ payload }, { call, put, take, select }) {
      const intl = IntlManager.MyIntl()
      const { subjectInfo: { outpatientNumber = '' } } = payload;
      let emptyContent = { ...contentMenu(intl) }
      emptyContent.Properties[0].Value = outpatientNumber
      yield put({
        type: 'updateState',
        payload: {
          content: emptyContent,
          editShow: true
        }
      })
    },

    *editSubmit({ payload }, { call, put, take, select }) {
      const { subjectId } = yield select(state => state.subjectDetail)
      const { outpatientNumber, subjectInfo } = payload
      let newInfo = { ...subjectInfo }
      newInfo.outpatientNumber = outpatientNumber
      const params = {
        subjectId,
        outpatientNumber
      }
      const res = yield call(PutOutpatientNumber, params)
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            subjectInfo: newInfo,
            editShow: false
          }
        })
      } else {
        const { error } = res.data
        error.message && message.error(error.message)
      }
    },

    *submitQuestion({ payload }, { call, put, take, select }) {
      const { parentInterviewQuestion } = yield select(state => state.subjectDetail)
      const { id } = parentInterviewQuestion || {}
      const { answerContent, isComplete, finishedPage, intl } = payload || {}
      const params = {
        userQuestionnaireId: id,
        answerContent,
        isComplete,
        finishedPage
      }
      const submitQuestionRes = yield call(PutQuestionnaire, params)
      if (submitQuestionRes.response.ok) {
        if (isComplete)
          message.success(intl.formatMessage({ id: 'submitted' }))
        yield put({
          type: 'updateState',
          payload: {
            parentInterviewSubmitData: null,
            parentInterviewIsFininshed: false,
            parentInterviewDrawerVisible: false
          }
        })
      }
    }
  },

  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(({ pathname, query }) => {
    //     const payload = { ...query, entry: ENTRY.Path }
    //     if (pathname === '/system/center/detail/subjectDetail') {
    //       dispatch({ type: 'loadPage', payload: payload })
    //     }
    //   })
    // },
  },
}
