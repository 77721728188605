import getRequest from 'utils/request'
import { parseRes } from 'utils/utils'

const Locations = '/api/vrclassroom/TestLocation/Locations'
const locationsPaged = '/api/vrclassroom/TestLocation/LocationsPaged'
const createOrUpdateLocation = '/api/vrclassroom/TestLocation/CreateOrUpdateLocation'
const locationDetail = '/api/vrclassroom/TestLocation/Detail'

const locationList = '/api/vrclassroom/testLocation'
const addLocation = '/api/vrclassroom/testLocation'
const manageLocation = '/api/vrclassroom/testLocation/{id}'

export async function CreateLocation(data) {
  return getRequest()(addLocation, {
    method: 'POST',
    data,
  })
}

export async function GetLocation(id) {
  return getRequest()(manageLocation.replace('{id}', id), {
    method: 'GET',
  }).then(parseRes)
}

export async function DeleteLocation(id) {
  return getRequest()(manageLocation.replace('{id}', id), {
    method: 'DELETE',
  })
}

export async function UpdateLocation(id, data) {
  return getRequest()(manageLocation.replace('{id}', id), {
    method: 'PUT',
    data,
  })
}

export async function GetLocations(bodyData) {
  return getRequest()(Locations, {
    method: 'POST',
    data: bodyData,
  })
}

export async function GetLocationList(data) {
  return getRequest()(locationList, {
    method: 'GET',
    params: data,
  }).then(parseRes)
}

export async function GetLocationDetail(queryData) {
  return getRequest()(locationDetail, {
    method: 'GET',
    params: queryData,
  })
}

export async function PostCreateOrUpdateLocation(data) {
  return getRequest()(createOrUpdateLocation, {
    method: 'POST',
    data,
  })
}

export async function PostLocationsPaged(queryData, bodyData) {
  return getRequest()(locationsPaged, {
    method: 'POST',
    params: queryData,
    data: bodyData,
  })
}
