import getRequest from 'utils/request'

const serviceMenu = {
  feedback: 'feedbackProperties',
  target: 'trainingTarget',
  tag: 'tag',
  category: 'programCategory',
  track: 'track',
  material: 'programMaterial',
  questionnaire: 'questionnaire',
  assessment: 'assessment',
  teacher: 'teacher',
  courseItem: 'courseContentsDetail',
  courseCategory: 'courseCategory',
  course: 'course',
  banner: 'banner',
  trainingPlan: 'trainingPlanTemplate',
  trainingCourse: 'courseWare',
  templateRules: 'trainingPlanTemplate/rules',
  offlinePlan: 'OfflineTrainingPlan',
  offlineRules: 'offlineTrainingPlan/rules'
}

const GetPrepareForEdit = '/api/rocketAdmin/{prepare}/{id}'
const GetList = '/api/rocketAdmin/{prepare}'
const PostTestListApi = '/api/rocketAdmin/course/list'
const PutPrepare = '/api/rocketAdmin/{prepare}/{id}'
const DeletePrepare = '/api/rocketAdmin/{prepare}/{id}'
const PostPrepare = '/api/rocketAdmin/{prepare}'

const PostPrepareForEdit = '/api/rocketAdmin/{prepare}/prepareForEdit'
const updateTrackDuration = '/api/rocketAdmin/track/duration'
const PostCourseContentDetailApi = '/api/rocketAdmin/courseContentsDetail/prepareForUpdate'

const NewTrainingPlanListApi = '/api/rocketAdmin/trainingPlanV2'
const GetNewTrainingPlanApi = '/api/rocketAdmin/trainingPlanV2/{id}'
const PostEditNewTrainingPlanApi = '/api/rocketAdmin/trainingPlanV2/prepareForEdit'
const GetnewTrainingPlanCategoriesApi = '/api/rocketAdmin/trainingPlanV2/categories'
const PutDescriptionApi = '/api/rocketAdmin/offlineTrainingPlan/description'
const PutTrainingPlanTemplateApi = '/api/rocketAdmin/trainingPlanTemplate/description'
const PutTrainingPlanV2Api = '/api/rocketAdmin/trainingPlanV2/description'

export async function PutTrainingPlanV2(data) {
  return getRequest()(PutTrainingPlanV2Api, {
    method: 'PUT',
    data,
  })
}

export async function PutTrainingPlanTemplate(data) {
  return getRequest()(PutTrainingPlanTemplateApi, {
    method: 'PUT',
    data,
  })
}

export async function PutDescription(data) {
  return getRequest()(PutDescriptionApi, {
    method: 'PUT',
    data,
  })
}

export async function GetnewTrainingPlanCategories(data) {
  return getRequest()(GetnewTrainingPlanCategoriesApi, {
    method: 'GET',
    params: data,
  })
}

export async function GetNewTrainingPlanList(data) {
  return getRequest()(NewTrainingPlanListApi, {
    method: 'GET',
    params: data,
  })
}

export async function PostList(data) {
  return getRequest()(PostTestListApi, {
    method: 'POST',
    data,
  })
}


export async function PostNewTrainingPlanList(data) {
  return getRequest()(NewTrainingPlanListApi, {
    method: 'POST',
    data,
  })
}

export async function PutNewTrainingPlanList(data) {
  return getRequest()(NewTrainingPlanListApi, {
    method: 'PUT',
    data,
  })
}

export async function PostEditNewTrainingPlan(data) {
  return getRequest()(PostEditNewTrainingPlanApi, {
    method: 'POST',
    data,
  })
}

export async function GetNewTrainingPlanDetail(data) {
  return getRequest()(GetNewTrainingPlanApi.replace('{id}', data.id), {
    method: 'GET',
    params: data,
  })
}

export async function PostPrepareData(data, type) {
  return getRequest()(PostPrepare.replace('{prepare}', serviceMenu[type]), {
    method: 'POST',
    data,
  })
}

export async function GetPrepareList(data, type) {
  return getRequest()(GetList.replace('{prepare}', serviceMenu[type]), {
    method: 'GET',
    params: data,
  })
}

export async function GetPrepareEdit(id, type) {
  return getRequest()(GetPrepareForEdit.replace('{prepare}', serviceMenu[type]).replace('{id}', id), {
    method: 'GET',
  })
}

export async function PutPrepareData(data, id, type) {
  return getRequest()(PutPrepare.replace('{prepare}', serviceMenu[type]).replace('{id}', id), {
    method: 'PUT',
    data,
  })
}

export async function PutTrackDuration(data,) {
  return getRequest()(updateTrackDuration, {
    method: 'PUT',
    data,
  })
}

export async function DeletePrepareData(id, type) {
  return getRequest()(DeletePrepare.replace('{prepare}', serviceMenu[type]).replace('{id}', id), {
    method: 'DELETE',
  })
}

//{id}
export async function PrepareForEdit(data, type) {
  return getRequest()(PostPrepareForEdit.replace('{prepare}', serviceMenu[type]), {
    method: 'POST',
    data,
  })
}

const putTemplateRules = '/api/rocketAdmin/trainingPlanTemplate/rules'
const putOfflineRuls = '/api/rocketAdmin/offlineTrainingPlan/rules'

export async function UpdateTemplateRules(data) {
  return getRequest()(putTemplateRules, {
    method: 'PUT',
    data,
  })
}

export async function UpdateOfflineRules(data) {
  return getRequest()(putOfflineRuls, {
    method: 'PUT',
    data,
  })
}

export async function PostCourseContentDetail(data) {
  return getRequest()(PostCourseContentDetailApi, {
    method: 'POST',
    data,
  })
}