import React, { useEffect, useState } from 'react'
import { Avatar, Space, message, Modal } from 'antd'
import moment from 'moment'
import { connect } from 'dva'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import { genderLocale, getAdminName, getUserImg } from 'utils/utils'
import styles from './index.less'
import centerIcon from 'assets/icon_centre.png'
import dateIcon from 'assets/icon_date.png'
import emailIcon from 'assets/icon_date.png'
import genderIcon from 'assets/icon_gender.png'
import nameIcon from 'assets/icon_name.png'
import passwordIcon from 'assets/icon_password.png'
import phoneIcon from 'assets/icon_phone.png'
import iconResume from 'assets/Icon_resume.png'
import { useIntl } from 'umi'
import { FormOutlined } from '@ant-design/icons'
import { FreezeUser } from 'services/userManager'
import {
    GetUserLoginCode,
    PostGenerateLoginCode,
    PostResolvingLoginCode,
} from 'services/account'

const { confirm } = Modal

const validPasswordContent = (intl) => {
    return {
      Properties: [
        {
          EditorType: 'Input',
          ShowTitle: intl.formatMessage({ id: 'verifyPassword' }),
          FormKey: 'password',
          AdditionalData: null,
          Value: null,
          Setting: {
            Required: true,
            isPassword: true
          },
          Description: null,
        },
      ]
    }
  }

const AccountSetting = ({
    userInfo,
    currentUser,
    userAllTenant,
    canEditResumes,
    editAccountInfo,
    editEmail,
    editAmount,
    editPassword,
    editResumes,
    trainerDetail,
    downloadCertificate,
    trainingFeedback,
    isShowFeedback,
    isShowDownload,
    isShowLookFeedback,
    lookTrainingFeedback,
    editTelphone,
    isAdmin = false,
    reloadPage = () => {}
}) => {
    const [open, setOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState({});
    const [modalLoading, setModalLoading] = useState(false);
    const [newTenantList, setNewTenantList] = useState(userAllTenant)

    const { current, adminRoles: userAdminRoles = [] } = currentUser
    const { birthday, countryCode, email, gender, name, phoneNumber, picture, resumes, roles: adminRoles = [], id: userId } = userInfo || {}
    const telephone = `${countryCode ? countryCode + '-' : ''}${phoneNumber || ''}`

    const intl = useIntl()
    const { roles = [] } = current || {}

    const isStatusActive = (startTime, endTime) => {
        return moment().isSameOrAfter(moment(startTime)) && moment().isSameOrBefore(moment(endTime))
    }

    const showStatusText = (startTime, endTime) => {
        if (isStatusActive(startTime, endTime)) {
            return intl.formatMessage({ id: 'vaild' });
        } else if (moment().isAfter(moment(endTime))) {
            return intl.formatMessage({ id: 'expired' });
        } else {
            return intl.formatMessage({ id: 'NotActive' });
        }
    }

    const getLoginCode = async () => {
        const res = await GetUserLoginCode({ id: userId });

        if (res.response.ok) {
            const codeData = res?.data || [];

            if (codeData.length > 0) {
                const newData = newTenantList.map(item => {
                    const { id } = item || {};
                    const index = codeData.findIndex(code => code.tenantId === id)
                    if (index > -1) {
                        return {
                            ...item,
                            ...codeData[index],
                        }
                    } else {
                        return {
                            ...item
                        }
                    }
                })

                setNewTenantList(newData)
            }
        } else {
            const { error } = res && res.data || {}
            const { message: errorInfo } = error || {}
            errorInfo && message.error(errorInfo)
        }
    }

    useEffect(() => {
        if (!isAdmin && newTenantList && newTenantList.length > 0) {
            getLoginCode()
        }
    }, [userAllTenant])

    useEffect(() => {
        if (!isAdmin) {
            setNewTenantList(userAllTenant)
        }
    }, [userAllTenant])

    const { trainerCredentialses, vratAmount, assessmentAmount, courseAmount, planV2CourseAmount } = trainerDetail || {}

    const listSupport = (intl) => {
        return {
            creationTime: {
                showText: intl.formatMessage({ id: 'dateOfIssue' }),
                showType: 'Time',
            },
            displayName: {
                showText: intl.formatMessage({ id: 'nameOther' }),
                showType: 'Text',
            },
            picture: {
                showText: intl.formatMessage({ id: 'badge' }),
                showType: 'Text',
                render(text, record, index) {
                    const { trainingModule, endTime, startTime } = record || {}
                    const { qualificationMedals } = trainingModule || {}
                    const isActive = isStatusActive(startTime, endTime)
                    //筛选已经上传的勋章
                    const showPics = qualificationMedals.filter(item => isActive ? item.issuedStyle && item.issuedStyle.url : item.unissuedStyle && item.unissuedStyle.url)
                    return <Space>
                        {
                            showPics.map(item => {
                                const url = isActive ? item.issuedStyle.url : item.unissuedStyle.url
                                return <img key={item.id} src={url} />
                            })
                        }
                    </Space>
                }
            },
            credentialsCode: {
                showText: intl.formatMessage({ id: 'certificateNumber' }),
                showType: 'Text',
            },
            validData: {
                showText: intl.formatMessage({ id: 'availablePeriod' }),
                showType: 'Text',
            },
            trainerName: {
                showText: intl.formatMessage({ id: 'trainer' }),
                showType: 'Text',
            },
            certifier: {
                showText: intl.formatMessage({ id: 'certifier' }),
                showType: 'Text',
            },
            status: {
                showText: intl.formatMessage({ id: 'status' }),
                showType: 'Text',
            }
        }
    }

    const tableProps = {
        listData: trainerCredentialses ? trainerCredentialses.map(item => {
            const { startTime, endTime, trainingModule } = item || {}
            const { qualificationMedals } = trainingModule || {}
            const medalInfo = qualificationMedals && qualificationMedals[0]
            const { displayName } = medalInfo || {}
            return {
                ...item,
                validData: `${moment(startTime).format('YYYY/MM/DD')}~${moment(endTime).format('YYYY/MM/DD')}`,
                status: showStatusText(startTime, endTime),
                displayName
            }
        }) : [],
        listSupport: listSupport(intl),
        Actions: isShowFeedback || isShowDownload || isShowLookFeedback ? [
            {
                showText: intl.formatMessage({ id: 'trainfeedback' }),
                itemRender(record) {
                    const { trainingModule: { questionnaires } } = record
                    const hasQuestionnaires = questionnaires.length > 0
                    return isShowFeedback && hasQuestionnaires ? <a onClick={() => trainingFeedback(record)}>{intl.formatMessage({ id: 'trainfeedback' })}</a> : null
                }
            },
            {
                showText: intl.formatMessage({ id: 'downloadTheCertificate' }),
                itemRender(record) {
                    const { credential } = record || {}
                    const { url } = credential || {}
                    return isShowDownload && url ? (<a onClick={() => /*downloadLongRangeImg(credential)*/ downloadCertificate(record)}>{intl.formatMessage({ id: 'downloadTheCertificate' })}</a>) : null
                }
            },
            {
                showText: intl.formatMessage({ id: 'viewTainingFeedback' }),
                itemRender(record) {
                    return isShowLookFeedback ? <a onClick={() => lookTrainingFeedback(record)}>{intl.formatMessage({ id: 'viewTainingFeedback' })}</a> : null
                }
            }
        ] : null
    }

    const modalProps = {
        visible: open,
        content: validPasswordContent(intl),
        title: intl.formatMessage({ id: 'verifyPassword' }),
        loading: modalLoading,
        onOk: async (values) => {
            setModalLoading(true);
            const { id } = currentItem || {}
            const params = {
                userId,
                tenantId: id,
                username: email,
                ...values,
                needNew: true,
                clientId: 'rocket-client'
            }
            const res = await PostGenerateLoginCode(params)

            if (res.response.ok) {
                getLoginCode();
                setOpen(false)
            } else {
                const {
                    data: { error },
                } = res;
                message.error(error.message);
            }
            setModalLoading(false);
        },
        onCancel() {
            setOpen(false)
        }
    }

    // 企业管理员
    const isEnterAdmin = () => {
        return JSON.stringify(roles) !== '[]' ? roles.some(r => r.name === 'EnterpriseAdmin') : false
    }

    const isAreaAdmin = () => {
        return JSON.stringify(roles) !== '[]' ? roles.some(r => r.name === 'AreaAdmin') : false
    }

    // 是否是中心管理员
    const isCenterAdmin = () => {
        return JSON.stringify(roles) !== '[]' ? roles.some(r => r.name === 'CenterAdmin') : false
    }

    return (
        <div className={styles.settings}>
            <div className={styles.settingsTop}>
                <div className={styles.settingsAvatar}>
                    <div className={styles.settingsTitle}>{intl.formatMessage({ id: 'profilePhoto' })}</div>
                    {/* <Avatar size={128} src={picture && picture.url} /> */}
                    {getUserImg(userInfo, { height: '128px', width: '128px', borderRadius: '50%' })}
                </div>
                <div className={styles.settingsInfo}>
                    <div className={styles.settingsInfoTitle}>
                        <div className={styles.settingsInfoTitle1}>{intl.formatMessage({ id: 'accountSetting' })}</div>
                        {editAccountInfo && !isEnterAdmin() && <div className={styles.settingsInfoTitle2} onClick={editAccountInfo}>{intl.formatMessage({ id: 'edit' })}</div>}
                    </div>
                    <div className={styles.settingsItem}>
                        <div className={styles.settingsItemIcon}><img src={nameIcon} width={15} /></div>
                        <div className={styles.settingsItemTitle}>{intl.formatMessage({ id: 'name' })}：</div>
                        <div className={styles.settingsItemContent}>{name || intl.formatMessage({ id: 'noYet' })}</div>
                    </div>
                    {/* <div className={styles.settingsItem}>
                        <div className={styles.settingsItemIcon}><img src={phoneIcon} width={15} /></div>
                        <div className={styles.settingsItemTitle}>手机号：</div>
                        <div className={styles.settingsItemContent}>{telephone}</div>
                    </div> */}
                    <div className={styles.settingsItem}>
                        <div className={styles.settingsItemIcon}><img src={genderIcon} width={15} /></div>
                        <div className={styles.settingsItemTitle}>{intl.formatMessage({ id: 'gender' })}：</div>
                        <div className={styles.settingsItemContent}>{gender ? genderLocale(gender, intl) : ''}</div>
                    </div>
                    <div className={styles.settingsItem}>
                        <div className={styles.settingsItemIcon}><img src={dateIcon} width={15} /></div>
                        <div className={styles.settingsItemTitle}>{intl.formatMessage({ id: 'birthday' })}：</div>
                        <div className={styles.settingsItemContent}>{birthday ? (birthday.startsWith('0001') ? '' : (moment(birthday).isValid() ? moment(birthday).format('YYYY-MM-DD') : '')) : ''}</div>
                    </div>
                    {isAdmin && adminRoles && adminRoles.length > 0 &&
                        <div className={styles.settingsItem}>
                            <div className={styles.settingsItemIcon}><img src={centerIcon} width={15} /></div>
                            <div className={styles.settingsItemTitle}>{intl.formatMessage({ id: 'administratorType' })}</div>
                            <div className={styles.settingsItemContent}>
                                {
                                    adminRoles.map((item, index) => {
                                        return <div key={item}>
                                            {getAdminName(item) + intl.formatMessage({ id: 'administrator' })}
                                        </div>
                                    })
                                }
                            </div>
                        </div>}
                    {!isAdmin && <div className={styles.settingsItem}>
                        <div className={styles.settingsItemIcon}><img src={iconResume} width={15} /></div>
                        <div className={styles.settingsItemTitle}>{intl.formatMessage({ id: 'cv' })}：</div>
                        <div className={styles.settingsItemContent}>
                            {
                                resumes && resumes.length > 0 ?
                                    <React.Fragment>
                                        <div>
                                            {
                                                resumes.map(item => {
                                                    const { fileName, url } = item || {}
                                                    return (
                                                        <div
                                                            key={item.id}
                                                            onClick={() => {
                                                                localStorage.setItem('reviewfileUrl', url)
                                                                window.open('/system/reviewfile', '_blank')
                                                            }}
                                                        >
                                                            <a>{fileName}</a>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        {
                                            canEditResumes &&
                                            <div><FormOutlined style={{ cursor: 'pointer' }} onClick={editResumes} /></div>
                                        }

                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        {
                                            canEditResumes &&
                                            <FormOutlined style={{ cursor: 'pointer' }} onClick={editResumes} />
                                        }
                                    </React.Fragment>

                            }
                        </div>
                    </div>}
                    {
                        !isAdmin && newTenantList && newTenantList.length > 0 &&
                        <div className={styles.settingsItem}>
                            <div className={styles.settingsItemIcon}><img src={centerIcon} width={15} /></div>
                            <div className={styles.settingsItemTitle}>{intl.formatMessage({ id: 'center' })}：</div>
                            <div className={styles.settingsItemContent}>
                                {
                                    newTenantList.map((item, index) => {
                                        const { state, id, displayName, loginCode, isValid = false, expirationTime } = item || {};
                                        return (
                                            <div key={item.displayName + index}>
                                                <span>{item.displayName}-{item.roles.map(roleItem => roleItem.displayName).join('，')}</span>
                                                <span style={{ marginLeft: '10px' }}>{intl.formatMessage({ id: item.state === "Effective" ? "valid" : "invalid" })}</span>
                                                {userAdminRoles.length > 0 && <a style={{ marginLeft: '10px' }} onClick={() => {
                                                    const showText = intl.formatMessage({ id: state === 'Effective' ? 'freeze' : 'activate' })

                                                    confirm({
                                                        okText: showText,
                                                        cancelText: intl.formatMessage({ id: 'cancel' }),
                                                        title: <span style={{ color: 'red' }}>{`${intl.formatMessage({ id: 'areYouSureTo' })} ${showText} ${`${displayName}-${name}` || '(No Name)'}`}</span>,
                                                        onOk: async () => {
                                                            const newState = state === 'Effective' ? 'Freeze' : 'Unfreeze'
                                                            const res = await FreezeUser({
                                                                operate: newState, userId, tenantId: id
                                                            })

                                                            if (res.response.ok) {
                                                                message.success(state === 'Effective' ? '已冻结！' : '已重新启用！')

                                                                setNewTenantList(newTenantList.map(i => {
                                                                    if(i.id === id) {
                                                                        return {
                                                                            ...i,
                                                                            state: state === 'Effective' ? 'Invalid' : 'Effective',
                                                                        }
                                                                    }
                                                                    return i;
                                                                }))
                                                                reloadPage()
                                                            } else {
                                                                const { error } = res.data
                                                                message.error(error.message)
                                                            }                                                        },
                                                    });
                                                }}>
                                                    {intl.formatMessage({ id: 'switchStatus' })}
                                                </a>}
                                                {state === 'Effective' && <Space style={{ marginLeft: '10px' }}>
                                                    <a onClick={() => {
                                                        setOpen(true)
                                                        setCurrentItem(item)
                                                    }}>
                                                        {intl.formatMessage({ id: loginCode ? 'Refresh the ID code' : 'Generate the ID code' })}
                                                    </a>
                                                    <span>
                                                        {loginCode ? isValid ? `${loginCode} (${expirationTime ? moment(expirationTime).format('YYYY-MM-DD HH:mm:ss') : '∞'})` : intl.formatMessage({ id: 'expiredOther' }) : ''}
                                                    </span>
                                                </Space>}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }
                    {
                        !isCenterAdmin() && !isAdmin &&
                        <div className={styles.settingsItem}>
                            <div className={styles.settingsItemIcon}><img src={centerIcon} width={15} /></div>
                            <div className={styles.settingsItemTitle}>{intl.formatMessage({ id: 'remainingTimes' })}</div>
                            <div className={styles.settingsItemContent}>
                                <div>
                                    <span>{intl.formatMessage({ id: 'remainingVRAT' })}&#32;</span>
                                    <span style={{ marginLeft: '10px' }}>{vratAmount > 500 ? intl.formatMessage({ id: 'unlimitedTimes' }) : vratAmount}&#12288;</span>
                                </div>
                                <div>
                                    <span>{intl.formatMessage({ id: 'remainingAssessments' })}&#32;</span>
                                    <span style={{ marginLeft: '10px' }}>{assessmentAmount > 500 ? intl.formatMessage({ id: 'unlimitedTimes' }) : assessmentAmount}&#12288;</span>
                                </div>
                                <div>
                                    <span>{intl.formatMessage({ id: 'remainingTrainingSessions' })}&#32;</span>
                                    <span style={{ marginLeft: '10px' }}>{courseAmount > 500 ? intl.formatMessage({ id: 'unlimitedTimes' }) : courseAmount}&#12288;</span>
                                </div>
                                <div>
                                    <span>{intl.formatMessage({ id: 'RemainingHFSTraining' })}&#32;</span>
                                    <span style={{ marginLeft: '10px' }}>{planV2CourseAmount > 500 ? intl.formatMessage({ id: 'unlimitedTimes' }) : planV2CourseAmount}&#12288;</span>
                                </div>
                            </div>
                            {editAmount && !(isEnterAdmin() || isAreaAdmin()) && <div className={styles.settingsItemOperate} onClick={editAmount}>{intl.formatMessage({ id: 'edit' })}</div>}
                        </div>
                    }
                </div>
                <div className={styles.settingsChange}>
                    <div className={styles.settingsTitle}>{intl.formatMessage({ id: 'loginSecurity' })}</div>
                    <div className={styles.settingsChangeItem}>
                        <div className={styles.settingsItemIcon}><img src={emailIcon} width={15} /></div>
                        <div className={styles.settingsItemTitle}>{intl.formatMessage({ id: 'email' })}：</div>
                        <div className={styles.settingsItemContent}>{email}</div>
                        {editEmail && email && <div className={styles.settingsItemOperate} onClick={editEmail}>{intl.formatMessage({ id: 'change' })}</div>}
                    </div>
                    <div className={styles.settingsChangeItem}>
                        <div className={styles.settingsItemIcon}><img src={passwordIcon} width={15} /></div>
                        <div className={styles.settingsItemTitle}>{intl.formatMessage({ id: 'password' })}：</div>
                        <div className={styles.settingsItemContent}>**********</div>
                        {editPassword && <div className={styles.settingsItemOperate} onClick={editPassword}>{intl.formatMessage({ id: 'change' })}</div>}
                    </div>
                    <div className={styles.settingsChangeItem}>
                        <div className={styles.settingsItemIcon}><img src={phoneIcon} width={15} /></div>
                        <div className={styles.settingsItemTitle}>{intl.formatMessage({ id: 'phone' })}：</div>
                        <div className={styles.settingsItemContent}>{telephone || '18888888888'}</div>
                        {editTelphone && telephone && <div className={styles.settingsItemOperate} onClick={editTelphone}>{intl.formatMessage({ id: 'change' })}</div>}
                    </div>
                </div>
            </div>
            {/* {!isAdmin && <div className={styles.settingsBottom}>
                <div className={styles.settingsBottomTitle}>{intl.formatMessage({ id: 'certificationList' })}</div>
                <MagicTable {...tableProps} />
            </div>} */}
            <MagicFormModal {...modalProps} />
        </div>
    )
}

export default connect(({ user }) => ({ currentUser: user.currentUser }))(
    AccountSetting
)