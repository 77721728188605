import React, { useState, useEffect } from 'react';
import { connect, useIntl } from 'umi';
import { Tabs, Spin } from 'antd';

import { GetTrainingList } from 'services/trainingSystem';

import PersonalInfoCard from './components/PersonalInfoCard';
import CertProgress from './components/CertProgress';
import LearnTab from './components/LearnTab';
import TrainingTab from './components/TrainingTab';
import SupervisionTab from './components/SupervisionTab';
import TestTab from './components/TestTab';
import CertificateTab from './components/CertificateTab';
import ApplyForm from './components/ApplyForm';

import styles from './index.less';

const PersonalCenterCard = ({
  dispatch,
  userInfo,
  roleType, // 自己定义的, 外部传入的 { centerAdmin："代表中心管理员看别人的数据", personal:　"看自己" , admin }
  tabkey,
  tabList,
  detail,
  isFininshed,
  onClickItem,
  onChangeTabs,
  showTrainingApplication,
  startStudy,
  awardQualifications,
  awardModuleList,
  trainerDetail,
  loading,
  showSupervision,
  goToPay,
  payUrl,
  payStatusPollingRes,
  currentUser,
  applyDrawerVisible
}) => {
  const intl = useIntl();

  const { id: trainerId } = userInfo || {};

  const [certList, setCertList] = useState(null);
  const [certListLoading, setCertListLoading] = useState(false);
  const [tabActiveKey, setTabActiveKey] = useState('');
  const [allowReloadTab, setAllowReloadTab] = useState(false); // 是否更新Tab页面
  const [allowReloadProgress, setAllowReloadProgress] = useState(false); // 是否更新上方最新进度详情

  const getCertList = async () => {
    setCertListLoading(true);
    const res = await GetTrainingList({ trainerId });
    if (res.response.ok) {
      const { data = [] } = res || {};
      setCertList(data);
    }
    setCertListLoading(false);
  };

  useEffect(() => {
    if (trainerId) {
      getCertList();
    }
  }, [trainerId]);

  useEffect(() => {
    return () => {
      setCertList(null);
    }
  }, [])

  const personalProps = {
    userInfo,
    roleType, // 自己定义的, 外部传入的 { centerAdmin："代表中心管理员看别人的数据", personal:　"看自己" , admin }
    awardQualifications,
    awardModuleList,
    trainerDetail,
    showSupervision,
    currentUser,
  };

  const learnTabProps = {
    dispatch,
    userInfo,
    roleType, // 自己定义的, 外部传入的 { centerAdmin："代表中心管理员看别人的数据", personal:　"看自己" , admin }
    tabkey,
    tabList,
    detail,
    isFininshed,
    onClickItem,
    onChangeTabs,
    showTrainingApplication,
    startStudy,
    trainerDetail,
    loading,
    goToPay,
    payUrl,
    payStatusPollingRes,
  };

  const trainingTabProps = {
    roleType,
    userInfo,
    allowReloadTab,
    onChangeAllowReloadProgress: () => {
      setAllowReloadProgress(!allowReloadProgress);
    }
  };

  const tabItems = [
    {
      label: intl.formatMessage({ id: 'tabLearning' }),
      key: 'Learning',
      children: <LearnTab {...learnTabProps} />,
    },
    {
      label: intl.formatMessage({ id: 'tabTraining' }),
      key: 'Training',
      children: <TrainingTab {...trainingTabProps} />,
    },
    {
      label: intl.formatMessage({ id: 'tabSupervision' }),
      key: 'Supervision',
      children: <SupervisionTab {...trainingTabProps} />,
    },
    {
      label: intl.formatMessage({ id: 'tabTest' }),
      key: 'Test',
      children: <TestTab {...trainingTabProps} />,
    },
    {
      label: intl.formatMessage({ id: 'tabCertificateList' }),
      key: 'CertificateList',
      children: <CertificateTab {...trainingTabProps} />,
    },
  ];

  const progressProps = {
    userInfo,
    roleType,
    certList,
    getCertList,
    allowReloadProgress,
    showTrainingApplication,
    onChangeStep: (key) => {
      setTabActiveKey(key);
    },
    onChangeAllowReloadTab: () => {
      setAllowReloadTab(!allowReloadTab);
    }
  };

  const applyFormProps = {
    userInfo,
    roleType,
    showTrainingApplication,
    applyDrawerVisible,
  };

  return (
    <div className={styles.personalCenterCard}>
      <PersonalInfoCard {...personalProps} />
      {certListLoading ? (
        <div style={{ marginTop: '50px', width: '100%', textAlign: 'center' }}>
          <Spin />
        </div>
      ) : (
        <>
          {certList?.length > 0 ? (
            <>
              <CertProgress {...progressProps} />
              {
                tabActiveKey && (
                  <Tabs
                    destroyInactiveTabPane={true}
                    activeKey={tabActiveKey}
                    items={tabItems}
                    onChange={(key) => setTabActiveKey(key)}
                  />
                )
              }
            </>
          ) : (
            certList !== null && <ApplyForm {...applyFormProps} />
          )}
        </>
      )}
    </div>
  );
};

export default connect(({ user }) => ({ currentUser: user.currentUser }))(
  PersonalCenterCard,
);
