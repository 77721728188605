import getRequest from 'utils/request';
import { examUrl, payBaseURL } from 'utils/config';

const PostExamListApi = '/api/examination/list';
const getExamListApi = '/api/examination';
const GetExamDetailApi = '/api/examination/detail';
const GetExamInfoApi = '/api/examination/prepare';
const PostExamPaperApi = '/api/rocketAdmin/Examination/generateExamPaper';
const PutExamAnswerApi = '/api/examination/userExamination/answer';
const PostStartExamApi = '/api/examination/userExamination/start';
const PostExamResultListApi = '/api/rocketAdmin/Examination/list';
const GetExamResutlApi = '/api/examination/userExamination/details';
const GetOtherResutlApi = '/api/rocketAdmin/trainerTrainingExamination';
const PutExamApi = '/api/examination/userExamination/makeupExamination';
const GetAgainExamApi = '/api/rocketAdmin/Examination/makeup';
const PostPayResutlApi = '/api/payment/listByCodes';
const GetFirstExamApi = '/api/rocketAdmin/Examination/first';

export async function GetFirstExam(data) {
  return getRequest()(GetFirstExamApi, {
    method: 'POST',
    data,
  });
}

export async function PostPayResult(data) {
  return getRequest({ myBaseURL: payBaseURL, credentials: false })(
    PostPayResutlApi,
    {
      method: 'POST',
      data,
    },
  );
}

export async function GetExamDetail(data) {
  return getRequest({ myBaseURL: examUrl, credentials: false })(
    GetExamDetailApi,
    {
      method: 'GET',
      params: data,
    },
  );
}

export async function GetExamInfo(data) {
  return getRequest({ myBaseURL: examUrl, credentials: false })(
    GetExamInfoApi,
    {
      method: 'GET',
      params: data,
    },
  );
}

export async function GetAgainExam(data) {
  return getRequest()(GetAgainExamApi, {
    method: 'POST',
    data,
  });
}

export async function GetExamList(data) {
  return getRequest({ myBaseURL: examUrl, credentials: false })(
    PostExamListApi,
    {
      method: 'POST',
      data,
    },
  );
}

export async function GetExamListData(data) {
  return getRequest({})(PostExamResultListApi, {
    method: 'POST',
    data,
  });
}

export async function GetOtherExamResult(data) {
  return getRequest()(GetOtherResutlApi, {
    method: 'GET',
    params: data,
  });
}

export async function PostExamList(data) {
  return getRequest({ myBaseURL: examUrl, credentials: false })(
    getExamListApi,
    {
      method: 'POST',
      data,
    },
  );
}

export async function PutExamList(data) {
  return getRequest({ myBaseURL: examUrl, credentials: false })(
    getExamListApi,
    {
      method: 'PUT',
      data,
    },
  );
}

export async function PostExamPaper(data) {
  return getRequest()(PostExamPaperApi, {
    method: 'GET',
    params: data,
  });
}

export async function PutExamPaper(data) {
  return getRequest({ myBaseURL: examUrl, credentials: false })(
    PutExamAnswerApi,
    {
      method: 'PUT',
      data,
    },
  );
}

export async function PostStartExamPaper(data) {
  return getRequest({ myBaseURL: examUrl, credentials: false })(
    PostStartExamApi,
    {
      method: 'PUT',
      data,
    },
  );
}

export async function GetExamResult(data) {
  return getRequest({ myBaseURL: examUrl, credentials: false })(
    GetExamResutlApi,
    {
      method: 'GET',
      params: data,
    },
  );
}

export async function PutExamPaperAgain(data) {
  return getRequest({ myBaseURL: examUrl, credentials: false })(PutExamApi, {
    method: 'PUT',
    data,
  });
}
