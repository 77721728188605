
import { Pagination } from 'antd'
import { useIntl } from 'umi'

const PaginationCom = ({ onPageChange, pageIndex, current, pageSize, total = 10 }) => {
  const intl = useIntl()

  const totalShowText = intl.formatMessage({ id: 'total' })
  const itemShowText = intl.formatMessage({ id: 'items' })
  return (
    <Pagination
      style={{ textAlign: 'left', marginTop: 10 }}
      showTotal={total => `${totalShowText} ${total} ${itemShowText}`}
      onChange={onPageChange}
      onShowSizeChange={onPageChange}
      current={current || pageIndex}
      pageSize={pageSize}
      // showSizeChanger
      showQuickJumper
      pageSizeOptions={['6', '12', '18', '24']}
      size="small"
      total={total}
    />
  )
}

export default PaginationCom
