import { Form, Radio, DatePicker, TimePicker, Input, InputNumber, Slider, Upload, Image, Button } from 'antd'
import moment from 'moment';
import config from 'utils/config'
import { getLocale } from 'umi'
import { getAuthHeader } from 'cognitiveleap-core-us/utils/auth'
import { UploadOutlined } from '@ant-design/icons'
import { range } from 'utils/utils'
import { IntlManager } from 'utils/helper'

const { baseURL, uploadFeedbackImage, uploadFeedbackVideo } = config
// 反馈多语言 匹配zh en key
const language = getLocale() === 'zh-CN' ? 'zh' : 'en';

const FormItem = Form.Item
const { TextArea } = Input;

export function showAttributeName(type, value, item) {
  switch (type) {
    case "Rating":
      if (item.programFeedbackProperty && item.programFeedbackProperty.extraProperties) {
        const { showText: ratingOptions = [] } = item.programFeedbackProperty.extraProperties[language] ?? item.programFeedbackProperty.extraProperties;
        return ratingOptions[value - 1];
      }
    // const ratingOptions = item.programFeedbackProperty && item.programFeedbackProperty.extraProperties && item.programFeedbackProperty.extraProperties.showText
    // return ratingOptions[value - 1];
    case "Selection":
      if (item.programFeedbackProperty && item.programFeedbackProperty.extraProperties) {
        const { options: selectionOptions = [] } = item.programFeedbackProperty.extraProperties[language] ?? item.programFeedbackProperty.extraProperties;
        const selectionSelected = selectionOptions.find(item => item.value == value);
        return selectionSelected.text;
      }
    // const selectionOptions = item.programFeedbackProperty && item.programFeedbackProperty.extraProperties && item.programFeedbackProperty.extraProperties.options
    // const selectionSelected = selectionOptions.find(item => item.value == value)
    // return selectionSelected.text
    case 'TimePicker':
      return moment(value).isValid() ? moment(value).format('YYYY/MM/DD HH:mm') : ''
    case "ImagePicker":
      const imageList = value ? JSON.parse(value) : null
      return (
        <div>
          {
            imageList && Array.isArray(imageList) && imageList.length > 0 ?
              imageList.map((item, index) => {
                return <Image
                  width={100}
                  height={100}
                  src={item}
                  key={index}
                  style={{ objectFit: 'cover' }}
                />
              })
              :
              null
          }
        </div>
      )
    case "VideoPicker":
      const videoList = value ? JSON.parse(value) : null
      return (
        <div>
          {
            videoList && Array.isArray(videoList) && videoList.length > 0 ?
              videoList.map((item, index) => {
                return <video
                  height={300}
                  src={item}
                  key={index}
                  controls={true}
                />
              })
              :
              null
          }
        </div>
      )
    default:
      return value;
  }
}

export function showAttributeDOM(type, extraProperties, parmas, value) {
  const formatMessage = IntlManager.intl.formatMessage
  switch (type) {
    case "Rating":
      const { showText = [] } = extraProperties[language] ?? extraProperties
      return (
        <Radio.Group>
          {
            showText.length > 0 && showText.map((item, index) => {
              return (
                <Radio.Button key={index + 1} value={`${index + 1}`}>
                  {item}
                </Radio.Button>
              )
            })}
        </Radio.Group>
      )
    case "Selection":
      const { options = [] } = extraProperties[language] ?? extraProperties
      return (
        <Radio.Group>
          {
            options.length > 0 && options.map(item => {
              const { text, value } = item
              return (
                <Radio.Button key={value} value={value}>
                  {text}
                </Radio.Button>
              )
            })
          }
        </Radio.Group>
      )
    case "Duration":
      const { minStep, secStep, showSec, desc } = extraProperties || {}
      const isShowSec = showSec === 'true'
      return <TimePicker
        placeholder={desc || formatMessage({ id: 'pleaseSelectDuration' })}
        format={isShowSec ? 'HH:mm:ss' : 'HH:mm'}
        minuteStep={minStep ? parseInt(minStep) : 5}
        secondStep={secStep ? parseInt(secStep) : 5}
        inputReadOnly={true}
      />
    case "String":
      const { defaultLines } = extraProperties || {}
      return <TextArea rows={defaultLines || 7} />;
    case "TimePicker":
      const { maxTime, minuteStep } = extraProperties
      const maxEndTime = {
        today: moment().local(),
        current: moment().local()
      }[maxTime]

      const { minStartTime, format: formataa, placeholder, disabledHours, showNow, defaultValue } = parmas
      return <DatePicker
        disabledDate={(current) => {
          const customMinStartTime = {
            today: current.isBefore(minStartTime, 'day'),
            current: current.isBefore(minStartTime)
          }[maxTime]
          const customMaxStartTime = {
            today: current.isAfter(maxEndTime, 'day'),
            current: current.isAfter()
          }[maxTime]

          const minNotOk = minStartTime != null ? customMinStartTime : false
          const maxNotOk = maxEndTime != null ? customMaxStartTime : false
          return minNotOk || maxNotOk
        }}
        disabledTime={(current) => {
          if (maxTime === "current") {
            if (current && moment(current).format("YYYY/MM/DD") === moment().format("YYYY/MM/DD")) {
              const hours = moment().format("HH")
              return {
                disabledHours: () => range(parseInt(hours) + 2, 24),
                disabledMinutes: () => []
              }
            }
          }
          return {
            disabledHours: () => [],
            disabledMinutes: () => [],
            disabledSeconds: () => []
          }
        }}
        format={formataa}
        showTime={{
          showTime: true,
          defaultValue: defaultValue || null
        }}
        minuteStep={parseInt(minuteStep) > 0 ? parseInt(minuteStep) : 1}
        placeholder={placeholder || formatMessage({ id: 'pleaseSelectDate' })}
        // disabledHours={() => disabledHours || []}
        showNow={showNow}
      />;
    case "Number":
      const { maxValue, minValue, step, style } = extraProperties || {}
      const min = minValue !== null ? parseInt(minValue) : 0
      if (style) {
        const sliderMaxValue = maxValue !== null ? parseInt(maxValue) : 100
        return <Slider
          min={min}
          max={sliderMaxValue}
          step={step !== null ? parseInt(step) : 1}
          tipFormatter={value => value}
          marks={{
            [min]: min,
            [sliderMaxValue]: sliderMaxValue
          }}
        />
      }
      else
        return <InputNumber
          max={maxValue !== null ? parseInt(maxValue) : Infinity}
          min={minValue !== null ? parseInt(minValue) : 0}
          step={step !== null ? parseInt(step) : 1}
        />
    case "ImagePicker":
      const { title: imagePickerTitle } = extraProperties || {}
      const imageUploadProps = {
        accept: 'image/*',
        action: baseURL + uploadFeedbackImage,
        headers: getAuthHeader(),
        listType: "picture",
        defaultFileList: value
      }
      return <Upload {...imageUploadProps}>
        <Button icon={<UploadOutlined />}>{imagePickerTitle || formatMessage({ id: 'uploadAImage' })}</Button>
      </Upload>
    case "VideoPicker":
      const { title: videoPickerTitle } = extraProperties || {}
      const videoUploadProps = {
        accept: 'video/*',
        action: baseURL + uploadFeedbackVideo,
        headers: getAuthHeader(),
        defaultFileList: value
      }
      return <Upload {...videoUploadProps}>
        <Button icon={<UploadOutlined />}>{videoPickerTitle || formatMessage({ id: 'uploadAVideo' })}</Button>
      </Upload>
    default:
      return <div></div>;
  }
}

export function formatSubmitValue(type, value, parmas) {
  switch (type) {
    case 'TimePicker':
      return value ? moment(value).format() : ''
    case "Duration":
      const { extraProperties } = parmas || {}
      const { showSec } = extraProperties || {}
      const format = showSec === 'true' ? 'HH:mm:ss' : 'HH:mm'
      return value ? moment(value).format(format) : ''
    case "String":
      return value ? value : ''
    case "ImagePicker":
      const { fileList: imageFileList } = value || {}
      const resultImageFile = imageFileList ? imageFileList.map(item => item && item.response && item.response[0] && item.response[0].url || '') : ''
      return JSON.stringify(resultImageFile)
    case "VideoPicker":
      const { fileList: videoFileList } = value || {}
      const resultVideoFile = videoFileList ? videoFileList.map(item => item && item.response && item.response[0] && item.response[0].url || '') : ''
      return JSON.stringify(resultVideoFile)
    default:
      return value
  }
}

export function getFeedbackInitValue(type, value) {
  switch (type) {
    case "Duration":
      const { showSec } = value || {}
      const isShowSec = showSec === 'true'
      return moment(isShowSec ? '00:00:00' : '00:00', isShowSec ? 'HH:mm:ss' : 'HH:mm');
    case "Number":
      const { minValue, modalDefaultValue } = value || {}
      const min = minValue !== null ? parseInt(minValue) : 0
      return modalDefaultValue !== null ? parseInt(modalDefaultValue) : min
    default:
      return null
  }
}

export function getFeedbackValue(type, value) {
  switch (type) {
    case "Duration":
      return moment(value, 'HH:mm:ss');
    case 'TimePicker':
      return moment(value, 'YYYY/MM/DD HH:mm')
    case 'VideoPicker':
    case 'ImagePicker':
      const myValue = value ? JSON.parse(value) : []
      return myValue && myValue.length > 0 ? myValue.map((item, index) => {
        return {
          uid: item,
          url: item,
          name: item,
          status: "done",
        }
      }) : []
    default:
      return value
  }
}