import { useState } from 'react';
import { message } from 'antd';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';
import { PostPass, PostReject, PostCancel } from 'services/device';

const showModalTitle = {
  pass: '通过注册申请',
  reject: '驳回注册申请',
  logout: '注销设备',
};

const showPlaceholder = {
  pass: '请输入通过注册申请的备注',
  reject: '请输入驳回注册申请的备注（例如，驳回原因） ',
  logout: '请输入注销设备的备注（例如，注销原因）',
};

const DeviceModal = ({ visible, currentState, onCloseModal, reloadPage }) => {
  const [loading, setLoading] = useState(false);

  const { type, record } = currentState || {};

  const {
    DeviceSN,
    TenantName,
    RegisterDeviceId,
    DeviceDetailDtos = [],
  } = record || {};

  const contentMenu = {
    Properties: [
      {
        EditorType: 'Input',
        ShowTitle: '设备序列号',
        FormKey: 'DeviceSN',
        AdditionalData: null,
        Value: DeviceSN,
        Setting: {
          Required: false,
          Disabled: true,
        },
        Description: '',
      },
      {
        EditorType: 'Input',
        ShowTitle: '所属中心',
        FormKey: 'TenantName',
        AdditionalData: null,
        Value: TenantName,
        Setting: {
          Required: false,
          Disabled: true,
        },
        Description: '',
      },
      {
        EditorType: 'Input',
        ShowTitle: '备注',
        FormKey: 'remark',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: type === 'logout',
          Props: {
            placeholder: showPlaceholder[type],
          },
        },
        Description: '',
      },
    ],
  };

  const modalProps = {
    title: showModalTitle[type],
    visible,
    content: contentMenu,
    loading,
    onCancel() {
      onCloseModal();
    },
    onOk: async (value) => {
      setLoading(true);

      const { remark } = value || {};

      let res;

      const props = {
        registerDeviceId:
          DeviceDetailDtos.length > 0
            ? DeviceDetailDtos[0].RegisterDeviceId
            : RegisterDeviceId,
        sourceType: 'CLWeb',
        remark,
      };

      switch (type) {
        case 'pass':
          res = await PostPass(props);
          break;
        case 'reject':
          res = await PostReject(props);
          break;
        case 'logout':
          res = await PostCancel(props);
          break;
      }

      if (res.response.ok) {
        reloadPage();
      } else {
        const {
          data: { Error },
        } = res;
        message.error(Error?.Message);
      }

      setLoading(false);
      onCloseModal();
    },
  };

  return <MagicFormModal {...modalProps} />;
};

export default DeviceModal;
