import React from 'react'

import { Button, Modal } from 'antd'
import { IntlManager } from 'utils/helper'
import styles from './index.less'


const ReleaseModal = ({ visible, data, onCancel, signToRead }) => {
    const intl = IntlManager.MyIntl()
    const { title, extraProperties, isRead } = data || {}
    const { Summary, Attachment, AttachmentNames } = extraProperties || {}

    const reviewFile = (url) => {
        localStorage.setItem('reviewfileUrl', url)
        window.open('/system/reviewfile', '_blank')
    }

    return (
        <Modal
            footer={null}
            open={visible}
            onCancel={onCancel}
            title={<b>{intl("systemMessageDetails")}</b>}
            className={styles.releaseModal}
        >
            <div className={styles.releaseModalContent}>
                <div className={styles.releaseModalContentTitle}>{title}</div>
                {
                    Summary &&
                    <div className={styles.releaseModalContentMain}>
                        <div dangerouslySetInnerHTML={{ __html: Summary }}></div>
                    </div>
                }
                {
                    Array.isArray(AttachmentNames) && AttachmentNames.length > 0 &&
                    <div className={styles.attachment}>
                        {
                            AttachmentNames.map((item, index) => {
                                return <div className={styles.attachmentItem} onClick={() => reviewFile(Attachment[index])} key={index}><a>{item}</a></div>
                            })
                        }
                    </div>
                }
            </div>
            {
                !isRead &&
                <div className={styles.releaseModalContentBottom}>
                    <Button className={styles.releaseModalContentButton} type='primary' onClick={() => signToRead(data)}>{intl("checked")}</Button>
                </div>
            }
        </Modal>
    )
}

export default ReleaseModal