import React, { useState } from 'react';
import moment from 'moment';
import { useIntl, getLocale } from 'umi';
import { AuditOutlined } from '@ant-design/icons';
import { Button, Space, Popover } from 'antd';
import { getUserImg } from 'utils/utils';
import TrainWithClassDrawer from 'components/TrainWithClassDrawer';
import NumberAnimate from 'cognitiveleap-core-us/components/Animate/Number';

import styles from '../index.less';

const isZh = getLocale() === 'zh-CN';

const PersonalInfoCard = ({
  userInfo,
  roleType, // 自己定义的, 外部传入的 { centerAdmin："代表中心管理员看别人的数据", personal:　"看自己" , admin }
  awardQualifications,
  awardModuleList,
  trainerDetail,
  showSupervision,
  currentUser,
}) => {
  const intl = useIntl();
  const [trainClassVisible, setTrainClassVisible] = useState(false); // 培训带课情况Drawer

  const { current } = currentUser;
  const { roles = [] } = current || {};

  // 前台，财务不显示见习督导记录
  let roleName = '';
  if (roles.length > 0) {
    const { name } = roles[0];
    roleName = name;
  }
  const showRecordBtn =
    roleType !== 'centerAdmin' &&
    !(roleName === 'FrontDesk' || roleName === 'Finance');

  let modelKeys = [];
  const { name } = userInfo || {};

  const {
    trainerCredentialses = [],
    completedSessions,
    childrenTrained,
  } = trainerDetail || {};
  modelKeys =
    trainerCredentialses &&
    trainerCredentialses.map((item) => {
      const { trainingModule, endTime } = item || {};
      const { name } = trainingModule || {};
      if (moment(endTime).isSameOrAfter(moment())) return name;
      else return null;
    });

  const trainWithClassProps = {
    visible: trainClassVisible,
    userInfo: userInfo,
    onCloseDraw: () => {
      setTrainClassVisible(false);
    },
  };

  return (
    <div>
      <div className={styles.simpleInfoCard}>
        <Space direction="vertical">
          <Space>
            {getUserImg(userInfo, { height: '50px', width: '50px' })}
            <div className={styles.simpleInfoCardNameTopContentName}>
              {name}
            </div>
          </Space>
          <div className={styles.simpleInfoCardNameBottom}>
            {trainerCredentialses &&
              trainerCredentialses.length > 0 &&
              trainerCredentialses.map((item) => {
                const { endTime, trainingModule, credentialsCode, startTime } =
                  item || {};
                const { qualificationMedals } = trainingModule || {};
                const medalInfo = qualificationMedals && qualificationMedals[0];
                const { issuedStyle, displayName } = medalInfo || {};
                const { url } = issuedStyle || {};
                return moment(endTime).isSameOrAfter(moment()) ? (
                  <Popover
                    key={item.id}
                    content={
                      <div style={{ fontWeight: '600', fontSize: '16px' }}>
                        <div>
                          {intl.formatMessage({ id: 'certificate' })}：
                          {displayName || ''}
                        </div>
                        <div>
                          {intl.formatMessage({ id: 'certificateNumber' })}：
                          {credentialsCode || ''}
                        </div>
                        <div>
                          {intl.formatMessage({ id: 'availablePeriod' })}：
                          {startTime
                            ? moment(startTime).format(
                                isZh ? 'YYYY/MM/DD' : 'MM/DD/YYYY',
                              )
                            : ''}{' '}
                          -{' '}
                          {endTime
                            ? moment(endTime).format(
                                isZh ? 'YYYY/MM/DD' : 'MM/DD/YYYY',
                              )
                            : ''}
                        </div>
                      </div>
                    }
                  >
                    <img src={url} style={{ cursor: 'pointer' }} />
                  </Popover>
                ) : (
                  <span key={item.id}></span>
                );
              })}
          </div>
        </Space>
        {/* {roleType === 'admin' && (
          <Space wrap={true}>
            {awardModuleList &&
              awardModuleList.length > 0 &&
              awardModuleList.map((item) => {
                const { displayName, name } = item || {};
                return (
                  <Button
                    key={item.id}
                    onClick={() =>
                      awardQualifications && awardQualifications(item)
                    }
                    className={styles.simpleInfoCardNameTopBtn}
                    disabled={modelKeys && modelKeys.includes(name)}
                  >
                    {intl.formatMessage({ id: 'Issue' })}
                    {displayName}
                    {intl.formatMessage({ id: 'qulification' })}
                  </Button>
                );
              })}
          </Space>
        )} */}
        <Space size={40}>
          <Space direction="vertical" align="center">
            <div style={{ fontSize: '18px', fontWeight: '500', width: '120px', textAlign: 'center' }}>
              {intl.formatMessage({ id: 'Completed Sessions' })}
            </div>
            <div style={{ fontSize: '22px', fontWeight: '600' }}>
              <NumberAnimate>{completedSessions || 0}</NumberAnimate>
            </div>
          </Space>
          <Space direction="vertical" align="center">
            <div style={{ fontSize: '18px', fontWeight: '500', width: '120px', textAlign: 'center' }}>
              {intl.formatMessage({ id: 'Children Trained' })}
            </div>
            <div style={{ fontSize: '22px', fontWeight: '600' }}>
              <NumberAnimate>{childrenTrained || 0}</NumberAnimate>
            </div>
          </Space>
          {/* {showRecordBtn && (
            <Button onClick={showSupervision} icon={<AuditOutlined />}>
              {intl.formatMessage({ id: 'supervisionReport' })}
            </Button>
          )}
          <Button
            onClick={() => setTrainClassVisible(true)}
            icon={<AuditOutlined />}
          >
            {intl.formatMessage({ id: 'Training and Classes' })}
          </Button> */}
        </Space>
      </div>
      {/* 带课情况 */}
      {trainClassVisible && <TrainWithClassDrawer {...trainWithClassProps} />}
    </div>
  );
};

export default PersonalInfoCard;
