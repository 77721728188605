import React from 'react'
// import PDF from 'react-pdf-js'
import { Document, Page, pdfjs } from 'react-pdf';
import { degrees, PDFDocument, rgb } from 'pdf-lib';
import { connect } from 'umi';
import fontkit from "@pdf-lib/fontkit";
import { Spin, Pagination, Popover } from 'antd'
import { eventDebounce } from 'cognitiveleap-core-us/utils/utils'
import styles from './index.less'
import leftCircleFillGrey from 'assets/left-circle-fill-grey.png'
import leftCircleFill from 'assets/left-circle-fill.png'
import rightCircleFillGrey from 'assets/right-circle-fill-grey.png'
import rightCircleFill from 'assets/right-circle-fill.png'
import { IntlManager } from 'utils/helper'

//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = `//cl-cdn.oss-cn-shanghai.aliyuncs.com/js/pdf.worker.min.2.12.313.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.js`;

class ShowPdf extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            numPages: 0,
            current: 1,
            url: '',
            pdfWidth: 0,
            pdfHeight: 0,
            renderMode: 'svg',
            pdfUrl: ''
        }
    }

    onLoadSuccess = async (obj) => {
        const page = await obj.getPage(1);
        const { _pdfInfo: { numPages } } = obj
        const { _pageInfo: { view } } = page
        this.setState({ numPages: numPages, pdfWidth: view[2], pdfHeight: view[3] })
    }

    onChange = (current) => {
        eventDebounce(() => {
            this.setState({ current })
            if (this.state.renderMode !== 'svg') {
                this.setState({
                    renderMode: 'svg',
                })
            }
        }, 300)
    }

    static getDerivedStateFromProps(props, state) {
        const { url } = state
        const { url: newUrl } = props || {}
        if (newUrl !== url) {
            return {
                url: newUrl,
                current: 1
            }
        }
        return null
    }

    modifyPdf = async (url) => {
        const { currentUser } = this.props || {};
        const { staff, current } = currentUser || {};
        const { name, email } = staff || {};
        const { displayName } = current || {};
        const showName = name || email || 'Admin';

        const heightSpace = 165;
        const widthSpace = 165;

        try {
            const text = `${showName}-${displayName}`;
            const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());

            const pdfDoc = await PDFDocument.load(existingPdfBytes);

            // 加载自定义字体
            const fontUrl = 'https://cdn.cogleap.cn/static/assets/pingfang.ttf';
            const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());

            // // // 自定义字体挂载
            pdfDoc.registerFontkit(fontkit);
            const customFont = await pdfDoc.embedFont(fontBytes);

            const page = pdfDoc.getPages();

            page.forEach(item => {
                const { height, width } = item.getSize();

                for (let i = 0; i < (height % heightSpace); i++) {
                    for (let j = 0; j < (width % widthSpace); j++) {
                        if (i <= 6 && j <= 5) {
                            item.drawText(text, {
                                x: j * widthSpace,
                                y: i * heightSpace,
                                size: 18,
                                font: customFont,
                                color: rgb(0.38, 0.38, 0.38),
                                opacity: 0.1,
                                rotate: degrees(25),
                            });
                        } else {
                            break;
                        }
                    }
                }
            });
            const pdfUrl = URL.createObjectURL(
                new Blob([await pdfDoc.save()], { type: 'application/pdf' }),
            );

            this.setState({
                pdfUrl
            });
        } catch (err) {
            console.log(err);
            this.setState({
                pdfUrl: ''
            });
        }
    }

    updateServerPdf = () => {
        let { url } = this.props
        url && this.modifyPdf(url)
    }

    componentDidMount() {
        this.updateServerPdf()
    }

    componentDidUpdate (prevProps) {
        const { url } = this.props
        if (url !== prevProps.url) {
            this.setState({
                pdfUrl: ''
            });
            this.updateServerPdf()
        }
    }

    componentWillUnmount() {
        this.setState({
            pdfUrl: ''
        });
    }

    render() {
        const intl = IntlManager.MyIntl()

        const { current, numPages, pdfWidth, pdfHeight, pdfUrl } = this.state
        let { url, scale, isShowLeftRight, isPdf } = this.props
        if (scale < 1) {
            if (pdfHeight >= 700) {
                scale = 700 / pdfHeight
            } else {
                scale = (window.innerWidth * 0.5) / pdfWidth
            }
        } else {
            if (pdfWidth > screen.width * 0.8) {
                scale = screen.width / (pdfWidth * 1.2)
            }
        }
        const canLeft = isPdf && current > 1
        const canRight = isPdf && current < numPages
        return (
            <div className={styles.showPdf} key={pdfUrl}>
                <div className={styles.spaning}>{!numPages && isPdf && <Spin spinning={true} />}</div>
                <div className={styles.showPdfContent}>
                    {
                        isShowLeftRight &&
                        <div className={styles.leftImgImg}>
                            {
                                current !== 1 ?
                                    canLeft ?
                                        <Popover content={<div>{intl('clickBlankAreaBothSide')}</div>} title={intl('promptForAction')} trigger="hover" style={{ zIndex: 999 }}>
                                            <img onClick={() => canLeft && this.onChange(current - 1)} src={leftCircleFillGrey} />
                                        </Popover>
                                        :
                                        <img src={leftCircleFill} />
                                    : null
                            }
                        </div>
                    }
                    {
                        isPdf ?
                            // <PDF
                            //     file={url}
                            //     page={current}
                            //     onDocumentComplete={this.onLoadSuccess}
                            //     scale={scale}
                            //     //workerSrc = {'https://cl-cdn.oss-cn-shanghai.aliyuncs.com/js/pdf28335.js'}
                            //     //workerSrc={'https://cl-cdn.oss-cn-shanghai.aliyuncs.com/js/pdf.js'}
                            // />

                            <div style={{ height: `${pdfHeight * scale}px`, width: `${pdfWidth * scale}px`, minWidth: '400px' }}>
                                <Document
                                    file={pdfUrl}
                                    loading={null}
                                    onLoadSuccess={this.onLoadSuccess}
                                    onContextMenu={(e) => e.preventDefault()}
                                    noData={null}
                                // options={{
                                //     cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
                                //     cMapPacked: true,
                                //   }}
                                // options={{
                                //     cMapUrl: 'https://cl-cdn.oss-cn-shanghai.aliyuncs.com/js/pdf.js',
                                //     cMapPacked: true,
                                //   }}
                                >
                                    <Page pageNumber={current} renderMode={this.state.renderMode} scale={scale} onRenderError={(error) => {
                                        const { message } = error || {};
                                        if (message === 'Unknown IR type: Shading') {
                                            this.setState({
                                                renderMode: 'canvas',
                                            })
                                        }
                                    }} />
                                </Document>
                            </div>
                            :
                            <div className={styles.isNotPdf}>{intl('uploadFilePDFFormat')}</div>
                    }
                    {
                        isShowLeftRight &&
                        <div className={styles.rightImgImg}>
                            {
                                current !== numPages ?
                                    canRight ?
                                        <Popover content={<div>{intl('clickBlankAreaBothSide')}</div>} title={intl('promptForAction')} trigger="hover">
                                            <img onClick={() => canRight && this.onChange(current + 1)} src={rightCircleFillGrey} />
                                        </Popover>
                                        :
                                        <img src={rightCircleFill} />
                                    : null
                            }
                        </div>
                    }
                </div>
                <div className={styles.leftImg} onClick={() => canLeft && this.onChange(current - 1)}></div>
                <div className={styles.rightImg} onClick={() => canRight && this.onChange(current + 1)}></div>
                <div className={styles.pagination}>
                    <Pagination simple total={numPages} current={current} pageSize={1} onChange={this.onChange} />
                </div>
                {/* {
                    isPdf ?
                        <PDF
                            file={url}
                            page={current}
                            onDocumentComplete={this.onLoadSuccess}
                            scale={scale}
                            workerSrc={'https://cl-cdn.oss-cn-shanghai.aliyuncs.com/js/pdf.js'}
                        />
                        :
                        <div className={styles.isNotPdf}>请上传PDF格式文件</div>
                }

                <div className={styles.pagination}><Pagination simple total={numPages} current={current} pageSize={1} onChange={this.onChange} /></div>
                {
                    isShowLeftRight &&
                    <React.Fragment>
                        <div className={styles.leftImg}>
                            {
                                canLeft ?
                                    <img src={leftCircleFillGrey} onClick={() => this.onChange(current - 1)} />
                                    :
                                    <img src={leftCircleFill} />
                            }
                        </div>
                        <div className={styles.rightImg}>
                            {
                                canRight ?
                                    <img src={rightCircleFillGrey} onClick={() => this.onChange(current + 1)} />
                                    :
                                    <img src={rightCircleFill} />
                            }
                        </div>
                    </React.Fragment>
                } */}
            </div>
        )
    }
}

export default connect(({ user }) => ({ currentUser: user.currentUser }))(ShowPdf)