import React, { useState, useEffect } from 'react';
import { Space, Timeline, Button } from 'antd';
import { history, useIntl } from 'umi';

import { GetPracticalTest } from 'services/trainingSystem';

import CreateOralHandsDrawer from './Modals/CreateOralHandsDrawer';
import SpinCom from './SpinCom';

import styles from '../index.less';

import moment from 'moment';

const PracticalTest = ({
  progressIndex,
  selectCertData,
  userInfo,
  roleType,
  onChangeAllowReloadTab,
  allowReloadProgress,
}) => {
  const intl = useIntl();

  const [practicalData, setPracticalData] = useState({});
  const [visible, setVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [type, setType] = useState('');
  const [loading, setLoading] = useState(false);

  const { id: trainerId } = userInfo || {};
  const { id: trainerTrainingModuleId } = selectCertData || {};

  const initData = async () => {
    setLoading(true);
    const res = await GetPracticalTest({ trainerTrainingModuleId });
    if (res.response.ok) {
      const { data } = res || {};
      setPracticalData(data || {});
    }
    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, [allowReloadProgress]);

  const { handsOnTest, oralTest, hasHandsOnTest, hasOralTest } =
    practicalData || {};

  // if (Object.keys(practicalData).length === 0) {
  //   return <></>;
  // }

  const showList = [
    {
      type: 'oralTest',
      title: intl.formatMessage({ id: 'Oral Test' }),
      visible: hasOralTest,
      data: oralTest,
    },
    {
      type: 'handsOnTest',
      title: intl.formatMessage({ id: 'Handson Test' }),
      visible: hasHandsOnTest,
      data: handsOnTest,
    },
  ];

  const onCreate = (item) => {
    const { data, type } = item || {};
    setCurrentItem(data);
    setType(type);
    setVisible(true);
  };

  const createOralHandsDrawerProps = {
    visible,
    type,
    roleType,
    trainerTrainingModuleId,
    currentItem,
    onClose: () => {
      setVisible(false);
      onChangeAllowReloadTab && onChangeAllowReloadTab();
      initData();
    },
  };

  if (loading) {
    return <SpinCom />;
  }

  return (
    <>
      <Timeline>
        {showList
          .filter((item) => item.visible)
          .map((item, index) => {
            const { type, title, data } = item;
            const { finished, lastModificationTime, passed } = data || {};
            return (
              <Timeline.Item key={type}>
                <div className={styles.practicalTrainingItem}>
                  <div className={styles.practicalTrainingItemTitle}>
                    {progressIndex}.{index + 1}{' '}
                    {intl.formatMessage({ id: 'Practical Test' })} - {title}
                  </div>
                  <div className={styles.practicalTrainingItemContent}>
                    {finished ? (
                      <Space>
                        <div>
                          {intl.formatMessage({ id: passed ? 'pass' : 'Fail' })}
                        </div>
                        <a onClick={() => onCreate(item)}>
                          {intl.formatMessage({ id: 'View' })}
                        </a>
                        <div>
                          {intl.formatMessage({ id: 'Updated Time' })}:{' '}
                          {moment(lastModificationTime).format(
                            'MM/DD/YYYY HH:mm',
                          )}
                        </div>
                      </Space>
                    ) : roleType === 'admin' ? (
                      <a onClick={() => onCreate(item)}>
                        {intl.formatMessage({
                          id:
                            Object.keys(data || {}).length === 0
                              ? 'Create'
                              : 'Edit',
                        })}
                        {title}
                      </a>
                    ) : (
                      <div>
                        {intl.formatMessage({ id: 'Waiting for Test' })}
                      </div>
                    )}
                  </div>
                </div>
              </Timeline.Item>
            );
          })}
      </Timeline>
      {visible && <CreateOralHandsDrawer {...createOralHandsDrawerProps} />}
    </>
  );
};

export default PracticalTest;
