import getRequest from 'utils/request'

import download from 'cognitiveleap-core-us/utils/download'

const AddTestCenter = '/api/Admin/AddTestingCenter'
const PostTestRequestStatistics = '/api/vrclassroom/Admin/Center/TestRequestStatistics'
const UpdateTestCenter = '/api/Admin/UpdateTestingCenter'
const PostStatistics = '/api/vrclassroom/Admin/TestRequestStatistics'
const GetTestCenterList = '/api/Admin/TestingCenterList'
const GetCaseList = '/api/vrclassroom/Admin/TestRequestsPaged'
const GetCenterDetail = '/api/vrclassroom/Admin/CenterDetail'
const PostAdminTestExcelApi = '/api/vrclassroom/Admin/TestRequestsExcel'
const GetCaseDetail = '/api/vrclassroom/Admin/TestRequestsDetail'
const SystemInfo = '/api/vrclassroom/Admin/SystemInfo/List'
const ChangeLog = '/api/vrclassroom/Admin/SystemInfo/ChangeLog'
const ChangeStatus = '/api/vrclassroom/Admin/SystemInfo/ChangeStatus'
const UpdateAllowQuesionnaire = '/api/vrclassroom/Admin/AllowQuestionnaires'
const DownloadAllExcel = '/api/vrclassroom/Admin/TestRequestStatistics/Excel'
const DownloadCenterExcel = '/api/vrclassroom/Admin/Center/TestRequestStatistics/Excel'

function getStr(str) {
  var result = str.match(/UTF-8.+/g)
  return result[0]
}

export async function GetAllStatistic(data) {
  return getRequest()(DownloadAllExcel, {
    reponseType: 'blob',
    method: 'POST',
    data,
  }).then(res => {
    const { response } = res
    if (response.ok) {
      var filename = response.headers.get('Content-Disposition')
      filename = getStr(filename)
      const reportTitle = decodeURIComponent(filename).replace("UTF-8''", '')
      response.blob().then(blob => {
        download(blob, reportTitle)
      })
    }
  })
}

export async function GetCenterStatistic(centerId, data) {
  return getRequest()(DownloadCenterExcel, {
    reponseType: 'blob',
    method: 'POST',
    params: { centerId },
    data,
  }).then(res => {
    const { response } = res
    if (response.ok) {
      var filename = response.headers.get('Content-Disposition')
      filename = getStr(filename)
      const reportTitle = decodeURIComponent(filename).replace("UTF-8''", '')
      response.blob().then(blob => {
        download(blob, reportTitle)
      })
    }
  })
}

export async function GetCenterList(pageIndex, pageSize, searchField, bodyData) {
  return getRequest()(GetTestCenterList, {
    method: 'POST',
    params: { pageIndex, pageSize, searchField },
    data: bodyData,
  })
}

export async function PostAdminTestExcel(urlData, bodyData) {
  return getRequest()(PostAdminTestExcelApi, {
    reponseType: 'blob',
    method: 'POST',
    params: urlData,
    data: bodyData,
  }).then(res => {
    const { response } = res
    if (response.ok) {
      var filename = response.headers.get('Content-Disposition')
      filename = getStr(filename)
      const reportTitle = decodeURIComponent(filename).replace("UTF-8''", '')
      response.blob().then(blob => {
        download(blob, reportTitle)
      })
    }
  })
}

export async function UpdateCenter(data) {
  return getRequest()(UpdateTestCenter, {
    method: 'POST',
    data,
  })
}

export async function AddCenter(data) {
  return getRequest()(AddTestCenter, {
    method: 'POST',
    data,
  })
}

export async function AllowQuesionnaire(data) {
  return getRequest()(UpdateAllowQuesionnaire, {
    method: 'PUT',
    data,
  })
}

export async function CenterDetail(centerId) {
  return getRequest()(GetCenterDetail, {
    method: 'GET',
    params: { centerId },
  })
}

export async function CaseDetail(id) {
  return getRequest()(GetCaseDetail, {
    method: 'GET',
    params: { id },
  })
}

export async function GetCase(urlData, bodyData) {
  return getRequest()(GetCaseList, {
    method: 'POST',
    params: urlData,
    data: bodyData,
  })
}

export async function PostAdminStatistics(pageIndex, pageSize, data) {
  return getRequest()(PostStatistics, {
    method: 'POST',
    params: { pageIndex, pageSize },
    data,
  })
}

export async function GetSystemInfo() {
  return getRequest()(SystemInfo, {
    method: 'GET',
  })
}

export async function PutChangeLog(bodyData) {
  return getRequest()(ChangeLog, {
    method: 'PUT',
    data: bodyData,
  })
}

export async function PutChangeStatus(bodyData) {
  return getRequest()(ChangeStatus, {
    method: 'PUT',
    data: bodyData,
  })
}

export async function PostCenterTestRequestStatistics(queryData, bodyData) {
  return getRequest()(PostTestRequestStatistics, {
    method: 'POST',
    params: queryData,
    data: bodyData,
  })
}
