export const tagsData = (intl) => [
    { title: intl.formatMessage({ id: 'month' }), key: 'Month' },
    { title: intl.formatMessage({ id: 'year' }), key: 'Year' }
];

export const dateConfig = (intl) => {
    return {
        'Month': {
            small: 'month',
            choice: `${intl.formatMessage({ id: 'pleaseSelectMonth' })}：`,
            placeholder: `${intl.formatMessage({ id: 'pleaseSelectMonth' })}：`,
            format: 'YYYY-MM'
        },
        'Year': {
            small: 'year',
            choice: `${intl.formatMessage({ id: 'pleaseSelectYear' })}：`,
            placeholder: `${intl.formatMessage({ id: 'pleaseSelectYear' })}：`,
            format: 'YYYY'
        },
    }
}

export const formatConfig = {
    'Month': {
        small: 'month',
        format: 'YYYY-MM'
    },
    'Year': {
        small: 'year',
        format: 'YYYY'
    }
}

export const tableColumns = (intl) => [
    {
        title: intl.formatMessage({ id: 'designation' }),
        dataIndex: 'name',
    },
    {
        title: intl.formatMessage({ id: 'number' }),
        dataIndex: 'number',
    },
    {
        title: intl.formatMessage({ id: 'proportion' }),
        dataIndex: 'proportion',
    },
]

export const childrenAgeTableColums = (intl) => [
    {
        title: intl.formatMessage({ id: 'designationOther' }),
        dataIndex: 'age',
    },
    {
        title: intl.formatMessage({ id: 'age' }),
        dataIndex: 'name'
    },
    {
        title: intl.formatMessage({ id: 'number' }),
        dataIndex: 'number',
    },
    {
        title: intl.formatMessage({ id: 'proportion' }),
        dataIndex: 'proportion',
    }
]

export const childrenAgeDataSource = (intl) => [
    {
        key: '1',
        name: '0-3',
        number: 0,
        proportion: '0',
        age: intl.formatMessage({ id: 'toddler' }),
    },
    {
        key: '2',
        name: '3-5.5',
        number: 0,
        proportion: '0',
        age: intl.formatMessage({ id: 'preschool' }),
    },
    {
        key: '3',
        name: '5.5-13',
        number: 0,
        proportion: '0',
        age: intl.formatMessage({ id: 'gradeSchool' }),
    },
    {
        key: '4',
        name: '13-18',
        number: 0,
        proportion: '0',
        age: intl.formatMessage({ id: 'teenage' }),
    },
    {
        key: '5',
        name: '>=18',
        number: 0,
        proportion: '0',
        age: intl.formatMessage({ id: 'adult' }),
    }
]

export const childrenGengerDataSource = (intl) => [
    {
        key: '1',
        name: intl.formatMessage({ id: 'male' }),
        number: 0,
        proportion: '0',
        gender: 'Male'
    },
    {
        key: '2',
        name: intl.formatMessage({ id: 'female' }),
        number: 0,
        proportion: '0',
        gender: 'Female'
    },
    {
        key: '3',
        name: intl.formatMessage({ id: 'unknown' }),
        number: 0,
        proportion: '0',
        gender: 'Unknown'
    },
    {
        key: '4',
        name: intl.formatMessage({ id: 'other' }),
        number: 0,
        proportion: '0',
        gender: 'Others'
    }
]

export const childrenKeyIndicators = (intl) => [
    {
        title: intl.formatMessage({ id: 'newChildrenToday' }),
        data: 0,
        field: 'addedToday'
    },
    // {
    //     title: intl.formatMessage({ id: 'signedChildrenToday' }),
    //     data: 0,
    //     field: 'checkInToday'
    // },
    {
        title: intl.formatMessage({ id: 'newChildrenMonth' }),
        data: 0,
        field: 'newThisMonth'
    },
    {
        title: intl.formatMessage({ id: 'trainChildCount' }),
        data: 0,
        field: 'enabledOfflineCourseSubjectTotal'
    },
    {
        title: intl.formatMessage({ id: 'unboundChildCount' }),
        data: 0,
        field: 'cumulativeUntie'
    },
    {
        title: intl.formatMessage({ id: 'anthorChildCount' }),
        data: 0,
        field: 'cumulativeAuthorization'
    }
];

export const childrenLegend = (intl) => [
    {
        title: intl.formatMessage({ id: 'newChildren' }),
        field: 'newQuantity'
    },
    {
        title: intl.formatMessage({ id: 'unboundChildNumber' }),
        field: 'numberOfUntied'
    }
]

export const guardianLegend = (intl) => [
    {
        title: intl.formatMessage({ id: 'newParent' }),
        field: 'newQuantity'
    }
]

export const guardianKeyIndicators = (intl) => [
    {
        title: intl.formatMessage({ id: 'newParentToday' }),
        data: 0,
        field: 'addedToday'
    },
    {
        title: intl.formatMessage({ id: 'newParentMonth' }),
        data: 0,
        field: 'newThisMonth'
    },
    {
        title: intl.formatMessage({ id: 'anthorParentCount' }),
        data: 0,
        field: 'cumulativeAuthorization'
    }
]

export const guardianGengerDataSource = (intl) => [
    {
        key: '1',
        name: intl.formatMessage({ id: 'male' }),
        number: 0,
        proportion: '0',
        gender: 'Male'
    },
    {
        key: '2',
        name: intl.formatMessage({ id: 'female' }),
        number: 0,
        proportion: '0',
        gender: 'Female'
    },
    {
        key: '3',
        name: intl.formatMessage({ id: 'unknown' }),
        number: 0,
        proportion: '0',
        gender: 'Unknown'
    },
    {
        key: '4',
        name: intl.formatMessage({ id: 'other' }),
        number: 0,
        proportion: '0',
        gender: 'Others'
    }
]

export const vratKeyIndicators = (intl) => [
    {
        title: `${intl.formatMessage({ id: 'newReportToday' })}`,
        data: 0,
        field: 'AddedToday'
    },
    {
        title: `${intl.formatMessage({ id: 'newReportMonth' })}`,
        data: 0,
        field: 'AddedMonth'
    },
    // {
    //     title: `${intl.formatMessage({ id: 'anthorReportGenerationCount' })}(5-13)`,
    //     data: 0,
    //     field: 'CumulativelyAdded'
    // },
    {
        title: `${intl.formatMessage({ id: 'anthorReportGenerationCount' })}`,
        data: 0,
        field: 'Total'
    },
    {
        title: intl.formatMessage({ id: 'availableTest' }),
        subTitle: '',
        subTitleField: 'LatestExpirationTime',
        data: 0,
        field: 'TestResourceAmount',
        dataField: 'LastAmount'
    }
]

export const vratLegend = (intl) => [
    // {
    //     title: `${useIntl().formatMessage({ id: 'reportGeneration' })}(5-13)`,
    //     field: 'AgeNum'
    // },
    {
        title: `${intl.formatMessage({ id: 'reportGeneration' })}`,
        field: 'Num'
    }
]

export const ilsKeyIndicators = (intl) => [
    {
        title: `${intl.formatMessage({ id: 'dailySubscriptions' })}`,
        data: 0,
        field: 'addedToday'
    },
    {
        title: `${intl.formatMessage({ id: 'monthlySubscriptions' })}`,
        data: 0,
        field: 'addedMonth'
    },
    {
        title: `${intl.formatMessage({ id: 'monthlyUsedClass' })}`,
        data: 0,
        field: 'courseQuantityMonth'
    },
    {
        title: `${intl.formatMessage({ id: 'totalPlans' })}`,
        data: 0,
        field: 'cumulativeQuantity'
    },
    {
        title: `${intl.formatMessage({ id: 'totalUsedClass' })}`,
        data: 0,
        field: 'courseQuantity'
    },
    {
        title: `${intl.formatMessage({ id: 'goingPlans' })}`,
        data: 0,
        field: 'startNum'
    }
]

export const ilsLegend = (intl) => [
    {
        title: `${intl.formatMessage({ id: 'newTrainingPlans' })}`,
        field: 'createNum'
    },
    {
        title: `${intl.formatMessage({ id: 'completedTrainingPlans' })}`,
        field: 'endNum'
    },
    {
        title: `${intl.formatMessage({ id: 'totalUsedClass' })}`,
        field: 'courseQuantity'
    }
]

export const oneToMoreKeyIndicators = (intl) => [
    {
        title: `${intl.formatMessage({ id: 'goingPlans' })}`,
        data: 0,
        field: 'planQuantityDoing'
    },
    {
        title: `${intl.formatMessage({ id: 'classNum' })}`,
        data: 0,
        field: 'groupQuantity'
    },
    {
        title: `${intl.formatMessage({ id: 'monthlyUsedClass' })}`,
        data: 0,
        field: 'courseQuantityMonth'
    },
    {
        title: `${intl.formatMessage({ id: 'monthlySubscriptions' })}`,
        data: 0,
        field: 'planQuantityMonth'
    },
    {
        title: `${intl.formatMessage({ id: 'totalUsedClass' })}`,
        data: 0,
        field: 'courseQuantity'
    },
    // {
    //     title: `${intl.formatMessage({ id: 'dailySubscriptions' })}`,
    //     data: 0,
    //     field: 'planQuantityToday'
    // },
    {
        title: `${intl.formatMessage({ id: 'totalPlans' })}`,
        data: 0,
        field: 'planQuantity'
    },
]