
import React from 'react'
import { Select } from 'antd'
import { connect } from 'umi'
const { Option } = Select;

const all = {
    id: '',
    displayName: '全部'
}

const AnalysisTenantSelect = ({ currentUser, currentTenant, onChange, showAll }) => {
    const newCurrentUser = JSON.parse(JSON.stringify(currentUser))
    const { canChangeList = [], centerList = [], adminRoles = [] } = newCurrentUser;

    // 仅admin显示所有中心, 其余管理员都是显示权限内中心
    const showAllCenter = adminRoles.some(role => role.name === 'admin')
    let tempList = showAllCenter ? canChangeList : centerList
    if (showAll) {
        if (!tempList.find(item => item.id === '')) {
            tempList.unshift(all)
        }
    } else {
        const index = tempList.findIndex(item => item.id === '')
        if (index >= 0) {
            tempList.splice(index, 1)
        }
    }
    const currentTenantItem = tempList && tempList.find(item => item.id === (currentTenant && currentTenant.id))
    return (
        <Select
            showSearch
            maxTagTextLength={12}
            style={{ width: 200 }}
            onChange={onChange}
            value={currentTenantItem && currentTenantItem.displayName}
            filterOption={(input, option) => {
                return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }}
        >
            {
                tempList.length > 0 &&
                tempList.map(item => {
                    return <Option value={item.id} key={item.id}>{item.displayName}</Option>
                })
            }
        </Select>
    )
}

export default connect(({ user }) => ({ currentUser: user.currentUser }))(AnalysisTenantSelect);