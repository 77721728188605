import React, { useState, useEffect } from 'react';
import { message, Modal, Space, Button } from 'antd';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';
import {
  ExclamationCircleOutlined,
  ExclamationCircleFilled,
  SmileOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import {
  matchDynamicForm,
  addOptionsToProperties,
} from 'cognitiveleap-core-us/utils/utils';
import moment from 'moment';
import { useIntl } from 'umi';
import ConfirmModal from 'components/ConfirmChildInfoModal';

import { PutSubject, CheckSubject } from 'services/userManager';
import {
  GetRegenerateList,
  GetSpecialReminder,
} from 'services/rocketService/Assessment';
import { GetVratRegeneratelist } from 'services/vrat/questionnaire';

let assessmentInterval;
let vratInterval;

const showReminderList = (intl) => {
  return {
    A: intl.formatMessage({ id: 'reminderA' }),
    B: intl.formatMessage({ id: 'reminderB' }),
    C: intl.formatMessage({ id: 'reminderC' }),
    D: intl.formatMessage({ id: 'reminderD' }),
  };
};

const EditChildInfo = ({
  modalVisible,
  subjectInfo,
  reloadPage,
  closeModal,
}) => {
  const intl = useIntl();

  const [regenerateAssessmentLoading, setRegenerateAssessmentLoading] =
    useState(false);
  const [regenerateVratLoading, setRegenerateVratLoading] = useState(false);
  const [addSubjectInfo, setAddSubjectInfo] = useState({});
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [regenerateVisible, setRegenerateVisible] = useState(false);
  const [assessmentList, setAssessmentList] = useState([]);
  const [vratList, setVratList] = useState([]);

  const {
    avaliableGuardianRoles = [],
    guardians = [],
    id: userId,
    name: oldName,
  } = subjectInfo || {};
  const { role } = guardians.length ? guardians[0] : {};

  const { name, gender } = addSubjectInfo || {};

  subjectInfo = {
    ...subjectInfo,
    guardianRole: role,
  };

  const contentMenu = {
    Properties: [
      {
        EditorType: 'Input',
        ShowTitle: intl.formatMessage({ id: 'name' }),
        FormKey: 'name',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: true,
        },
        Description: null,
      },
      {
        EditorType: 'DatePicker',
        ShowTitle: intl.formatMessage({ id: 'birthday' }),
        FormKey: 'birthDay',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: true,
          DateType: 'date',
          format: 'MM/DD/YYYY',
          DisabledDate: (current) => {
            return current && current > moment();
          },
        },
        Description: intl.formatMessage({ id: 'pleaseCheckCarefully' }),
      },
      {
        EditorType: 'Dropdown',
        Value: 'Male',
        Setting: {
          DropdownOptions: [
            {
              Value: intl.formatMessage({ id: 'male' }),
              Id: 'Male',
            },
            {
              Value: intl.formatMessage({ id: 'female' }),
              Id: 'Female',
            },
            // {
            //   Value: intl.formatMessage({ id: 'other' }),
            //   Id: 'Others',
            // },
          ],
          Required: true,
        },
        ShowTitle: intl.formatMessage({ id: 'gender' }),
        FormKey: 'gender',
        Description: null,
      },
      {
        EditorType: 'Dropdown',
        Value: 'Right',
        Setting: {
          DropdownOptions: [
            {
              Value: intl.formatMessage({ id: 'left' }),
              Id: 'LeftHand',
            },
            {
              Value: intl.formatMessage({ id: 'right' }),
              Id: 'Right',
            },
          ],
          Required: true,
        },
        ShowTitle: intl.formatMessage({ id: 'dominantHand' }),
        FormKey: 'dominantHand',
        Description: null,
      },
      {
        EditorType: 'Dropdown',
        Value: 'Mother',
        Setting: {
          DropdownOptions: [],
          Required: true,
        },
        ShowTitle: intl.formatMessage({ id: 'relationship' }),
        FormKey: 'guardianRole',
        Description: (
          <div style={{ marginTop: '16px', color: '#F96B4B' }}>
            {intl.formatMessage({ id: 'editChildSubmitTip' })}
          </div>
        ),
      },
      {
        EditorType: 'Input',
        ShowTitle: intl.formatMessage({ id: 'locatedCity' }),
        FormKey: 'city',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: false,
        },
        Description: null,
      },
      {
        EditorType: 'Dropdown',
        Value: '',
        Setting: {
          DropdownOptions: [
            {
              Value: intl.formatMessage({ id: 'kindergarten' }),
              Id: '0',
            },
            {
              Value: '1',
              Id: '1',
            },
            {
              Value: '2',
              Id: '2',
            },
            {
              Value: '3',
              Id: '3',
            },
            {
              Value: '4',
              Id: '4',
            },
            {
              Value: '5',
              Id: '5',
            },
            {
              Value: '6',
              Id: '6',
            },
            {
              Value: '7',
              Id: '7',
            },
            {
              Value: '8',
              Id: '8',
            },
            {
              Value: '9',
              Id: '9',
            },
            {
              Value: '10',
              Id: '10',
            },
            {
              Value: '11',
              Id: '11',
            },
            {
              Value: '12',
              Id: '12',
            },
            {
              Value: intl.formatMessage({ id: 'other' }),
              Id: '13',
            },
          ],
          Required: false,
        },
        ShowTitle: intl.formatMessage({ id: 'grade' }),
        FormKey: 'gradeName',
        Description: null,
      },
      {
        EditorType: 'Dropdown',
        Value: null,
        Setting: {
          DropdownOptions: [
            {
              Value: intl.formatMessage({ id: 'public' }),
              Id: 'Public',
            },
            {
              Value: intl.formatMessage({ id: 'private' }),
              Id: 'Private',
            },
            {
              Value: intl.formatMessage({ id: 'international' }),
              Id: 'International',
            },
            {
              Value: intl.formatMessage({ id: 'notAttendingSchool' }),
              Id: 'NotGoingToSchool',
            },
          ],
          // Required: true,
        },
        ShowTitle: intl.formatMessage({ id: 'schoolNature' }),
        FormKey: 'schoolNature',
        Description: null,
      },
    ],
  };

  const roleOptions = avaliableGuardianRoles.map((item) => ({
    Value: item.displayName,
    Id: item.name,
  }));

  const regenerateAssessmentFunc = async () => {
    const res = await GetRegenerateList({
      userId,
    });
    if (res.response.ok) {
      if (res.data.length) {
        setRegenerateVisible(true);
        setAssessmentList(res.data);
        if (res.data.every((item) => item.status !== 'Create')) {
          clearInterval(assessmentInterval);
          setRegenerateAssessmentLoading(false);
        }
      } else {
        setAssessmentList(null);
        clearInterval(assessmentInterval);
        setRegenerateAssessmentLoading(false);
      }
    }
  };

  const regenerateAssessment = () => {
    setRegenerateAssessmentLoading(true);
    if (assessmentInterval) clearInterval(assessmentInterval);
    regenerateAssessmentFunc();
    assessmentInterval = setInterval(regenerateAssessmentFunc, 5 * 1000);
  };

  const regenerateVratFunc = async () => {
    const res = await GetVratRegeneratelist({
      userId,
    });
    if (res.response.ok) {
      if (res.data.length) {
        setRegenerateVisible(true);
        setVratList(res.data);
        if (res.data.every((item) => item.Finish)) {
          clearInterval(vratInterval);
          setRegenerateVratLoading(false);
        }
      } else {
        setVratList(null);
        clearInterval(vratInterval);
        setRegenerateVratLoading(false);
      }
    }
  };

  const regenerateVrat = () => {
    setRegenerateVratLoading(true);
    if (vratInterval) clearInterval(vratInterval);
    regenerateVratFunc();
    vratInterval = setInterval(regenerateVratFunc, 5 * 1000);
  };

  useEffect(() => {
    if (vratList == null && assessmentList == null) {
      checkReminder();
      setVratList([]);
      setAssessmentList([]);
    }
  }, [vratList, assessmentList]);

  const checkSubject = async (value) => {
    const { guardianRole, name } = value;
    const relationshipLocale = avaliableGuardianRoles.find((item) => {
      return item.name === guardianRole;
    }).displayName;

    const newSubjectInfo = {
      ...value,
      relationshipLocale,
    };

    setAddSubjectInfo(newSubjectInfo);

    // 不修改姓名不做重名判断
    if (name === oldName) {
      setConfirmModalVisible(true);
      return;
    }

    const res = await CheckSubject(newSubjectInfo);

    if (res.response.ok) {
      const { hadSame } = res.data;
      if (hadSame) {
        Modal.confirm({
          title: (
            <span style={{ color: 'red', fontWeight: 500 }}>
              {intl.formatMessage({ id: 'sameNamePrompt' })}
            </span>
          ),
          content: (
            <span style={{ color: 'red' }}>
              {intl.formatMessage({ id: 'sureCenterAddSameChild' })}
            </span>
          ),
          okText: intl.formatMessage({ id: 'return' }),
          cancelText: intl.formatMessage({ id: 'submit' }),
          icon: <ExclamationCircleFilled />,
          okButtonProps: {
            style: {
              background: '#fff',
              color: '#5a5a5a',
              borderRadius: '5px',
              border: '1px solid #ccc',
            },
          },
          cancelButtonProps: {
            style: {
              background: 'red',
              color: '#fff',
              borderRadius: '5px',
              border: '1px solid red',
            },
          },
          onCancel() {
            setConfirmModalVisible(true);
          },
        });
      } else {
        setConfirmModalVisible(true);
      }
    }
  };

  const addSubject = async () => {
    setConfirmModalVisible(false);
    closeModal();

    const res = await PutSubject({
      userId,
      ...addSubjectInfo,
    });

    if (res.response.ok) {
      message.success(intl.formatMessage({ id: 'editChildSuccess' }, { name }));
      reloadPage();
      regenerateAssessment();
      regenerateVrat();
    }
  };

  const modalProps = {
    loading: false,
    title: intl.formatMessage({ id: 'editProfile' }),
    visible: modalVisible,
    onCancel: closeModal,
    onOk: checkSubject,
    content: matchDynamicForm(
      addOptionsToProperties(contentMenu, ['guardianRole'], roleOptions),
      subjectInfo,
    ),
  };

  const showStatus = {
    Create: <LoadingOutlined style={{ color: '#357afb', fontSize: '18px' }} />,
    Success: (
      <CheckCircleOutlined style={{ color: '#59c52d', fontSize: '18px' }} />
    ),
    Failed: (
      <CloseCircleOutlined style={{ color: '##fb4654', fontSize: '18px' }} />
    ),
  };

  const confirmModalProps = {
    type: 'edit',
    loading: false,
    subjectInfo: addSubjectInfo,
    visible: confirmModalVisible,
    onCancel() {
      setConfirmModalVisible(false);
    },
    onOk: addSubject,
  };

  const checkReminder = async () => {
    setRegenerateVisible(false);

    // if (assessmentList.length) {
    const res = await GetSpecialReminder({
      userId,
    });

    if (res.response.ok) {
      if (res.data.length && res.data.some((item) => item.remind)) {
        Modal.confirm({
          title: intl.formatMessage({ id: 'specialReminder' }),
          icon: <ExclamationCircleOutlined />,
          width: 520,
          content: (
            <ol style={{ marginLeft: '-25px' }}>
              {res.data
                .filter((item) => item.remind)
                .map((item) => {
                  const { type } = item || {};
                  return (
                    <li type="A" key={type}>
                      {name}
                      {showReminderList(intl)[type]}
                    </li>
                  );
                })}
            </ol>
          ),
          okText: intl.formatMessage({ id: 'submit' }),
        });
      }
    }
    // }
  };

  return (
    <>
      <MagicFormModal {...modalProps} />
      <ConfirmModal {...confirmModalProps} />
      <Modal
        title={
          <Space>
            <SmileOutlined style={{ color: '#2a79fb', fontSize: '22px' }} />
            {intl.formatMessage({ id: 'Regenerate Report' })}
          </Space>
        }
        open={regenerateVisible}
        onOk={() => {}}
        closable={false}
        keyboard={false}
        width={600}
        footer={[
          <Button
            type="primary"
            disabled={regenerateVratLoading || regenerateAssessmentLoading}
            onClick={checkReminder}
          >
            {intl.formatMessage({ id: 'submit' })}
          </Button>,
        ]}
      >
        <p>
          {intl.formatMessage(
            { id: 'regenerateTip' },
            {
              name,
              who: intl.formatMessage({
                id: gender === 'Male' ? 'his' : 'her',
              }),
            },
          )}
        </p>
        {vratList && Boolean(vratList.length) && (
          <>
            {vratList.map((item) => {
              const { CaseId, Finish } = item || {};
              return (
                <p key={CaseId}>
                  <span style={{ display: 'inline-block', minWidth: '280px' }}>
                    — {intl.formatMessage({ id: 'vcatReport' })}
                    {CaseId}
                  </span>
                  {!Finish ? (
                    <LoadingOutlined
                      style={{ color: '#357afb', fontSize: '18px' }}
                    />
                  ) : (
                    <CheckCircleOutlined
                      style={{ color: '#59c52d', fontSize: '18px' }}
                    />
                  )}
                </p>
              );
            })}
          </>
        )}
        {assessmentList && Boolean(assessmentList.length) && (
          <>
            {assessmentList.map((item) => {
              const { name, status } = item || {};
              return (
                <p key={name}>
                  <span style={{ display: 'inline-block', minWidth: '280px' }}>
                    — {name}
                    {intl.formatMessage({ id: 'showReport' })}
                  </span>
                  {showStatus[status]}
                </p>
              );
            })}
          </>
        )}
        <p>
          <span style={{ fontWeight: '550', color: '#756a71' }}>
            {intl.formatMessage({ id: 'closeGeneratedTip' })}
          </span>
        </p>
      </Modal>
    </>
  );
};

export default React.memo(EditChildInfo);
