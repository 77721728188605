import React from 'react'
import * as Survey from 'survey-react'
import * as widgets from "surveyjs-widgets"
import $ from "jquery"
import "easy-autocomplete/dist/easy-autocomplete.css"
import 'survey-react/survey.css'
import { eventDebounce } from 'cognitiveleap-core-us/utils/utils'
import styles from './questionnaire.less'
import { getLocale } from 'umi'
import { Spin } from 'antd'

window["$"] = window["jQuery"] = $;
require("easy-autocomplete/dist/jquery.easy-autocomplete.js");
const language = getLocale()

const myRe = new RegExp('"title":{[^}]+}|"text":{[^}]+}', 'g')

const languageOption = {
  'zh-CN': 'zh-cn',
  'en-US': 'en',
  'zh-cn': 'zh-cn', // 兼顾旧的数据
  'en': 'en'
}

const languageOptionObs = {
  'zh-CN': 'en',
  'en-US': 'zh-cn'
}
class Questionnaire extends React.Component {
  constructor(props) {
    super(props)

    this.timer = ""
    this.interval = "" // 定时器
    this.onComplete = props.onComplete

    this.time = props.time
    this.sendAnswer = ''
    this.loopCompleteTime = props.loopCompleteTime || 'noLoop' // 多久保存一次
    this.state = {
      model: null,
      answerContent: null,
      isComplete: false,
    }
  }

  componentDidMount() {
    widgets.autocomplete(Survey);
    const model = this.initModel()
    this.modelAddListener(model)
  }

  componentDidUpdate(prevProps) {
    // 典型用法（不要忘记比较 props）：
    if (this.props.content !== prevProps.content) {
      this.modelClearListener()
      this.clearTimers()
      const model = this.initModel()
      this.modelAddListener(model)
      //this.setState({model})
    }
  }

  replacer(match) {
    const { title, text } = JSON.parse('{' + match + '}')

    if (title) {
      if (!(title.hasOwnProperty(languageOption[language]) || title.hasOwnProperty('default'))) {
        return title.hasOwnProperty(languageOptionObs[language]) && "title" + ':' + '"' + title[languageOptionObs[language]] + '"'
      }
    } else if (text) {
      if (!(text.hasOwnProperty(languageOption[language]) || text.hasOwnProperty('default'))) {
        return text.hasOwnProperty(languageOptionObs[language]) && "text" + ':' + '"' + text[languageOptionObs[language]] + '"'
      }
    }

    return match
  }

  updatePageNavigation(newLocale, interval = 0) {
    var navElement = document.querySelector('div.sv_progress > span');
    if (navElement) {
      const { model } = this.state

      var currentPageNo = model.currentPageNo + 1;
      var totalPages = model.visiblePages.length;

      if (totalPages <= 1) return;

      if (interval) {
        setTimeout(() => {
          navElement.innerHTML = `${currentPageNo} / ${totalPages}`;
        }, interval)
      } else {
        navElement.innerHTML = `${currentPageNo} / ${totalPages}`;
      }
    }
  }

  initModel() {
    const that = this
    if (that.loopCompleteTime !== 'noLoop') {
      that.interval = setInterval(() => {
        that.sendAnswer !== '' && that.props.onComplete({ answerContent: that.sendAnswer, isComplete: false })
      }, that.loopCompleteTime)
    }

    const { locale = language, content, answer, editable = "ture", answerContent } = this.props
    const { answerContent: answerData } = answer || {}
    const data = answerContent || answerData;
    const tempContent = myRe.test(content) ? content.replaceAll(myRe, this.replacer) : null;
    const showContent = Function('"use strict";return (' + (tempContent ?? content) + ')')()
    const model = new Survey.Model(showContent)
    const currLocale = languageOption[locale]

    model.currentPageNo = answer && answer.finishedPage || 0
    model.showProgressBar = this.props.showProgressBar ? 'bottom' : ''
    model.goNextPageAutomatic = false
    model.showNavigationButtons = true
    model.locale = currLocale
    model.focusFirstQuestionAutomatic = false
    model.allowCompleteSurveyAutomatic = false
    model.pageNextText = currLocale === 'zh-cn' ? '下一题' : 'Next'
    model.pagePrevText = currLocale === 'zh-cn' ? '上一题' : 'Previous'
    model.data = data && JSON.parse(data)
    if (!editable) {
      model.mode = 'display'
    }

    this.setState({ model: model })
    return model
  }

  modelAddListener(model) {
    const that = this
    const { onComplete, onValueChange, time: propsTime } = this.props
    let doAnimantion = true
    // model.onCurrentPageChanging.add(function (sender, options) {
    //   const newCurrentPage = options.newCurrentPage.data.currentPageNo
    //   if (!doAnimantion) return
    //   options.allowChanging = false
    //   // eventDebounce(()=> {
    //   //   if(!that.time){
    //   //     onValueChange && onValueChange({ answerContent: JSON.stringify(sender.data), isComplete: false, finishedPage: newCurrentPage})
    //   //   }else{
    //   //     if(that.time === 1){
    //   //       that.time = propsTime
    //   //       onValueChange && onValueChange({ answerContent: JSON.stringify(sender.data), isComplete: false, finishedPage: newCurrentPage})
    //   //     } else {
    //   //       that.time--;
    //   //     }
    //   //   }
    //   // }, 0)
    //   this.timer = setTimeout(function () {
    //     console.log('hahahahahahah')
    //     doAnimantion = false
    //     sender.currentPage = options.newCurrentPage
    //     doAnimantion = true
    //   }, 250)
    // })

    model.onCompleting.add(function (sender, options) {
      if (!doAnimantion) return;
      options.allowComplete = false
      doAnimantion = false
      eventDebounce(() => {
        if (!that.time) {
          onComplete({ answerContent: JSON.stringify(sender.data), isComplete: true })
        } else {
          that.time = propsTime
          onComplete({ answerContent: JSON.stringify(sender.data), isComplete: true })
        }
      }, 100)
      doAnimantion = true
    })

     // 监听页面切换事件
    model.onCurrentPageChanged.add(function(sender, options) {
      // 更新页码导航显示
      that.updatePageNavigation();
    });

    model.onPropertyChanged.add(function(sender, options) {
      // 更新页码导航显示
      if (options.name === "locale") {
        const { newValue } = options || {}
        var newLocale = newValue === 'zh-cn' ? 'zh-cn' : 'en';
        model.pageNextText = newLocale === 'zh-cn' ? '下一题' : 'Next'
        model.pagePrevText = newLocale === 'zh-cn' ? '上一题' : 'Previous'
        that.updatePageNavigation(newLocale, 80);
      }
    });

    model.onValueChanging.add(function (sender, options) {
      const newCurrentPage = options.question.data.PageCount
      if (!doAnimantion) return;
      options.allowComplete = false
      doAnimantion = false
      eventDebounce(() => {
        that.sendAnswer = JSON.stringify(sender.data)
        if (!that.time) {
          onValueChange && onValueChange({ answerContent: JSON.stringify(sender.data), isComplete: false, finishedPage: newCurrentPage, needSubmit: false })
        } else {
          if (that.time === 1) {
            onValueChange && onValueChange({ answerContent: JSON.stringify(sender.data), isComplete: false, finishedPage: newCurrentPage, needSubmit: true })
            that.time = propsTime
          } else {
            onValueChange && onValueChange({ answerContent: JSON.stringify(sender.data), isComplete: false, finishedPage: newCurrentPage, needSubmit: false })
            that.time--;
          }
        }
      }, 0)
      doAnimantion = true
    })
  }

  modelClearListener() {
    const { model } = this.state
    if (model) {
      model.onCurrentPageChanging.clear()
      model.onCompleting.clear()
      model.onValueChanging.clear()
    }
  }

  clearTimers() {
    clearTimeout(this.timer)
    clearInterval(this.interval)
  }

  componentWillUnmount() {
    this.modelClearListener()
    this.clearTimers()
  }

  render() {
    // const { answer, answerContent } = this.props
    // const { answerContent: answerData } = answer || {}
    // const data = answerContent || answerData
    const { model } = this.state

    this.updatePageNavigation();

    if (model)
      return (
        <div className={styles.container}>
          <Survey.Survey model={model}
          //data={data && JSON.parse(data)} 
          />
        </div>
      )
    else {
      return <Spin />
    }
  }
}

export default Questionnaire
