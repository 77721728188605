import React, { useEffect, useState } from 'react';
import { useIntl } from 'umi';
import { Drawer, Tabs, Select, Spin, Cascader } from 'antd';
import Loading from 'components/Loading';

import { 
  GetPlanPunchHistory, 
  GetPlanItemPunchHistory,
  GetChildFeedbackData,
  GetParentFeedbackData,
  GetChildList
} from 'services/group';

import AdminClockInRecord from 'components/ClockInRecord';
import TrainingStatistics from 'cognitiveleap-core-us/components/Charts/ILSChart/GroupTrainingStatistics';
import CourseStatistics from 'cognitiveleap-core-us/components/Charts/ILSChart/GroupCourseStatistics';

import styles from './index.less';

const { TabPane } = Tabs;
const { Option } = Select;

const ChartsDrawer = (props) => {
  const intl = useIntl();
  const {
    visible = false,
    currentNum = 0,
    currentTablePlan = {},
    onClose = () => {},
    GroupTrainingPlanId,
  } = props;

  let optionLists = Array(parseInt(currentNum)).fill(0).map((_, index) => {
    const tempData = currentNum - index
    return {
      value: tempData,
      label: intl.formatMessage({ id: 'whichSession' }, { num: tempData }),
      isLeaf: false,
    }
  })

  // 添加全部选项
  optionLists.unshift({
    value: -1,
    label: intl.formatMessage({ id: 'all' }),
    isLeaf: true,
  })

  const [ilsActiveKey, setIlsActiveKey] = useState('chart');
  const [loading, setLoading] = useState({
    trainingLoading: false,
    totalLoading: false,
    parentLoading: false,
    childLoading: false,
  });
  const [ilstrainerRecord, setIlstrainerRecord] = useState([]);
  const [ilsparentRecord, setIlsparentRecord] = useState([]);
  const [childRecord, setChildRecord] = useState([]);
  const [parentRecord, setParentRecord] = useState([]);
  const [shouldGetData, setShouldGetData] = useState(false);
  const [selectNum, setSelectNum] = useState({
    allFeedNum: -1,
    childFeedData: [],
    parentFeedData: [],
  });
  const [options, setOptions] = useState(optionLists);

  const [recordPagination, setRecordPagination] = useState({
    ilstrainer: {
      current: 0,
      pageSize: 10,
    },
    ilstotal: {
      current: 0,
      pageSize: 10,
    },
    childPagination: {
      current: 0,
      pageSize: 10,
    },
    parentPagination: {
      current: 0,
      pageSize: 10,
    }
  });

  useEffect(() => {
    onTabsChange();
  }, []);

  const onTabsChange = async (key = 'chart') => {
    setIlsActiveKey(key);

    let res;
    let pagination;
    switch (key) {
      case 'ilstrainer':
        setLoading({
          ...loading,
          trainingLoading: true,
        });
        pagination = recordPagination.ilstrainer;

        const props = selectNum.allFeedNum > 0 ? {
          Num: selectNum.allFeedNum
        } : {}

        res = await GetPlanItemPunchHistory({
          GroupTrainingPlanId,
          SkipCount: 0,
          MaxResultCount: (pagination.current + 1) * pagination.pageSize,
          ...props,
        });
        if (res.response.ok) {
          setIlstrainerRecord(res.data);
        }
        setLoading({
          ...loading,
          trainingLoading: false,
        });
        break;
      case 'ilsparent':
        setLoading({
          ...loading,
          totalLoading: true,
        });
        pagination = recordPagination.ilstotal;

        res = await GetPlanPunchHistory({
          GroupTrainingPlanId,
          SkipCount: 0,
          MaxResultCount: (pagination.current + 1) * pagination.pageSize,
        });
        if (res.response.ok) {
          setIlsparentRecord(res.data);
        }
        setLoading({
          ...loading,
          totalLoading: false,
        });
        break;
      case 'chart':
        setShouldGetData(true);
        break;
      case 'childFeedback':
        setLoading({
          ...loading,
          childLoading: true,
        });
        pagination = recordPagination.childPagination;

        const childProps = selectNum.childFeedData.length >= 2 ? {
          Num: selectNum.childFeedData[0],
          UserId: selectNum.childFeedData[1]
        } : {};

        res = await GetChildFeedbackData({
          GroupTrainingPlanId,
          SkipCount: 0,
          MaxResultCount: (pagination.current + 1) * pagination.pageSize,
          ...childProps
        });

        if (res.response.ok) {
          setChildRecord(res.data);
        }

        setLoading({
          ...loading,
          childLoading: false,
        });
        break;
      case 'parentFeedback':
        setLoading({
          ...loading,
          parentLoading: true,
        });
        pagination = recordPagination.parentPagination;

        const parentProps = selectNum.parentFeedData.length >= 2 ? {
          Num: selectNum.parentFeedData[0],
          SubjectId: selectNum.parentFeedData[1]
        } : {};

        res = await GetParentFeedbackData({
          GroupTrainingPlanId,
          SkipCount: 0,
          MaxResultCount: (pagination.current + 1) * pagination.pageSize,
          ...parentProps
        });
        if (res.response.ok) {
          setParentRecord(res.data);
        }

        setLoading({
          ...loading,
          parentLoading: false,
        });
        break;
    }
  };

  const getRecord = async () => {
    let res;
    let pagination;
    switch (ilsActiveKey) {
      case 'ilstrainer':
        setLoading({
          ...loading,
          trainingLoading: true,
        });
        pagination = recordPagination.ilstrainer;

        const props = selectNum.allFeedNum > 0 ? {
          Num: selectNum.allFeedNum
        } : {}

        res = await GetPlanItemPunchHistory({
          GroupTrainingPlanId,
          SkipCount: (pagination.current + 1) * pagination.pageSize,
          MaxResultCount: pagination.pageSize,
          ...props
        });
        setRecordPagination({
          ...recordPagination,
          ilstrainer: {
            current: pagination.current + 1,
            pageSize: pagination.pageSize,
          },
        });
        if (res.response.ok) {
          setIlstrainerRecord({
            items: ilstrainerRecord.items.concat(res.data.items),
            totalCount: res.data.totalCount,
          });
        }
        setLoading({
          ...loading,
          trainingLoading: false,
        });
        break;
      case 'ilsparent':
        setLoading({
          ...loading,
          totalLoading: true,
        });
        pagination = recordPagination.ilstotal;

        res = await GetPlanPunchHistory({
          GroupTrainingPlanId,
          SkipCount: (pagination.current + 1) * pagination.pageSize,
          MaxResultCount: pagination.pageSize,
        });
        setRecordPagination({
          ...recordPagination,
          ilstotal: {
            current: pagination.current + 1,
            pageSize: pagination.pageSize,
          },
        });
        if (res.response.ok) {
          setIlsparentRecord({
            items: ilsparentRecord.items.concat(res.data.items),
            totalCount: res.data.totalCount,
          });
        }
        setLoading({
          ...loading,
          totalLoading: false,
        });
        break;
      case 'childFeedback':
        setLoading({
          ...loading,
          childLoading: true,
        });
        pagination = recordPagination.childPagination;

        const childProps = selectNum.childFeedData.length >= 2 ? {
          Num: selectNum.childFeedData[0],
          UserId: selectNum.childFeedData[1]
        } : {};

        res = await GetChildFeedbackData({
          GroupTrainingPlanId,
          SkipCount: (pagination.current + 1) * pagination.pageSize,
          MaxResultCount: pagination.pageSize,
          ...childProps
        });

        setRecordPagination({
          ...recordPagination,
          childPagination: {
            current: pagination.current + 1,
            pageSize: pagination.pageSize,
          },
        });

        if (res.response.ok) {
          setChildRecord({
            items: childRecord.items.concat(res.data.items),
            totalCount: res.data.totalCount,
          });
        }

        setLoading({
          ...loading,
          childLoading: false,
        });
        break;
      case 'parentFeedback':
        setLoading({
          ...loading,
          parentLoading: true,
        });
        pagination = recordPagination.parentPagination;

        const parentProps = selectNum.parentFeedData.length >= 2 ? {
          Num: selectNum.parentFeedData[0],
          SubjectId: selectNum.parentFeedData[1]
        } : {};

        res = await GetParentFeedbackData({
          GroupTrainingPlanId,
          SkipCount: (pagination.current + 1) * pagination.pageSize,
          MaxResultCount: pagination.pageSize,
          ...parentProps
        });

        setRecordPagination({
          ...recordPagination,
          parentPagination: {
            current: pagination.current + 1,
            pageSize: pagination.pageSize,
          },
        });

        if (res.response.ok) {
          setParentRecord({
            items: parentRecord.items.concat(res.data.items),
            totalCount: res.data.totalCount,
          });
        }

        setLoading({
          ...loading,
          parentLoading: false,
        });
        break;
    }
  };

  const ilstrainerPropsData = {
    dataSource: ilstrainerRecord,
    loadData: getRecord,
    isLoading: loading.trainingLoading,
    from: 'group',
  };

  const totalPropsData = {
    dataSource: ilsparentRecord,
    loadData: getRecord,
    isLoading: loading.totalLoading,
    from: 'group',
  };

  const userOfflineTrainingPlanProps = {
    UserOfflineTrainingPlanId: GroupTrainingPlanId,
    shouldGetData,
    Loading,
    callback: () => {
      setShouldGetData(false);
    },
  };

  const childFeedbackProps = {
    dataSource: childRecord,
    loadData: getRecord,
    isLoading: loading.childLoading,
    from: 'group',
  }

  const parentFeedbackProps = {
    dataSource: parentRecord,
    loadData: getRecord,
    isLoading: loading.parentLoading,
    from: 'group',
  }

  useEffect(() => {
    changeData()
  }, [selectNum])

  const changeData = async () => {
    let res;
    let pagination;
    switch (ilsActiveKey) {
      case 'ilstrainer':
        setLoading({
          ...loading,
          trainingLoading: true,
        });
        pagination = recordPagination.ilstrainer;

        const props = selectNum.allFeedNum > 0 ? {
          Num: selectNum.allFeedNum
        } : {}

        res = await GetPlanItemPunchHistory({
          GroupTrainingPlanId,
          SkipCount: 0,
          MaxResultCount: pagination.pageSize,
          ...props
        });

        if (res.response.ok) {
          setIlstrainerRecord(res.data);
        }
        setLoading({
          ...loading,
          trainingLoading: false,
        });
        break;
      case 'ilsparent':
        setLoading({
          ...loading,
          totalLoading: true,
        });
        pagination = recordPagination.ilstotal;

        res = await GetPlanPunchHistory({
          GroupTrainingPlanId,
          SkipCount: 0,
          MaxResultCount: pagination.pageSize,
        });

        if (res.response.ok) {
          setIlsparentRecord(res.data);
        }
        setLoading({
          ...loading,
          totalLoading: false,
        });
        break;
      case 'childFeedback':
        setLoading({
          ...loading,
          childLoading: true,
        });
        pagination = recordPagination.childPagination;

        const childProps = selectNum.childFeedData.length >= 2 ? {
          Num: selectNum.childFeedData[0],
          UserId: selectNum.childFeedData[1]
        } : {};

        res = await GetChildFeedbackData({
          GroupTrainingPlanId,
          SkipCount: 0,
          MaxResultCount: (pagination.current + 1) * pagination.pageSize,
          ...childProps
        });

        if (res.response.ok) {
          setChildRecord(res.data);
        }

        setLoading({
          ...loading,
          childLoading: false,
        });
        break;
      case 'parentFeedback':
        setLoading({
          ...loading,
          parentLoading: true,
        });
        pagination = recordPagination.parentPagination;

        const parentProps = selectNum.parentFeedData.length >= 2 ? {
          Num: selectNum.parentFeedData[0],
          SubjectId: selectNum.parentFeedData[1]
        } : {};

        res = await GetParentFeedbackData({
          GroupTrainingPlanId,
          SkipCount: 0,
          MaxResultCount: (pagination.current + 1) * pagination.pageSize,
          ...parentProps
        });
        if (res.response.ok) {
          setParentRecord(res.data);
        }

        setLoading({
          ...loading,
          parentLoading: false,
        });
        break;
    }
  };

  const loadData = async (selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;

    const res = await GetChildList({
      GroupTrainingPlanId,
      Num: targetOption.value,
    })

    if (res.response.ok) { 
      const activeData = res.data.filter(child => child.attendance === 'Attend')

      targetOption.loading = false;
      targetOption.children = activeData.map(item => {
          const { userId, userName } = item
          return {
            label: userName,
            value: userId,
          }
        })
          
      setOptions([...options]);
    }
  };

  return (
    <Drawer
      open={visible}
      width="700"
      onClose={onClose}
      title={
        <b>
          {`${currentTablePlan && currentTablePlan.displayName} ` +
            intl.formatMessage({ id: 'chartsAndFeedbackRecords' })}
        </b>
      }
    >
      <Tabs type="card" activeKey={ilsActiveKey} onChange={onTabsChange}>
        <TabPane tab={intl.formatMessage({ id: 'chart' })} key="chart">
          <div style={{ width: '80%', margin: 'auto' }}>
            <TrainingStatistics {...userOfflineTrainingPlanProps} />
            <CourseStatistics {...userOfflineTrainingPlanProps} />
          </div>
        </TabPane>
        <TabPane
          tab={intl.formatMessage({ id: 'overallFeedback' })}
          key="ilsparent"
        >
          <AdminClockInRecord {...totalPropsData} />
        </TabPane>
        <TabPane
          tab={intl.formatMessage({ id: 'activityFeedback' })}
          key="ilstrainer"
        >
          <div className={styles.allfeedback}>
            <div>
              <AdminClockInRecord {...ilstrainerPropsData} />
            </div>
            <div className={styles.allfeedbackChild}>
              <Select
                placeholder={intl.formatMessage({ id: 'pleaseSelect' })}
                optionFilterProp="classList"
                style={{ width: 195 }}
                onChange={(value) => {
                  setSelectNum({
                    ...selectNum,
                    allFeedNum: value
                  })
                }}
              >
                <Option value="-1">{intl.formatMessage({ id: 'all' })}</Option>
                {
                  Array(parseInt(currentNum)).fill(0).map((_, index) => {
                    const tempData = currentNum - index
                    return <Option key={tempData} value={tempData}>{intl.formatMessage({ id: 'whichSession' }, { num: tempData })}</Option>
                  })
                }
              </Select>
            </div>
          </div>
        </TabPane>
        <TabPane
          tab={intl.formatMessage({ id: 'personalFeedback' })}
          key="childFeedback"
        >
          <div className={styles.allfeedback}>
            <div>
              <AdminClockInRecord {...childFeedbackProps} />
            </div>
            <div className={styles.allfeedbackChild}>
              <Cascader 
                  placeholder={intl.formatMessage({ id: 'pleaseSelect' })}
                  options={options} 
                  loadData={loadData} 
                  onChange={(value) => {
                    // [20, 'ddbf83d8-62a6-4e10-8961-1e6375afe396']
                    if (value[0] < 0 || value.length >= 2 || value.length === 0 ) {
                      setSelectNum({
                        ...selectNum,
                        childFeedData: value
                      })
                    }
                  }} 
                  changeOnSelect 
                />
            </div>
          </div>
        </TabPane>
        <TabPane
          tab={intl.formatMessage({  id: 'parentFeedback' })}
          key="parentFeedback"
        >
          <div className={styles.allfeedback}>
            <div>
              <AdminClockInRecord {...parentFeedbackProps} />
            </div>
            <div className={styles.allfeedbackChild}>
              <Cascader 
                placeholder={intl.formatMessage({ id: 'pleaseSelect' })}
                options={options} 
                loadData={loadData} 
                onChange={(value) => {
                  if (value[0] < 0 || value.length >= 2 || value.length === 0 ) {
                    setSelectNum({
                      ...selectNum,
                      parentFeedData: value
                    })
                  }
                }} 
                changeOnSelect 
              />
            </div>
          </div>
        </TabPane>
      </Tabs>
    </Drawer>
  );
};

export default ChartsDrawer;
