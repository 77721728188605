import { message } from 'antd'
import getRequest from 'utils/request'
import download from 'cognitiveleap-core-us/utils/download'

import { pdfBaseUrl } from 'utils/config'

// node 服务 不要到/api/
const GetDownloadApi = '/downloadPdf'

export async function GetDownloadPdf(data, fileName) {
    const { response } = await getRequest({ myBaseURL: pdfBaseUrl })(GetDownloadApi, {
        method: 'POST',
        data
    })

    if (response && response.ok) {
        response.blob().then(blob => {
            download(blob, fileName + '.pdf')
        })
    } else {
        message.error("下载失败，刷新后重试")
        return 'downloadFail'
    }
    // return getRequest({ myBaseURL: pdfBaseUrl })(GetDownloadApi, {
    //     method: 'POST',
    //     data
    // }).then(res => {
    //     const { response } = res
    //     console.log(res);
    //     if (response.ok) {
    //         response.blob().then(blob => {
    //             download(blob, fileName + '.pdf')
    //         })
    //     }
    // })
}