import { Modal, Form, Radio, Button } from 'antd'
import styles from './index.less'
import { IntlManager } from 'utils/helper'
const FormItem = Form.Item


const PublishMenu = ['Unpublish', 'Hide', 'Public', 'Expire', 'Trash']

const StatusModal = ({
  visible,
  onCancel,
  initialValue,
  onOk,
  description
}) => {
  const intl = IntlManager.MyIntl()

  const onSubmit = (values) => {
    onOk(values)
  }

  const onQuit = () => {
    onCancel()
  }

  return (
    <Modal
      title={'Change Status'}
      open={visible}
      destroyOnClose={true}
      maskClosable={false}
      onCancel={onQuit}
      footer={null}
    >
      <Form
        layout="horizontal"
        initialValues={{ status: initialValue }}
        onFinish={onSubmit}
        preserve={false}
      >
        <FormItem
          label={'Action'}
          name='status'
          rules={[{
            required: true,
          }]}
          extra={description}
        >
          <Radio.Group>
            {PublishMenu.map(item => {
              return (
                <Radio key={item} value={item}>
                  {item}
                </Radio>
              )
            })}
          </Radio.Group>
        </FormItem>
        <div className={styles.footer}>
          <Button className={styles.footerLeft} onClick={onQuit}>{intl("cancel")}</Button>
          <Button type="primary" htmlType="submit">
            {intl("submit")}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default StatusModal
