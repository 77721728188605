import getRequest from 'utils/request';

const toSupervisorApi = '/api/rocketAdmin/trainerSupervise/toSupervisor/{userId}';
const trainerToSupervisorApi =
  '/api/rocketAdmin/trainerSupervise/trainerToSupervisor';
const getSupervisedListApi = '/api/rocketAdmin/trainerSupervise/supervisedList';
const cancelSupervisorApi =
  '/api/rocketAdmin/trainerSupervise/cancelSupervisor/{userId}';
const deleteSupervisorApi = '/api/rocketAdmin/trainerSupervise/trainerToSupervisor';
const postSendInviterEmailApi =
  '/api/rocketAdmin/trainerSupervise/sendInviteTrainerToSupervisorEmail';

export async function PostSendInviterEmail(data) {
  return getRequest()(postSendInviterEmailApi, {
    method: 'POST',
    params: data,
  });
}

export async function ToSupervisor(data) {
  return getRequest()(toSupervisorApi.replace('{userId}', data.id), {
    method: 'POST',
    data,
  });
}

export async function TrainerToSupervisor(data) {
  return getRequest()(trainerToSupervisorApi, {
    method: 'POST',
    data,
  });
}

export async function GetSupervisedList(data) {
  return getRequest()(getSupervisedListApi, {
    method: 'GET',
    params: data,
  });
}

export async function CancelSupervisor(data) {
  return getRequest()(cancelSupervisorApi.replace('{userId}', data.id), {
    method: 'POST',
    data,
  });
}

export async function DeleteSupervisor(data) {
  return getRequest()(deleteSupervisorApi, {
    method: 'DELETE',
    params: data,
  });
}
