
import moment from 'moment'

export function getAnalysisSubTitle(field, data, intl){
    switch(field){
        case 'LatestExpirationTime':
            return `${intl.formatMessage({ id: 'expiredDate' })}${moment(data).format('YYYY/MM/DD HH:mm')}`
        default:
            return ''
    }
}

export function  getAnalysisData(field, data, intl){
    switch(field){
        case 'LastAmount':
            return data ? ( data === -1 ? `${intl.formatMessage({ id: 'unlimited' })}` : data ) : `${intl.formatMessage({ id: 'noYet' })}`;
        default:
            return ''
    }
}