import react from 'react';
import { Modal, Radio, Select, Icon, Row, Col, Spin, Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styles from './index.less';
import { useIntl } from 'umi';

const { Option } = Select;
const ClassAdjustmentModal = (
  {
    userOfflineTrainingPlan = {},
    classAdjustmentModalVisible,
    classAdjustmentOnok,
    addClassType,
    updateData,
    choicedCClass,
    latelyCourse,
    latelyCourseIsLoading,
    loading
  }) => {
  const intl = useIntl()

  const hideClassAdjustmentModal = () => {
    updateData('classAdjustmentModalVisible', false);
  }

  const onChange = (e) => {
    updateData('addClassType', e.target.value);
  }

  const onClickSubmit = () => {
    classAdjustmentOnok(
      addClassType,
      {
        'addpreoneclass': latelyOnecourse.map(item => item.num),
        'addpretwoclass': latelyTwocourse.map(item => item.num),
        'addcclass': choicedCClass
      }[addClassType]
    );
  }

  const latelyOnecourse = latelyCourse && latelyCourse.recentCourses && latelyCourse.recentCourses.slice(0, 1) || [];

  const latelyTwocourse = latelyCourse && latelyCourse.recentCourses && latelyCourse.recentCourses.slice(0, 2) || [];

  const cClassDataSource = latelyCourse && latelyCourse.cCourse && latelyCourse.cCourse.map(item => { return { key: item.code, title: item.code, ...item } }) || []

  const isV4 = userOfflineTrainingPlan.ilsVersion === 'V4'

  const handleChange = (value) => {
    updateData('choicedCClass', [value]);
  }

  return (
    <div>
      <Modal
        width={600}
        title={<b>{intl.formatMessage({ id: 'adjustSessionTime'  })}</b>}
        footer={null}
        open={classAdjustmentModalVisible}
        style={{ wordBreak: 'break-word' }}
        onCancel={() => { hideClassAdjustmentModal() }}
      >
        <Spin spinning={latelyCourseIsLoading}>
          <div className={styles.ClassAdjustmentModalContent}>
            <div className={styles.ClassAdjustmentModalContentTitle}>{intl.formatMessage({ id: 'anySuggestions'  })}</div>
            <Radio.Group onChange={e => onChange(e)} value={addClassType}>
              {/* {!isV4 ?<Radio value='addpreoneclass' className={styles.ClassAdjustmentModalContentRadio} disabled={latelyOnecourse && latelyOnecourse.length < 1}>
                {intl.formatMessage({ id: 'absentRepeatOne'  })}{latelyOnecourse.map((item, index) => <span key={index}>{`${item && item.courseCode}，`}</span>)}
              </Radio>:null
              }
              {!isV4?<Radio value='addpretwoclass' className={styles.ClassAdjustmentModalContentRadio} disabled={latelyTwocourse && latelyTwocourse.length < 2}>
                {intl.formatMessage({ id: 'absentRepeatTwo'  })}{latelyTwocourse.map((item, index) => <span key={index}>{`${item && item.courseCode}，`}</span>)}
              </Radio>:null} */}
              <Radio value='addcclass' className={styles.ClassAdjustmentModalContentRadio}>
                <span>{intl.formatMessage({ id: 'calmingLessonDes'  })}</span>
              </Radio>
              <div style={{ marginTop: '10px', marginLeft: '25px' }}>
                <Select
                  placeholder={intl.formatMessage({ id: 'pleaseSelect' })}
                  style={{ width: 250 }}
                  onChange={handleChange}
                  value={choicedCClass[0]}
                  disabled={addClassType != 'addcclass'}
                >
                  {
                    cClassDataSource.map(item => {
                      return <Option key={item.code} value={item.code}>{item.title}</Option>
                    })
                  }
                </Select>
              </div>
            </Radio.Group>
          </div>
          <div className={styles.tip}>
            <ExclamationCircleOutlined className={styles.icon} />
            <span className={styles.tipTitle}>{intl.formatMessage({ id: 'adjustmentDes' })}</span>
          </div>
          <Row gutter={24}>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button onClick={() => { hideClassAdjustmentModal() }}>{intl.formatMessage({ id: 'cancel' })}</Button>
            </Col>
            <Col span={12} style={{ textAlign: 'left' }}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={onClickSubmit}
                loading={loading}
              >
                {intl.formatMessage({ id: 'submit' })}
              </Button>
            </Col>
          </Row>
        </Spin>
      </Modal>
    </div>
  )
}

export default ClassAdjustmentModal;