import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { useIntl } from 'umi';

import {
  GetTrainerTrainingSupervision,
  DeleteTrainerTrainingSupervision,
} from 'services/trainingSystem';

import AddSupervisionModal from './Modals/AddSupervisionModal';
import FeedbackQuestionDrawer from './Modals/FeedbackQuestionDrawer';

import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';

const timeFormat = 'MM/DD/YYYY HH:mm';

const SupervisionTab = ({ roleType, userInfo, allowReloadTab, onChangeAllowReloadProgress }) => {
  const intl = useIntl();

  const { id } = userInfo || {};

  const [showList, setShowList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentTrainItem, setCurrentTrainItem] = useState({});
  const [type, setType] = useState('');
  const [visible, setVisible] = useState({
    addSupervisionModalVisible: false,
    feedbackQuestionDrawerVisible: false,
  });
  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const initData = async () => {
    setLoading(true);

    const res = await GetTrainerTrainingSupervision({
      TrainerId: id,
      SkipCount: (pagination.current - 1) * pagination.pageSize,
      MaxResultCount: pagination.pageSize,
    });

    if (res.response.ok) {
      const { items = [], totalCount } = res.data || {};
      setShowList(items);
      setPagination({
        ...pagination,
        total: totalCount,
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, [pagination.current, pagination.pageSize, allowReloadTab]);

  const listSupport = {
    trainingModule: {
      showText: intl.formatMessage({ id: 'Module' }),
      showType: 'Text',
      render: (_, record) => {
        const { trainingModule } = record || {};
        const { displayName } = trainingModule || {};

        return displayName;
      },
    },
    order: {
      showText: intl.formatMessage({ id: 'NoNumber' }),
      showType: 'Text',
    },
    status: {
      showText: intl.formatMessage({ id: 'status' }),
      showType: 'Text',
      render: (text) => {
        switch (text) {
          case 'InProgress':
            return intl.formatMessage({ id: 'ongoing' });
          case 'Done':
            return intl.formatMessage({ id: 'done' });
          case 'NotStart':
            return intl.formatMessage({ id: 'notStart' });
          default:
            return text;
        }
      }
    },
    supervisor: {
      showText: intl.formatMessage({ id: 'Supervisor' }),
      showType: 'Text',
      render: (_, record) => {
        const { supervisor } = record || {};
        const { name } = supervisor || {};

        return name;
      },
    },
    supervisorScoring: {
      showText: intl.formatMessage({ id: 'Scores' }),
      showType: 'Text',
      render: (text) => {
        return text % 1 === 0 ? text : text.toFixed(1);
      }
    },
    ssFinishTime: {
      showText: intl.formatMessage({ id: 'Feedback Time' }),
      showType: 'Time',
      addtional: {
        format: timeFormat,
      },
    },
    tsFinishTime: {
      showText: intl.formatMessage({ id: 'Record Time' }),
      showType: 'Time',
      addtional: {
        format: timeFormat,
      },
    },
  };

  const onDelete = async (record) => {
    const { id } = record || {};
    const res = await DeleteTrainerTrainingSupervision({ id });
    if (res.response.ok) {
      initData();
      onChangeAllowReloadProgress && onChangeAllowReloadProgress();
    }
  };

  const onFeedbackQuestion = (item, type) => {
    setType(type);
    setCurrentTrainItem(item);
    setVisible({
      ...visible,
      feedbackQuestionDrawerVisible: true,
    });
  };

  const tableProps = {
    loading,
    listData: showList,
    listSupport,
    pagination,
    onTableChange(page) {
      const { current, pageSize } = page;

      setPagination({
        ...pagination,
        current,
        pageSize,
      });
    },
    Actions: [
      {
        showText: 'Fill In Supervisor Feedback',
        itemRender(record) {
          const { supervisorSSummaryFinish, trainerTrainingModuleStatus, status } = record || {};
          if (trainerTrainingModuleStatus === 'Stopped' && !supervisorSSummaryFinish) {
            return null;
          }
          if (roleType === 'admin') {
            return status !== 'NotStart' ? (
              <a onClick={() => onFeedbackQuestion(record, 'SupervisorFeedback')}>
                {intl.formatMessage({
                  id: supervisorSSummaryFinish ? 'View' : 'Fill In',
                })}
                {intl.formatMessage({ id: 'Supervisor Feedback' })}
              </a>
            ) : null
          }
          return supervisorSSummaryFinish ? (
            <a onClick={() => onFeedbackQuestion(record, 'SupervisorFeedback')}>
              {intl.formatMessage({
                id: 'View',
              })}
              {intl.formatMessage({ id: 'Supervisor Feedback' })}
            </a>
          ) : null;
        },
      },
      {
        showText: 'Fill In Trainer Records',
        itemRender(record) {
          const { trainerSummaryFinish, trainerTrainingModuleStatus, status } = record || {};
          if (trainerTrainingModuleStatus === 'Stopped' && !trainerSummaryFinish) {
            return null;
          }
          if (roleType === 'personal') {
            return status !== 'NotStart' ? (
              <a onClick={() => onFeedbackQuestion(record, 'TrainerRecords')}>
                {intl.formatMessage({
                  id: trainerSummaryFinish ? 'View' : 'Fill In',
                })}
                {intl.formatMessage({ id: 'Trainer Records' })}
              </a>
            ) : null;
          }
          return trainerSummaryFinish ? (
            <a onClick={() => onFeedbackQuestion(record, 'TrainerRecords')}>
              {intl.formatMessage({
                id: 'View',
              })}
              {intl.formatMessage({ id: 'Trainer Records' })}
            </a>
          ) : null;
        },
      },
      roleType === 'admin'
        ? {
          showText: 'Delete',
          itemRender(record) {
            const { status, trainerTrainingModuleStatus } = record || {};
            if (trainerTrainingModuleStatus === 'Stopped') {
              return null;
            }
            return status === 'NotStart' ? (
              <a onClick={() => onDelete(record)}>
                {intl.formatMessage({ id: 'delete' })}
              </a>
            ) : null;
          },
        }
        : null,
    ],
  };

  const addSupervisionModalProps = {
    userInfo,
    visible: visible.addSupervisionModalVisible,
    closeModal: () => {
      setVisible({
        ...visible,
        addSupervisionModalVisible: false,
      });
    },
    reloadPage: () => {
      initData();
      onChangeAllowReloadProgress && onChangeAllowReloadProgress();
    },
  };

  const feedbackQuestionDrawerProps = {
    visible: visible.feedbackQuestionDrawerVisible,
    type,
    currentTrainItem,
    roleType,
    onClose: () => {
      setVisible({
        ...visible,
        feedbackQuestionDrawerVisible: false,
      });
      initData();
      onChangeAllowReloadProgress && onChangeAllowReloadProgress();
    },
  };

  const onAddSupervision = () => {
    setVisible({
      ...visible,
      addSupervisionModalVisible: true,
    });
  };

  return (
    <div>
      {roleType === 'admin' && (
        <div style={{ textAlign: 'right' }}>
          <Button type="link" onClick={onAddSupervision}>
            {intl.formatMessage({ id: 'Add Supervision' })}
          </Button>
          <p />
        </div>
      )}

      <MagicTable {...tableProps} />

      {visible.addSupervisionModalVisible && (
        <AddSupervisionModal {...addSupervisionModalProps} />
      )}
      {visible.feedbackQuestionDrawerVisible && (
        <FeedbackQuestionDrawer {...feedbackQuestionDrawerProps} />
      )}
    </div>
  );
};

export default SupervisionTab;
