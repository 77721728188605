/*
ATTENTION, SELF CONTROL, SPEED: SELECT CorrectHits/52, (468 - CommissionErros)/52, (1 - TargetsRT) * 100 FROM cpt_output_results WHERE Block = 0 and CasdId = i"

STEADINESS: SELECT (1 - TimeActive) * 100 FROM head_features WHERE BlockNum = 0 and CaseId = i"

SENSITIVITY, STYLE: SELECT DPrime / 6 * 100, 50 + C / 2 * 50 FROM signal_detection WHERE Block = 0 and CaseId = i"
*/

import ReactEcharts from 'echarts-for-react'
import { useIntl } from 'umi'

const radarChart = ({ data }) => {
  const intl = useIntl();

  const showdata = data
  if (!showdata) return <div />

  const {
    Attention,
    ContinuousAttention,
    Sensitivity,
    ReactionStability,
    Reactivity,
    SelfControl,
  } = showdata || {};

  const _Attention = Math.round(Attention * 100);
  const _ContinuousAttention = Math.round(ContinuousAttention * 100);
  const _Sensitivity = Math.round(Sensitivity * 100);
  const _ReactionStability = Math.round(ReactionStability * 100);
  const _Reactivity = Math.round(Reactivity * 100);
  const _SelfControl = Math.round(SelfControl * 100);

  const option = {
    tooltip: {
      show: false,
    },
    legend: {
      data: [
        intl.formatMessage({ id: 'report.attentionSkill.chart.legendW' }),
        intl.formatMessage({ id: 'report.attentionSkill.chart.legendU' }),
      ],
      top: -5,
    },
    splitNumber: 3,
    radar: {
      // shape: 'circle',
      silent: true,
      name: {
        textStyle: {
          color: '#000',
        },
      },
      indicator: [
        {
          name: `${intl.formatMessage({ id: 'report.attentionSkill.chart.attention' })}\n${_Attention}`,
          max: 100,
        },
        {
          name: `${intl.formatMessage({ id: 'report.attentionSkill.chart.continuous' })}\n${_ContinuousAttention}`,
          max: 100,
        },
        {
          name: `${intl.formatMessage({ id: 'report.attentionSkill.chart.sensitivity' })}\n${_Sensitivity}`,
          max: 100,
        },
        {
          name: `${intl.formatMessage({ id: 'report.attentionSkill.chart.reactSta' })}\n${_ReactionStability}`,
          max: 100,
        },
        {
          name: `${intl.formatMessage({ id: 'report.attentionSkill.chart.reactivity' })}\n${_Reactivity}`,
          max: 100,
        },
        {
          name: `${intl.formatMessage({ id: 'report.attentionSkill.chart.self' })}\n${_SelfControl}`,
          max: 100,
        },
      ],
      splitArea: {
        areaStyle: {
          color: '#fff',
        },
      },
      axisLine: {
        lineStyle: {
          color: '#fff',
        },
      },
      splitLine: {
        lineStyle: {
          color: ['#999', '#fff', '#fff'],
        },
      },
      splitNumber: 3,
      center: ['50%', '53%'],
      axisLabel: {
        align: 'center',
      },
      nameGap: 10,
    },
    series: [
      {
        name: 'Standard vs Your',
        type: 'radar',
        areaStyle: {
          color: '#fff',
        },
        silent: true,
        data: [
          {
            value: [50, 50, 50, 50, 50, 50],
            name: intl.formatMessage({ id: 'report.attentionSkill.chart.legendW' }),
            silent: false,
            // label: {
            //   normal: {
            //     show: true,
            //     formatter: function (params) {
            //       return params.value
            //     },
            //     // position: [-10, -15],
            //   }
            // },
            areaStyle: {
              color: '#EEA9A9',
            },
            lineStyle: {
              color: '#DF6161',
              type: 'dashed',
            },
            itemStyle: {
              color: '#EEA9A9',
            },
            symbolSize: 1,
          },
          {
            value: [
              _Attention,
              _ContinuousAttention,
              _Sensitivity,
              _ReactionStability,
              _Reactivity,
              _SelfControl,
            ],
            name: intl.formatMessage({ id: 'report.attentionSkill.chart.legendU' }),
            silent: true,
            areaStyle: {
              // color: '#6CC4E2'
              color: '#36abd3',
            },
            // lineStyle: {
            //   color: '#6CC4E2'
            // },
            itemStyle: {
              color: '#36abd3',
            },
            label: {
              show: false,
              fontSize: 12,
              color: '#000',
            },
            symbolSize: 7,
          },
        ],
      },
    ],
  }

  return (
    <ReactEcharts
      option={option}
      style={{ height: '430px', width: '123%', margin: 'auto', marginLeft: -20 }}
    />
  )
}

export default radarChart
