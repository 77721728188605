import getRequest from 'utils/request';

const GetInfo = '/api/us/Account/Info'
const GetTenantInfo = '/api/business/account/tenantInfo'
const GetMenu = '/api/business/menu/project'
const GetActivate = '/api/business/user/activateAccount'
const GetActivateEmail = '/api/business/user/activateNewEmail'
const CheckToken = '/api/business/user/checkTokenAndUser'
const CheckRegisterApi = '/api/us/contentAdmin/needRegister'

const GetPermission = '/api/business/permission/auth'
const GetTenantQRCode = '/api/business/QrCode/tenant'
const getTetanntList = '/api/business/tenant/changeList'
const PreviewQrcodeApi = '/api/business/QrCode/preview'
const PutUserInfoApi = '/api/business/user/profile'
const PostCheckPasswordApi = '/api/business/user/resetEmailCheckPassword'
const PostSendCodeApi = '/api/business/user/sendEmailCode'
const PutUserEmailApi = '/api/business/user/userEmail'
const PostChangePasswordApi = '/api/us/Account/ChangePassword'
const GetOnlineApi = '/api/business/user/userLoginStatus'
const GetTrainingApplicationApi = '/api/rocketAdmin/QrCode/ApplyTraining'
const GetFeedbackApplicationApi = '/api/rocketAdmin/QrCode/TrainingFeedback'
const GetGrantApi = '/api/rocketAdmin/QrCode/grant'
const GetReportApi = '/api/rocketAdmin/QrCode/assessmentReport'
const GetNewTrainerTrainingModuleApi = '/api/rocketAdmin/trainerTraining/newTrainerTrainingModule'
const PostLogoutUserApi = '/api/business/user/logoutUser'
const GetProfileApi = '/api/us/Internal/Account/Profile'
const PostUsResetEmailApi = '/api/us/Internal/Account/ResetEmailAsync'
const PostUsChangePhoneNumberApi = '/api/us/Internal/Account/ChangePhoneNumber'
const PostUsChangePasswordApi = '/api/us/Internal/Account/ChangePassword'
const PostSendPhoneCodeApi = '/api/us/Internal/Account/SendChangePhoneNumberToken'
const PostCheckEmailPasswordApi = '/api/us/Internal/Account/ResetEmailCheckPassword'
const PostSendEmailApi = '/api/us/Internal/Account/SendEmailCode'
const PutChangeEmailByCodeApi = '/api/us/Internal/Account/ChangeEmailByCode'

const GetParentQrCodeApi = '/api/rocketAdmin/QrCode/ParentConsole'
const GetCoachQrCodeApi = '/api/rocketAdmin/QrCode/CoachConsole'
const PostTrainerActiveApi =
  '/api/rocketAdmin/trainerSupervise/trainerToSupervisorByToken';

// Login Code
const GetUserLoginCodeApi = '/api/rocketAdmin/account/userLoginCode/{userId}';
const PostGenerateLoginCodeApi = '/api/rocketAdmin/account/generateLoginCode';
const PostResolvingLoginCodeApi = '/api/rocketAdmin/account/resolvingLoginCode';

export async function GetUserLoginCode(data) {
  return getRequest()(GetUserLoginCodeApi.replace('{userId}', data.id), {
    method: 'GET',
    params: data,
  });
}

export async function PostGenerateLoginCode(data) {
  return getRequest()(PostGenerateLoginCodeApi, {
    method: 'POST',
    data,
  });
}

export async function PostResolvingLoginCode(data) {
  return getRequest()(PostResolvingLoginCodeApi, {
    method: 'POST',
    data,
  });
}

export async function PostTrainerActive(token) {
  return getRequest()(PostTrainerActiveApi, {
    method: 'POST',
    params: { token },
  });
}

export async function PostSendEmail(data) {
  return getRequest()(PostSendEmailApi, {
    method: 'POST',
    params: data,
  });
}

export async function PutChangeEmailByCode(data) {
  return getRequest()(PutChangeEmailByCodeApi, {
    method: 'PUT',
    data,
  });
}

export async function PostCheckEmailPassword(data) {
  return getRequest()(PostCheckEmailPasswordApi, {
    method: 'POST',
    data,
  });
}

export async function ResetEmailAsync(data) {
  return getRequest()(PostUsResetEmailApi, {
    method: 'POST',
    data,
  });
}

export async function ChangePhoneNumberUs(userInfo, data) {
  return getRequest()(PostUsChangePhoneNumberApi, {
    method: 'POST',
    params: userInfo,
    data,
  });
}

export async function PostSendPhoneCode(userInfo, data) {
  return getRequest()(PostSendPhoneCodeApi, {
    method: 'POST',
    params: userInfo,
    data,
  });
}

export async function ChangePasswordUs(userInfo, data) {
  return getRequest()(PostUsChangePasswordApi, {
    method: 'POST',
    params: userInfo,
    data,
  });
}

export async function GetTetanntList() {
  return getRequest()(getTetanntList, {
    method: 'GET',
  });
}

export async function GetProfile(data) {
  return getRequest()(GetProfileApi, {
    method: 'GET',
    params: data,
  });
}

export async function PutProfile(data) {
  return getRequest()(GetProfileApi, {
    method: 'PUT',
    data,
  });
}

export async function GetLoginInfo() {
  return getRequest()(GetInfo, {
    method: 'GET',
  });
}

export async function GetGrant() {
  return getRequest()(GetGrantApi, {
    method: 'GET',
  });
}

export async function GetParentQrCode() {
  return getRequest()(GetParentQrCodeApi, {
    method: 'GET',
  });
}

export async function GetCoachQrCode() {
  return getRequest()(GetCoachQrCodeApi, {
    method: 'GET',
  });
}

export async function GetReport() {
  return getRequest()(GetReportApi, {
    method: 'GET',
  });
}

export async function GetUserPermission() {
  return getRequest()(GetPermission, {
    method: 'GET',
  });
}

export async function TenantInfo() {
  return getRequest()(GetTenantInfo, {
    method: 'GET',
  });
}

export async function ActivateAccount(data) {
  return getRequest()(GetActivate, {
    method: 'POST',
    data,
  });
}

export async function ActivateEmail(data) {
  return getRequest()(GetActivateEmail, {
    method: 'POST',
    data,
  });
}

export async function CheckActivateToken(token) {
  return getRequest()(CheckToken, {
    method: 'GET',
    params: { token },
  });
}

export async function CheckRegister(token) {
  return getRequest()(CheckRegisterApi, {
    method: 'GET',
    params: { token },
  });
}

export async function GetProjectMenu() {
  return getRequest()(GetMenu, {
    method: 'GET',
  });
}

export async function GetTenantQR(tenantId) {
  return getRequest()(GetTenantQRCode, {
    method: 'GET',
    params: { tenantId },
  });
}

export async function PreviewQrcode(tenantId) {
  return getRequest()(PreviewQrcodeApi, {
    method: 'GET',
    params: { tenantId },
  });
}

export async function PutUserInfo(data) {
  return getRequest()(PutUserInfoApi, {
    method: 'PUT',
    data,
  });
}

export async function PostCheckPassword(data) {
  return getRequest()(PostCheckPasswordApi, {
    method: 'POST',
    data,
  });
}

export async function PostSendCode(data) {
  return getRequest()(PostSendCodeApi, {
    method: 'POST',
    data,
  });
}

export async function PutUserEmail(data) {
  return getRequest()(PutUserEmailApi, {
    method: 'PUT',
    data,
  });
}

export async function PostChangePassword(data) {
  return getRequest()(PostChangePasswordApi, {
    method: 'POST',
    data,
  });
}

export async function GetOnline() {
  return getRequest()(GetOnlineApi, {
    method: 'GET',
  });
}

export async function GetTrainingApplication() {
  return getRequest()(GetTrainingApplicationApi, {
    method: 'GET',
  });
}

export async function GetFeedbackApplication() {
  return getRequest()(GetFeedbackApplicationApi, {
    method: 'GET',
  });
}

export async function GetNewTrainerTrainingModule() {
  return getRequest()(GetNewTrainerTrainingModuleApi, {
    method: 'GET',
  });
}

export async function PostLogoutUserApplication() {
  return getRequest()(PostLogoutUserApi, {
    method: 'POST',
  });
}
