import { Empty, Spin } from 'antd'
import React from 'react'
import { IntlManager } from 'utils/helper'
import styles from './index.less'

const MusicRecord = ({ musicRecord, isLoading }) => {
    const intl = IntlManager.MyIntl()

    if (isLoading) {
        return <Spin />
    }

    const dataSource = musicRecord && musicRecord.userAudioPunch

    return (
        <div className={styles.musicRecord}>
            {
                dataSource && Array.isArray(dataSource) && dataSource.length > 0 ?
                    dataSource.map((item, index) => {
                        const { listenedList, num } = item
                        return (
                            <div key={index} className={styles.musicRecordItem}>
                                <div className={styles.musicRecordItemTitle}>{intl('whichSession', { num: num })}</div>
                                <div>
                                    <span>{intl('listened')}</span>
                                    {
                                        listenedList && Array.isArray(listenedList) && listenedList.length > 0 ?
                                        listenedList.map((listenedItem, listenedIndex) => {
                                            return (
                                                <span key={listenedIndex}>
                                                    {listenedList.length - 1 === listenedIndex ? listenedItem : listenedItem + '，'}
                                                </span>
                                            )
                                        })
                                        :
                                        <span>{intl('noMusicPlayed')}</span>
                                    }
                                </div>
                            </div>
                        )
                    })
                    :
                    <Empty
                        description={intl("emptyData")}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
            }
        </div>
    )
}

export default MusicRecord