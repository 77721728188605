import React, { useState, useEffect } from 'react';
import { useIntl, connect } from 'umi';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';

import {
  GetSupervisorList,
  GetTrainingList,
  PostTrainingAppointment,
  PutTrainingAppointment,
} from 'services/trainingSystem';

const MakeAppointmentModal = ({
  currentUser,
  userInfo,
  currentTrainItem = {},
  trainerTrainingModuleId,
  visible = false,
  closeModal = () => {},
  reloadPage = () => {},
}) => {
  const intl = useIntl();

  const { id: trainerId } = userInfo || {};

  const { hostRoles = [], staff } = currentUser || {};
  const { id: userId } = staff || {};

  const isSupervisor = hostRoles.find(item => item.name === "supervisor");

  const {
    duration = 0.5,
    title,
    supervisorId,
    trainerTrainingModuleId: currentTrainerTrainingModuleId,
    startTime,
    note,
    id,
  } = currentTrainItem || {};

  const [supervisorList, setSupervisorList] = useState([]);
  const [certList, setCertList] = useState([]);
  const [loading, setLoading] = useState(false);

  const initData = async () => {
    const resList = await Promise.all([
      GetSupervisorList({ SkipCount: 0, MaxResultCount: 99 }),
      GetTrainingList({ trainerId, TrainingStep: 'PracticalTraining' }),
    ]);
    if (resList.every((item) => item.response.ok)) {
      const { items = [] } = resList[0].data || {};
      const { data = [] } = resList[1] || {};

      setSupervisorList(items);
      setCertList(data);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  if (!(certList.length || supervisorList.length)) {
    return <></>;
  }

  const getSupervisorId = () => {
    if (supervisorId) return supervisorId;
    if (supervisorList.length === 1) return supervisorList[0].id;
    if (isSupervisor) return userId;
    return null;
  }

  const contentMenu = {
    Properties: [
      {
        EditorType: 'SingleWithSearch',
        ShowTitle: intl.formatMessage({ id: 'SelectModule' }),
        FormKey: 'trainerTrainingModuleId',
        AdditionalData: null,
        Value: trainerTrainingModuleId || currentTrainerTrainingModuleId,
        Setting: {
          DropdownOptions: certList.map((item) => {
            const { id, trainingModule } = item || {};
            const { name } = trainingModule || {};
            return {
              Value: name,
              Id: id,
            };
          }),
          Required: true,
        },
        Description: '',
      },
      {
        EditorType: 'Input',
        ShowTitle: intl.formatMessage({ id: 'Title of training content' }),
        FormKey: 'title',
        AdditionalData: null,
        Value: title,
        Setting: {
          Required: true,
        },
        Description: '',
      },
      {
        EditorType: 'DatePicker',
        ShowTitle: intl.formatMessage({ id: 'startTime' }),
        FormKey: 'startTime',
        AdditionalData: null,
        Value: startTime,
        Setting: {
          format: 'MM-DD-YYYY HH:mm',
          minuteStep: 5,
          Required: true,
          DateType: 'time',
        },
        Description: null,
      },
      {
        EditorType: 'NumberInput',
        ShowTitle: intl.formatMessage({ id: 'duration' }),
        FormKey: 'duration',
        AdditionalData: null,
        Value: duration,
        Setting: {
          Required: true,
          Props: {
            min: 0.5,
            max: 10,
            step: 0.5,
          },
        },
        Description: '',
      },
      {
        EditorType: 'SingleWithSearch',
        ShowTitle: intl.formatMessage({ id: 'Trainer' }),
        FormKey: 'supervisorId',
        AdditionalData: null,
        Value: getSupervisorId(),
        Setting: {
          DropdownOptions: supervisorList.map((item) => {
            const { id, name } = item || {};
            return {
              Id: id,
              Value: name,
            };
          }),
          Required: true,
        },
        Description: '',
      },
      {
        EditorType: 'Input',
        ShowTitle: intl.formatMessage({ id: 'Note' }),
        FormKey: 'note',
        AdditionalData: null,
        Value: note,
        Setting: {
          Required: false,
        },
        Description: '',
      },
    ],
  };

  const props = {
    title: intl.formatMessage({ id: 'Make Appointment' }),
    visible,
    content: contentMenu,
    loading,
    onCancel() {
      closeModal();
    },
    async onOk(value) {
      setLoading(true);

      const res =
        Object.keys(currentTrainItem).length > 0
          ? await PutTrainingAppointment({
              ...value,
              id,
            })
          : await PostTrainingAppointment(value);

      if (res.response.ok) {
        reloadPage();
      }

      closeModal();
      setLoading(false);
    },
  };

  return <MagicFormModal {...props} />;
};

export default connect(({ user }) => ({ currentUser: user.currentUser }))(MakeAppointmentModal);
