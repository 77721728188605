import React, { useState, useEffect } from 'react';
import { Modal, Form, Checkbox, Input, Col, Row, message } from 'antd';
import { connect, useIntl } from 'umi';

import { PostModules, PostGoals, GetGoals, PostPdt } from 'services/pdt';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const PdtStepForm = ({
  type = 'add',
  onClose = () => {},
  subjectId,
  guardianId,
  pdtModules = [],
  pdtGoals = [],
  pdtCustomGoal,
  dispatch,
}) => {
  const intl = useIntl();

  const isAdd = type === 'add';

  const [firstForm] = Form.useForm();
  const [lastForm] = Form.useForm();

  const [goalsList, setGoalsList] = useState([]);
  const [firstModal, setFirstModal] = useState(type !== 'goals' ? true : false);
  const [lastModal, setLastModal] = useState(type === 'goals' ? true : false);

  const onFinish = async (values) => {
    const { pdtModules } = firstForm.getFieldsValue(true);
    const { customGoal, goals } = values || {};
    const moduleProps = {
      subjectId,
      guardianId,
      pdtModules,
    };
    const goalsProps = {
      subjectId,
      customGoal,
      goals,
    };

    let resList = [];
    if (type === 'add') {
      resList = await Promise.all([PostPdt({ ...moduleProps, ...goalsProps })]);
    } else {
      resList = await Promise.all([
        type === 'modules' && PostModules(moduleProps),
        type === 'goals' && PostGoals(goalsProps),
      ]);
    }

    if (resList.filter((item) => item).some((item) => item.response.ok)) {
      dispatch({ type: 'subjectDetail/loadPage', payload: { id: subjectId } });
    } else {
      message.error('failed');
    }
  };

  const getGoalsData = async () => {
    const res = await GetGoals();
    if (res.response.ok) {
      setGoalsList(res.data);
      isAdd && setLastModal(true);
    } else {
      const { error } = res.data;
      message.error(error.message);
    }
  };

  useEffect(() => {
    type === 'goals' && getGoalsData();
  }, []);

  return (
    <div>
      <Modal
        title={intl.formatMessage({ id: 'StartFocusEDTxProgram' })}
        open={firstModal}
        width={800}
        okText={intl.formatMessage({ id: isAdd ? 'next' : 'submit' })}
        cancelText={intl.formatMessage({ id: 'cancel' })}
        onOk={() => {
          if (isAdd) {
            setFirstModal(false);
            getGoalsData();
          } else {
            firstForm.submit();
            onClose();
          }
        }}
        onCancel={() => {
          onClose();
        }}
      >
        <Form
          name="firstModal"
          form={firstForm}
          onFinish={onFinish}
          {...formItemLayout}
          initialValues={{
            pdtModules:
              pdtModules.length === 0
                ? ['Cbt', 'Emotion', 'ParentLearning', 'ParentEmotion']
                : pdtModules,
          }}
        >
          <Form.Item
            name="pdtModules"
            label={intl.formatMessage({ id: 'SetUpModules' })}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Checkbox.Group>
              <Row>
                <Col span={6}>
                  <Checkbox value="Cbt" style={{ lineHeight: '32px' }} disabled>
                    {intl.formatMessage({ id: 'CBTClass' })}
                  </Checkbox>
                </Col>

                <Col span={6}>
                  <Checkbox
                    value="Emotion"
                    style={{ lineHeight: '32px' }}
                    disabled
                  >
                    {intl.formatMessage({ id: 'Emotion' })}
                  </Checkbox>
                </Col>

                <Col span={6}>
                  <Checkbox
                    value="ParentLearning"
                    style={{ lineHeight: '32px' }}
                    disabled
                  >
                    {intl.formatMessage({ id: 'ParentLearning' })}
                  </Checkbox>
                </Col>

                <Col span={6}>
                  <Checkbox
                    value="ParentEmotion"
                    style={{ lineHeight: '32px' }}
                    disabled
                  >
                    {intl.formatMessage({ id: 'ParentEmotion' })}
                  </Checkbox>
                </Col>

                <Col span={6}>
                  <Checkbox
                    value="Exercise"
                    style={{ lineHeight: '32px' }}
                    disabled={pdtModules.includes('Exercise')}
                  >
                    {intl.formatMessage({ id: 'Exercise-PDT' })}
                  </Checkbox>
                </Col>

                <Col span={6}>
                  <Checkbox
                    value="Sleep"
                    style={{ lineHeight: '32px' }}
                    disabled={pdtModules.includes('Sleep')}
                  >
                    {intl.formatMessage({ id: 'Sleep-PDT' })}
                  </Checkbox>
                </Col>

                <Col span={6}>
                  <Checkbox
                    value="Nutrition"
                    style={{ lineHeight: '32px' }}
                    disabled={pdtModules.includes('Nutrition')}
                  >
                    {intl.formatMessage({ id: 'Nutrition-PDT' })}
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={intl.formatMessage({
          id: isAdd ? 'StartFocusEDTxProgram' : 'SetUpModules',
        })}
        open={lastModal}
        okText={intl.formatMessage({ id: 'submit' })}
        width={800}
        cancelText={intl.formatMessage({ id: isAdd ? 'previous' : 'cancel' })}
        onOk={() => {
          setLastModal(false);
          setFirstModal(true);
          lastForm.submit();
          onClose();
        }}
        onCancel={() => {
          if (isAdd) {
            setFirstModal(true);
            setLastModal(false);
          } else {
            onClose();
          }
        }}
      >
        <Form
          form={lastForm}
          name="lastModal"
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            goals: pdtGoals.map((item) => item.id),
            customGoal: pdtCustomGoal,
          }}
        >
          <Form.Item
            name="goals"
            label={intl.formatMessage({ id: 'setUpProgramGoals' })}
          >
            <Checkbox.Group>
              <Row>
                {goalsList.map((goal) => {
                  const { name, id } = goal;
                  return (
                    <Col key={id} span={6}>
                      <Checkbox value={id} style={{ lineHeight: '32px' }}>
                        {name}
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item
            name="customGoal"
            label={intl.formatMessage({ id: 'Custom Goal' })}
          >
            <Input.TextArea
              rows={4}
              placeholder={intl.formatMessage({ id: 'Add Custom Goal' })}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default connect(({ subjectDetail }) => ({ subjectDetail }))(PdtStepForm);
