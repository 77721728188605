import React, { useState, useEffect } from 'react';
import { history, useIntl } from 'umi';
import { message, Modal } from 'antd';

import {
  GetTestList,
  PostOralQuestionnaire,
  PostPracticalQuestionnaire,
} from 'services/trainingSystem';

import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import ArrangeRetestModal from './Modals/ArrangeRetestModal';
import CreateOralHandsDrawer from './Modals/CreateOralHandsDrawer';

import { GetFirstExam, GetAgainExam, GetExamDetail } from 'services/exam';

import styles from '../index.less';

const timeFormat = 'MM/DD/YYYY HH:mm';

const TestTab = ({
  roleType,
  userInfo,
  allowReloadTab,
  onChangeAllowReloadProgress,
}) => {
  const intl = useIntl();

  const { id } = userInfo || {};

  const [showList, setShowList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState('');
  const [option, setOption] = useState('view');
  const [currentItem, setCurrentItem] = useState({});
  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const initData = async (type) => {
    setLoading(true);

    const res = await GetTestList({
      TrainerId: id,
      SkipCount: (pagination.current - 1) * pagination.pageSize,
      MaxResultCount: pagination.pageSize,
    });

    if (res.response.ok) {
      const { items = [], totalCount } = res.data || {};

      setShowList(items);
      if (type === 'Retest' && items.length > 0) {
        onEdit(items[0]);
      }
      setPagination({
        ...pagination,
        total: totalCount,
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, [pagination.current, pagination.pageSize, allowReloadTab]);

  const listSupport = {
    trainingModule: {
      showText: intl.formatMessage({ id: 'Module' }),
      showType: 'Text',
      render: (_, record) => {
        const { trainingModule } = record || {};
        const { displayName } = trainingModule || {};

        return displayName;
      },
    },
    testType: {
      showText: intl.formatMessage({ id: 'Test Name' }),
      showType: 'Text',
      render: (text) => {
        switch (text) {
          case 'WrittenTest':
            return intl.formatMessage({ id: 'WrittenExam' });
          case 'HandsOnTest':
            return intl.formatMessage({ id: 'Handson Test' });
          case 'OralTest':
            return intl.formatMessage({ id: 'Oral Test' });
          default:
            return text;
        }
      },
    },
    passed: {
      showText: intl.formatMessage({ id: 'Result' }),
      showType: 'Text',
      render: (text) => {
        if (text === null) {
          return '-';
        }
        return intl.formatMessage({ id: text ? 'pass' : 'Fail' });
      },
    },
    score: {
      showText: intl.formatMessage({ id: 'Scores' }),
      showType: 'Text',
      render: (text, record) => {
        const { finished } = record || {};
        return finished ? (text % 1 === 0 ? text : text.toFixed(1)) : '-';
      },
    },
    retakeTimes: {
      showText: intl.formatMessage({ id: 'Remaining Retake Times' }),
      showType: 'Text',
    },
    endTime: {
      showText: intl.formatMessage({ id: 'Test Time' }),
      showType: 'Time',
      addtional: {
        format: timeFormat,
      },
    },
  };

  const arrangeRetestModalProps = {
    visible,
    examInfo: currentItem,
    closeModal: () => {
      setVisible(false);
    },
    reloadPage: () => {
      initData();
      onChangeAllowReloadProgress && onChangeAllowReloadProgress();
    },
  };

  const createOralHandsDrawerProps = {
    visible,
    type,
    roleType,
    currentItem,
    trainerTrainingModuleId: currentItem?.trainerTrainingModuleId,
    onClose: () => {
      setVisible(false);
      // if (option === 'edit') {
        initData();
        onChangeAllowReloadProgress && onChangeAllowReloadProgress();
      // }
    },
  };

  const onView = (record) => {
    setOption('view');
    const { testType, trainerTrainingModuleId } = record || {};

    if (testType === 'WrittenTest') {
      let pathname = '/system/center/detail/examResult';
      if (roleType === 'admin') {
        pathname = '/system/manage/detail/examResult';
      }
      history.push({
        pathname,
        query:
          roleType !== 'personal'
            ? {
                TrainerTrainingModuleId: trainerTrainingModuleId,
                TrainerId: id,
              }
            : { TrainerTrainingModuleId: trainerTrainingModuleId },
      });
    } else {
      setType(testType == 'OralTest' ? 'oralTest' : 'handsOnTest');
      setCurrentItem(record);
      setVisible(true);
    }
  };

  const onReset = async (record) => {
    setCurrentItem(record);
    setType('');
    const { testType, trainerTrainingModuleId } = record || {};
    if (testType === 'WrittenTest') {
      setVisible(true);
    } else {
      const params = {
        trainerTrainingModuleId,
      };

      const res =
        testType === 'OralTest'
          ? await PostOralQuestionnaire(params)
          : await PostPracticalQuestionnaire(params);

      if (res.response.ok) {
        initData('Retest');
      }
    }
  };

  const gotoExam = (record) => {
    const { writtenTest } = record || {};
    const { id } = writtenTest || {};
    history.push({
      pathname: '/system/center/detail/examPanel',
      query: {
        trainerTrainingModuleId: id,
      },
    });
  };

  const showConfirm = async (type, record) => {
    setLoading(true);
    const { trainerTrainingModuleId, writtenTest } = record || {};
    const { examinationId, fatherUserExaminationPaperId } = writtenTest || {};
    let examInfo = writtenTest || {};

    let totalQuestionCount = 0,
      fraction = 0,
      paymentAmount;
    let againSource; // 补考通过分数
    let newId;
    let isPay = false; // 是否已经支付
    let code; // 考试结算单号
    const params = {
      trainerTrainingModuleId,
    };

    const examDetailRes = await GetExamDetail({
      id: examinationId,
    });

    if (!examDetailRes.response.ok) {
      const { message: errorMessage } = examDetailRes.data?.error || {
        message: intl.formatMessage({ id: 'testInformationFail' }),
      };
      message.error(errorMessage, 3);
      return;
    }

    const { duration, regulations, passingScore } = examDetailRes.data.data;
    regulations.forEach((item) => {
      totalQuestionCount += item.quantity;
      fraction += item.quantity * item.fraction;
    });

    if (fatherUserExaminationPaperId) {
      newId = fatherUserExaminationPaperId;
    }

    // if (detail.lastUserExaminationPaperId) {
    //   newId = detail.lastUserExaminationPaperId;
    // }

    if (type !== 'first' && JSON.stringify(examInfo) !== '{}') {
      if (examInfo.makeUpExamination) {
        paymentAmount = examInfo.makeUpExamination.price;
        againSource = examInfo.makeUpExamination.passingScore;
      }
      code = examInfo.id;
      if (
        examInfo.status === 'NOT_STARTED' &&
        examInfo.type === 'MAKEUP' &&
        examInfo.makeUpExamination.price !== 0
      ) {
        // 说明时已经是补考创建的试卷
        const payRes = await GetPayStatus({ code });
        if (payRes && payRes.response && payRes.response.ok) {
          const { status } = payRes.data.data;
          if (status === 'Paid') {
            isPay = true;
          }
        } else {
          message.error(intl.formatMessage({ id: 'failedGetOrder' }));
        }
      } else if (examInfo.status === 'FAILED') {
        const againRes = await GetAgainExam(params);
        if (againRes.response.ok) {
          code = againRes.data.id;
          examInfo = {
            ...examInfo,
            id: code,
            type: 'MAKEUP',
            status: 'NOT_STARTED',
            makeUpExamination: againRes.data.makeUpExamination,
          };
        } else {
          const { message: errorMessage } = examDetailRes.data?.error || {
            message: intl.formatMessage({ id: 'generateRetestPaperFail' }),
          };
          message.error(errorMessage, 3);
        }
      }
    }

    setLoading(false);

    Modal.confirm({
      title: intl.formatMessage({ id: 'testInstructions' }),
      icon: '',
      width: 500,
      content:
        type === 'first' ? (
          <div className={styles.modelStyle}>
            <div className={styles.modelTitles}>
              <div>
                <div>{duration}</div>
                <div className={styles.describe}>
                  {intl.formatMessage({ id: 'examDuration' })}
                </div>
              </div>

              <div>
                <div>{totalQuestionCount}</div>
                <div className={styles.describe}>
                  {intl.formatMessage({ id: 'numberOfQuestions' })}
                </div>
              </div>

              <div>
                <div>{fraction}</div>
                <div className={styles.describe}>
                  {intl.formatMessage({ id: 'testScore' })}
                </div>
              </div>
            </div>
            <div>
              <ul>
                <li>{intl.formatMessage({ id: 'firstTimeExam' }, { name })}</li>
                <li>
                  {intl.formatMessage(
                    { id: 'examResults' },
                    { score: passingScore },
                  )}
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <div className={styles.modelStyle}>
            <div className={styles.modelTitles}>
              <div>
                <div>{duration}</div>
                <div className={styles.describe}>
                  {intl.formatMessage({ id: 'examDuration' })}
                </div>
              </div>

              <div>
                <div>{totalQuestionCount}</div>
                <div className={styles.describe}>
                  {intl.formatMessage({ id: 'numberOfQuestions' })}
                </div>
              </div>

              <div>
                <div>{fraction}</div>
                <div className={styles.describe}>
                  {intl.formatMessage({ id: 'testScore' })}
                </div>
              </div>
            </div>
            <div>
              <ul>
                <li>
                  {intl.formatMessage(
                    { id: 'thisTimeExamRetakeFee' },
                    { name, price: paymentAmount },
                  )}
                </li>
                <li>
                  {intl.formatMessage(
                    { id: 'examResults' },
                    { score: againSource },
                  )}
                </li>
              </ul>
            </div>
          </div>
        ),
      okText: intl.formatMessage({
        id:
          type === 'first'
            ? 'joinNow'
            : isPay
            ? 'continueWithLastExam'
            : paymentAmount == 0
            ? 'takeExam'
            : 'pay',
      }),
      cancelText: intl.formatMessage({ id: 'wait' }),
      async onOk() {
        if (type === 'first') {
          if (JSON.stringify(examInfo) === '{}') {
            const firstRes = await GetFirstExam(params);
            if (firstRes.response.ok) {
              newId = firstRes.data.id;
            } else {
              const { message: errorMessage } = firstRes.data?.error || {
                message: intl.formatMessage({ id: 'failedGenerateExam' }),
              };
              message.error(errorMessage, 3);
              return;
            }
          }
          history.push({
            pathname: '/system/center/detail/examPanel',
            query: {
              trainerTrainingModuleId: newId,
            },
          });
        } else {
          if (examInfo.makeUpExamination.price === 0) {
            history.push({
              pathname: '/system/center/detail/examPanel',
              query: {
                trainerTrainingModuleId: code,
              },
            });
          } else {
            if (isPay) {
              history.push({
                pathname: '/system/center/detail/examPanel',
                query: {
                  trainerTrainingModuleId: code,
                },
              });
            }
          }
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const onEdit = (record) => {
    const { testType } = record || {};

    setOption('edit');
    setType(testType == 'OralTest' ? 'oralTest' : 'handsOnTest');
    setCurrentItem(record);
    setVisible(true);
  };

  const tableProps = {
    loading,
    listData: showList.map((item) => {
      const { writtenTest } = item || {};
      const { makeUpExamination } = writtenTest || {};
      const { times } = makeUpExamination || {};

      return {
        ...item,
        retakeTimes: writtenTest ? times || 0 : '-',
      };
    }),
    listSupport,
    pagination,
    onTableChange(page) {
      const { current, pageSize } = page;

      setPagination({
        ...pagination,
        current,
        pageSize,
      });
    },
    Actions: [
      {
        showText: 'View',
        itemRender(record) {
          const { finished, testType, trainerTrainingModuleStatus } = record || {};
          if (finished) {
            return (
              <a onClick={() => onView(record)}>
                {intl.formatMessage({ id: 'View' })}
              </a>
            );
          } else {
            if (
              roleType === 'admin' &&
              (testType === 'OralTest' || testType === 'HandsOnTest')
            ) {
              if (trainerTrainingModuleStatus === 'Stopped') {
                return null;
              }
              return (
                <a onClick={() => onEdit(record)}>
                  {intl.formatMessage({ id: 'edit' })}
                </a>
              );
            }
          }
          return null;
        },
      },
      {
        showText: 'Retake Test',
        itemRender(record) {
          const { trainerTrainingModuleStatus } = record || {};

          if (roleType !== 'personal' || trainerTrainingModuleStatus === 'Stopped') {
            return null;
          }

          const { writtenTest } = record || {};
          const { makeUpExamination, status, type } = writtenTest || {};
          const { times = 0 } = makeUpExamination || {};

          if (status === 'FAILED' && times > 0) {
            return (
              <a onClick={() => showConfirm('again', record)}>
                {intl.formatMessage({ id: 'Retake Test' })}
              </a>
            );
          }

          if (status === 'DOING' || status === 'NOT_STARTED') {
            return (
              <a onClick={() => gotoExam(record)}>
                {intl.formatMessage({ id: 'Continue Test' })}
              </a>
            );
          }

          return null;
        },
      },
      {
        showText: 'Arrange Retest',
        itemRender(record) {
          const { arrangeRetest, retest, trainerTrainingModuleStatus } =
            record || {};
          if (trainerTrainingModuleStatus === 'Stopped') {
            return null;
          }
          if (roleType !== 'admin') {
            return null;
          }

          if (arrangeRetest) {
            const { writtenTest } = record || {};
            const { makeUpExamination } = writtenTest || {};
            const { times = 0 } = makeUpExamination || {};
            if (times == 0) {
              return (
                <a onClick={() => onReset(record)}>
                  {intl.formatMessage({
                    id: 'Arrange Retest',
                  })}
                </a>
              );
            }
          }

          if (retest) {
            return (
              <a onClick={() => onReset(record)}>
                {intl.formatMessage({
                  id: 'Retest',
                })}
              </a>
            );
          }
          return null;
        },
      },
    ],
  };

  return (
    <>
      <MagicTable {...tableProps} />
      {visible &&
        (type ? (
          <CreateOralHandsDrawer {...createOralHandsDrawerProps} />
        ) : (
          <ArrangeRetestModal {...arrangeRetestModalProps} />
        ))}
    </>
  );
};

export default TestTab;
