import React, { useState, useEffect } from 'react';
import { useIntl } from 'umi';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';

import {
  GetTrainingList,
  PostTrainerTrainingSupervision,
} from 'services/trainingSystem';

const AddSupervisionModal = ({
  userInfo,
  trainerTrainingModuleId,
  visible = false,
  closeModal = () => {},
  reloadPage = () => {},
}) => {
  const intl = useIntl();

  const { id: trainerId } = userInfo || {};

  const [certList, setCertList] = useState([]);
  const [loading, setLoading] = useState(false);

  const initData = async () => {
    const res = await GetTrainingList({ trainerId, TrainingStep: 'Supervision' });
    if (res.response.ok) {
      const { data = [] } = res || {};

      setCertList(data);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  if (!certList.length) {
    return <></>;
  }

  const contentMenu = {
    Properties: [
      {
        EditorType: 'SingleWithSearch',
        ShowTitle: intl.formatMessage({ id: 'SelectModule' }),
        FormKey: 'trainerTrainingModuleId',
        AdditionalData: null,
        Value: trainerTrainingModuleId,
        Setting: {
          DropdownOptions: certList.map((item) => {
            const { id, trainingModule } = item || {};
            const { name } = trainingModule || {};
            return {
              Value: name,
              Id: id,
            };
          }),
          Required: true,
        },
        Description: '',
      },
      {
        EditorType: 'NumberInput',
        ShowTitle: intl.formatMessage({ id: 'Times' }),
        FormKey: 'times',
        AdditionalData: null,
        Value: 1,
        Setting: {
          Required: true,
          Props: {
            min: 1,
            max: 10,
            step: 1,
          },
        },
        Description: '',
      },
    ],
  };

  const props = {
    title: 'Add Supervision',
    visible,
    content: contentMenu,
    loading,
    onCancel() {
      closeModal();
    },
    async onOk(value) {
      setLoading(true);

      const res = await PostTrainerTrainingSupervision(value);

      if (res.response.ok) {
        reloadPage();
      }

      closeModal();
      setLoading(false);
    },
  };

  return <MagicFormModal {...props} />;
};

export default AddSupervisionModal;
