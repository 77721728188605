import { useState } from 'react';
import { Typography } from 'antd';
import { useIntl } from 'umi';

const { Paragraph } = Typography;

const ParagraphWithShowLess = (props) => {
  const intl = useIntl();
  // 通过重载组件来收起文字
  const [resetFlag, setResetFlag] = useState(true);
  // 是否展示收起按钮 当文字足以触发ellipsis时才展示收起按钮
  const [showLessBtnFlag, setShowLessBtnFlag] = useState(false);
  const { children } = props || {};

  const handleShowLess = () => {
    setResetFlag(false);
    setTimeout(() => {
      setResetFlag(true);
    }, 0);
  };

  return resetFlag ? (
    <Paragraph
      ellipsis={{
        rows: 2,
        expandable: true,
        symbol: intl.formatMessage({ id: 'Show More' }),
        onEllipsis: () => setShowLessBtnFlag(true),
      }}
    >
      {children}
      {showLessBtnFlag ? (
        <span style={{ color: '#1890ff', cursor: 'pointer' }} onClick={handleShowLess}>
          &nbsp;&nbsp;{intl.formatMessage({ id: 'Show Less' })}
        </span>
      ) : null}
    </Paragraph>
  ) : null;
};

export default ParagraphWithShowLess;
