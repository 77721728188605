import getRequest from 'utils/request'

const GetInfoByName = '/api/vrclassroom/Questionnaire/InfoByName'
const GetInfoById = '/api/vrclassroom/Questionnaire/InfoById'
const UpdateInfo = '/api/vrclassroom/Questionnaire/Info'
const GetAllInfo = '/api/vrclassroom/Questionnaire/Infos'
const AddQuestionnaire = '/api/vrclassroom/Questionnaire/Info'
const SaveAnswer = '/api/vrclassroom/Questionnaire/Answer'
const GetAnswerById = '/api/vrclassroom/Questionnaire/Answer'
const GetDoctorTemplate = '/api/vrclassroom/Questionnaire/DoctorCommentTemplete'
const GetInfoByAnswerId = '/api/vrclassroom/Questionnaire/Answer'
const UpdateAnswer = '/api/vrclassroom/Questionnaire/Answer'
const GetVratRegeneratelistApi = '/api/vrclassroom/testRequest/regenerateList/{userId}'

//(Admin)Get All Questionnaires
export async function GetInfos(queryData) {
  return getRequest()(GetAllInfo, {
    method: 'GET',
    params:queryData,
  })
}

//Get Questionnaire By Name
export async function GetByName(questionnaireName) {
  return getRequest()(GetInfoByName, {
    method: 'GET',
    params:{questionnaireName}
  })
}

//Get Questionnaire ById
export async function GetById(questionnaireId) {
  return getRequest()(GetInfoById, {
    method: 'GET',
    params:{questionnaireId}
  })
}

//(Admin)Add A new Questionnaire
export async function AddInfo(data) {
  return getRequest()(AddQuestionnaire, {
    method: 'POST',
    data,
  })
}
//	(Admin) Update Questionnaire
export async function UpdateQuestionnaire(questionnaireId,data) {
  return getRequest()(UpdateInfo, {
    method: 'PUT',
    params:{questionnaireId},
    data,
  })
}

export async function Answer(data) {
  return getRequest()(SaveAnswer,{
      method: 'POST',
      data,
    }
  )
}

export async function GetAnswer(caseId, questionnaireId) {
  return getRequest()(GetAnswerById,
    {
      method: 'GET',
      params:{questionnaireId,caseId}
    }
  )
}

export async function GetAllInfoByAnswerId(answerId) {
  return getRequest()(GetInfoByAnswerId, {
    method: 'GET',
    params:{answerId}
  })
}

export async function UpdateOrNewAnswer(data) {
  return getRequest()(UpdateAnswer, {
    method: 'POST',
    data,
  })
}

export async function GetAllDocTemplate() {
  return getRequest()(GetDoctorTemplate, {
    method: 'GET',
  })
}

export async function GetVratRegeneratelist(data) {
  return getRequest()(GetVratRegeneratelistApi.replace('{userId}', data.userId), {
    method: 'GET',
    params: data
  })
}
