module.exports = {
  name: 'CognitiveleapWeb',
  baseURL: process.env.pcUrl,
  localesExcelName: process.env.localesExcelName,
  mobileUrl: process.env.mobileUrl,
  uploadTrainingProgramUrl: '/api/rocketAdmin/File/TrainingProgramMedias',
  uploadExcelUrl: '/api/rocketAdmin/trainingProgram/importExcel',
  uploadImagesUrl: '/api/rocketAdmin/File/Images',
  uploadCourseMedias: '/api/rocketAdmin/File/CourseMedias',
  uploadOfflineTrainingPlanMedias:
    '/api/rocketAdmin/File/OfflineTrainingPlanMedias',
  uploadTenantPic: '/api/business/File/TenantPicture',
  uploadUserPicUrl: '/api/business/File/UserPicture',
  uploadCategoryPic: '/api/rocketAdmin/File/CategoryImage',
  uploadTrackAudio: '/api/rocketAdmin/File/AudioTrack',
  uploadTenantLogo: '/api/business/File/TenantLogo',
  uploadBAttachment: '/api/business/File/WebAttachment',
  uploadFeedbackImage: '/api/rocketAdmin/File/PunchPicture',
  uploadFeedbackVideo: '/api/rocketAdmin/File/PunchVideo',
  uploadPdtImage: '/api/rocketAdmin/File/NutritionPicture',
  uploadContactEmailImage: '/api/business/File/UserClipboardPicture',
  uploadDTResource: '/api/pdt/File/UploadDailyTopicResource',
  uploadDTPdf: '/api/pdt/File/UploadDailyTopicPdf',
  uploadDTCoverImage: '/api/pdt/File/UploadDailyTopicCoverImage',
  uploadDTHeaderImage: '/api/pdt/File/UploadDailyTopicHeaderImage',
  notificationIntervalTime: 60,
  musicRecordIntervalTime: 15,
  hfsContractTime: process.env.hfsContractTime,
  hfsCodeTime: 60,
  uploadTrainingMedias: '/api/rocketAdmin/File/TrainingMedias',
  uploadUserResumes: '/api/business/File/UserResumes',
  uploadCredentials: '/api/rocketAdmin/File/Credentials',
  uploadCourseMediasVod: '/api/rocketAdmin/File/CourseMediasVod',
  uploadTrainingProgramMediasVod:
    '/api/rocketAdmin/File/TrainingProgramMediasVod',
  pcUrl: process.env.pcUrl,
  examUrl: process.env.examUrl,
  payBaseURL: process.env.payUrl,
  pdfBaseUrl: process.env.pdfUrl,
  showLogoutBtn: process.env.showLogoutBtn,
};
