import React, { useState, useEffect } from 'react';
import { Space, Timeline } from 'antd';
import { useIntl } from 'umi';

import { GetTrainerTrainingSupervisionDetail } from 'services/trainingSystem';

import AddSupervisionModal from './Modals/AddSupervisionModal';
import FeedbackQuestionDrawer from './Modals/FeedbackQuestionDrawer';
import SpinCom from './SpinCom';

import styles from '../index.less';

const Supervision = ({
  progressIndex,
  roleType,
  trainerTrainingModuleId,
  userInfo,
  onChangeAllowReloadTab,
  allowReloadProgress,
}) => {
  const intl = useIntl();

  const { id: trainerId } = userInfo || {};

  const [loading, setLoading] = useState(false);
  const [supervisionList, setSupervisionList] = useState([]);
  const [currentTrainItem, setCurrentTrainItem] = useState({});
  const [type, setType] = useState('');
  const [visible, setVisible] = useState({
    addSupervisionModalVisible: false,
    feedbackQuestionDrawerVisible: false,
  });

  const initData = async () => {
    setLoading(true);
    const res = await GetTrainerTrainingSupervisionDetail({
      trainerTrainingModuleId,
    });

    if (res.response.ok) {
      const { data = [] } = res || {};
      setSupervisionList(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (trainerTrainingModuleId) {
      initData();
    }
  }, [trainerTrainingModuleId, allowReloadProgress]);

  const onAddSupervision = () => {
    setVisible({
      ...visible,
      addSupervisionModalVisible: true,
    });
  };

  const renderEmptyCom = () => {
    return (
      <Timeline>
        <Timeline.Item>
          <div className={styles.practicalTrainingItem}>
            <div className={styles.practicalTrainingItemTitle}>
              {progressIndex}.1 {intl.formatMessage({ id: 'tabSupervision' })}
            </div>
            <div className={styles.practicalTrainingItemContent}>
              <Space>
                <div>
                  {intl.formatMessage({ id: 'Waiting for Appointment' })}
                </div>
                {roleType === 'admin' && (
                  <a onClick={onAddSupervision}>
                    {intl.formatMessage({ id: 'Add Supervision' })}
                  </a>
                )}
              </Space>
            </div>
          </div>
        </Timeline.Item>
      </Timeline>
    );
  };

  const addSupervisionModalProps = {
    userInfo,
    visible: visible.addSupervisionModalVisible,
    trainerTrainingModuleId,
    closeModal: () => {
      setVisible({
        ...visible,
        addSupervisionModalVisible: false,
      });
    },
    reloadPage: () => {
      initData();
      onChangeAllowReloadTab && onChangeAllowReloadTab();
    },
  };

  const feedbackQuestionDrawerProps = {
    visible: visible.feedbackQuestionDrawerVisible,
    type,
    currentTrainItem,
    roleType,
    onClose: () => {
      setVisible({
        ...visible,
        feedbackQuestionDrawerVisible: false,
      });
      initData();
      onChangeAllowReloadTab && onChangeAllowReloadTab();
    },
  };

  const onFeedbackQuestion = (item, type) => {
    setType(type);
    setCurrentTrainItem(item);
    setVisible({
      ...visible,
      feedbackQuestionDrawerVisible: true,
    });
  };

  if (loading) {
    return <SpinCom />;
  }

  return (
    <>
      {supervisionList.length === 0 ? (
        renderEmptyCom()
      ) : (
        <Timeline>
          {supervisionList.map((item, index) => {
            const {
              id,
              order,
              supervisorSSummaryFinish,
              trainerSummaryFinish,
              status,
            } = item || {};
            const isFinished = supervisorSSummaryFinish && trainerSummaryFinish;

            return (
              <Timeline.Item color={isFinished ? 'gray' : 'blue'} key={id}>
                <div className={styles.practicalTrainingItem}>
                  <div className={styles.practicalTrainingItemTitle}>
                    {progressIndex}.{order}{' '}
                    {intl.formatMessage({ id: 'tabSupervision' })}:{' '}
                    {intl.formatMessage({ id: 'session' })} {order}
                  </div>
                  {status !== 'NotStart' && (
                    <div className={styles.practicalTrainingItemContent}>
                      <Space size={'large'}>
                        {roleType === 'admin' ? (
                          <>
                            {supervisorSSummaryFinish ? (
                              <div
                                onClick={() =>
                                  onFeedbackQuestion(item, 'SupervisorFeedback')
                                }
                                style={{
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                }}
                              >
                                {intl.formatMessage({
                                  id: 'View',
                                })}
                                {intl.formatMessage({
                                  id: 'Supervisor Feedback',
                                })}
                              </div>
                            ) : (
                              <a
                                onClick={() =>
                                  onFeedbackQuestion(item, 'SupervisorFeedback')
                                }
                              >
                                {intl.formatMessage({
                                  id: 'Fill In',
                                })}
                                {intl.formatMessage({
                                  id: 'Supervisor Feedback',
                                })}
                              </a>
                            )}
                          </>
                        ) : supervisorSSummaryFinish ? (
                          <div
                            onClick={() =>
                              onFeedbackQuestion(item, 'SupervisorFeedback')
                            }
                            style={{
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                          >
                            {intl.formatMessage({
                              id: 'View',
                            })}
                            {intl.formatMessage({
                              id: 'Supervisor Feedback',
                            })}
                          </div>
                        ) : (
                          <div>
                            {intl.formatMessage({
                              id: 'Supervisor Feedback Unfilled',
                            })}
                          </div>
                        )}

                        {roleType === 'personal' ? (
                          <>
                            {trainerSummaryFinish ? (
                              <div
                                onClick={() =>
                                  onFeedbackQuestion(item, 'TrainerRecords')
                                }
                                style={{
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                }}
                              >
                                {intl.formatMessage({
                                  id: 'View',
                                })}
                                {intl.formatMessage({ id: 'Trainer Records' })}
                              </div>
                            ) : (
                              <a
                                onClick={() =>
                                  onFeedbackQuestion(item, 'TrainerRecords')
                                }
                              >
                                {intl.formatMessage({
                                  id: 'Fill In',
                                })}
                                {intl.formatMessage({ id: 'Trainer Records' })}
                              </a>
                            )}
                          </>
                        ) : trainerSummaryFinish ? (
                          <div
                            onClick={() =>
                              onFeedbackQuestion(item, 'TrainerRecords')
                            }
                            style={{
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                          >
                            {intl.formatMessage({
                              id: 'View',
                            })}
                            {intl.formatMessage({ id: 'Trainer Records' })}
                          </div>
                        ) : (
                          <div>
                            {intl.formatMessage({
                              id: 'Trainer Records Unfilled',
                            })}
                          </div>
                        )}
                      </Space>
                    </div>
                  )}
                </div>
              </Timeline.Item>
            );
          })}
          {roleType === 'admin' &&
            supervisionList.every(
              (item) =>
                item.supervisorSSummaryFinish && item.trainerSummaryFinish,
            ) && (
              <Timeline.Item>
                <div className={styles.practicalTrainingItem}>
                  <div className={styles.practicalTrainingItemTitle}>
                    {progressIndex}.{supervisionList.length + 1}{' '}
                    {intl.formatMessage({ id: 'tabSupervision' })}
                  </div>
                  <div className={styles.practicalTrainingItemContent}>
                    <Space>
                      <div>
                        {intl.formatMessage({ id: 'Waiting for Appointment' })}
                      </div>
                      <a onClick={onAddSupervision}>
                        {intl.formatMessage({ id: 'Add Supervision' })}
                      </a>
                    </Space>
                  </div>
                </div>
              </Timeline.Item>
            )}
        </Timeline>
      )}
      {visible.addSupervisionModalVisible && (
        <AddSupervisionModal {...addSupervisionModalProps} />
      )}
      {visible.feedbackQuestionDrawerVisible && (
        <FeedbackQuestionDrawer {...feedbackQuestionDrawerProps} />
      )}
    </>
  );
};

export default Supervision;
