import getRequest from 'utils/request';

const PostModulesApi = '/api/pdt/subjectPdt/modules';
const PostGoalsApi = '/api/pdt/subjectPdt/goals';
const GetGoalsApi = '/api/pdt/subjectPdt/goals';
const GetCategoryAllApi = '/api/nutrition/category/webAll';
const PutCategoryApi = '/api/nutrition/category/web';
const PostColorCalculateApi = '/api/nutrition/category/webColorCalculate';
const GetPdtProgressApi = '/api/learningpdt/user-subsection/progress';
const GetPdtLearningAnalysisApi = '/api/learningpdt/user-subsection/analysisByWeek';
const PostPdtApi = '/api/pdt/subjectPdt/startPdt';
const PostNutritionApi = '/api/nutrition/meal/webModuleDetail';
const PostExerciseListApi = '/lambda/parent/getChildExerciseList';
const PostCbtListApi = '/lambda/parent/getChildCBTList';
const PostNutritionRecordApi = '/api/nutrition/meal/webRecording';
const PostChildEmotionListApi = '/lambda/parent/getChildEmotionList'
const GetSleepAnslysisApi = '/api/sleeppdt/user-sleep/progress';
const PutWebHideCategoryApi = '/api/nutrition/category/webHideCategory';
const GetTrainingListApi = '/api/business/subject/pdtTrainingList';
const PostStopPdtApi = '/api/pdt/subjectPdt/stop';
const PostRestartPdtApi = '/api/pdt/subjectPdt/restart';
const GetSubjectPdtApi = '/api/pdt/subjectPdt';
const GetParentEmotionFeedbackTimesApi = '/api/pdt/parentEmotion/feedbackTimes/{subjectId}';
const PostReviewApi = '/api/pdt/subjectPdt/reviewApplication';
const GetChildCancelApi = '/api/pdt/subjectPdt/cancelApplication/{subjectId}';

export async function GetChildCancel(data) {
  return getRequest()(GetChildCancelApi.replace('{subjectId}', data.subjectId), {
    method: 'GET',
    params: data,
  });
}

export async function PostReview(data) {
  return getRequest()(PostReviewApi, {
    method: 'POST',
    data,
  });
}

export async function GetParentEmotionFeedbackTimes(data) {
  return getRequest()(GetParentEmotionFeedbackTimesApi.replace('{subjectId}', data.subjectId), {
    method: 'GET',
    params: data,
  });
}

export async function GetSubjectPdt(data) {
  return getRequest()(GetSubjectPdtApi, {
    method: 'GET',
    params: data,
  });
}


export async function PostStopPdt(data) {
  return getRequest()(PostStopPdtApi, {
    method: 'POST',
    data,
  });
}

export async function PostRestartPdt(data) {
  return getRequest()(PostRestartPdtApi, {
    method: 'POST',
    data,
  });
}

export async function GetTrainingList(data) {
  return getRequest()(GetTrainingListApi, {
    method: 'GET',
    params: data,
  });
}


export async function PutWebHideCategory(data) {
  return getRequest()(PutWebHideCategoryApi, {
    method: 'PUT',
    params: data,
  });
}

export async function PostChildEmotionList(data) {
  return getRequest()(PostChildEmotionListApi, {
    method: 'POST',
    data,
  });
}

export async function PostNutritionRecord(id, data) {
  return getRequest()(PostNutritionRecordApi, {
    method: 'POST',
    params: id,
    data,
  });
}

export async function PostExerciseList(data) {
  return getRequest()(PostExerciseListApi, {
    method: 'POST',
    data,
  });
}

export async function PostCbtList(data) {
  return getRequest()(PostCbtListApi, {
    method: 'POST',
    data,
  });
}

export async function PostNutrition(id) {
  return getRequest()(PostNutritionApi, {
    method: 'POST',
    params: id,
    data: {},
  });
}

export async function GetSleepAnslysis(data) {
  return getRequest()(GetSleepAnslysisApi, {
    method: 'GET',
    params: data,
  });
}

export async function PostPdt(data) {
  return getRequest()(PostPdtApi, {
    method: 'POST',
    data,
  });
}

export async function PostModules(data) {
  return getRequest()(PostModulesApi, {
    method: 'POST',
    data,
  });
}

export async function PostGoals(data) {
  return getRequest()(PostGoalsApi, {
    method: 'POST',
    data,
  });
}

export async function GetGoals(data) {
  return getRequest()(GetGoalsApi, {
    method: 'GET',
    params: data,
  });
}

export async function GetCategoryAll(data) {
  return getRequest()(GetCategoryAllApi, {
    method: 'GET',
    params: data,
  });
}

export async function AddCategory(data) {
  return getRequest()(PutCategoryApi, {
    method: 'POST',
    data,
  });
}

export async function PutCategory(data) {
  return getRequest()(PutCategoryApi, {
    method: 'PUT',
    data,
  });
}

export async function DelCategory(data) {
  return getRequest()(PutCategoryApi, {
    method: 'DELETE',
    params: data,
  });
}

export async function PostColorCalculate(data) {
  return getRequest()(PostColorCalculateApi, {
    method: 'POST',
    data,
  });
}

export async function PutColorCalculate(data) {
  return getRequest()(PostColorCalculateApi, {
    method: 'PUT',
    data,
  });
}

export async function GetPdtProgress(data) {
  return getRequest()(GetPdtProgressApi, {
    method: 'GET',
    params: data,
  });
}

export async function GetPdtLearningAnalysis(data) {
  return getRequest()(GetPdtLearningAnalysisApi, {
    method: 'GET',
    params: data,
  });
}