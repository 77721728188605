import getRequest from 'utils/request'

const GetCurrent = '/api/us/Language/Current'
const GetSupported = '/api/us/Language/Supported'
const PostSet = '/api/us/Language/Set'

export async function SetLanguage(data) {
  return getRequest()(PostSet, {
    method: 'POST',
    params: data,
  })
}


export async function Current() {
  return getRequest()(GetCurrent, {
    method: 'GET',
  })
}

export async function LanguageSupported() {
  return getRequest()(GetSupported, {
    method: 'GET',
  })
}
