import React from 'react'
import { Modal, Transfer } from 'antd'

export default class SelectModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      targetKeys: props.targetKeys || [],
      selectedKeys: [],
    }
  }

  handleCancel = () => {
    this.setState({ selectedKeys: [], targetKeys: [] })
    this.props.onCancel()
  }

  handleOk = targetKeys => {
    this.props.onOk(targetKeys)
    this.setState({ selectedKeys: [], targetKeys: [] })
  }

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({ selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys] })
  }

  handleChange = targetKeys => {
    this.setState({ targetKeys })
  }

  // componentWillReceiveProps(props){
  //   this.setState({
  //     targetKeys: props.targetKeys
  //   })
  // }

  // static getDerivedStateFromProps(props, state){
  //   console.log('getDerivedStateFromProps',props)
  //   return {
  //     targetKeys: props.targetKeys
  //   }
  // }

  render() {
    const { selectModalVisible, allList, title, loading } = this.props
    const { targetKeys, selectedKeys } = this.state
    const showData = allList.map(item => {
      return {
        ...item,
        key: item.id,
      }
    })
    
    return (
      <Modal
        width={800}
        destroyOnClose={true}
        title={title}
        open={selectModalVisible}
        onOk={() => this.handleOk(targetKeys)}
        onCancel={this.handleCancel}
        confirmLoading={loading}
      >
        <Transfer
          listStyle={{
            width: 350,
            height: 400,
          }}
          showSearch
          dataSource={showData}
          titles={['Source', 'Target']}
          targetKeys={targetKeys}
          selectedKeys={selectedKeys}
          onChange={this.handleChange}
          onSelectChange={this.handleSelectChange}
          filterOption={(inputValue, option) => option.title && option.title.indexOf(inputValue) > -1}
          render={item => item.title}
        />
      </Modal>
    )
  }
}
