import React from 'react'
import { Card } from 'antd'
import moment from 'moment'
import { showAttributeName } from 'utils/feedback'
import { useIntl } from 'umi'

const FeedbackShow = ({ feedbacks, feedbackProperties: feedbackPropertiesOption }) => {
  const intl = useIntl();
  const showCard = feedbackItem => {
    const { id, feedbackProperties, creationTime } = feedbackItem
    return (
      <Card key={id} title={`${intl.formatMessage({ id: 'feedbackTime' })}${moment(creationTime).local().format('YYYY/MM/DD HH:mm')}`}>
        {
          feedbackProperties.map(item => {
            const { displayName, value, type, programFeedbackProperty, id, programFeedbackPropertyId } = item
            const { displayName: groupDisplayName } = programFeedbackProperty
            return (
              <p key={id || programFeedbackPropertyId}>
                <b>{`${displayName || groupDisplayName}: `}</b>
                <span>{showAttributeName(type, value, item)}</span>
              </p>
            )
          }
          )
        }
      </Card>
    )
  }

  return <div>{feedbacks.length > 0 ? feedbacks.map(item => showCard(item)) : intl.formatMessage({ id: 'noFeedbackData' })}</div>
}

export default FeedbackShow
