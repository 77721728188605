import getRequest from 'utils/request'

const GetVideoUrlApi = '/api/rocketAdmin/File/PlayAuth'
const PutCourseConetentDetailApi = '/api/rocketAdmin/courseContentsDetail/vid'
const PutTrainingProgramApi = '/api/rocketAdmin/trainingProgram/vid'

export async function GetVideoUrl(data) {
    return getRequest()(GetVideoUrlApi, {
        method: 'GET',
        params: data,
    })
}

export async function PutCourseConetentDetail(data) {
    return getRequest()(PutCourseConetentDetailApi, {
        method: 'PUT',
        data
    })
}

export async function PutTrainingProgramVideo(data){
    return getRequest()(PutTrainingProgramApi, {
        method: 'PUT',
        data
    })
}