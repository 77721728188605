import getRequest from 'utils/request'

const getunReadCountApi = '/api/business/message/unreadCount'
const getNotificationApi = '/api/business/message'
const postSignToReadApi = '/api/business/message/read'
const postSignAllToReadApi = '/api/business/message/readAll'

export async function GetunReadCount(data) {
    return getRequest()(getunReadCountApi, {
      method: 'GET',
      params: data
    })
}

export async function GetNotification(data){
  return getRequest()(getNotificationApi, {
    method: 'GET',
    params: data,
  })
}

export async function PostSignToRead(data){
  return getRequest()(postSignToReadApi, {
    method: 'POST',
    data,
  })
}

export async function PostSignAllToRead(data){
  return getRequest()(postSignAllToReadApi, {
    method: 'POST',
    data,
  })
}