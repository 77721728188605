import React from 'react';
import { history, useIntl } from 'umi';
import { Timeline, Space, message } from 'antd';

import styles from '../index.less';

import moment from 'moment';

const OnlineLearning = ({
  roleType,
  progressIndex,
  moduleDetail,
  selectCertData,
  showTrainingApplication,
}) => {
  const intl = useIntl();

  const {
    status,
    creationTime,
    trainingApplication,
    trainingStepsProgress = [],
  } = moduleDetail || {};

  const { questionnaireAndAnswer } = trainingApplication || {};
  const { answer = {} } = questionnaireAndAnswer || {};

  const { courseId, total, finish } =
    trainingStepsProgress.find(
      (item) => item.trainingStep === 'OnlineLearning',
    ) || {};

  const { trainingModule, id: moduleId } = selectCertData || {};

  return (
    <>
      <Timeline>
        <Timeline.Item>
          <div className={styles.onlineLearnItem}>
            <div className={styles.onlineLearnItemTitle}>
              {progressIndex}.1{' '}
              {intl.formatMessage({ id: 'Arrange Learning Modules' })}
            </div>
            <div className={styles.onlineLearnItemContent}>
              {intl.formatMessage({ id: 'Course Allocation Date' })}:{' '}
              {moment(creationTime).format('MM/DD/YYYY')}
            </div>
          </div>
        </Timeline.Item>
        <Timeline.Item>
          <div className={styles.onlineLearnItem}>
            <div className={styles.onlineLearnItemTitle}>
              {progressIndex}.2{' '}
              {intl.formatMessage({ id: 'Fill In Application Form' })}
            </div>
            <div className={styles.onlineLearnItemContent}>
              {roleType === 'personal' ? (
                Object.keys(answer).length > 0 ? (
                  <div
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={showTrainingApplication}
                  >
                    {intl.formatMessage({
                      id: 'View',
                    })}
                  </div>
                ) : (
                  <a onClick={showTrainingApplication}>
                    {intl.formatMessage({
                      id: 'Fill In',
                    })}
                  </a>
                )
              ) : Object.keys(answer).length > 0 ? (
                <div
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={showTrainingApplication}
                >
                  {intl.formatMessage({
                    id: 'View',
                  })}
                </div>
              ) : (
                <div>
                  {intl.formatMessage({
                    id: 'Unfilled',
                  })}
                </div>
              )}
            </div>
          </div>
        </Timeline.Item>
        <Timeline.Item>
          <div className={styles.onlineLearnItem}>
            <div className={styles.onlineLearnItemTitle}>
              {progressIndex}.3{' '}
              {intl.formatMessage({ id: 'Learn Online Courses' })}
            </div>
            <div className={styles.onlineLearnItemContent}>
              <Space>
                <div>
                  {intl.formatMessage({ id: 'Progress' })}: {finish} / {total}
                </div>
                {courseId &&
                  status == 'Learning' &&
                  roleType === 'personal' && (
                    <a
                      onClick={() => {
                        if (Object.keys(answer).length > 0) {
                          history.push({
                            pathname: '/system/center/detail/trainingDetail',
                            query: {
                              moduleId,
                              courseId,
                            },
                          });
                        } else {
                          message.warning({
                            key: 'Complete the training',
                            content: intl.formatMessage({
                              id: 'Complete the training',
                            }),
                            duration: 2,
                            onClose: showTrainingApplication,
                          });
                        }
                      }}
                    >
                      {intl.formatMessage({ id: 'To Study' })}
                    </a>
                  )}
              </Space>
            </div>
          </div>
        </Timeline.Item>
      </Timeline>
    </>
  );
};

export default OnlineLearning;
