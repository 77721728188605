import React from 'react'
import style from './index.less'
import NumberAnimate from 'cognitiveleap-core-us/components/Animate/Number'

const ShowDataAnalysis = ({ keyIndicators }) => {
    return (
        <div className={style.ShowDataAnalysis}>
            <div className={style.ShowDataAnalysisTop}>
                {
                    keyIndicators.map((item) => {
                        return (
                            <div className={style.ShowDataAnalysisTopItem} key={`${item.field}`}>
                                <div className={style.ShowDataAnalysisTopItemTitle}>{item.title}</div>
                                {
                                    item.subTitle &&
                                    <div className={style.ShowDataAnalysisTopItemSubTitle}>{item.subTitle}</div>
                                }
                            </div>
                        )
                    })
                }
            </div>
            <div className={style.ShowDataAnalysisBottom}>
                {
                    keyIndicators.map((item) => {
                        return (
                            <div className={style.ShowDataAnalysisBottomItem} key={`${item.field}`}>
                                <NumberAnimate>{item.data}</NumberAnimate>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ShowDataAnalysis
