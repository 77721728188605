import React, { useEffect, useState } from 'react';
import {
  Button,
  Drawer,
  Spin,
  Empty,
  Select,
  message,
  Modal,
  Space,
  Divider,
} from 'antd';
import { useIntl } from 'umi';
import moment from 'moment';
import { PlusOutlined, FormOutlined } from '@ant-design/icons';

import {
  getUserCredentialQuestionnaires,
  postPracticalQuestionnaire,
  postOralQuestionnaire,
} from 'services/questionnaires';
import { PutQuestionnaire, GetQuestionnaire } from 'services/trainingSystem';

import { getCorrentRateAndResult } from 'cognitiveleap-core-us/utils/utils';

import Question from 'components/Question';
import ShowSurvey from 'cognitiveleap-core-us/components/ShowSurvey';

import styles from './index.less';

const { Option } = Select;

// type: Oral, Practical
const ShowItem = ({ type, TrainingModuleId, showTitle, userInfo = {} }) => {
  const intl = useIntl();

  const { trainerId, id } = userInfo;
  const UserId = trainerId || id;

  const [loading, setLoading] = useState({
    spinLoading: false,
    btnLoading: false,
  });
  const [surveryLoading, setsurveryLoading] = useState(false);
  const [listData, setListData] = useState({});
  const [eidtSupervisionVisible, setEidtSupervisionVisible] = useState(false);
  const [supervisorQuestion, setSupervisorQuestion] = useState(null);
  const [supervisionQuestionDetail, setSupervisionQuestionDetail] =
    useState(null);
  const [supervisorSubmitData, setSupervisorSubmitData] = useState(null);
  const [supervisorIsFinshed, setSupervisorIsFinshed] = useState(false);
  const [selectId, setSelectId] = useState('');

  const { items } = listData || {};
  const { items: selfItems = [] } = items || {};

  const getQuestionRes = async (id) => {
    setsurveryLoading(true);
    setSelectId(id);

    const res = await GetQuestionnaire({
      id,
    });

    if (res.response.ok) {
      setSupervisionQuestionDetail(res.data);
    } else {
      const {
        data: { error },
      } = res;
      message.warning(error.message);
    }

    setsurveryLoading(false);
  };

  const initData = async () => {
    setLoading({
      ...loading,
      spinLoading: true,
    });

    const res = await getUserCredentialQuestionnaires({
      UserId,
      TrainingModuleId,
      Type: type,
    });

    if (res.response.ok) {
      const { items } = res.data || {};
      const { items: selfItems = [] } = items || {};
      if (selfItems.length > 0) {
        const { id } = selfItems[0] || {};
        getQuestionRes(id);
      }
      setListData(res.data);
    } else {
      const {
        data: { error },
      } = res;
      message.warning(error.message);
    }

    setLoading({
      ...loading,
      spinLoading: false,
    });
  };

  useEffect(() => {
    initData();
  }, []);

  // 添加问卷
  const addQuesionnaire = async () => {
    setLoading({
      ...loading,
      btnLoading: true,
    });

    const params = {
      trainerId: UserId,
      trainingModuleId: TrainingModuleId,
    };

    const res =
      type === 'Oral'
        ? await postOralQuestionnaire(params)
        : await postPracticalQuestionnaire(params);

    if (res.response.ok) {
      setLoading({
        ...loading,
        btnLoading: false,
      });

      setEidtSupervisionVisible(true);
      setSupervisorQuestion(res.data);
    } else {
      const {
        data: { error },
      } = res;
      message.warning(error.message);
    }

    setLoading({
      ...loading,
      btnLoading: false,
    });
  };

  // 创建问卷
  const editQuesionnaire = () => {
    setEidtSupervisionVisible(true);
    setSupervisorQuestion(supervisionQuestionDetail);
  };

  // 填写问卷
  const {
    questionnaireAndAnswer: supervisorQuestionnaireAndAnswer,
    id: userQuestionnaireId,
  } = supervisorQuestion || {};
  const { questionnaire: supervisorQuestionnaire, answer: supervisorAnswer } =
    supervisorQuestionnaireAndAnswer || {};
  const { jsonString: supervisorJsonString } = supervisorQuestionnaire || {};
  const { answerContent: supervisorAnswerContent } = supervisorSubmitData || {};

  // 展示问卷
  const {
    questionnaireAndAnswer: supervisionDetaiQuestionnaireAndAnswerl,
    fillerUser,
  } = supervisionQuestionDetail || {};
  const {
    answer: supervisionDetaiAnswer,
    questionnaire: supervisionDetaiQuestionnaire,
  } = supervisionDetaiQuestionnaireAndAnswerl || {};
  const {
    jsonString: supervisionDetaiJsonString,
    correctRate: supervisionDetaiCorrectRate,
  } = supervisionDetaiQuestionnaire || {};
  const { answerContent: supervisionDetaiAnswerContent } =
    supervisionDetaiAnswer || {};
  const { name } = fillerUser || {};

  let questionArr = [],
    correntRateAndResult = null;
  if (supervisionQuestionDetail) {
    correntRateAndResult = getCorrentRateAndResult(
      supervisionDetaiJsonString,
      supervisionDetaiAnswerContent,
      supervisionDetaiCorrectRate,
    );
    questionArr = correntRateAndResult && correntRateAndResult.questionArr;
  } else {
    questionArr = [];
  }

  // 提交问卷
  const supervisorSubmit = async (answerContent, isComplete, finishedPage) => {
    const res = await PutQuestionnaire({
      answerContent,
      isComplete,
      finishedPage,
      userQuestionnaireId,
    });

    if (res.response.ok) {
      if (isComplete) {
        initData();
        setEidtSupervisionVisible(false);
        setSupervisorQuestion(null);
        setSupervisorIsFinshed(false);
        setSupervisorSubmitData(null);
        message.success(intl.formatMessage({ id: 'submitted' }));
      }
    } else {
      const {
        data: { error },
      } = res;
      message.warning(error.message);
    }
  };

  // 问卷完成
  const onComplete = ({ answerContent, isComplete, finishedPage }) => {
    if (isComplete) {
      Modal.confirm({
        title: (
          <div style={{ color: 'red' }}>
            {intl.formatMessage({ id: 'submitDes' })}
          </div>
        ),
        okText: intl.formatMessage({ id: 'submit' }),
        cancelText: intl.formatMessage({ id: 'cancel' }),
        onOk: () => {
          setSupervisorIsFinshed(true);
          supervisorSubmit(answerContent, isComplete, finishedPage);
        },
      });
      return;
    } else {
      supervisorSubmit(answerContent, isComplete, finishedPage);
    }
  };

  // 填写/编辑问卷Props
  const supervisorQuestionProps = {
    content: supervisorJsonString ? supervisorJsonString : '',
    answerContent: supervisorAnswerContent || '',
    answer: supervisorAnswer,
    onComplete,
    onValueChange: ({
      answerContent,
      isComplete,
      finishedPage,
      needSubmit,
    }) => {
      setSupervisorSubmitData({
        answerContent,
        isComplete,
        finishedPage,
      });
      if (needSubmit) {
        onComplete({ answerContent, isComplete, finishedPage });
      }
    },
  };

  const supervisorOnClose = () => {
    if (!supervisorIsFinshed && supervisorSubmitData) {
      onComplete(supervisorSubmitData);
    }
    setEidtSupervisionVisible(false);
  };

  // 呈现问卷Props
  const showSurveyProps = {
    contents: questionArr,
    choice: supervisionDetaiAnswerContent
      ? JSON.parse(JSON.stringify(supervisionDetaiAnswerContent))
      : {},
    showIcon: false,
  };

  const onSelectChange = (value) => {
    getQuestionRes(value);
  };

  return (
    <>
      <Spin spinning={loading.spinLoading}>
        <div className={styles.header}>
          {selfItems.length > 0 ? (
            <Space>
              <div className={styles.title}>
                第几次提交：
                {selfItems.length -
                  selfItems.findIndex((item) => item.id === selectId)}
                <Divider type="vertical" />
                提交人：{name || 'Admin'}
              </div>
            </Space>
          ) : (
            <div></div>
          )}
          <Space>
            {selfItems.length > 0 && (
              <Select
                value={selectId}
                style={{ width: 170, color: '#1890ff' }}
                bordered={false}
                onChange={onSelectChange}
              >
                {selfItems.map((item) => {
                  const { id, finishTime } = item || {};
                  return (
                    <Option key={id} value={id}>
                      {moment(finishTime).format('YYYY/MM/DD HH:mm')}
                    </Option>
                  );
                })}
              </Select>
            )}
            {selfItems.length > 0 && (
              <Button
                loading={loading.btnLoading}
                icon={<FormOutlined />}
                onClick={editQuesionnaire}
              >
                {showTitle}
              </Button>
            )}
            <Button
              loading={loading.btnLoading}
              icon={<PlusOutlined />}
              onClick={addQuesionnaire}
            >
              {showTitle}
            </Button>
          </Space>
        </div>
        {selfItems.length > 0 ? (
          <Spin spinning={surveryLoading}>
            <ShowSurvey {...showSurveyProps} />
          </Spin>
        ) : (
          <Empty />
        )}
      </Spin>
      {/* 填写见习督导小结 */}
      {supervisorQuestion && (
        <Drawer
          title={<b style={{ fontSize: '20px' }}>填写{showTitle}问卷</b>}
          width={700}
          open={eidtSupervisionVisible}
          onClose={supervisorOnClose}
        >
          <Question {...supervisorQuestionProps} />
        </Drawer>
      )}
    </>
  );
};

export default ShowItem;
