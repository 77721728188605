import ReactEcharts from 'echarts-for-react'
import { useIntl } from 'umi'

const MovementPathBlocks = ({ number, styles }) => {
  const intl = useIntl();
  const option = {
    grid: {
      top: '0%',
      right: '30%',
      bottom: '70%',
    },
    xAxis: {
      name: number === 4 ? intl.formatMessage({ id: 'report.movementPath.chart.xAxis' }) : '',
      type: 'category',
      data: [number],
      boundaryGap: true,
      axisLine: {
        lineStyle: {
          color: '#777',
        },
        symbol: number === 4 ? ['none', 'arrow'] : 'none',
      },
      axisTick: {
        inside: true,
      },
    },
    yAxis: {
      show: false,
      name: 'Movement Path',
    },
    series: [
      {
        data: [],
        type: 'line',
        // width: 256
      },
    ],
  }

  return <ReactEcharts option={option} className={styles.MovementPathBlocks} />
}

export default MovementPathBlocks
