import React, { useState, useEffect } from 'react';
import {
  Form,
  Select,
  Upload,
  Modal,
  Input,
  Spin,
  DatePicker,
  message,
  Space,
  Image as AntdImage,
} from 'antd';
import { useIntl, connect } from 'umi';

import {
  GetSupervisorList,
  GetPrepareForIssueCertificate,
  GetCredentialsCode,
  PostIssueCertificate,
  PutTrainerCertificate,
} from 'services/trainingSystem';
import config from 'utils/config';
import { getAuthHeader } from 'cognitiveleap-core-us/utils/auth';
const { baseURL, uploadCredentials } = config;

import moment from 'moment';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const AutoGenerationCertModal = ({
  currentUser,
  type,
  visible,
  selectItem,
  selectCertData,
  trainingModuleId,
  onCloseModal,
}) => {
  const intl = useIntl();

  const { hostRoles = [], staff } = currentUser || {};
  const { id: userId } = staff || {};

  const isSupervisor = hostRoles.find((item) => item.name === 'supervisor');

  const [form] = Form.useForm();

  const {
    id,
    trainerId,
    name,
    username,
    startTime,
    credentialsCode,
    credential,
    supervisorId,
  } = selectItem || {};

  const { id: trainerTrainingModuleId } = selectCertData || {};

  const isEdit = type === 'edit';
  const { url } = credential || {};

  const [currentItem, setCurrentItem] = useState({});
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState({
    formLoading: false,
    btnLoading: false,
  });
  const [supervisorList, setSupervisorList] = useState([]);
  const [certificateInfo, setCertificateInfo] = useState({});
  const [showCredentialPic, setCertificatePic] = useState(
    isEdit ? { url } : {},
  );

  const { qualificationMedal } = certificateInfo || {};
  const {
    certificateCode,
    certificateNumber,
    certificateStyle = [],
    displayName,
  } = qualificationMedal || {};

  const handleOk = async () => {
    setLoading({
      ...loading,
      btnLoading: true,
    });
    if (Object.keys(currentItem).length) {
      // 自动生成，防止生成证书后修改信息，生成证书信息与修改后信息不匹配的情况
      generatePic(currentItem, () => form.submit());
    } else {
      form.submit();
    }
  };

  const onFinish = async (values) => {
    const params = {
      ...values,
      trainerId,
      trainingModuleId,
      startTime: moment(values.startTime).format(),
    };
    const res = isEdit
      ? await PutTrainerCertificate({ id, ...params })
      : await PostIssueCertificate({ trainerTrainingModuleId, ...params });
    if (res.response.ok) {
      onCloseModal();
    } else {
      const { error, code } = res.data || {};
      switch (code) {
        case 'RocketSystem:CertificateNumberAlreadyUsed':
          onFinish(values);
          break;
        default:
          message.error(error.message);
          break;
      }
    }
    setLoading({
      ...loading,
      btnLoading: false,
    });
  };

  const initData = async () => {
    setLoading({
      ...loading,
      formLoading: true,
    });
    const resList = await Promise.all([
      GetSupervisorList({ SkipCount: 0, MaxResultCount: 99 }),
      GetPrepareForIssueCertificate(
        isEdit ? { trainingModuleId, id } : { trainingModuleId },
      ),
    ]);
    if (resList.every((item) => item.response.ok)) {
      const { items = [] } = resList[0].data || {};

      setSupervisorList(items);
      setCertificateInfo(resList[1].data || {});
    }
    setLoading({
      ...loading,
      formLoading: false,
    });
  };

  useEffect(() => {
    initData();
  }, []);

  // 自动生成证书
  const generatePic = async (data, callback) => {
    const { style } = data || {};
    const { url } = style || {};
    const { name, credentialsCode, startTime } =
      form.getFieldsValue(true) || {};

    if (!(name && credentialsCode && startTime)) {
      message.warning(
        intl.formatMessage({ id: 'fill in the complete information' }),
      );
      return;
    }

    setCurrentItem(data);

    setLoading({
      ...loading,
      formLoading: true,
    });

    // 清空本地选择
    if (fileList.length > 0) {
      setFileList([]);
      form.setFieldsValue({
        credential: null,
      });
    }

    // 创建一个新的 Image 对象
    const image = new Image();

    // 使用 Fetch API 获取远程图片数据
    const response = await fetch(url, {
      method: 'GET',
    });
    const blob = await response.blob();
    const imageUrlObject = URL.createObjectURL(blob);

    // 设置图片源为获取到的 Blob URL
    image.src = imageUrlObject;

    // 图片加载完成后执行以下操作
    image.onload = () => {
      // 创建一个 Canvas 元素
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // 设置 Canvas 大小与图片一致
      canvas.width = image.width;
      canvas.height = image.height;

      // 将图片绘制到 Canvas 上
      ctx.drawImage(image, 0, 0);

      // 证书添加名字
      ctx.font = '220px DancingScript';
      ctx.fillStyle = '#44546b';
      ctx.textAlign = 'center';
      ctx.fillText(name, canvas.width / 2, canvas.height * 0.46);

      // 证书添加编号和时间
      ctx.font = '38px NeoHelvetica';
      ctx.fillStyle = '#777777';
      ctx.textAlign = 'center';
      ctx.fillText(credentialsCode, canvas.width - 470, canvas.height - 414);
      ctx.fillText(
        moment(startTime).format('YYYY/MM/DD'),
        canvas.width - 620,
        canvas.height - 349,
      );

      // 将 Canvas 转换为新的 Blob 对象
      canvas.toBlob((blob) => {
        if (!blob) return;
        // 创建 FormData 对象，用于包装 Blob 对象和其他表单数据
        const formData = new FormData();
        // 将生成的 Blob 对象添加到 FormData 中
        formData.append('file', blob, `${credentialsCode}.jpg`);

        // 使用 fetch 发送 POST 请求将图片上传到服务器
        fetch(baseURL + uploadCredentials, {
          method: 'POST',
          headers: {
            ...getAuthHeader(),
          },
          body: formData,
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Failed to upload image');
            }
            return response.json();
          })
          .then((data) => {
            // 成功上传后返回服务器响应的数据
            const { id } = data[0] || {};
            setCertificatePic(data[0]);
            form.setFieldsValue({
              credential: id,
            });
            if (callback) callback();
            setLoading({
              ...loading,
              formLoading: false,
            });
          })
          .catch((error) => {
            console.log('Failed to upload image: ' + error.message);
          });

        // Optional: clean up the blob URL after use
        URL.revokeObjectURL(imageUrlObject);
      }, 'image/jpeg');
    };
  };

  const handlePreview = (file) => {
    const { response } = file || {};
    if (response && response.length) {
      const { url } = response[0] || {};
      window.open(url);
    }
  };

  const getSupervisorId = () => {
    if (supervisorId) return supervisorId;
    if (supervisorList.length === 1) return supervisorList[0].id;
    if (isSupervisor) return userId;
    return null;
  };

  return (
    <Modal
      title={`${intl.formatMessage({
        id: 'Issue Certificate',
      })} - ${displayName}`}
      open={visible}
      onOk={handleOk}
      onCancel={onCloseModal}
      width={750}
      loading={loading.btnLoading}
    >
      <Spin spinning={loading.formLoading}>
        <Form
          form={form}
          name="AutoGenerationCertForm"
          {...formItemLayout}
          onFinish={onFinish}
          initialValues={
            isEdit
              ? {
                  name: username || name,
                  supervisorId: getSupervisorId(),
                  startTime: moment(startTime),
                  credentialsCode,
                }
              : {
                  name: username || name,
                  supervisorId: getSupervisorId(),
                }
          }
        >
          <Form.Item
            label={intl.formatMessage({ id: 'studentName' })}
            name="name"
            rules={[
              {
                required: true,
                message: `${intl.formatMessage({
                  id: 'studentName',
                })} ${intl.formatMessage({ id: 'isRequired' })}`,
              },
            ]}
          >
            <Input placeholder={intl.formatMessage({ id: 'pleaseEnter' })} />
          </Form.Item>

          <Form.Item
            name="supervisorId"
            label={intl.formatMessage({ id: 'Supervisor' })}
            rules={[
              {
                required: true,
                message: `${intl.formatMessage({
                  id: 'Supervisor',
                })} ${intl.formatMessage({ id: 'isRequired' })}`,
              },
            ]}
          >
            <Select
              showSearch
              filterOption={(inputValue, item) => {
                const { children } = item || {};
                return (
                  children?.toLowerCase().indexOf(inputValue?.toLowerCase()) >=
                  0
                );
              }}
            >
              {supervisorList.map((item) => {
                const { id, name } = item || {};
                return (
                  <Option value={id} key={id}>
                    {name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="startTime"
            label={intl.formatMessage({ id: 'activeTime' })}
            rules={[
              {
                type: 'object',
                required: true,
                message: `${intl.formatMessage({
                  id: 'activeTime',
                })} ${intl.formatMessage({ id: 'isRequired' })}`,
              },
            ]}
          >
            <DatePicker
              disabledDate={(current) => {
                return current && current.isBefore(moment(), 'day');
              }}
              onChange={(date) => {
                if (date) {
                  let _credentialsCode;
                  if (isEdit && credentialsCode?.includes('CL')) {
                    _credentialsCode =
                      credentialsCode.slice(0, 2) +
                      moment(date).format('MMDDYY') +
                      certificateCode +
                      credentialsCode.slice(-3);
                  } else {
                    _credentialsCode = `CL${moment(date).format(
                      'MMDDYY',
                    )}${certificateCode}${certificateNumber}`;
                  }
                  form.setFieldsValue({
                    credentialsCode: _credentialsCode,
                  });
                } else {
                  form.setFieldsValue({
                    credentialsCode: null,
                  });
                }
              }}
            />
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.startTime !== currentValues.startTime
            }
          >
            {() => {
              const { credentialsCode } = form.getFieldsValue(true) || {};
              return (
                <Form.Item
                  name="credentialsCode"
                  label={intl.formatMessage({ id: 'certificateNumber' })}
                  extra={intl.formatMessage({ id: 'Automatically generated' })}
                  rules={[
                    {
                      type: 'string',
                      required: true,
                      message: `${intl.formatMessage({
                        id: 'certificateNumber',
                      })} ${intl.formatMessage({ id: 'isRequired' })}`,
                    },
                  ]}
                >
                  {credentialsCode == undefined ? (
                    <a
                      onClick={() =>
                        message.warning(
                          intl.formatMessage({
                            id: 'Please select the effective time first',
                          }),
                        )
                      }
                    >
                      {intl.formatMessage({ id: 'Generate Number' })}
                    </a>
                  ) : (
                    <span>{credentialsCode}</span>
                  )}
                </Form.Item>
              );
            }}
          </Form.Item>

          <Form.Item
            name="credential"
            label={intl.formatMessage({ id: 'Certificate Style' })}
            extra={intl.formatMessage({ id: 'autoGenTip' })}
            rules={[
              {
                type: 'string',
                required: true,
                message: `${intl.formatMessage({
                  id: 'Certificate Style',
                })} ${intl.formatMessage({ id: 'isRequired' })}`,
              },
            ]}
          >
            <Space direction="vertical">
              <Upload
                name="credentialPng"
                maxCount={1}
                accept="image/*"
                action={baseURL + uploadCredentials}
                listType="picture"
                fileList={fileList}
                headers={getAuthHeader()}
                onPreview={handlePreview}
                onChange={({ file, fileList }) => {
                  const { status, response } = file || {};
                  if (fileList.length === 0) {
                    form.setFieldsValue({
                      credential: null,
                    });
                  }
                  if (status === 'done') {
                    const { id } = response[0] || {};
                    form.setFieldsValue({
                      credential: id,
                    });
                  }
                  setFileList(fileList);
                }}
              >
                <a
                  onClick={() => {
                    // 清空选择
                    if (Object.keys(showCredentialPic).length > 0) {
                      setCertificatePic({});
                      setCurrentItem({});
                      form.setFieldsValue({
                        credential: null,
                      });
                    }
                  }}
                >
                  {intl.formatMessage({ id: 'local upload' })}
                </a>
              </Upload>
              {certificateStyle.map((item) => {
                const { id, name } = item || {};
                return (
                  <a key={id} onClick={() => generatePic(item)}>
                    {intl.formatMessage({
                      id: currentItem?.id === id ? 'regenerate' : 'generate',
                    })}
                    {intl.formatMessage({ id: 'nameStyle' }, { name })}
                  </a>
                );
              })}
              {Object.keys(showCredentialPic).length > 0 && (
                <AntdImage width={300} src={showCredentialPic.url} />
              )}
            </Space>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default connect(({ user }) => ({ currentUser: user.currentUser }))(
  AutoGenerationCertModal,
);
