import React from 'react'
import 'video.js/dist/video-js.css'
import styles from './VideoPlayer.less'

export default class VideoPlayer extends React.PureComponent {
  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    const { url, poster, onTouchStart, videoId } = this.props
    return (
      <div>
        <div className={`${styles.contralBarFocusNone}`}>
          <div data-vjs-player>
            <video
              // id="my-video"
              id={videoId}
              //ref={node => (this.videoNode = node)}
              className="video-js vjs-default-skin vjs-big-play-centered"
              poster={poster}
              // data-setup='{"fluid": true}'
              preload="auto"
              onTouchStart={() => onTouchStart()}
              controls={true}
              height={400}
              width={550}
            >
              <source src={url} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    )
  }
}
