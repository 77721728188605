import React, { useEffect, useState } from 'react';
import { Drawer, Spin, Modal, Button, Tabs, Empty } from 'antd';
import { useIntl } from 'umi';

import {
  PostQuestionList,
  PutQuestionnaire,
  GetQuestionnaire,
  PostTrainerTrainingFeedback,
} from 'services/trainingSystem';

import ShowSurvey from 'cognitiveleap-core-us/components/ShowSurvey';
import Question from 'components/Question';

import { getCorrentRateAndResult } from 'cognitiveleap-core-us/utils/utils';

const { TabPane } = Tabs;

const TrainerFeedbackDrawer = ({
  visible,
  roleType,
  currentItem = {},
  selectCertData,
  onClose,
}) => {
  const intl = useIntl();

  const { trainingModule } = selectCertData || {};
  const { id: trainingModuleId, questionnaires = [] } = trainingModule || {};

  if (questionnaires.length === 0) {
    return <></>;
  }

  const [loading, setLoading] = useState(false);
  const [questionDetail, setQuestionDetail] = useState({});
  const [tabListData, setTabListData] = useState([]);
  const [isFinished, setIsFinished] = useState(false);
  const [submitData, setSubmitData] = useState({});
  const [activeKey, setActiveKey] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const { feedback } = currentItem || {};
  const currentFeedback =
    feedback.find((item) => item.questionnaireId === activeKey) || {};
  let { id: userQuestionnaireId, finishTime } = currentFeedback || {};

  const finished = finishTime != null;

  const initData = async () => {
    setLoading(true);
    const res = await GetQuestionnaire({
      id: userQuestionnaireId,
    });

    if (res.response.ok) {
      setQuestionDetail(res.data);
    }

    setLoading(false);
  };

  const createQuestion = async () => {
    setLoading(true);

    const params = {
      trainingModuleId,
      questionnaireId: activeKey,
    };

    const res = await PostTrainerTrainingFeedback(params);

    if (res.response.ok) {
      setQuestionDetail(res.data);
    }

    setLoading(false);
  };

  const getQuestionnaireIdsList = async () => {
    setLoading(true);

    const params = {
      QuestionnaireIds: questionnaires,
    };

    const res = await PostQuestionList(params);

    if (res.response.ok) {
      setActiveKey(questionnaires[0]);
      const questionListInfo = res.data || {};
      const showTabList = questionnaires.map((id) => {
        return questionListInfo.find((item) => item.id === id);
      });
      setTabListData(showTabList);
    }

    setLoading(false);
  };

  useEffect(() => {
    getQuestionnaireIdsList();
  }, []);

  useEffect(() => {
    if (activeKey) {
      if (Object.keys(currentFeedback || {}).length === 0) {
        if (roleType === 'personal') {
          createQuestion();
        }
      } else {
        initData();
      }
    }
  }, [activeKey]);

  const onSubmit = async (answerContent, isComplete, finishedPage) => {
    setLoading(true);

    const params = {
      userQuestionnaireId,
      answerContent,
      isComplete,
    };

    const res = await PutQuestionnaire(params);

    if (res.response.ok) {
      setQuestionDetail({});
      setSubmitData({});
      setIsFinished(false);
      setIsEdit(false);
      onClose();
    }

    setLoading(false);
  };

  const onComplete = ({ answerContent, isComplete, finishedPage }) => {
    if (isComplete) {
      Modal.confirm({
        title: (
          <div style={{ color: 'red' }}>
            {intl.formatMessage({ id: 'submitDes' })}
          </div>
        ),
        okText: intl.formatMessage({ id: 'submit' }),
        cancelText: intl.formatMessage({ id: 'cancel' }),
        onOk: () => {
          setIsFinished(true);
          onSubmit(answerContent, isComplete, finishedPage);
        },
      });
      return;
    } else {
      onSubmit(answerContent, isComplete, finishedPage);
    }
  };

  const onValueChange = ({
    answerContent,
    isComplete,
    finishedPage,
    needSubmit,
  }) => {
    setSubmitData({
      answerContent,
      isComplete,
      finishedPage,
    });
    if (needSubmit) {
      onComplete({ answerContent, isComplete, finishedPage });
    }
  };

  if (Object.keys(questionDetail).length === 0) {
    return <></>;
  }

  let questionArr = [],
    correntRateAndResult = null;

  const { questionnaireAndAnswer, id } = questionDetail || {};
  if (!userQuestionnaireId) {
    userQuestionnaireId = id;
  }
  const { questionnaire, answer: supervisorAnswer } =
    questionnaireAndAnswer || {};
  const { jsonString: supervisorJsonString, correctRate } = questionnaire || {};
  const { answerContent: showAnswerContent } = supervisorAnswer || {};
  const { answerContent: supervisorAnswerContent } = submitData || {};

  const questionProps = {
    content: supervisorJsonString ? supervisorJsonString : '',
    answerContent: supervisorAnswerContent || '',
    answer: supervisorAnswer,
    onComplete: onComplete,
    onValueChange: onValueChange,
  };

  correntRateAndResult = getCorrentRateAndResult(
    supervisorJsonString,
    showAnswerContent,
    correctRate,
  );
  questionArr = correntRateAndResult && correntRateAndResult.questionArr;

  const showSurveyProps = {
    contents: questionArr,
    choice: showAnswerContent
      ? JSON.parse(JSON.stringify(showAnswerContent))
      : {},
    showIcon: false,
  };

  const onDrawerClose = () => {
    if (finished && !isEdit) {
      onClose();
    } else {
      if (!isFinished) {
        if (Object.keys(submitData).length > 0) {
          onComplete(submitData);
        } else {
          onClose();
        }
      }
    }
  };

  return (
    <Drawer
      title={
        <b style={{ fontSize: '20px' }}>
          {intl.formatMessage({ id: 'Certificate Feedback' })}
        </b>
      }
      open={visible}
      width={700}
      onClose={onDrawerClose}
      bodyStyle={{ padding: '20px' }}
    >
      {tabListData && (
        <Tabs
          activeKey={activeKey}
          type="card"
          onChange={(id) => {
            if (isEdit) {
              setIsEdit(false);
            }

            setActiveKey(id);
          }}
        >
          {tabListData.map((item) => {
            const { displayName, id } = item || {};
            return (
              <TabPane tab={displayName} key={id}>
                <Spin spinning={loading}>
                  {Object.keys(questionDetail || {}).length === 0 ? (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  ) : (finished && !isEdit) || roleType !== 'personal' ? (
                    <>
                      {roleType === 'personal' && (
                        <div style={{ textAlign: 'right' }}>
                          <Button
                            type="primary"
                            onClick={() => {
                              setSubmitData(supervisorAnswer);
                              setIsEdit(true);
                            }}
                          >
                            {intl.formatMessage({ id: 'edit' })}
                          </Button>
                          <p />
                        </div>
                      )}
                      <ShowSurvey {...showSurveyProps} />
                    </>
                  ) : (
                    <Question {...questionProps} />
                  )}
                </Spin>
              </TabPane>
            );
          })}
        </Tabs>
      )}
    </Drawer>
  );
};

export default TrainerFeedbackDrawer;
