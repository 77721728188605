import React from 'react';
import style from './index.less';
import moment from 'moment';
import { useIntl } from 'umi'

const DataTop = ({ lastestTrainingPlan, type }) => {
  const intl = useIntl();
  return (
    <div className={type == 'admin' ? style.adminDataTop : style.centerDataTop}>
      <div>{intl.formatMessage({ id: 'startTime' })}: {moment(lastestTrainingPlan && lastestTrainingPlan.creationTime).local().format('YYYY-MM-DD HH:mm')}</div>
      <div>{intl.formatMessage({ id: 'punchTotalDay' })}: {lastestTrainingPlan && lastestTrainingPlan.finishedDayCount}</div>
      <div>{intl.formatMessage({ id: 'continueClockDay' })}: {lastestTrainingPlan && lastestTrainingPlan.continueDayCount}</div>
    </div>
  )
}

export default DataTop;