import React, { useState } from 'react';
import { useIntl } from 'umi';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';

import { PutExamPaperAgain } from 'services/exam';

const ArrangeRetestModal = ({
  examInfo = {},
  visible = false,
  closeModal = () => {},
  reloadPage = () => {},
}) => {
  const intl = useIntl();

  const { id, makeUpExamination } = examInfo;
  const { times = 0, passingScore = 0 } = makeUpExamination || {};

  const [loading, setLoading] = useState({
    modelLoading: false,
  });

  if (Object.keys(examInfo).length === 0) {
    return <></>;
  }

  const contentMenu = {
    Properties: [
      // {
      //   EditorType: 'NumberInput',
      //   ShowTitle: '剩余补考次数',
      //   FormKey: 'addTimes',
      //   AdditionalData: null,
      //   Value: times,
      //   Setting: {
      //     Required: true,
      //     Props: {
      //       min: 0,
      //     },
      //   },
      //   Description: '',
      // },
      {
        EditorType: 'NumberInput',
        ShowTitle: intl.formatMessage({
          id: 'Passing Score for Remedial Exams',
        }),
        FormKey: 'passingScore',
        AdditionalData: null,
        Value: passingScore,
        Setting: {
          Required: true,
          Props: {
            min: 0,
          },
        },
        Description: '',
      },
    ],
  };

  const modalProps = {
    title: intl.formatMessage({ id: 'Editing makeup exams' }),
    visible,
    content: contentMenu,
    onCancel() {
      closeModal();
    },
    loading: loading.modelLoading,
    async onOk(value) {
      setLoading({
        ...loading,
        modelLoading: true,
      });

      const { passingScore, addTimes } = value;
      const params = {
        times: 1,
        userExaminationPaperId: id,
        passingScore,
      };
      const res = await PutExamPaperAgain(params);

      if (res.response.ok) {
        reloadPage();
      }

      closeModal();

      setLoading({
        ...loading,
        modelLoading: false,
      });
    },
  };

  return <MagicFormModal {...modalProps} />;
};

export default ArrangeRetestModal;
