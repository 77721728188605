import getRequest from 'utils/request'

const serviceMenu = {
  Course: 'course',
  Assessment: 'assessment',
  OfflineTrainingPlan: 'OfflineTrainingPlan',
  TrainingPlan: 'trainingPlanTemplate'
}

const GetTenantPaged = '/api/business/tenant'
const GetTenant = '/api/business/tenant/{id}'
const PostTenant = '/api/business/tenant'
const PutTenant = '/api/business/tenant'
const Prepare = '/api/business/tenant/prepareForEdit'
const PutRemiderApi = '/api/business/tenant/hfsReminderAmount'
const PutRemiderValueApi = '/api/business/tenant/vratReminderAmount'
const PutState = '/api/business/tenant/state'
const SendEmail = '/api/business/tenant/sendEmailToTenant/{tenantId}'
const GetTenantQRCode = '/api/business/tenant/qRCodeLink/{tenantId}'

const ResourceType = '/api/rocketAdmin/ResourceType'

const GetTenantGrantByType = '/api/rocketAdmin/{prepare}/tenantGrant/{tenantId}'
const PutTenantGrantByType = '/api/rocketAdmin/{prepare}/tenantGrant'
const GetAreaListApi = '/api/business/tenant/areaList'
const GetRegionListApi = '/api/business/tenant/enterpriseSearchList'
const GetSimpleTenantApi = '/api/business/tenantProfile/simple/{tenantId}'
const TrainingPlanV2Api = '/api/rocketAdmin/trainingPlanV2/grant'

const GetTenantInfoNoPermissionApi = '/api/business/tenantProfile/logo/{tenantId}'
const PostChangeEmailApi = '/api/business/tenant/changeTenantEmail'

const PostTenantsInfoApi = '/api/business/tenant/find'

export async function GetTenantList(data) {
  return getRequest()(GetTenantPaged, {
    method: 'GET',
    params: data
  })
}

export async function PostTenantsInfo(data) {
  return getRequest()(PostTenantsInfoApi, {
      method: 'POST',
      data
  })
}

export async function GetTrainingPlanV2(data) {
  return getRequest()(TrainingPlanV2Api, {
    method: 'GET',
    params: data
  })
}

export async function PostTrainingPlanV2(data) {
  return getRequest()(TrainingPlanV2Api, {
    method: 'POST',
    data
  })
}

export async function PostSendEmail(tenantId) {
  return getRequest()(SendEmail.replace('{tenantId}', tenantId), {
    method: 'POST',
  })
}

export async function GetQRCode(tenantId) {
  return getRequest()(GetTenantQRCode.replace('{tenantId}', tenantId), {
    method: 'GET',
  })
}

export async function PutTenantState(data) {
  return getRequest()(PutState, {
    method: 'PUT',
    data,
  })
}

export async function PutRemiderValue(data) {
  return getRequest()(PutRemiderValueApi, {
    method: 'PUT',
    data,
  })
}

export async function PutRemider(data) {
  return getRequest()(PutRemiderApi, {
    method: 'PUT',
    data,
  })
}

export async function GetTenantInfo(id) {
  return getRequest()(GetTenant.replace('{id}', id), {
    method: 'GET',
  })
}

export async function DeleteTenantInfo(id) {
  return getRequest()(GetTenant.replace('{id}', id), {
    method: 'DELETE',
  })
}

export async function GetRecourseType(id) {
  return getRequest()(ResourceType, {
    method: 'GET',
    params: { tenantId: id }
  })
}


export async function PostTenantData(data, type) {
  return getRequest()(PostTenant, {
    method: 'POST',
    data: { ...data, type },
  })
}

export async function PutTenantData(data) {
  return getRequest()(PutTenant, {
    method: 'PUT',
    data,
  })
}

export async function PrepareForEdit(data) {
  return getRequest()(Prepare, {
    method: 'POST',
    data,
  })
}

export async function GetTenantGrant(id, type, data = {}) {
  return getRequest()(GetTenantGrantByType.replace('{prepare}', serviceMenu[type]).replace('{tenantId}', id), {
    method: 'GET',
    params: data
  })
}

export async function UpdateTenantGrant(type, data = {}) {
  return getRequest()(PutTenantGrantByType.replace('{prepare}', serviceMenu[type]), {
    method: 'PUT',
    data
  })
}

export async function GetAreaList(data) {
  return getRequest()(GetAreaListApi, {
    method: 'GET',
    params: data
  })
}

export async function GetRegionList() {
  return getRequest()(GetRegionListApi, {
    method: 'GET'
  })
}

export async function GetSimpleTenant(tenantId) {
  return getRequest()(GetSimpleTenantApi.replace('{tenantId}', tenantId), {
    method: 'GET',
    params: { tenantId }
  })
}

export async function GetTenantInfoNoPermission(tenantId) {
  return getRequest()(GetTenantInfoNoPermissionApi.replace('{tenantId}', tenantId), {
    method: 'GET'
  })
}

export async function PostChangeEmail(data) {
  return getRequest()(PostChangeEmailApi, {
    method: 'POST',
    data,
  })
}