import getRequest from 'utils/request'

const postSleepStatistic = '/api/rocketAdmin/trainingPlan/sleepStatistics'
const postAnalysis = '/api/rocketAdmin/trainingPlan/sleepAnalysis'
const postDietaryAnalysisData = '/api/rocketAdmin/trainingPlan/nutritionLimit';
const postDietaryStructureData = '/api/rocketAdmin/trainingPlan/nutritionPercent';
const userInfo = '/api/rocketAdmin/trainingPlan/userInfo'
const getSleepRecord = '/api/rocketAdmin/trainingPlan/punchHistory';
const getSleepOfflinePlan = '/api/rocketAdmin/trainingPlan';

export async function SleepStatistic(data) {
    return getRequest()(postSleepStatistic, {
        method: 'POST',
        data,
    })
}

export async function Analysis(data) {
    return getRequest()(postAnalysis, {
        method: 'POST',
        data,
    })
}

// 获取限制营养分析数据
export async function DietaryAnalysisData(data){
    return getRequest()(postDietaryAnalysisData, {
        method: 'POST',
        data,
    })
}

// 获取健康饮食结构分析
export async function DietaryStructureData(data){
    return getRequest()(postDietaryStructureData, {
        method: 'POST',
        data,
    })
}

export async function PlanUserInfo(data) {
    return getRequest()(userInfo, {
        method: 'POST',
        data,
    })
}

// 获取睡眠打卡记录
export async function GetSleepRecord(data){
    return getRequest()(getSleepRecord, {
        method: 'GET',
        params: data
    })
}

// 获取exercise和offlline中的Plan
export async function GetSleepOfflinePlan(data){
    return getRequest()(getSleepOfflinePlan,{
        method: 'GET',
        params: data
    })
}


