import { GetSleepRecord } from 'services/rocketService/TrainingPlan';

export default {
  namespace: 'centerClockInRecord',

  state: {
    sleepRecord: null,
    nutritionRecord: null,
    exerciseRecord: null,
    pagination: {
      sleep: {
        current: 0,
        pageSize: 10,
      },
      nutrition: {
        current: 0,
        pageSize: 10,
      },
      exercise: {
        current: 0,
        pageSize: 10,
      }
    },
    isShowExerciseRecord: false,
    currentExerciseId: ''
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },

  effects: {

    *getRecord({ payload: { id, type, current } }, { all, call, put, take, select }) {
          const { subjectId } = yield select(state => state.subjectDetail);

          const { pagination, sleepRecord, nutritionRecord, exerciseRecord } = yield select(state => state.centerClockInRecord);

          const res = yield call(GetSleepRecord, { 
              SubjectId: subjectId, 
              UserTrainingPlanId: id,
              SkipCount: ( current - 1 ) * ( pagination[type].pageSize ),
              MaxResultCount: pagination[type].pageSize
          })

          if (res.response.ok) {
              const totalCount = res.data.totalCount;
              const newPagination = Object.assign({}, pagination, { [type]: { current, pageSize: pagination[type].pageSize } });
              switch(type){
                case 'sleep' :
                  yield put({ type: 'updateState', payload: { 
                      sleepRecord: sleepRecord ? { items: sleepRecord.items.concat(res.data.items), totalCount } : res.data, 
                      isShowExerciseRecord: false,
                      pagination: newPagination
                  }});
                  break
                case 'nutrition':
                  yield put({ type: 'updateState', payload: { 
                      nutritionRecord: nutritionRecord ? { items: nutritionRecord.items.concat(res.data.items), totalCount } : res.data, 
                      isShowExerciseRecord: false,
                      pagination: newPagination
                  }});
                  break
                case 'exercise':
                  yield put({ type: 'updateState', payload: { 
                      exerciseRecord: exerciseRecord ? { items: exerciseRecord.items.concat(res.data.items), totalCount } : res.data, 
                      pagination: newPagination,
                      isShowExerciseRecord: true,
                      currentExerciseId: id
                  }});
                  break;
              }
          }
    },

    *resetGetRecord({ payload: { id, type } }, { all, call, put, take, select }){
        const { pagination } = yield select(state => state.centerClockInRecord);
        yield put({ type: 'updateState', payload: {
            exerciseRecord: null,
            pagination: Object.assign({},pagination, {exercise: {current: 1, pageSize: 10}}),
            isShowExerciseRecord: true
          }
        })
        yield put({ type: 'getRecord', payload: { id, type, current: 1 } })
  },
},

  subscriptions: {
    setup({ dispatch, history }) {
      // Warning: [app.unmodel] subscription should return unlistener function, check these subscriptions setup
      return () => {}
    },
  },
}
