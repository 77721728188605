import React, { useEffect, useState } from 'react';
import { Drawer, Spin, Modal, Button } from 'antd';
import { useIntl } from 'umi';

import {
  GetQuestionnaire,
  PutOralQuestionnaire,
  PutPracticalQuestionnaire,
  PostOralQuestionnaire,
  PostPracticalQuestionnaire,
} from 'services/trainingSystem';

import ShowSurvey from 'cognitiveleap-core-us/components/ShowSurvey';
import Question from 'components/Question';

import { getCorrentRateAndResult } from 'cognitiveleap-core-us/utils/utils';

const CreateOralHandsDrawer = ({
  visible,
  type,
  roleType,
  currentItem = {},
  trainerTrainingModuleId,
  onClose,
}) => {
  const intl = useIntl();

  let { id: userQuestionnaireId, finished } = currentItem || {};

  const [loading, setLoading] = useState(false);
  const [questionDetail, setQuestionDetail] = useState({});
  const [isFinished, setIsFinished] = useState(false);
  const [submitData, setSubmitData] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  const initData = async () => {
    setLoading(true);
    const res = await GetQuestionnaire({
      id: userQuestionnaireId,
    });

    if (res.response.ok) {
      setQuestionDetail(res.data);
    }

    setLoading(false);
  };

  const createQuestion = async () => {
    setLoading(true);

    const params = {
      trainerTrainingModuleId,
    };

    const res =
      type === 'oralTest'
        ? await PostOralQuestionnaire(params)
        : await PostPracticalQuestionnaire(params);

    if (res.response.ok) {
      setQuestionDetail(res.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (Object.keys(currentItem || {}).length === 0) {
      createQuestion();
    } else {
      initData();
    }
  }, []);

  const onSubmit = async (answerContent, isComplete, finishedPage) => {
    setLoading(true);

    const params = {
      userQuestionnaireId,
      answerContent,
      isComplete,
    };

    const res =
      type === 'oralTest'
        ? await PutOralQuestionnaire(params)
        : await PutPracticalQuestionnaire(params);

    if (res.response.ok) {
      setQuestionDetail({});
      setSubmitData({});
      setIsFinished(false);
      setIsEdit(false);
      onClose();
    }

    setLoading(false);
  };

  const onComplete = ({ answerContent, isComplete, finishedPage }) => {
    if (isComplete) {
      Modal.confirm({
        title: (
          <div style={{ color: 'red' }}>
            {intl.formatMessage({ id: 'submitDes' })}
          </div>
        ),
        okText: intl.formatMessage({ id: 'submit' }),
        cancelText: intl.formatMessage({ id: 'cancel' }),
        onOk: () => {
          setIsFinished(true);
          onSubmit(answerContent, isComplete, finishedPage);
        },
      });
      return;
    } else {
      onSubmit(answerContent, isComplete, finishedPage);
    }
  };

  const onValueChange = ({
    answerContent,
    isComplete,
    finishedPage,
    needSubmit,
  }) => {
    setSubmitData({
      answerContent,
      isComplete,
      finishedPage,
    });
    if (needSubmit) {
      onComplete({ answerContent, isComplete, finishedPage });
    }
  };

  if (Object.keys(questionDetail).length === 0) {
    return <></>;
  }

  let questionArr = [],
    correntRateAndResult = null;

  const { questionnaireAndAnswer, id } = questionDetail || {};
  if (!userQuestionnaireId) {
    userQuestionnaireId = id;
  }
  const { questionnaire, answer: supervisorAnswer } =
    questionnaireAndAnswer || {};
  const { jsonString: supervisorJsonString, correctRate } = questionnaire || {};
  const { answerContent: showAnswerContent } = supervisorAnswer || {};
  const { answerContent: supervisorAnswerContent } = submitData || {};

  const questionProps = {
    content: supervisorJsonString ? supervisorJsonString : '',
    answerContent: supervisorAnswerContent || '',
    answer: supervisorAnswer,
    onComplete: onComplete,
    onValueChange: onValueChange,
  };

  correntRateAndResult = getCorrentRateAndResult(
    supervisorJsonString,
    showAnswerContent,
    correctRate,
  );
  questionArr = correntRateAndResult && correntRateAndResult.questionArr;

  const showSurveyProps = {
    contents: questionArr,
    choice: showAnswerContent
      ? JSON.parse(JSON.stringify(showAnswerContent))
      : {},
    showIcon: false,
  };

  const onDrawerClose = () => {
    if (finished) {
      onClose();
    } else {
      if (!isFinished) {
        if (Object.keys(submitData).length > 0) {
          onComplete(submitData);
        } else {
          onClose();
        }
      }
    }
  };

  return (
    <Drawer
      title={
        <b style={{ fontSize: '20px' }}>
          {intl.formatMessage({
            id: type === 'oralTest' ? 'Oral Test' : 'Handson Test',
          })}
        </b>
      }
      open={visible}
      width={700}
      onClose={onDrawerClose}
    >
      <Spin spinning={loading}>
        {finished && !isEdit ? (
          <>
            {roleType === 'admin' && (
              <div style={{ textAlign: 'right' }}>
                <Button
                  type="primary"
                  onClick={() => {
                    setSubmitData(supervisorAnswer);
                    setIsEdit(true);
                  }}
                >
                  {intl.formatMessage({ id: 'edit' })}
                </Button>
                <p />
              </div>
            )}
            <ShowSurvey {...showSurveyProps} />
          </>
        ) : (
          <Question {...questionProps} />
        )}
      </Spin>
    </Drawer>
  );
};

export default CreateOralHandsDrawer;
