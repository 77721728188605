import React, { useState, useEffect } from 'react';
import { Card, Space, Divider, Progress, Spin } from 'antd';
import { useIntl } from 'umi';
import selectTag from 'assets/selectTag.png';

import { GetTrainerTrainingModuleDetail } from 'services/trainingSystem';
import { eventDebounce } from 'cognitiveleap-core-us/utils/utils';

import OnlineLearning from './OnlineLearning';
import PracticalTraining from './PracticalTraining';
import Supervision from './Supervision';
import TheoreticalTest from './TheoreticalTest';
import PracticalTest from './PracticalTest';
import Certificate from './Certificate';

import styles from '../index.less';

const tabKeyList = {
  OnlineLearning: 'Learning',
  PracticalTraining: 'Training',
  TheoreticalTest: 'Test',
  Supervision: 'Supervision',
  PracticalTest: 'Test',
  Certificate: 'CertificateList',
};

const CertProgress = ({
  userInfo,
  certList,
  roleType,
  allowReloadProgress,
  showTrainingApplication,
  onChangeStep,
  onChangeAllowReloadTab,
}) => {
  const intl = useIntl();

  const { id: trainerId } = userInfo || {};

  if (certList.length === 0) {
    return <></>;
  }

  const [currentTag, setCurrentTag] = useState(0);
  const [progressIndex, setProgressIndex] = useState(0);
  const [moduleDetail, setModuleDetail] = useState({});
  const [selectCert, setSelectCert] = useState(certList[0]?.id || '');
  const [moduleDetailLoading, setModuleDetailLoading] = useState(false);

  const selectCertData = certList.find((item) => item.id === selectCert) || {};
  const { trainingStepsProgress = [] } = moduleDetail || {};

  const getTrainingDetail = async (id) => {
    setModuleDetailLoading(true);
    if (id) setSelectCert(id);
    const res = await GetTrainerTrainingModuleDetail({
      trainerTrainingModuleId: id || selectCert,
    });
    if (res.response.ok) {
      const { data = {} } = res || {};
      const { trainingStep, trainingStepsProgress = [] } = data || {};

      const index = trainingStepsProgress.findIndex(
        (item) => item.trainingStep === trainingStep,
      );

      setProgressIndex(index + 1);
      setCurrentTag(trainingStep);
      onChangeStep(tabKeyList[trainingStep]);
      setModuleDetail(data);
    }
    setModuleDetailLoading(false);
  };

  useEffect(() => {
    if (selectCert) {
      getTrainingDetail();
    }
  }, []);

  const onChange = (value, index) => {
    setCurrentTag(value);
    setProgressIndex(index);
    onChangeStep(tabKeyList[value]);
  };

  const stepItems = trainingStepsProgress.map((item) => {
    const { courseId, trainingStep, finish, total } = item || {};
    return {
      courseId,
      key: trainingStep,
      title: intl.formatMessage({ id: trainingStep }),
      progress: (finish / total) * 100,
    };
  });

  const onLineLearnProps = {
    roleType,
    progressIndex,
    moduleDetail,
    selectCertData,
    showTrainingApplication,
    onChangeAllowReloadTab,
  };

  const practicalTrainingProps = {
    progressIndex,
    roleType,
    userInfo,
    trainerTrainingModuleId: selectCert,
    onChangeAllowReloadTab,
    allowReloadProgress,
  };

  const supervisionProps = {
    progressIndex,
    roleType,
    userInfo,
    trainerTrainingModuleId: selectCert,
    onChangeAllowReloadTab,
    allowReloadProgress,
  };

  const theoreticalTestProps = {
    progressIndex,
    roleType,
    userInfo,
    selectCertData,
    onChangeAllowReloadTab,
    allowReloadProgress,
  };

  const showComList = [
    {
      key: 'OnlineLearning',
      child: <OnlineLearning {...onLineLearnProps} />,
    },
    {
      key: 'PracticalTraining',
      child: <PracticalTraining {...practicalTrainingProps} />,
    },
    {
      key: 'Supervision',
      child: <Supervision {...supervisionProps} />,
    },
    {
      key: 'TheoreticalTest',
      child: <TheoreticalTest {...theoreticalTestProps} />,
    },
    {
      key: 'PracticalTest',
      child: <PracticalTest {...theoreticalTestProps} />,
    },
    {
      key: 'Certificate',
      child: <Certificate {...theoreticalTestProps} />,
    },
  ];

  return (
    <Card
      title={
        <div className={styles.cardTitle}>
          <div className={styles.cardTitleIcon}></div>
          <div className={styles.cardTitleText}>
            {intl.formatMessage({ id: 'CertificationProcess' })}
          </div>
        </div>
      }
      bordered={false}
    >
      <Space className={styles.certList} split={<Divider type="vertical" />}>
        {certList.map((item) => {
          const { id, trainingModule } = item || {};
          const { displayName } = trainingModule || {};
          return (
            <div
              style={{
                cursor: 'pointer',
                fontWeight: '600',
                color:
                  id == selectCert ? 'rgba(0,0,0,0.85)' : 'rgba(0,0,0,0.45)',
              }}
              className={styles.underlineFromCenter}
              key={id}
              onClick={() => eventDebounce(() => getTrainingDetail(id), 300)}
            >
              {displayName}
            </div>
          );
        })}
      </Space>

      <Spin spinning={moduleDetailLoading}>
        <div className={styles.customProgress}>
          {stepItems.length >= 2 && <div className={styles.progressLine}></div>}
          <div className={styles.customProgressContainer}>
            {stepItems.map((item, index) => {
              const { key, title, progress, courseId } = item || {};
              const isCurrent = currentTag == key;
              return (
                <div
                  key={key}
                  className={styles.customProgressItem}
                  onClick={() => onChange(key, index + 1)}
                >
                  <Progress
                    type="circle"
                    percent={progress}
                    width={38}
                    strokeWidth={8}
                    format={() => (
                      <span
                        style={{
                          fontSize: '18px',
                          fontWeight: 600,
                          color: parseInt(progress)
                            ? 'rgba(0,0,0,0.85)'
                            : 'rgba(0,0,0,0.45)',
                        }}
                      >
                        {index + 1}
                      </span>
                    )}
                    strokeColor={{
                      '0%': '#3C84FF',
                      '100%': '#3C84FF',
                    }}
                  />
                  <div
                    className={styles.customProgressItemTitle}
                    style={{
                      color: parseInt(progress)
                        ? 'rgba(0,0,0,0.85)'
                        : 'rgba(0,0,0,0.45)',
                    }}
                  >
                    {title}
                  </div>
                  {isCurrent ? (
                    <img src={selectTag} />
                  ) : (
                    <div className={styles.notSelectTag}></div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        {showComList.filter((item) => currentTag === item.key).length > 0 && (
          <div className={styles.progressContent}>
            {showComList.filter((item) => currentTag === item.key)[0].child}
          </div>
        )}
      </Spin>
    </Card>
  );
};

export default CertProgress;
