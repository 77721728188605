import getRequest from 'utils/request'
import { getLocale } from 'umi'
import download from 'cognitiveleap-core-us/utils/download'

const LangOptions = {
  'en-US': 'en',
  'zh-CN': 'zh-Hans',
  'es-ES': 'es'
}

function getStr(str) {
  var result = str.match(/UTF-8.+/g)
  return result[0]
}

const GetLineWithIconData = '/api/vrclassroom/DataAnalysis/Test/HeadVelocity'
const GetReportInfo = '/api/vrclassroom/DataAnalysis/Test/Info'

const GetRadarData = '/api/vrclassroom/DataAnalysis/Test/Radar'
const GetRoseData = '/api/vrclassroom/DataAnalysis/Test/Rose'
const GetOverView = '/api/vrclassroom/DataAnalysis/Test/OverView'
const GetContinuous = '/api/vrclassroom/DataAnalysis/Test/ContinuousAttention'
const GetMovementPath = '/api/vrclassroom/DataAnalysis/Test/MovementPath'
const GetCharacteristic = '/api/vrclassroom/DataAnalysis/Test/MotionCharacteristics'
const GetPathLength = '/api/vrclassroom/DataAnalysis/Test/PathLength'
const GetReport = '/api/vrclassroom/DataAnalysis/Test/DownloadPdf'
const GetParentStaticQrApi = '/api/vrclassroom/QrCode/staticQR'
const GetReportVersionApi = '/reportConfig.json'
const PostUpdateParentVersionApi = '/api/vrclassroom/TestingCenter/ParentReportUiVersion'
const PostUpdateRecommendationVersionApi = '/api/vrclassroom/TestingCenter/RecommendationReportUiVersion'
const GetParentReport = '/api/vrclassroom/DataAnalysis/Test/ParentReportDownloadPdf'
const PostSendEmail = '/api/vrclassroom/TestingCenter/SendReportToEmail'
const GetQrCodeApi = '/api/vrclassroom/QrCode/buyQR'
const GetScreeningMotionFlagApi = '/api/vrclassroom/DataAnalysis/Test/ScreeningMotionFlag'
const GetScreeningCPTFlagApi = '/api/vrclassroom/DataAnalysis/Test/ScreeningCPTFlag'

export async function GetScreeningMotionFlag(caseId, code, apiVersion = 2) {
  return getRequest()(GetScreeningMotionFlagApi, {
    method: 'GET',
    params: { caseId, code, 'api-version': apiVersion },
  })
}

export async function GetScreeningCPTFlag(caseId, code, apiVersion = 2) {
  return getRequest()(GetScreeningCPTFlagApi, {
    method: 'GET',
    params: { caseId, code, 'api-version': apiVersion },
  })
}

export async function SendReportEmail(data) {
  const language = LangOptions[getLocale()]
  return getRequest()(PostSendEmail, {
    method: 'POST',
    data: {
      ...data,
      language,
    },
  })
}

export async function GetQrCode(caseId) {
  return getRequest()(GetQrCodeApi, {
    method: 'GET',
    responseType: 'blob',
    params: { caseId }
  })
}

export async function GetReportVersion() {
  return getRequest({ myBaseURL: window.location.origin })(GetReportVersionApi, {
    method: 'GET',
  })
}

export async function PostUpdateParentVersion(data) {
  return getRequest()(PostUpdateParentVersionApi,{
      method: 'POST',
      params: data,
    })
}

export async function PostUpdateRecommendationVersion(data) {
  return getRequest()(PostUpdateRecommendationVersionApi,{
      method: 'POST',
      params: data,
    })
}

export async function GetParentStaticQr() {
  return getRequest()(GetParentStaticQrApi, {
    method: 'GET',
    responseType: 'blob'
  })
}

export async function ReportInfo(caseId, code) {
  return getRequest()(GetReportInfo, {
    method: 'GET',
    params: { code, caseId },
  })
}

export async function GetRadar(caseId, code, apiVersion = 2) {
  return getRequest()(GetRadarData, {
    method: 'GET',
    params: { code, caseId, 'api-version': apiVersion },
  })
}

export async function GetRose(caseId, code, apiVersion = 2) {
  return getRequest()(GetRoseData, {
    method: 'GET',
    params: {
      code,
      caseId,
      'api-version': apiVersion,
      blockNum: 0,
    },
  })
}

export async function GetPDFReport(caseId, apiVersion = 3.1, reGenerate = false,) {
  const language = LangOptions[getLocale()]
  let uiVersion;
  const versionRes = await getRequest({ myBaseURL: window.location.origin })(GetReportVersionApi, {
    method: 'GET',
  });
  if (versionRes.response.ok) {
    const { baseReportVersion } = versionRes.data || {}
    uiVersion = baseReportVersion
  }
  return getRequest()(GetReport, {
    reponseType: 'blob',
    method: 'GET',
    params: {
      reGenerate,
      caseId,
      language,
      uiVersion,
      'api-version': apiVersion,
    },
  }).then(res => {
    const { response } = res
    if (response.ok) {
      var filename = response.headers.get('Content-Disposition')
      filename = getStr(filename)
      const reportTitle = decodeURIComponent(filename).replace("UTF-8''", '')
      response.blob().then(blob => {
        download(blob, reportTitle)
      })
    }
  })
}

export async function GetParentPDFReport(caseId, apiVersion = 3.1, uiVersion, reportModel, reGenerate = false) {
  const language = LangOptions[getLocale()]
  return getRequest()(GetParentReport, {
    reponseType: 'blob',
    method: 'GET',
    params: {
      reGenerate,
      caseId,
      language,
      'api-version': apiVersion,
      uiVersion,
      reportModel
    },
  }).then(res => {
    const { response } = res
    if (response.ok) {
      var filename = response.headers.get('Content-Disposition')
      filename = getStr(filename)
      const reportTitle = decodeURIComponent(filename).replace("UTF-8''", '')
      response.blob().then(blob => {
        download(blob, reportTitle)
      })
    }
  })
}

//这个用V1
export async function GetLineWithIcon(caseId, code, apiVersion = 1) {
  return getRequest()(GetLineWithIconData, {
    method: 'GET',
    params: { code, caseId, 'api-version': apiVersion },
  })
}

export async function GetOverViewData(caseId, code, apiVersion = 2) {
  return getRequest()(GetOverView, {
    method: 'GET',
    params: { code, caseId, 'api-version': apiVersion },
  })
}

export async function GetCharacteristicData(caseId, code, apiVersion = 2) {
  return getRequest()(GetCharacteristic, {
    method: 'GET',
    params: { code, caseId, 'api-version': apiVersion },
  })
}

export async function GetContinuousData(caseId, code, apiVersion = 2) {
  return getRequest()(GetContinuous, {
    method: 'GET',
    params: { code, caseId, 'api-version': apiVersion },
  })
}

export async function GetPathLengthData(caseId, code, apiVersion = 2) {
  return getRequest()(GetPathLength, {
    method: 'GET',
    params: { code, caseId, 'api-version': apiVersion },
  })
}

export async function GetMovementPathData(caseId, code, apiVersion = 2) {
  return getRequest()(GetMovementPath, {
    method: 'GET',
    params: { code, caseId, 'api-version': apiVersion },
  })
}
