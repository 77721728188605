import { GetSleepOfflinePlan } from 'services/rocketService/TrainingPlan'
  
  export default {
    namespace: 'subjectExercise',
  
    state: {
      planInfo: null,
      list: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
    },
  
    reducers: {
      updateState(state, { payload }) {
        return {
          ...state,
          ...payload,
        }
      },
    },
  
    effects: {
  
      *loadList({ payload: { current = 1, type } }, { all, call, put, take, select }) {
        const { subjectInfo } = yield select(state => state.subjectDetail);
        const { pagination, planInfo } = yield select(state => state.subjectExercise);
        let res = yield call(GetSleepOfflinePlan, { 
            SubjectId: subjectInfo.id, 
            Type: 'Online',
            SkipCount: ( current - 1 ) * ( pagination.pageSize ),
            MaxResultCount: pagination.pageSize
        })
  
        if (res.response.ok) {
          const totalCount = res.data.totalCount;
          const newPagination = Object.assign({}, pagination, { current, pageSize: pagination.pageSize });
          yield put({
            type: 'updateState',
            payload: { 
              planInfo: planInfo && type == 'load'? { items: planInfo.items.concat(res.data.items), totalCount } : res.data,
              pagination: newPagination
            },
          })
        }
      },
  
      *changeTable({ payload: data }, { call, put, select }) {
        const { pagination: originPage } = yield select(state => state.subjectExercise)
        const { pagination } = data
        yield put({ type: 'updateState', payload: { pagination: { ...originPage, ...pagination } } })
        yield put({ type: 'loadList' })
      },
    },
  
    subscriptions: {
      setup({ dispatch, history }) {
        // Warning: [app.unmodel] subscription should return unlistener function, check these subscriptions setup
        return ()=>{}
      },
    },
  }
  