import React from 'react'
import { Divider, message } from 'antd'
import { connect } from 'umi'
import styles from '../detail.less'
import VideoPlayer from 'components/VideoReact/VideoPlayer'
import ReportRichText from 'components/ReportRichText/ReportRichText'
import MyVideo from 'components/MyVideo'
import videojs from 'video.js'
import { IntlManager } from 'utils/helper'
import 'video.js/dist/video-js.css'

class TrainingProgramContent extends React.PureComponent {
  canShowVideoPlayer() {
    const programInfo = this.props
    let url = ''
    if (programInfo !== null) {
      url = programInfo.videos.length > 0 ? programInfo.videos[0].url : ''
    }
    return url !== ''
  }

  componentDidMount() {
    // instantiate Video.js
    // if (this.canShowVideoPlayer()) {
    //   this.player = videojs(`my-video-${this.props.index}`, {}, function onPlayerReady() {
    // console.log('onPlayerReady', this)
    // })

    // }
  }

  componentDidUpdate() {
    // const programInfo = this.props
    // const url = programInfo.videos.length > 0 ? programInfo.videos[0].url : ''
    // const posterUrl = programInfo.images.length > 0 ? programInfo.images[0].url : ''
    // if (this.canShowVideoPlayer()) {
    //   if (this.player) {
    //     this.player.src(url)
    //     this.player.poster(posterUrl)
    //   } else {
    //     this.player = videojs(`my-video-${this.props.index}`, {}, function onPlayerReady() {
    //       // console.log('onPlayerReady', this)
    //     })
    //     /// 这边不手动更新也可以，因为video组件会重新渲染，可以拿到props里面的值
    //     // this.player.src(url)
    //     // this.player.poster(posterUrl)
    //   }
    // } else {
    //   if (this.player) {
    //     this.player.dispose()
    //     this.player = null
    //   }
    // }
  }

  // destroy player on unmount
  componentWillUnmount() {
    // if (this.player) {
    //   this.player.dispose()
    //   this.player = null
    // }
  }

  // onTouchStart() {
  //   if (this.player.paused()) {
  //     this.player.play()
  //   } else {
  //     this.player.pause()
  //   }
  // }

  /// type: String Number  Boolean  Duration  Rating
  switchFeedbackType(feedbackProperty) {
    switch (feedbackProperty.type) {
      case 'String':
        return `(${feedbackProperty.extraProperties.minLength}~${feedbackProperty.extraProperties.maxLength})`
      case 'Number':
      case 'Duration':
      case 'Rating':
        return `(${feedbackProperty.extraProperties.minValue}~${feedbackProperty.extraProperties.maxValue})`
      default:
        return ''
    }
  }

  render() {
    const intl = IntlManager.MyIntl()

    const programInfo = this.props
    if (programInfo === null) return null
    let videoProps = {}
    const {
      title,
      code,
      targets,
      tags,
      from,
      suggestedTimeLength,
      agePeriod,
      content,
      materials,
      feedbackProperties,
      difficultyIncrease,
      isRegisterAction,
      categories,
      precautions,
      hideTitle = false,
      ilsVersion,
      possibleDifficulties,
      suggestionsOnGroupTeaching,
      groupCourseItemId
    } = programInfo

    let id = programInfo.videos && programInfo.videos[0] && programInfo.videos[0].id
    let url = programInfo.videos && programInfo.videos[0] && programInfo.videos[0].url
    let coverUrl = programInfo.videos && programInfo.videos[0] && programInfo.videos[0].coverUrl
    let videoJsOptions = {
      url: '',
      // poster: '',
      // onTouchStart: this.onTouchStart,
      // videoId: `my-video-${programInfo.index}`,
    }

    const isVersion2 = ilsVersion === 'V4'
    const needCategoryVersion = isVersion2 ?'2':'1'

    if (programInfo != null) {
      if (programInfo.videos.length > 0) {
        videoJsOptions.url = programInfo.videos[0].url
      }
      if (programInfo.images.length > 0) {
        videoJsOptions.poster = programInfo.images[0].url
      }
    }

    if (this.canShowVideoPlayer()) {
      videoProps = {
        id,
        url,
        coverUrl,
        width: '711px',
        height: '400px',
        groupCourseItemId,
        from
      }
    }

    return (
      <div>
        {!hideTitle && <p className={styles.category}>
          <span className={styles.categoryTitle}>{programInfo.title}</span>
          {programInfo.difficulty && <span>{programInfo.difficulty.replace('L', 'lv')}</span>}
        </p>}
        <p>{programInfo.subTitle}</p>

        {this.canShowVideoPlayer() ? (
          <div className={styles.video}>
            {/* <VideoPlayer {...videoJsOptions} /> */}
            <MyVideo {...videoProps} />
          </div>
        ) : (
          <div>
            {videoJsOptions.poster && <img alt="img" className={styles.poster} src={videoJsOptions.poster} />}
          </div>
        )}
        <div>
          {/* 编号 */}
          <div className={styles.betweenItem}>
            <Divider type="vertical" className={styles.dividerColor} />
            <span className={styles.infoTitle}>{intl('numberOrder')} : </span>
            <span>{code}</span>
          </div>

          {/* 训练目标 */}
          <div className={styles.betweenItem}>
            <Divider type="vertical" className={styles.dividerColor} />
            <span className={styles.infoTitle}>{intl('trainingGoals')} : </span>
            <span>
              {targets.map((item, index) => {
                return (
                  <span key={item.id}>{`${item.displayName}${index === targets.length - 1 ? '' : ', '
                    }`}</span>
                )
              })}
            </span>
          </div>

          <div className={styles.betweenItem}>
            <Divider type="vertical" className={styles.dividerColor} />
            <span className={styles.infoTitle}>{intl('type')} : </span>
            <span>
              {categories.filter(e=>e.categoryType === needCategoryVersion).map((item, index) => {
                return (
                  <span key={item.id}>{`${item.displayName}${index === categories.filter(e=>e.categoryType === needCategoryVersion).length - 1 ? '' : ' , '
                    }`}</span>
                )
              })}
            </span>
          </div>

          <div className={styles.betweenItem}>
            <Divider type="vertical" className={styles.dividerColor} />
            <span className={styles.infoTitle}>{intl('trainingMaterials')} : </span>
            <span>
              {
                materials && Array.isArray(materials) && materials.length > 0 ?
                  materials.map(item => {
                    const { displayName, materialDescription, requiredCount } = item
                    return (
                      <div className={styles.materials} key={item.id}>
                        <span className={styles.materialsPoint} />
                        <span>{displayName}</span>
                        <span>{materialDescription}</span>
                        <span className={styles.materialsCount}>{intl('number')}：{Boolean(requiredCount) ? requiredCount : intl('certainNumber')}</span>
                      </div>
                    )
                  })
                  :
                  <div className={styles.materials}>{intl('none')}</div>
              }
            </span>
          </div>

          {/* <div className={styles.betweenItem}>
            <Divider type="vertical" className={styles.dividerColor} />
            <span className={styles.infoTitle}>注册动作 : </span>
            <span>{isRegisterAction ? '是 （请勿随意替换，可能影响训练效果）' : '否'}</span>
          </div> */}

          {/* 预计训练时长(分钟) */}
          {/* <div className={styles.betweenItem}>
            <Divider type="vertical" className={styles.dividerColor} />
            <span className={styles.infoTitle}>预计训练时长(分钟) : </span>
            <span>{suggestedTimeLength}</span>
          </div> */}

          {/* 适合年龄 */}
          {/* <div className={styles.betweenItem}>
            <Divider type="vertical" className={styles.dividerColor} />
            <span className={styles.infoTitle}>适合年龄 : </span>
            <span>
              {`${agePeriod != null ? agePeriod.start : ''}-${
                agePeriod != null ? agePeriod.end : ''
              }`}
            </span>
          </div> */}

          {/* 标签 */}
          {/* <div className={styles.betweenItem}>
            <Divider type="vertical" className={styles.dividerColor} />
            <span className={styles.infoTitle}>标签 : </span>
            {tags.map((item, index) => {
              return (
                <span key={index}>{`${item.displayName}${
                  index === tags.length - 1 ? '' : ' , '
                }`}</span>
              )
            })}
          </div> */}

          {/* 教具 */}
          {/* <div className={styles.betweenItem}>
            <Divider type="vertical" className={styles.dividerColor} />
            <span className={styles.infoTitle}>教具 : </span>
          </div>
          <div className={styles.liItem}>
            {materials.map((item, index) => {
              return <div key={index}>{`${item.displayName} ${item.requiredCount}个`}</div>
            })}
          </div> */}

          {/* 反馈 */}
          {/* <div className={styles.betweenItem}>
            <Divider type="vertical" className={styles.dividerColor} />
            <span className={styles.infoTitle}>反馈 : </span>
          </div>
          <div className={styles.liItem}>
            {feedbackProperties.map((item, index) => {
              return (
                <div key={item.id}>
                  <Badge
                    status="default"
                    color={'rgba(0, 0, 0, 0.65)'}
                    text={`${item.displayName}${this.switchFeedbackType(item)}`}
                  />
                </div>
              )
            })}
          </div> */}

          {/*
            <div className={styles.betweenItem}>
              <Divider type="vertical" className={styles.dividerColor} />
              <span className={styles.infoTitle}>难度增加 : </span>
            </div>
            <div className={styles.richText}>
              <ReportRichText content={difficultyIncrease} />
            </div>
          */}

          <div className={styles.betweenItem}>
            <Divider type="vertical" className={styles.dividerColor} />
            <span className={styles.infoTitle}>{intl('actionSummary')} : </span>
          </div>
          <div className={styles.richText}>
            <ReportRichText content={content} />
          </div>

          <div className={styles.betweenItem}>
            <Divider type="vertical" className={styles.dividerColor} />
            <span className={styles.infoTitle}>{intl('trainingTimesPrecautions')}</span>
          </div>
          <div className={styles.richText}>
            {
              precautions ?
                <ReportRichText content={precautions} />
                :
                <div className={styles.precautionsEmpty}>{intl('none')}</div>
            }
          </div>
          <div className={styles.betweenItem}>
            <Divider type="vertical" className={styles.dividerColor} />
            <span className={styles.infoTitle}>{intl('possibleDifficultiesChildren')}：</span>
          </div>
          <div className={styles.richText}>
            {
              possibleDifficulties ?
                <ReportRichText content={possibleDifficulties} />
                :
                <div className={styles.precautionsEmpty}>{intl('none')}</div>
            }
          </div>
          { 
            (from === 'groupOfflinePlanDetail' || from === 'groupClassDetail') && <>
              <div className={styles.betweenItem}>
                <Divider type="vertical" className={styles.dividerColor} />
                <span className={styles.infoTitle}>{intl('groupTeachingSuggestions')}：</span>
              </div>
              <div className={styles.richText}>
                {
                  suggestionsOnGroupTeaching ?
                    <ReportRichText content={suggestionsOnGroupTeaching} />
                    :
                    <div className={styles.precautionsEmpty}>{intl('none')}</div>
                }
              </div>
            </>
          }
        </div>
      </div>
    )
  }
}

export default connect(({ musicplayer }) => ({
  isOpen: musicplayer.isOpen
}))(TrainingProgramContent)

