import React, { useState, useEffect } from 'react';
import { Space, Timeline, message, Modal, Button } from 'antd';
import { history, useIntl } from 'umi';

import { GetTheoreticalTestW } from 'services/trainingSystem';
import ArrangeRetestModal from './Modals/ArrangeRetestModal';
import SpinCom from './SpinCom';

import { GetFirstExam, GetAgainExam, GetExamDetail } from 'services/exam';

import styles from '../index.less';

import moment from 'moment';

const TheoreticalTest = ({
  progressIndex,
  selectCertData,
  userInfo,
  roleType,
  onChangeAllowReloadTab,
  allowReloadProgress,
}) => {
  const intl = useIntl();

  const [examInfo, setExamInfo] = useState({});
  const [visible, setVisible] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const { id: trainerId } = userInfo || {};
  const { trainingModule, id: trainerTrainingModuleId } = selectCertData || {};
  const { examinationId, name } = trainingModule || {};

  const {
    fatherUserExaminationPaperId,
    status,
    type,
    id,
    updateTime,
    makeUpExamination,
  } = examInfo || {};

  const { times = 0 } = makeUpExamination || {};

  const isEmpty = Object.keys(examInfo).length === 0;

  const initData = async () => {
    setLoading(true);
    const res = await GetTheoreticalTestW({ trainerTrainingModuleId });
    if (res.response.ok) {
      const { data } = res || {};
      setExamInfo(data || {});
    }
    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, [allowReloadProgress]);

  const showConfirm = async (type) => {
    setBtnLoading(true);
    let totalQuestionCount = 0,
      fraction = 0,
      paymentAmount;
    let againSource; // 补考通过分数
    let newId;
    let isPay = false; // 是否已经支付
    let code; // 考试结算单号
    const params = {
      trainerTrainingModuleId,
    };

    const examDetailRes = await GetExamDetail({
      id: examinationId,
    });

    if (!examDetailRes.response.ok) {
      const { message: errorMessage } = examDetailRes.data?.error || {
        message: intl.formatMessage({ id: 'testInformationFail' }),
      };
      message.error(errorMessage, 3);
      return;
    }

    const { duration, regulations, passingScore } = examDetailRes.data.data;
    regulations.forEach((item) => {
      totalQuestionCount += item.quantity;
      fraction += item.quantity * item.fraction;
    });

    if (fatherUserExaminationPaperId) {
      newId = fatherUserExaminationPaperId;
    }

    // if (detail.lastUserExaminationPaperId) {
    //   newId = detail.lastUserExaminationPaperId;
    // }

    if (type !== 'first' && JSON.stringify(examInfo) !== '{}') {
      if (examInfo.makeUpExamination) {
        paymentAmount = examInfo.makeUpExamination.price;
        againSource = examInfo.makeUpExamination.passingScore;
      }
      code = examInfo.id;
      if (
        examInfo.status === 'NOT_STARTED' &&
        examInfo.type === 'MAKEUP' &&
        examInfo.makeUpExamination.price !== 0
      ) {
        // 说明时已经是补考创建的试卷
        const payRes = await GetPayStatus({ code });
        if (payRes && payRes.response && payRes.response.ok) {
          const { status } = payRes.data.data;
          if (status === 'Paid') {
            isPay = true;
          }
        } else {
          message.error(intl.formatMessage({ id: 'failedGetOrder' }));
        }
      } else if (examInfo.status === 'FAILED') {
        const againRes = await GetAgainExam(params);
        if (againRes.response.ok) {
          code = againRes.data.id;
          setExamInfo({
            ...examInfo,
            id: code,
            type: 'MAKEUP',
            status: 'NOT_STARTED',
            makeUpExamination: againRes.data.makeUpExamination,
          });
        } else {
          const { message: errorMessage } = examDetailRes.data?.error || {
            message: intl.formatMessage({ id: 'generateRetestPaperFail' }),
          };
          message.error(errorMessage, 3);
        }
      }
    }

    setBtnLoading(false);

    Modal.confirm({
      title: intl.formatMessage({ id: 'testInstructions' }),
      icon: '',
      width: 500,
      content:
        type === 'first' ? (
          <div className={styles.modelStyle}>
            <div className={styles.modelTitles}>
              <div>
                <div>{duration}</div>
                <div className={styles.describe}>
                  {intl.formatMessage({ id: 'examDuration' })}
                </div>
              </div>

              <div>
                <div>{totalQuestionCount}</div>
                <div className={styles.describe}>
                  {intl.formatMessage({ id: 'numberOfQuestions' })}
                </div>
              </div>

              <div>
                <div>{fraction}</div>
                <div className={styles.describe}>
                  {intl.formatMessage({ id: 'testScore' })}
                </div>
              </div>
            </div>
            <div>
              <ul>
                <li>{intl.formatMessage({ id: 'firstTimeExam' }, { name })}</li>
                <li>
                  {intl.formatMessage(
                    { id: 'examResults' },
                    { score: passingScore },
                  )}
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <div className={styles.modelStyle}>
            <div className={styles.modelTitles}>
              <div>
                <div>{duration}</div>
                <div className={styles.describe}>
                  {intl.formatMessage({ id: 'examDuration' })}
                </div>
              </div>

              <div>
                <div>{totalQuestionCount}</div>
                <div className={styles.describe}>
                  {intl.formatMessage({ id: 'numberOfQuestions' })}
                </div>
              </div>

              <div>
                <div>{fraction}</div>
                <div className={styles.describe}>
                  {intl.formatMessage({ id: 'testScore' })}
                </div>
              </div>
            </div>
            <div>
              <ul>
                <li>
                  {intl.formatMessage(
                    { id: 'thisTimeExamRetakeFee' },
                    { name, price: paymentAmount },
                  )}
                </li>
                <li>
                  {intl.formatMessage(
                    { id: 'examResults' },
                    { score: againSource },
                  )}
                </li>
              </ul>
            </div>
          </div>
        ),
      okText: intl.formatMessage({
        id:
          type === 'first'
            ? 'joinNow'
            : isPay
            ? 'continueWithLastExam'
            : paymentAmount == 0
            ? 'takeExam'
            : 'pay',
      }),
      cancelText: intl.formatMessage({ id: 'wait' }),
      async onOk() {
        if (type === 'first') {
          if (JSON.stringify(examInfo) === '{}') {
            const firstRes = await GetFirstExam(params);
            if (firstRes.response.ok) {
              newId = firstRes.data.id;
            } else {
              const { message: errorMessage } = firstRes.data?.error || {
                message: intl.formatMessage({ id: 'failedGenerateExam' }),
              };
              message.error(errorMessage, 3);
              return;
            }
          }
          history.push({
            pathname: '/system/center/detail/examPanel',
            query: {
              trainerTrainingModuleId: newId,
            },
          });
        } else {
          if (examInfo.makeUpExamination.price === 0) {
            history.push({
              pathname: '/system/center/detail/examPanel',
              query: {
                trainerTrainingModuleId: code,
              },
            });
          } else {
            if (isPay) {
              history.push({
                pathname: '/system/center/detail/examPanel',
                query: {
                  trainerTrainingModuleId: code,
                },
              });
            }
          }
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const gotoExam = () => {
    history.push({
      pathname: '/system/center/detail/examPanel',
      query: {
        trainerTrainingModuleId: id,
      },
    });
  };

  const getExamResult = async () => {
    let pathname = '/system/center/detail/examResult';
    if (roleType === 'admin') {
      pathname = '/system/manage/detail/examResult';
    }
    history.push({
      pathname,
      query:
        roleType !== 'personal'
          ? {
              TrainerTrainingModuleId: trainerTrainingModuleId,
              TrainerId: trainerId,
            }
          : { TrainerTrainingModuleId: trainerTrainingModuleId },
    });
  };

  const arrangeRetestModalProps = {
    visible,
    examInfo,
    closeModal: () => {
      setVisible(false);
    },
    reloadPage: () => {
      initData();
      onChangeAllowReloadTab && onChangeAllowReloadTab();
    },
  };

  const onArrangeRetest = () => {
    setVisible(true);
  };

  if (loading) {
    return <SpinCom />;
  }

  return (
    <>
      <Timeline>
        <Timeline.Item>
          <div className={styles.practicalTrainingItem}>
            <div className={styles.practicalTrainingItemTitle}>
              {progressIndex}.1{' '}
              {intl.formatMessage({ id: 'Theoretical Test Written Test' })}
            </div>
            <div className={styles.practicalTrainingItemContent}>
              <Space>
                {isEmpty ? (
                  roleType === 'personal' ? (
                    <Button
                      type="link"
                      loading={btnLoading}
                      onClick={() => showConfirm('first')}
                    >
                      {intl.formatMessage({ id: 'Take Test' })}
                    </Button>
                  ) : (
                    <div>{intl.formatMessage({ id: 'notRealStart' })}</div>
                  )
                ) : roleType === 'personal' ? (
                  <>
                    {(status === 'DOING' ||
                      (status === 'NOT_STARTED' && type === 'FIRST')) && (
                      <a onClick={gotoExam}>
                        {intl.formatMessage({ id: 'Continue Test' })}
                      </a>
                    )}
                    {status === 'NOT_STARTED' && type === 'MAKEUP' && (
                      <Space>
                        <a onClick={getExamResult}>
                          {intl.formatMessage({ id: 'View' })}
                        </a>
                        {Boolean(
                          Object.keys(makeUpExamination || {}).length,
                        ) && (
                          <Button
                            type="link"
                            loading={btnLoading}
                            onClick={() => showConfirm('again')}
                          >
                            {intl.formatMessage({ id: 'Retake Test' })}
                          </Button>
                        )}
                        <div>
                          {intl.formatMessage({ id: 'Updated Time' })}:{' '}
                          {moment(updateTime).format('MM/DD/YYYY')}
                        </div>
                      </Space>
                    )}
                    {status === 'FAILED' && (
                      <Space>
                        <a onClick={getExamResult}>
                          {intl.formatMessage({ id: 'View' })}
                        </a>
                        {Boolean(Object.keys(makeUpExamination || {}).length) &&
                          times > 0 ? (
                            <Button
                              type="link"
                              loading={btnLoading}
                              onClick={() => showConfirm('again')}
                            >
                              {intl.formatMessage({ id: 'Retake Test' })}
                            </Button>
                          ) : (
                            <div>{intl.formatMessage({ id: 'Waiting for Retest' })}</div>
                          )}
                        <div>
                          {intl.formatMessage({ id: 'Updated Time' })}:{' '}
                          {moment(updateTime).format('MM/DD/YYYY')}
                        </div>
                      </Space>
                    )}
                    {status === 'PASSED' && (
                      <Space>
                        <div>{intl.formatMessage({ id: 'Passed' })}</div>
                        <a onClick={getExamResult}>
                          {intl.formatMessage({ id: 'View Result' })}
                        </a>
                        <div>
                          {intl.formatMessage({ id: 'Updated Time' })}:{' '}
                          {moment(updateTime).format('MM/DD/YYYY')}
                        </div>
                      </Space>
                    )}
                  </>
                ) : (
                  <>
                    {(status === 'DOING' ||
                      (status === 'NOT_STARTED' && type === 'FIRST')) && (
                      <div>{intl.formatMessage({ id: 'DOING' })}</div>
                    )}
                    {(status === 'FAILED' ||
                      (status === 'NOT_STARTED' && type === 'MAKEUP')) && (
                      <Space>
                        <div>{intl.formatMessage({ id: 'Fail' })}</div>
                        {roleType === 'admin' && (
                          <a onClick={onArrangeRetest}>
                            {intl.formatMessage({ id: 'Arrange Retest' })}
                          </a>
                        )}
                        <div>
                          {intl.formatMessage({ id: 'Updated Time' })}:{' '}
                          {moment(updateTime).format('MM/DD/YYYY')}
                        </div>
                      </Space>
                    )}
                    {status === 'PASSED' && (
                      <Space>
                        <div>{intl.formatMessage({ id: 'Passed' })}</div>
                        <a onClick={getExamResult}>
                          {intl.formatMessage({ id: 'View Result' })}
                        </a>
                        <div>
                          {intl.formatMessage({ id: 'Updated Time' })}:{' '}
                          {moment(updateTime).format('MM/DD/YYYY')}
                        </div>
                      </Space>
                    )}
                  </>
                )}
              </Space>
            </div>
          </div>
        </Timeline.Item>
      </Timeline>
      {visible && <ArrangeRetestModal {...arrangeRetestModalProps} />}
    </>
  );
};

export default TheoreticalTest;
