import { showAttributeDOM, formatSubmitValue, getFeedbackValue } from 'utils/feedback'
import { Form, Radio, Button, TimePicker, Input } from 'antd'
import moment from 'moment';
import { useIntl } from 'umi'

const FormItem = Form.Item
const { TextArea } = Input;

const FeedbackForm = ({
  id,
  loading,
  loadingKey,
  feedbackProperties = [],
  feedbacks,
  onOk
}) => {
  const intl = useIntl()

  const onClickSubmit = (values) => {
    let submitData = Object.keys(values).map(keyItem => {
      const currentData = feedbackProperties.find(item => item.id === keyItem)
      return {
        programFeedbackPropertyId: keyItem,
        value: formatSubmitValue(currentData.type, values[keyItem], currentData),
      }
    })
    submitData = submitData.filter(item => item.value != null);
    onOk(submitData)
  }
  const feedArr = feedbacks && feedbacks[0] ? feedbacks[0].feedbackProperties : []
  const getFeedbackItem = feedbackItem => {
    const { id, displayName, isRequired, extraProperties, type } = feedbackItem
    const initialValue = feedArr.find(item => item.programFeedbackPropertyId === id)?.value
    const value = initialValue ? getFeedbackValue(type, initialValue) : null
    return (
      <FormItem
        label={displayName}
        key={id}
        name={id}
        initialValue={value}
        rules={[
          {
            required: isRequired,
            message: `${displayName}${intl.formatMessage({ id: 'mandatoryField' })}`
          },
        ]}
      >
        {showAttributeDOM(type, extraProperties)}
      </FormItem>
    )
  }


  return (
    <div style={{ padding: '24px 14px' }}>
      <Form
        layout="horizontal"
        onFinish={onClickSubmit}
        layout="vertical"
      >
        {feedbackProperties.map(item => getFeedbackItem(item))}
        <Button
          type="primary"
          htmlType="submit"
          loading={loading && loadingKey.indexOf(id) > -1}
        >
          {intl.formatMessage({ id: "submitOther" })}
        </Button>
      </Form>
    </div>
  )
}

export default FeedbackForm
