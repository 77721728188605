import React from 'react'
import { Modal, Button } from 'antd'
import { useIntl } from 'umi'
import styles from './index.less'

const MyModal = ({ title, visible, children, loading, onOk, onCancel, onOkText, width }) => {
    const intl = useIntl()
    return (
        <Modal
            title={title}
            open={visible}
            footer={null}
            width={width}
            onCancel={onCancel}
        >
            {children}
            <div className={styles.footer}>
                <Button className={styles.footerCancel} onClick={onCancel}>{intl.formatMessage({ id: 'cancel' })}</Button>
                <Button type='primary' loading={loading} onClick={onOk}>{onOkText || intl.formatMessage({ id: 'submit' })}</Button>
            </div>
        </Modal>
    )
}

export default MyModal