import React, { useState } from 'react';
import { useIntl } from 'umi';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';

import { PostTrainingAppointmentFeedback } from 'services/trainingSystem';

const FeedBackModal = ({
  visible = false,
  currentTrainItem,
  closeModal = () => {},
  reloadPage = () => {},
}) => {
  const intl = useIntl();

  const { id, feedback, trainerVisible } = currentTrainItem || {};

  const [loading, setLoading] = useState(false);

  const contentMenu = {
    Properties: [
      {
        EditorType: 'RichEditorPro',
        ShowTitle: intl.formatMessage({ id: 'FeedbackContent' }),
        FormKey: 'feedback',
        AdditionalData: null,
        Value: feedback,
        Setting: {
          Props: {
            placeholder: intl.formatMessage({ id: 'Please record' }),
          },
        },
        Description: null,
      },
      {
        EditorType: 'Bool',
        ShowTitle: intl.formatMessage({ id: 'Visible to trainers' }),
        FormKey: 'trainerVisible',
        AdditionalData: null,
        Value: trainerVisible,
        Setting: {},
        Description: null,
      },
    ],
  };

  const props = {
    title: intl.formatMessage({ id: 'Feedback records' }),
    visible,
    content: contentMenu,
    loading,
    onSubmitText: intl.formatMessage({ id: 'submitOther' }),
    onCancel() {
      closeModal();
    },
    async onOk(value) {
      setLoading(true);

      const res = await PostTrainingAppointmentFeedback({
        id,
        ...value,
      });

      if (res.response.ok) {
        reloadPage();
      }

      closeModal();
      setLoading(false);
    },
  };

  return <MagicFormModal {...props} />;
};

export default FeedBackModal;
