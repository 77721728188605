import getRequest from 'utils/request';

const GetAssessmentTimesSettingRecordsApi =
  '/api/rocketAdmin/assessmentTimesSettingRecords';
const GetAssessmentTimesSettingPrepareApi =
  '/api/rocketAdmin/assessmentTimesSettingRecords/prepare';
const GetUserAssessmentTimesSettingRecordsApi =
  '/api/rocketAdmin/assessmentTimesSettingRecords/userAssessmentTimeSetting/{userId}';

const PostClassHoursSettingRecordsApi = '/api/rocketAdmin/classHoursSettingRecords';
const GetClassHoursSettingRecordsListApi =
  '/api/rocketAdmin/classHoursSettingRecords/recordsList';
const GetClassHoursSettingRecordsDetailApi =
  '/api/rocketAdmin/classHoursSettingRecords/records/{userId}';

export async function GetUserAssessmentTimesSettingRecords(data) {
  return getRequest()(GetUserAssessmentTimesSettingRecordsApi.replace('{userId}', data.userId), {
    method: 'GET',
    params: data,
  });
}

export async function PostClassHoursSettingRecords(data) {
  return getRequest()(PostClassHoursSettingRecordsApi, {
    method: 'POST',
    data,
  });
}

export async function GetClassHoursSettingRecordsList(data) {
  return getRequest()(GetClassHoursSettingRecordsListApi, {
    method: 'GET',
    params: data,
  });
}

export async function GetClassHoursSettingRecordsDetail(data) {
  return getRequest()(
    GetClassHoursSettingRecordsDetailApi.replace('{userId}', data.userId),
    {
      method: 'GET',
      params: data,
    },
  );
}

export async function GetAssessmentTimesSettingRecords(data) {
  return getRequest()(GetAssessmentTimesSettingRecordsApi, {
    method: 'GET',
    params: data,
  });
}

export async function GetAssessmentTimesSettingPrepare(data) {
  return getRequest()(GetAssessmentTimesSettingPrepareApi, {
    method: 'GET',
    params: data,
  });
}

export async function PostAssessmentTimesSettingRecords(data) {
  return getRequest()(GetAssessmentTimesSettingRecordsApi, {
    method: 'POST',
    data,
  });
}
