import getRequest from 'utils/request'

const AddToCategory = '/api/rocketAdmin/courseCategory/course'
const PutCategory = '/api/rocketAdmin/courseCategory/course'
const EditCategoryOrder = '/api/rocketAdmin/courseCategory/move/{categoryId}'
const EditCourseOrder = '/api/rocketAdmin/courseCategory/courseMove'
const DeleteCourse = '/api/rocketAdmin/courseCategory/course'
const PutPublishStatus = '/api/rocketAdmin/courseCategory/contentStatus'

export async function AddCategory(data) {
  return getRequest()(AddToCategory, {
    method: 'POST',
    data,
  })
}

export async function UpdateCategory(data) {
  return getRequest()(PutCategory, {
    method: 'PUT',
    data,
  })
}

export async function EditCategorySortOrder(categoryId, newSortOrder) {
  return getRequest()(EditCategoryOrder.replace('{categoryId}', categoryId), {
    method: 'PUT',
    params: { newSortOrder },
  })
}

export async function EditCourseSortOrder(data) {
  return getRequest()(EditCourseOrder, {
    method: 'PUT',
    params: data,
  })
}

export async function PublishStatus(data) {
  return getRequest()(PutPublishStatus, {
    method: 'PUT',
    data,
  })
}

export async function CourseDelete(data) {
  return getRequest()(DeleteCourse, {
    method: 'DELETE',
    data,
  })
}
