import React, { useState, useEffect } from 'react';
import { Space, Timeline, Popover, Spin } from 'antd';
import { useIntl } from 'umi';
import moment from 'moment';

import {
  GetTrainingAppointment,
  PostTrainingAppointmentComplete,
} from 'services/trainingSystem';

import SpinCom from './SpinCom';
import MakeAppointmentModal from './Modals/MakeAppointmentModal';
import FeedBackModal from './Modals/FeedBackModal';

import styles from '../index.less';

const PracticalTraining = ({
  progressIndex,
  roleType,
  trainerTrainingModuleId,
  userInfo,
  onChangeAllowReloadTab,
  allowReloadProgress,
}) => {
  const intl = useIntl();

  const { id: trainerId } = userInfo || {};

  const [loading, setLoading] = useState(false);
  const [practicalList, setPracticalList] = useState([]);
  const [currentTrainItem, setCurrentTrainItem] = useState({});
  const [visible, setVisible] = useState({
    makeAppointmentModalVisible: false,
    feedbackModalVisible: false,
  });

  const initData = async () => {
    setLoading(true);
    const params = {
      TrainerTrainingModuleId: trainerTrainingModuleId,
      TrainerId: trainerId,
      SkipCount: 0,
      MaxResultCount: 99,
    };

    const res = await GetTrainingAppointment(params);

    if (res.response.ok) {
      const { items = [] } = res.data || {};
      setPracticalList(items);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (trainerTrainingModuleId) {
      initData();
    }
  }, [trainerTrainingModuleId, allowReloadProgress]);

  const onMakeAppointment = () => {
    setVisible({
      ...visible,
      makeAppointmentModalVisible: true,
    });
  };

  const makeAppointmentModalProps = {
    userInfo,
    visible: visible.makeAppointmentModalVisible,
    trainerTrainingModuleId,
    closeModal: () => {
      setVisible({
        ...visible,
        makeAppointmentModalVisible: false,
      });
    },
    reloadPage: () => {
      initData();
      onChangeAllowReloadTab && onChangeAllowReloadTab();
    },
  };

  const feedBackModalProps = {
    visible: visible.feedbackModalVisible,
    currentTrainItem,
    closeModal: () => {
      setVisible({
        ...visible,
        feedbackModalVisible: false,
      });
    },
    reloadPage: () => {
      initData();
      onChangeAllowReloadTab && onChangeAllowReloadTab();
    },
  };

  const renderEmptyCom = () => {
    return (
      <Timeline>
        <Timeline.Item>
          <div className={styles.practicalTrainingItem}>
            <div className={styles.practicalTrainingItemTitle}>
              2.1 {intl.formatMessage({ id: 'Training' })}
            </div>
            <div className={styles.practicalTrainingItemContent}>
              <Space>
                <div>
                  {intl.formatMessage({ id: 'Waiting for Appointment' })}
                </div>
                {roleType === 'admin' && (
                  <a onClick={onMakeAppointment}>
                    {intl.formatMessage({ id: 'Make an Appointment' })}
                  </a>
                )}
              </Space>
            </div>
          </div>
        </Timeline.Item>
      </Timeline>
    );
  };

  const onComplete = async (item) => {
    const { id, feedback } = item || {};

    setLoading(true);
    const res = await PostTrainingAppointmentComplete({ id });

    if (res.response.ok) {
      initData();
      onChangeAllowReloadTab && onChangeAllowReloadTab();

      if (!feedback) {
        onFeedback(item);
        // return;
      }
    } else {
      setLoading(false);
    }
  };

  const onFeedback = async (item) => {
    setCurrentTrainItem(item);
    setVisible({
      ...visible,
      feedbackModalVisible: true,
    });
  };

  const getContent = (item) => {
    const { note, duration, startTime, supervisor } = item || {};
    const { name } = supervisor || {};
    return (
      <div style={{ width: '300px' }}>
        <Space direction="vertical">
          <Space>
            <b>{intl.formatMessage({ id: 'Start Time' })}: </b>
            <div>{moment(startTime).format('MM/DD/YYYY HH:mm')}</div>
          </Space>
          <Space>
            <b>{intl.formatMessage({ id: 'Duration' })}: </b>
            <div>{intl.formatMessage({ id: 'hrs' }, { duration })}</div>
          </Space>
          <Space>
            <b>{intl.formatMessage({ id: 'Trainer' })}: </b>
            <div>{name}</div>
          </Space>
          <Space>
            <b>{intl.formatMessage({ id: 'Note' })}: </b>
            <div>{note}</div>
          </Space>
        </Space>
      </div>
    );
  };

  const getFeedBackContent = (item) => {
    const { trainerVisible, feedback } = item || {};
    return (
      <div style={{ width: '300px' }}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <div dangerouslySetInnerHTML={{ __html: feedback }}></div>
          {roleType === 'admin' && (
            <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                {intl.formatMessage({ id: 'trainer' })}
                {intl.formatMessage({
                  id: trainerVisible ? 'trainerSee' : 'trainerNotSee',
                })}
              </div>
              <a onClick={() => onFeedback(item)}>
                {intl.formatMessage({ id: 'edit' })}
              </a>
            </Space>
          )}
        </Space>
      </div>
    );
  };

  if (loading) {
    return <SpinCom />;
  }

  return (
    <>
      {practicalList.length === 0 ? (
        renderEmptyCom()
      ) : (
        <Timeline>
          {practicalList.map((item, index) => {
            const {
              id,
              title,
              complete,
              creationTime,
              completeTime,
              feedback,
              trainerVisible,
              startTime,
            } = item || {};
            return (
              <Timeline.Item color={complete ? 'gray' : 'blue'} key={id}>
                <div className={styles.practicalTrainingItem}>
                  <div className={styles.practicalTrainingItemTitle}>
                    {progressIndex}.{index + 1}{' '}
                    {intl.formatMessage({ id: 'Training' })}: {title}
                  </div>
                  <div className={styles.practicalTrainingItemContent}>
                    <Space>
                      {complete ? (
                        <div>{intl.formatMessage({ id: 'completed' })}</div>
                      ) : (
                        <div>
                          {intl.formatMessage({ id: 'Appointment Time' })}:{' '}
                          {moment(startTime).format('MM/DD/YYYY HH:mm')}
                        </div>
                      )}
                      <Popover
                        content={getContent(item)}
                        title={
                          <b>
                            {intl.formatMessage({
                              id: 'Appointment Information',
                            })}
                          </b>
                        }
                      >
                        {complete ? (
                          <div
                            style={{
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                          >
                            {intl.formatMessage({ id: 'more' })}
                          </div>
                        ) : (
                          <a>{intl.formatMessage({ id: 'more' })}</a>
                        )}
                      </Popover>

                      {feedback
                        ? (roleType === 'admin' || trainerVisible) && (
                            <Popover
                              content={getFeedBackContent(item)}
                              title={
                                <b>{intl.formatMessage({ id: 'feedback' })}</b>
                              }
                            >
                              {complete ? (
                                <div
                                  style={{
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                  }}
                                >
                                  {intl.formatMessage({ id: 'feedback' })}
                                </div>
                              ) : (
                                <a>{intl.formatMessage({ id: 'feedback' })}</a>
                              )}
                            </Popover>
                          )
                        : roleType === 'admin' && (
                            <>
                              {complete ? (
                                <div
                                  onClick={() => onFeedback(item)}
                                  style={{
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                  }}
                                >
                                  {intl.formatMessage({ id: 'feedback' })}
                                </div>
                              ) : (
                                <a onClick={() => onFeedback(item)}>
                                  {intl.formatMessage({ id: 'feedback' })}
                                </a>
                              )}
                            </>
                          )}
                      {!complete && roleType === 'admin' && (
                        <a onClick={() => onComplete(item)}>
                          {intl.formatMessage({ id: 'Complete' })}
                        </a>
                      )}
                    </Space>
                  </div>
                </div>
              </Timeline.Item>
            );
          })}
          {roleType === 'admin' &&
            practicalList[practicalList.length - 1].complete && (
              <Timeline.Item>
                <div className={styles.practicalTrainingItem}>
                  <div className={styles.practicalTrainingItemTitle}>
                    {progressIndex}.{practicalList.length + 1}{' '}
                    {intl.formatMessage({ id: 'Training' })}
                  </div>
                  <div className={styles.practicalTrainingItemContent}>
                    <Space>
                      <div>
                        {intl.formatMessage({ id: 'Waiting for Appointment' })}
                      </div>
                      <a onClick={onMakeAppointment}>
                        {intl.formatMessage({ id: 'Make an Appointment' })}
                      </a>
                    </Space>
                  </div>
                </div>
              </Timeline.Item>
            )}
        </Timeline>
      )}
      {visible.makeAppointmentModalVisible && (
        <MakeAppointmentModal {...makeAppointmentModalProps} />
      )}
      {visible.feedbackModalVisible && (
        <FeedBackModal {...feedBackModalProps} />
      )}
    </>
  );
};

export default PracticalTraining;
