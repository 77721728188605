import React, { useState, useEffect } from 'react';
import { Modal, Radio, Spin, Button, Space } from 'antd';
import { useIntl } from 'umi';
import config from 'utils/config';
import moment from 'moment';

import {
  PostStartClass,
  GetFASPlan,
  GetBeforeStartMusic,
} from 'services/userManager';
import { history } from 'umi'
import { getToken, getTenantId, getUserTenantId } from 'cognitiveleap-core-us/utils/auth';
import { fixedZero, showErrorMsg } from 'cognitiveleap-core-us/utils/utils';

import styles from './index.less';

const { mobileUrl } = config;

const GenerateMusic = ({
  subjectInfo,
  visible,
  type = 'add',
  onClose = () => {},
  resetType = () => {},
  reloadPage = () => {},
  getFASReport = () => {},
}) => {
  const intl = useIntl()

  const { name, id: userId, stage } = subjectInfo;

  const getStageMusic = () => {
    switch (stage) {
      case '学前':
        return 'CMSMO';
      case '学龄':
        return 'CMSMO';
      case '黄金时代':
        return 'OpiOpii';
      default:
        return 'CMSMO'
    }
  }

  const [hfsData, setHfsData] = useState(null);
  const [editMusic, setEditMusic] = useState({});
  const [loading, setLoading] = useState(false);
  const [spining, setSpining] = useState(false);
  const [typeRadio, setTypeRadio] = useState(null);
  const [musicType, setMusicType] = useState(getStageMusic())
  const [firstVisible, setFirstVisible] = useState(null); // 默认visible是从父组件取得，这个是为了编辑可以回到上一步
  const [checkVisible, setCheckVisible] = useState(false);

  let { code, count, order } = editMusic;
  code = code && code.toUpperCase();
  count = count && fixedZero(count);

  const getData = async () => {
    setSpining(true);
    const res = await GetFASPlan({ userId });
    if (res.response.ok) {
      setTypeRadio(res.data !== '' ? 'Fas' : 'Other');
      if (res.data !== '') {
        setHfsData(res.data);
      }
    }
    setSpining(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const startClass = async (oldOrder = null) => {

    const musicType = getMusicType(typeRadio, stage)

    const props = {
      userId,
      edit: type === 'edit',
      musicType,
      // isUseFas: typeRadio === 'FAS',
      // isUseCmAndSmo: typeRadio === 'OTHER' && stage === '学前',
      // isUseSmg: typeRadio === 'OTHER' && stage !== '学前',
      order: oldOrder || 1,
    };
    const res = await PostStartClass(props);
    if (res.response.ok) {
      checkVisible && setCheckVisible(false);
      reloadPage();
    } else {
      showErrorMsg(res, intl);
    }
  };

  const getMusicType = (typeRadio, stage)=>{
    if(typeRadio === 'Fas') return 'Fas'
    else{
      if (stage === '学前') return 'CmSmo'
      else if (stage === '黄金时代') return 'OpiOpii'
      else return 'CmSmo'
    }
  }

  const getFasData = () => {
    const { tenantId, id, finishTime, needPay, status, assessment } = hfsData
    const { title } = assessment
    return status === 'ReportedReady' && needPay ?
      <span className={styles.noFasGet}>
        {intl.formatMessage({ id: 'FASReportObtained' })}
        <div
          className={styles.fasToGet}
          onClick={() => {
            onClose()
            resetType()
            getFASReport()
          }}
        >
          {intl.formatMessage({ id: 'obtainReport' })}＞
        </div>
      </span>
    : getTenantId() === tenantId ? (
      <span
        className={styles.lookFas}
        onClick={() => {
          const reportUrl = `${mobileUrl}/m/rocketReport?to=${id}&t=${getToken()}&h=${getTenantId()}&ts=${getUserTenantId()}`;
          window.open(reportUrl, '_blank');
        }}
      >
        {intl.formatMessage({ id: 'viewFASReports' })}{' '}
        {moment(finishTime).format('YYYY-MM-DD HH:mm:ss')}
      </span>
    ) : (
      <span className={styles.nolookFas}>
        {title}&nbsp;{moment(finishTime).format('YYYY/MM/DD HH:mm:ss')}
      </span>
    )
  }

  return (
    <div>
      {/* 生成优脑音乐 */}
      <Modal
        title={intl.formatMessage({ id: type === 'add' ? 'generationHFSMusic' : 'editHFSMusic' })}
        open={firstVisible || visible}
        width={700}
        confirmLoading={loading}
        okText={intl.formatMessage({ id: type === 'add' ? 'submit' : 'next' })}
        onOk={async () => {
          setLoading(true);
          firstVisible !== null && setFirstVisible(false);
          const musicType = getMusicType(typeRadio, stage)
          if (type === 'add') {
            startClass();
            onClose();
          } else {
            // 请求音乐信息
            const props = {
              userId,
              musicType,
              // isUseFas: typeRadio === 'FAS',
              // isUseCmAndSmo: typeRadio === 'OTHER' && stage === '学前',
              // isUseSmg: typeRadio === 'OTHER' && stage !== '学前',
            };
            const res = await GetBeforeStartMusic(props);
            if (res.response.ok) {
              setEditMusic(res.data);
            }
            onClose();
            setCheckVisible(true);
          }

          setLoading(false);
        }}
        onCancel={() => {
          resetType();
          onClose();
        }}
      >
        <Spin spinning={spining}>
          <div className={styles.panel}>
            <div className={styles.childInfo}>
              {intl.formatMessage({ id: 'subject' })}：<div className={styles.name}>{name}</div>
            </div>
            <div className={styles.dataInfo}>
              {intl.formatMessage({ id: 'archives' })}：<div className={styles.name}>{intl.formatMessage({ id: 'archivesCompleted' })}</div>
            </div>
            <div className={styles.type}>
              {intl.formatMessage({ id: 'generatioType' })}：
              <Radio.Group
                onChange={(e) => {
                  const data = e.target.value;
                  setTypeRadio(data);
                }}
                value={typeRadio}
              >
                <Radio disabled={hfsData === null} value={'Fas'}>
                  {intl.formatMessage({ id: 'basedOnHFSResults' })}
                </Radio>
                <Radio value={'Other'}>{intl.formatMessage({ id: 'otherType' })}</Radio>
              </Radio.Group>
            </div>
            <div className={styles.typeDes}>
              {hfsData === null ? (
                <span className={styles.noFas}>
                  {intl.formatMessage({ id: 'noHFSDes' })}
                </span>
              ) : getFasData()}
            </div>
            {typeRadio === 'Other' && (
              <div className={styles.musicType}>
                {intl.formatMessage({ id: 'outstandingStudentProgram' })}
                <Radio.Group
                  onChange={(e) => {
                    const data = e.target.value;
                    setMusicType(data);
                  }}
                  value={musicType}
                >
                  <Radio value={getStageMusic()}>
                    {intl.formatMessage({ id: `${getStageMusic()}` })}
                  </Radio>
                </Radio.Group>
              </div>
            )}
          </div>
        </Spin>
      </Modal>

      {/* 编辑优脑音乐第二步 */}
      <Modal
        title={intl.formatMessage({ id: 'editHFSMusic' })}
        open={checkVisible}
        width={600}
        footer={
          <Space>
            <Button
              onClick={() => {
                setCheckVisible(false);
                setFirstVisible(true);
              }}
            >
              {intl.formatMessage({ id: 'previous' })}
            </Button>
            {order > 1 && (
              <Button
                type="primary"
                ghost
                onClick={() => {
                  startClass(order);
                }}
              >
                {intl.formatMessage({ id: 'continuePreviousProgress' })}
              </Button>
            )}
            <Button
              type="primary"
              onClick={() => {
                startClass();
              }}
            >
              {intl.formatMessage({ id: order > 1 ? 'startFromFirst' : 'submit' })}
            </Button>
          </Space>
        }
        onOk={() => {
          resetType();
          setCheckVisible(false);
        }}
        onCancel={() => {
          setCheckVisible(false);
          setFirstVisible(true);
        }}
      >
        <div className={styles.editPanel}>
          {order > 1
            ? intl.formatMessage({ id: 'modulePreviouslyUpload' }, { newMusic: code + count , name: name, oldMusic: code + fixedZero(order) })
            : intl.formatMessage({ id: 'editMusicForName' }, { music: code + count, child: name })}
        </div>
      </Modal>
    </div>
  );
};

export default GenerateMusic;
