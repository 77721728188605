import React, { useState, useEffect } from 'react';

import { Card, Spin, Space } from 'antd';
import { useIntl } from 'umi';

import {
  GetLatestPreparation,
  PostPreparationTrainer,
} from 'services/trainingSystem';
import styles from '../index.less';

const ApplyForm = ({
  userInfo,
  roleType,
  showTrainingApplication,
  applyDrawerVisible,
}) => {
  const intl = useIntl();

  const { id: trainerId } = userInfo || {};

  const [loading, setLoading] = useState(false);
  const [applyInfo, setApplyInfo] = useState({});

  const { auditResult } = applyInfo || {};

  const initData = async () => {
    setLoading(true);
    const res = await GetLatestPreparation({ trainerId });

    if (res.response.ok) {
      setApplyInfo(res.data || {});
    } else {
      const {
        data: { error },
      } = res;
      message.error(error.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, [applyDrawerVisible]);

  const onApplyAgain = async () => {
    setLoading(true);

    const { tenantId, preparationTrainerId } = applyInfo || {};
    const res = await PostPreparationTrainer({
      tenantId,
      preparationTrainerId,
    });

    if (res.response.ok) {
      initData();
    } else {
      const {
        data: { error },
      } = res;
      message.error(error.message);
    }

    setLoading(false);
  };

  return (
    <Card
      title={
        <div className={styles.cardTitle}>
          <div className={styles.cardTitleIcon}></div>
          <div className={styles.cardTitleText}>
            {intl.formatMessage({ id: 'CertificationProcess' })}
          </div>
        </div>
      }
      bordered={false}
    >
      <Spin spinning={loading}>
        <div className={styles.appFormContent}>
          {Object.keys(applyInfo).length > 0 ? (
            <div>
              {auditResult === 'UnReviewed' && (
                <Space direction="vertical" style={{ textAlign: 'center' }}>
                  <div>
                    {intl.formatMessage({ id: 'Application pending approval' })}
                  </div>
                  <a onClick={showTrainingApplication}>
                    {intl.formatMessage({ id: 'View Trainer Info Form' })}
                  </a>
                </Space>
              )}
              {auditResult === 'NotPassed' && (
                <Space direction="vertical" style={{ textAlign: 'center' }}>
                  <div>
                    {intl.formatMessage({
                      id: 'Training application not approved',
                    })}
                  </div>
                  <Space>
                    {roleType == 'personal' && (
                      <span>
                        {intl.formatMessage({ id: 'Please' })}
                        <a onClick={onApplyAgain}>
                          {intl.formatMessage({ id: 'Apply Again' })}
                        </a>
                      </span>
                    )}
                    <a onClick={showTrainingApplication}>
                      {intl.formatMessage({ id: 'View Trainer Info Form' })}
                    </a>
                  </Space>
                </Space>
              )}
              {auditResult === 'Passed' && (
                <Space direction="vertical" style={{ textAlign: 'center' }}>
                  <div>
                    {intl.formatMessage({
                      id: 'Training application has been approved',
                    })}
                  </div>
                  <a onClick={showTrainingApplication}>
                    {intl.formatMessage({ id: 'View Trainer Info Form' })}
                  </a>
                </Space>
              )}
            </div>
          ) : (
            <Space direction="vertical" style={{ textAlign: 'center' }}>
              <div>
                {intl.formatMessage({ id: 'No training module assigned' })}
              </div>
              {roleType == 'personal' && (
                <div>
                  {intl.formatMessage({ id: 'Please' })}
                  <a onClick={() => showTrainingApplication('new')}>
                    {intl.formatMessage({ id: 'Apply Now' })}
                  </a>
                </div>
              )}
            </Space>
          )}
        </div>
      </Spin>
    </Card>
  );
};

export default ApplyForm;
