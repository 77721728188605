import getRequest from 'utils/request';

const GetDailyTopicApi = '/api/pdt/dailyTopic';
const PutDailyTopicApi = '/api/pdt/dailyTopic/{id}';
const PutContentStatusApi = '/api/pdt/dailyTopic/contentStatus';
const GetTagListApi = '/api/pdt/aDHDTag';
const PutTagApi = '/api/pdt/aDHDTag/{id}';
const GetWeekThemeApi = '/api/pdt/weekTheme';
const PutWeekThemeApi = '/api/pdt/weekTheme/{id}';
const PutWeekThemeStatusApi = '/api/pdt/weekTheme/contentStatus';
const PutWeekThemeOrderApi = '/api/pdt/weekTheme/move/{weekThemeId}';
const PostManageWeekThemeDaysApi =
  '/api/pdt/weekTheme/manageWeekOfDays';
const GetWeekOfDaysApi = '/api/pdt/weekTheme/weekOfDays';
const PutWeekOfDaysOrderApi = '/api/pdt/weekTheme/weekOfDaysMove';
const PutTemplateApi = '/api/pdt/template/{id}';
const PostTemplateStatusApi = '/api/pdt/template/contentStatus';
const PostManageTemplateApi = '/api/pdt/template/manageWeeks';
const GetTemplateOfWeeksApi = '/api/pdt/template/templateOfWeeks';
const PutTemplateOfWeeksMoveApi =
  '/api/pdt/template/templateOfWeeksMove';
const GetTemplateOfCentersApi =
  '/api/pdt/template/templateOfCenterGranted';
const GetGrantedCentersApi = '/api/pdt/template/grantedCenters';
const PostGrantCenterApi = '/api/pdt/template/grantCenters';
const PostGrantTemplatesApi = '/api/pdt/template/grantTemplates';
const DeleteTemplateOfCenterApi = '/api/pdt/template/templateOfCenter';
const GetTemplateApi = '/api/pdt/template';
const GetThoughtsApi = '/api/pdt/dailyTopic/thoughts';
const PostThoughtsApi = '/api/pdt/dailyTopic/thought';

export async function GetThoughts(data) {
  return getRequest()(GetThoughtsApi, {
    method: 'GET',
    params: data,
  });
}

export async function PostThoughts(data) {
  return getRequest()(PostThoughtsApi, {
    method: 'POST',
    data,
  });
}

export async function GetGrantedCenters(data) {
  return getRequest()(GetGrantedCentersApi, {
    method: 'GET',
    params: data,
  });
}

export async function GetTemplateOfCenters(data) {
  return getRequest()(GetTemplateOfCentersApi, {
    method: 'GET',
    params: data,
  });
}

export async function PostGrantCenter(data) {
  return getRequest()(PostGrantCenterApi, {
    method: 'POST',
    data,
  });
}

export async function PostGrantTemplates(data) {
  return getRequest()(PostGrantTemplatesApi, {
    method: 'POST',
    data,
  });
}

export async function DeleteTemplateOfCenter(data) {
  return getRequest()(DeleteTemplateOfCenterApi, {
    method: 'DELETE',
    params: data,
  });
}

export async function PutTemplateOfWeeksMove(data) {
  return getRequest()(PutTemplateOfWeeksMoveApi, {
    method: 'PUT',
    params: data,
  });
}

export async function GetTemplateOfWeeks(data) {
  return getRequest()(GetTemplateOfWeeksApi, {
    method: 'GET',
    params: data,
  });
}

export async function DeleteTemplateOfWeeks(data) {
  return getRequest()(GetTemplateOfWeeksApi, {
    method: 'DELETE',
    params: data,
  });
}

export async function GetTemplate(data) {
  return getRequest()(GetTemplateApi, {
    method: 'GET',
    params: data,
  });
}

export async function PostTemplate(data) {
  return getRequest()(GetTemplateApi, {
    method: 'POST',
    data,
  });
}

export async function PutTemplate(data) {
  return getRequest()(PutTemplateApi.replace('{id}', data.id), {
    method: 'PUT',
    data,
  });
}

export async function DeleteTemplate(data) {
  return getRequest()(PutTemplateApi.replace('{id}', data.id), {
    method: 'DELETE',
    params: data,
  });
}

export async function GetTemplateDetail(data) {
  return getRequest()(PutTemplateApi.replace('{id}', data.id), {
    method: 'GET',
    params: data,
  });
}

export async function PostTemplateStatus(data) {
  return getRequest()(PostTemplateStatusApi, {
    method: 'PUT',
    data,
  });
}

export async function GetManageTemplate(data) {
  return getRequest()(PostManageTemplateApi, {
    method: 'GET',
    params: data,
  });
}

export async function PostManageTemplate(data) {
  return getRequest()(PostManageTemplateApi, {
    method: 'POST',
    data,
  });
}

export async function GetWeekTheme(data) {
  return getRequest()(GetWeekThemeApi, {
    method: 'GET',
    params: data,
  });
}

export async function PostWeekTheme(data) {
  return getRequest()(GetWeekThemeApi, {
    method: 'POST',
    data,
  });
}

export async function PutWeekTheme(data) {
  return getRequest()(PutWeekThemeApi.replace('{id}', data.id), {
    method: 'PUT',
    data,
  });
}

export async function DeleteWeekTheme(data) {
  return getRequest()(PutWeekThemeApi.replace('{id}', data.id), {
    method: 'DELETE',
    data,
  });
}

export async function GetWeekThemeDetail(data) {
  return getRequest()(PutWeekThemeApi.replace('{id}', data.id), {
    method: 'GET',
    params: data,
  });
}

export async function PutWeekThemeStatus(data) {
  return getRequest()(PutWeekThemeStatusApi, {
    method: 'PUT',
    data,
  });
}

export async function PutWeekThemeOrder(data) {
  return getRequest()(
    PutWeekThemeOrderApi.replace('{weekThemeId}', data.weekThemeId),
    {
      method: 'PUT',
      params: data,
    },
  );
}

export async function PostManageWeekThemeDays(data) {
  return getRequest()(PostManageWeekThemeDaysApi, {
    method: 'POST',
    data,
  });
}

export async function GetWeekOfDays(data) {
  return getRequest()(GetWeekOfDaysApi, {
    method: 'GET',
    params: data,
  });
}

export async function DeleteWeekOfDays(data) {
  return getRequest()(GetWeekOfDaysApi, {
    method: 'DELETE',
    params: data,
  });
}

export async function PutWeekOfDaysOrder(data) {
  return getRequest()(PutWeekOfDaysOrderApi, {
    method: 'PUT',
    params: data,
  });
}

export async function DeleteTag(data) {
  return getRequest()(PutTagApi.replace('{id}', data.id), {
    method: 'DELETE',
    data,
  });
}

export async function PutTag(data) {
  return getRequest()(PutTagApi.replace('{id}', data.id), {
    method: 'PUT',
    data,
  });
}

export async function GetTagDetail(data) {
  return getRequest()(PutTagApi.replace('{id}', data.id), {
    method: 'GET',
    params: data,
  });
}

export async function PostTagList(data) {
  return getRequest()(GetTagListApi, {
    method: 'POST',
    data,
  });
}

export async function GetTagList(data) {
  return getRequest()(GetTagListApi, {
    method: 'GET',
    params: data,
  });
}

export async function GetDailyTopic(data) {
  return getRequest()(GetDailyTopicApi, {
    method: 'GET',
    params: data,
  });
}

export async function PostDailyTopic(data) {
  return getRequest()(GetDailyTopicApi, {
    method: 'POST',
    data,
  });
}

export async function GetDailyTopicDetail(data) {
  return getRequest()(PutDailyTopicApi.replace('{id}', data.id), {
    method: 'GET',
    params: data,
  });
}

export async function PutDailyTopic(data) {
  return getRequest()(PutDailyTopicApi.replace('{id}', data.id), {
    method: 'PUT',
    data,
  });
}

export async function DeleteDailyTopic(data) {
  return getRequest()(PutDailyTopicApi.replace('{id}', data.id), {
    method: 'DELETE',
    data,
  });
}

export async function PutContentStatus(data) {
  return getRequest()(PutContentStatusApi, {
    method: 'PUT',
    data,
  });
}
