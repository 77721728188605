import React from 'react';
import { Modal, Radio, Form, Input, message } from 'antd';
import { useIntl } from 'umi';

import { PostReview } from 'services/pdt';

import styles from './index.less';

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};

const CancellationModal = ({ subjectInfo = {}, reviewData = {}, visible = false, closeModal = () => { }, reloadPage = () => { } }) => {
    const [form] = Form.useForm();
    const intl = useIntl();

    const { name } = subjectInfo;
    const { id } = reviewData;

    return <Modal
        width={700}
        title={intl.formatMessage({ id: 'reviewTheApplication' })}
        open={visible}
        onOk={() => {
            form.submit();
        }}
        onCancel={() => {
            closeModal();
        }}
    >
        <div className={styles.formPanel}>
            <div className={styles.formTitle}>
                {intl.formatMessage({ id: 'reviewResult' }, { name })}
            </div>
            <Form
                name="reviewForm"
                form={form}
                {...formItemLayout}
                onFinish={async (value) => {
                    const res = await PostReview({
                        ...value,
                        id
                    })
                    if (res.response.ok) {
                        reloadPage()
                    } else {
                        const { error } = res.data
                        error.message && message.error(error.message)
                    }
                    closeModal();
                }}
            >
                <Form.Item
                    name="result"
                    rules={[{ required: true, message: '选择审核结果' }]}
                >
                    <Radio.Group>
                        <Radio.Button value="Allow">{intl.formatMessage({ id: 'Allow' })}</Radio.Button>
                        <Radio.Button value="Reject">{intl.formatMessage({ id: 'Reject' })}</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name="notes">
                    <Input.TextArea
                        placeholder={intl.formatMessage({ id: 'ReasonAndNotes' })}
                        showCount
                        maxLength={100}
                        style={{ height: 120 }}
                    />
                </Form.Item>
            </Form>
        </div>
    </Modal>
};

export default CancellationModal;