import React, { useState, useEffect } from 'react';
import { message, Tooltip } from 'antd';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';

import { getSupervisorSSummaryList } from 'services/questionnaires';

const showTitleLength = 6;

const Source = ({ userInfo, getIntlMsg, timeFormat }) => {
  const { id } = userInfo || {};

  const [listData, setListData] = useState({});
  const [loading, setLoading] = useState(false);
  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const { accumulatedCredits, items } = listData || {};
  const { items: selfItems = [] } = items || {};

  const listSupport = {
    creationTime: {
      showText: getIntlMsg('addTime'),
      showType: 'Time',
      addtional: {
        format: timeFormat,
      },
    },
    type: {
      showText: getIntlMsg('type'),
      showType: 'Text',
    },
    order: {
      showText: getIntlMsg('Order'),
      showType: 'Text',
    },
    scoring: {
      showText: getIntlMsg('ScoreAdd'),
      showType: 'Text',
    },
    grade: {
      showText: getIntlMsg('Grade'),
      showType: 'Text',
    },
    comment: {
      showText: getIntlMsg('Comments'),
      showType: 'Text',
      render: (text, _) => {
        return text !== null ? (
          <Tooltip title={text}>
            <span>
              {text.substring(0, showTitleLength)}
              <span style={{ color: '#1890ff' }}>
                {text.length >= showTitleLength ? '...' : ''}
              </span>
            </span>
          </Tooltip>
        ) : (
          '-'
        );
      },
    },
  };

  const tableProps = {
    listData: selfItems,
    listSupport,
    pagination,
    loading,
    onTableChange(page, filt = {}, sorter = {}) {
      const { current, pageSize } = page;
      setPagination({
        ...pagination,
        current,
        pageSize,
      });
    },
  };

  const initData = async () => {
    setLoading(true);

    const res = await getSupervisorSSummaryList({
      UserId: id,
      SkipCount: (pagination.current - 1) * pagination.pageSize,
      MaxResultCount: pagination.pageSize,
    });

    if (res.response.ok) {
      const {
        items: { totalCount },
      } = res.data || {};
      setListData(res.data);
      setPagination({
        ...pagination,
        total: totalCount,
      });
    } else {
      const {
        data: { error },
      } = res;
      message.warning(error.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, [pagination.pageSize, pagination.current]);

  return (
    <div>
      <div
        style={{ fontWeight: 'bold', marginBottom: '10px', fontSize: '16px' }}
      >
        {getIntlMsg('Total Score')}: {accumulatedCredits}
      </div>
      <MagicTable {...tableProps} />
    </div>
  );
};

export default Source;
