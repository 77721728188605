import getRequest from 'utils/request'
import moment from 'moment'
const GetUserOfflinePlanList = '/api/rocketAdmin/userOfflinePlan'
const PostCreateUserOfflinePlan = '/api/rocketAdmin/userOfflinePlan'
const PostStartUserOfflinePlan = '/api/rocketAdmin/userOfflinePlan/start/{planId}'
const GetItemsGrouped = '/api/rocketAdmin/userOfflinePlan/itemsGrouped'
const GetILs = '/api/rocketAdmin/userOfflinePlan/iLs'
const GetCourseDetail = '/api/rocketAdmin/userOfflinePlan/items'
const GetUserOfflinePlan = '/api/rocketAdmin/userOfflinePlan'
const FindUserOfflinePlanList = '/api/rocketAdmin/userOfflinePlan/find'
const GetCurrentUserOfflinePlanList = '/api/rocketAdmin/userOfflinePlan/currentList'
const FinishCourse = '/api/rocketAdmin/userOfflinePlan/next'
const NotDoItem = '/api/rocketAdmin/userOfflinePlan/done'
const NotGuardianDoItem = '/api/rocketAdmin/userOfflinePlan/numDone'
const GetForPunch = '/api/rocketAdmin/userOfflinePlan/forPunch'
const SubmitPunch = '/api/rocketAdmin/userOfflinePlan/punch'
const PutSubmitPunch = '/api/rocketAdmin/userOfflinePlan/punch'
const SwitchCourseType = '/api/rocketAdmin/userOfflinePlan/changeDeductionType'
const UpdatePlayBookList = '/api/rocketAdmin/userOfflinePlan/nonePlaybook'
const GetForGuardianPunch = '/api/rocketAdmin/userOfflinePlan/forNumPunch'
const SubmitGuardianPunch = '/api/rocketAdmin/userOfflinePlan/punchForNum'
const PutSubmitGuardianPunch = '/api/rocketAdmin/userOfflinePlan/punchForNum'
const PostBeforeStartUserOfflinePlan = '/api/rocketAdmin/userOfflinePlan/beforeStart/{planId}'
const PostNewStartUserOfflinePlan = '/api/rocketAdmin/userOfflinePlan/start'
const GetLatelyCourse = '/api/rocketAdmin/userOfflinePlan/forCourseAdjustment/{planId}'
const PostCourseAdjustment = '/api/rocketAdmin/userOfflinePlan/courseAdjustment'
const PostBeforeCreatePlan = '/api/rocketAdmin/userOfflinePlan/beforeCreate/{subjectId}'
const GetBrainProgress = '/api/rocketAdmin/userOfflinePlan/numProgress'
const PostPostEndPlan = '/api/rocketAdmin/userOfflinePlan/end/{userOfflineTrainingPlanId}'
const PostRestart = '/api/rocketAdmin/userOfflinePlan/restart/{userOfflineTrainingPlanId}' // 一对一重新开启
const PostArrangeAssessment = '/api/rocketAdmin/userOfflinePlan/mediumAssessment/{userOfflinePlanId}'
const GetArrangeAssessment = '/api/rocketAdmin/userOfflinePlan/mediumAssessmentName/{userPlanId}'
const PostMusicFeedbackApi = '/api/rocketAdmin/userOfflinePlan/punchForAudio'
const GetMusicRecordApi = '/api/rocketAdmin/userOfflinePlan/audioPunch/{userOfflinePlanId}'
const PostGoToNextCourse = '/api/rocketAdmin/userOfflinePlan/startNext'
const CheckTryCourse = '/api/rocketAdmin/userOfflinePlan/checkCertificateState'
const GetGroupCourseDetailsApi = '/api/rocketAdmin/groupTrainingPlan/groupTrainingPlanCourseDetail'
const AdjustProgramLevel = '/api/rocketAdmin/userOfflinePlan/difficultyAdjustment'
const AdjustGroupProgramLevel = '/api/rocketAdmin/groupTrainingPlan/difficultyAdjustment'
const GetParentalInterviewsNeedApi = '/api/rocketAdmin/userOfflinePlan/parentalInterviewsNeed'
const GetBeforeExtendClassApi = '/api/rocketAdmin/userOfflinePlan/beforeExtendClasses/{userOfflineTrainingPlanId}'
const PostExtendClassApi = '/api/rocketAdmin/userOfflinePlan/extendClasses'
const DeleteUserOfflinePlanApi = '/api/rocketAdmin/userOfflinePlan'
const GetPlanListOverViewApi = '/api/rocketAdmin/userOfflinePlan/planListOverView'

export async function GetPlanListOverView(data) {
  return getRequest()(GetPlanListOverViewApi, {
    method: 'GET',
    params: data,
  })
}

export async function DeleteUserOfflinePlan(data) {
  return getRequest()(DeleteUserOfflinePlanApi, {
    method: 'DELETE',
    params: data,
  })
}

export async function PostExtendClass(data) {
  return getRequest()(PostExtendClassApi, {
    method: 'POST',
    data,
  })
}

export async function GetBeforeExtendClass(data) {
  return getRequest()(GetBeforeExtendClassApi.replace('{userOfflineTrainingPlanId}', data.userOfflineTrainingPlanId), {
    method: 'GET',
    params: data,
  })
}

export async function GetParentalInterviewsNeed(data) {
  return getRequest()(GetParentalInterviewsNeedApi, {
    method: 'GET',
    params: data,
  })
}

export async function GetGetUserOfflinePlanList(data) {
  return getRequest()(GetUserOfflinePlanList, {
    method: 'GET',
    params: data,
  })
}

export async function GetGroupCourseDetail(data) {
  return getRequest()(GetGroupCourseDetailsApi, {
    method: 'GET',
    params: data,
  })
}

export async function PostGroupAdjustProgramLevel(data) {
  return getRequest()(AdjustGroupProgramLevel, {
    method: 'POST',
    data,
  })
}

export async function PostAdjustProgramLevel(data) {
  return getRequest()(AdjustProgramLevel, {
    method: 'POST',
    data,
  })
}

export async function PostCheckTryCourse(data) {
  return getRequest()(CheckTryCourse, {
    method: 'POST',
    data,
  })
}

export async function GetUserOfflinePlanForPunch(data) {
  return getRequest()(GetForPunch, {
    method: 'GET',
    params: data,
  })
}

export async function GetUserOfflinePlanForNumPunch(data) {
  return getRequest()(GetForGuardianPunch, {
    method: 'GET',
    params: data,
  })
}

export async function NotDoneItem(id) {
  return getRequest()(NotDoItem, {
    method: 'POST',
    data: {
      "planItemId": id,
      "hasDone": false,
      "doneTime": moment().format()
    },
  })
}

export async function PostSwitchCourseType(data) {
  return getRequest()(SwitchCourseType, {
    method: 'POST',
    data
  })
}


export async function onGuardianNotDoItem(data) {
  return getRequest()(NotGuardianDoItem, {
    method: 'POST',
    data
  })
}

export async function CreateUserOfflinePlan(data) {
  return getRequest()(PostCreateUserOfflinePlan, {
    method: 'POST',
    data,
  })
}

export async function PutUpdatePlayBookList(data) {
  return getRequest()(UpdatePlayBookList, {
    method: 'PUT',
    data,
  })
}


export async function PostPunch(data) {
  return getRequest()(SubmitPunch, {
    method: 'POST',
    data,
  })
}

export async function PutPunch(data) {
  return getRequest()(PutSubmitPunch, {
    method: 'PUT',
    data,
  })
}

export async function PostNumPunch(data) {
  return getRequest()(SubmitGuardianPunch, {
    method: 'POST',
    data,
  })
}

export async function PutNumPunch(data) {
  return getRequest()(PutSubmitGuardianPunch, {
    method: 'PUT',
    data,
  })
}

export async function PostFindUserOfflinePlanList(data) {
  return getRequest()(FindUserOfflinePlanList, {
    method: 'POST',
    data,
  })
}

export async function NextCourse(data) {
  return getRequest()(FinishCourse, {
    method: 'POST',
    data,
  })
}

export async function GoToNextCourse(data) {
  return getRequest()(PostGoToNextCourse, {
    method: 'POST',
    data,
  })
}

export async function CurrentUserOfflinePlanList(data) {
  return getRequest()(GetCurrentUserOfflinePlanList, {
    method: 'GET',
    params: data,
  })
}

export async function StartUserOfflinePlan(planId) {
  return getRequest()(PostStartUserOfflinePlan.replace('{planId}', planId), {
    method: 'POST',
  })
}

export async function GetGetItemsGrouped(data) {
  return getRequest()(GetItemsGrouped, {
    method: 'GET',
    params: data,
  })
}

export async function GetPlanItemDetail(data) {
  return getRequest()(GetCourseDetail, {
    method: 'GET',
    params: data,
  })
}


export async function GetGetILs(data) {
  return getRequest()(GetILs, {
    method: 'GET',
    params: data,
  })
}

export async function GetGetUserOfflinePlan(data) {
  return getRequest()(GetUserOfflinePlan, {
    method: 'GET',
    params: data,
  })
}

export async function HasCClasssBeforeStartUserOfflinePlan(planId) {
  return getRequest()(PostBeforeStartUserOfflinePlan.replace('{planId}', planId), {
    method: 'POST',
    data: { planId }
  })
}

export async function NewStartUserOfflinePlan(data) {
  return getRequest()(PostNewStartUserOfflinePlan, {
    method: 'POST',
    data
  })
}

export async function GetLatelyCourseApi(data) {
  return getRequest()(GetLatelyCourse.replace('{planId}', data.planId), {
    method: 'GET',
    params: data
  })
}

export async function PostCourseAdjustmentApi(data) {
  return getRequest()(PostCourseAdjustment, {
    method: 'POST',
    data
  })
}

export async function PostBeforeCreate(data) {
  return getRequest()(PostBeforeCreatePlan.replace('{subjectId}', data.subjectId), {
    method: 'POST',
    data,
  })
}

export async function GetBrainRecord(data) {
  return getRequest()(GetBrainProgress, {
    method: 'GET',
    params: data
  })
}

export async function PostEndPlanApi(data) {
  return getRequest()(PostPostEndPlan.replace('{userOfflineTrainingPlanId}', data.userOfflineTrainingPlanId), {
    method: 'POST',
    data
  })
}
// 重新开启计划
export async function PostRestartPlanApi(data) {
  return getRequest()(PostRestart.replace('{userOfflineTrainingPlanId}', data.userOfflineTrainingPlanId), {
    method: 'POST',
  })
}

export async function GetArrangeAssessmentApi(data) {
  return getRequest()(GetArrangeAssessment.replace('{userPlanId}', data.userPlanId), {
    method: 'GET',
    params: data
  })
}

export async function PostArrangeAssessmentApi(data) {
  return getRequest()(PostArrangeAssessment.replace('{userOfflinePlanId}', data.userOfflinePlanId), {
    method: 'POST',
    data
  })
}

export async function PostMusicFeedback(data) {
  return getRequest()(PostMusicFeedbackApi, {
    method: 'POST',
    data
  })
}

export async function GetMusicRecord(id) {
  return getRequest()(GetMusicRecordApi.replace('{userOfflinePlanId}', id), {
    method: 'GET',
    params: {
      userOfflinePlanId: id
    }
  })
}