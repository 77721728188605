import React, { useState, useEffect } from 'react';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import { message } from 'antd';
import moment from 'moment';

import { getTrainingList } from 'services/questionnaires';

import ExpandedTable from './ExpandedTable';

const Certificate = ({ userInfo, getIntlMsg, timeFormat }) => {
  const { id } = userInfo || {};

  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const listSupport = {
    creationTime: {
      showText: getIntlMsg('arrangeTime'),
      showType: 'Time',
      addtional: {
        format: timeFormat,
      },
    },
    module: {
      showText: getIntlMsg('Training Module'),
      showType: 'Text',
    },
    startCenter: {
      showText: getIntlMsg('The Arranged Center'),
      showType: 'Text',
    },
    status: {
      showText: getIntlMsg('Certificate Status'),
      showType: 'Text',
    },
    codeName: {
      showText: getIntlMsg('Certificate Name'),
      showType: 'Text',
    },
    credentialsCode: {
      showText: getIntlMsg('certificateNumber'),
      showType: 'Text',
    },
    activeTime: {
      showText: getIntlMsg('validity'),
      showType: 'Text',
    },
  };

  const isStatusActive = (endTime) => {
    return moment().isSameOrBefore(moment(endTime));
  };

  const tableProps = {
    listData: listData.map((item) => {
      const { trainingModule, tenant, trainerCertificateDto, certificateStatus } = item || {};
      const { displayName } = trainingModule || {};

      const {
        credentialsCode,
        startTime,
        endTime,
        trainingModule: certificateTrainingModule,
        status: credentialsStatus
      } = trainerCertificateDto || {};

      const { qualificationMedals } = certificateTrainingModule || {};
      const medalInfo = qualificationMedals && qualificationMedals[0];
      const { displayName: cardDisplayName } = medalInfo || {};
      const { displayName: centerDisplayName } = tenant || {};

      return {
        ...item,
        module: displayName,
        startCenter: centerDisplayName,
        codeName: cardDisplayName,
        credentialsCode,
        status: {
          "Ineffective": getIntlMsg('NotActive'),
          "InEffect": getIntlMsg('Effectiveing'),
          "Expired": getIntlMsg('expired'),
          "NotIssued": getIntlMsg('unissued'),
          "Canceled": getIntlMsg('Cancelled'),
        }[certificateStatus],
        activeTime:
          startTime && endTime
            ? moment(startTime).format(timeFormat) +
              '-' +
              moment(endTime).format(timeFormat)
            : null,
      };
    }),
    listSupport,
    pagination,
    loading,
    expandable() {
      return {
        expandedRowKeys,
        expandedRowRender: (record) => (
          <ExpandedTable
            allData={record}
            getIntlMsg={getIntlMsg}
            timeFormat={timeFormat}
          />
        ),
        onExpandedRowsChange: (rows) => {
          setExpandedRowKeys([...rows]);
        },
      };
    },
    onTableChange(page, filt = {}, sorter = {}) {
      const { current, pageSize } = page;

      setPagination({
        ...pagination,
        current,
        pageSize,
      });

      expandedRowKeys.length > 0 && setExpandedRowKeys([]);
    },
  };

  const initData = async () => {
    setLoading(true);

    const res = await getTrainingList({
      TrainerId: id,
      SkipCount: (pagination.current - 1) * pagination.pageSize,
      MaxResultCount: pagination.pageSize,
    });

    if (res.response.ok) {
      const { totalCount, items = [] } = res.data || {};
      setListData(items);
      setPagination({
        ...pagination,
        total: totalCount,
      });
    } else {
      const {
        data: { error },
      } = res;
      message.warning(error.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, [pagination.pageSize, pagination.current]);

  return <MagicTable {...tableProps} />;
};

export default Certificate;
