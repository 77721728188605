import React, { useState } from 'react';
import { Drawer, Tabs } from 'antd';

import ShowItem from './ShowItem';

// type: Oral, Practical
const QuestionnaireRecordDrawer = ({
  visible = false,
  TrainingModuleId,
  userInfo = {},
  onClose = () => {},
}) => {
  const [activeKey, setActiveKey] = useState('Oral');

  const showTitle = activeKey === 'Practical' ? '实操' : '口试';

  const showItemProps = {
    type: activeKey,
    TrainingModuleId,
    userInfo,
    showTitle,
  };

  const tabItems = [
    {
      label: '口试记录',
      key: 'Oral',
      children: <ShowItem {...showItemProps} />,
    },
    {
      label: '实操记录',
      key: 'Practical',
      children: <ShowItem {...showItemProps} />,
    },
  ];

  return (
    <>
      <Drawer
        title={showTitle + '记录'}
        width={700}
        onClose={onClose}
        open={visible}
      >
        <Tabs
          type="card"
          activeKey={activeKey}
          items={tabItems}
          onChange={(value) => setActiveKey(value)}
        />
      </Drawer>
    </>
  );
};

export default QuestionnaireRecordDrawer;
