function ContextMenu(options) {
    let instance;

    function createMenu() {
        const ul = document.createElement('ul');
        ul.classList.add('custom-context-menu');
        const { menus } = options;
        if (menus && menus.length > 0) {
          for (let menu of menus) {
            const li = document.createElement('li');
            li.textContent = menu.name;
            li.onclick = menu.onClick;
            ul.appendChild(li);
            if (menu.children) {
              const level2Ul = document.createElement('ul');
              menu.children.map(level2Menu => {
                const level2Li = document.createElement('li');
                level2Li.active && level2Li.classlist.add('active')
                level2Li.classList.add(level2Menu.className)
                level2Li.textContent = level2Menu.name;
                level2Li.onclick = level2Menu.onClick;
                level2Ul.appendChild(level2Li);
              });
              li.appendChild(level2Ul);
            }
          }
        }
        const body = document.querySelector('body');
        body.appendChild(ul);
        return ul;
      }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createMenu();
            }
            return instance;
        },
    };
};

const demoContextMenu = ContextMenu({
    menus: [
        {
            name: "custom menu 1",
            onClick: function (e) {
                console.log("menu1 clicked");
            },
        },
        {
            name: "custom menu 2",
            onClick: function (e) {
                console.log("menu2 clicked");
            },
        },
        {
            name: "custom menu 3",
            onClick: function (e) {
                console.log("menu3 clicked");
            },
        },
    ],
});

function showMenu(event, contextMenu = demoContextMenu) {
    event.preventDefault();
    const menus = contextMenu.getInstance();
    menus.style.top = `${event.clientY}px`;
    menus.style.left = `${event.clientX}px`;
    menus.classList.remove("hidden");
}

function hideMenu(event, contextMenu = demoContextMenu) {
    const menus = contextMenu.getInstance();
    menus.classList.add("hidden");
}

export { showMenu, hideMenu, ContextMenu }
