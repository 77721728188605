import React from 'react'
import { Input, Button } from 'antd'
import { useIntl } from 'umi'
import { getRandomName } from 'utils/utils'

const CustomName = ({ value, onChange }) => {

  const intl = useIntl()

  const onNameChange = e => {
    const newName = e.target.value
    onChange(newName)
  }
  const sendChangeName = () => {
    onChange(getRandomName())
  }

  return (
    <div style={{ display: 'flex' }}>
      <Input type="text" value={value} onChange={e => onNameChange(e)} />
      <Button
        onClick={() => {
          sendChangeName()
        }}
      >
        {intl.formatMessage({ id: 'randomName' })}
      </Button>
    </div>
  )
}

export default CustomName
