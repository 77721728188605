import getRequest from 'utils/request'

const AddGroupApi = '/api/rocketAdmin/group'
const UpdateGroupApi = '/api/rocketAdmin/group'
const GetGroupApi = '/api/rocketAdmin/group/getList'
const GetGroupDetailApi = '/api/rocketAdmin/group/{id}'
const UpdateChildApi = '/api/rocketAdmin/group/user'
const UpdateItemApi = '/api/rocketAdmin/group/prepareForEdit'
const CreateTrainingPlanApi = '/api/rocketAdmin/groupTrainingPlan'
const AssignTrainersApi = '/api/rocketAdmin/group/assignTrainers'
const TrainPlanDetailApi = '/api/rocketAdmin/groupTrainingPlan/{id}'
const BeforeStartClassApi = '/api/rocketAdmin/groupTrainingPlan/beforeStartCourse'
const GetPlanCourseApi = '/api/rocketAdmin/groupTrainingPlan/groupTrainingPlanCourse'
const GetPlanListApi = '/api/rocketAdmin/groupTrainingPlan/groupCurrentPlanList'
const GetFeedBackApi = '/api/rocketAdmin/groupTrainingPlan/forPunch'
const PostFeedBackItemApi = '/api/rocketAdmin/groupTrainingPlan/punch'
const PostFeedBackTotalApi = '/api/rocketAdmin/groupTrainingPlan/punchForNum'
const PostDoneApi = '/api/rocketAdmin/groupTrainingPlan/done'
const PostEndTrainApi = '/api/rocketAdmin/groupTrainingPlan/end/{groupTrainingPlanId}'
const PostDoneNumApi = '/api/rocketAdmin/groupTrainingPlan/numDone'
const GetGroupCourseListApi = '/api/rocketAdmin/groupTrainingPlan/groupCourseList'
const PostCheckStateApi = '/api/rocketAdmin/groupTrainingPlan/checkCertificateState'
const StartCourseApi = '/api/rocketAdmin/groupTrainingPlan/startCourse'
const EndCourseApi = '/api/rocketAdmin/groupTrainingPlan/endCourse'
const StartClassApi = '/api/rocketAdmin/groupTrainingPlan/startClass'
const EndClassApi = '/api/rocketAdmin/groupTrainingPlan/endClass'
const ContinueClassApi = '/api/rocketAdmin/groupTrainingPlan/continueClass'
const GetListenRecordApi = '/api/rocketAdmin/groupTrainingPlan/userCourseRecordListening'
const PostChangeClassStatusApi = '/api/rocketAdmin/groupTrainingPlan/reverseUserCoursePlatform'
const GetCheckMusciStatusApi = '/api/rocketAdmin/groupTrainingPlan/checkUserMusicCourseProgress'
const GetChildStatusApi = '/api/rocketAdmin/groupTrainingPlan/refreshChildStatus'
const GetChildListDetailApi = '/api/rocketAdmin/group/childList/{groupId}'
const GetPlanPunchHistoryApi = "/api/rocketAdmin/groupTrainingPlan/planPunchHistory"
const GetPlanItemPunchHistoryApi = '/api/rocketAdmin/groupTrainingPlan/planItemPunchHistory'
const PutChangeAttendanceApi = '/api/rocketAdmin/groupTrainingPlan/attendance'
const GetChildFeedbackApi = '/api/rocketAdmin/groupTrainingPlan/forChildPunch'
const PostChildFeedbackApi = '/api/rocketAdmin/groupTrainingPlan/punchForChild'
const GetParentalInterviewsNeedApi = '/api/rocketAdmin/groupTrainingPlan/parentalInterviewsNeed'
const GetChildListApi = '/api/rocketAdmin/groupTrainingPlan/childList'
const GetParentFeedbackDataApi = '/api/rocketAdmin/groupTrainingPlan/punchHistory'
const GetChildFeedbackDataApi = '/api/rocketAdmin/groupTrainingPlan/userPunchHistory'
const PostRestartApi = '/api/rocketAdmin/groupTrainingPlan/restart/{groupTrainingPlanId}' // 一对多重新开启

export async function GetChildFeedbackData(data) {
    return getRequest()(GetChildFeedbackDataApi, {
        method: 'GET',
        params: data,
    })
}

export async function GetParentFeedbackData(data) {
    return getRequest()(GetParentFeedbackDataApi, {
        method: 'GET',
        params: data,
    })
}

export async function GetChildList(data) {
    return getRequest()(GetChildListApi, {
        method: 'GET',
        params: data,
    })
}

export async function GetParentalInterviewsNeed(data) {
    return getRequest()(GetParentalInterviewsNeedApi, {
        method: 'GET',
        params: data,
    })
}

export async function PutChildFeedback(data) {
    return getRequest()(PostChildFeedbackApi, {
        method: 'PUT',
        data,
    })
}

export async function PostChildFeedback(data) {
    return getRequest()(PostChildFeedbackApi, {
        method: 'POST',
        data,
    })
}

export async function GetChildFeedback(data) {
    return getRequest()(GetChildFeedbackApi, {
        method: 'GET',
        params: data,
    })
}

export async function GetPlanPunchHistory(data) {
    return getRequest()(GetPlanPunchHistoryApi, {
        method: 'GET',
        params: data,
    })
}

export async function GetPlanItemPunchHistory(data) {
    return getRequest()(GetPlanItemPunchHistoryApi, {
        method: 'GET',
        params: data,
    })
}

export async function PutAttendance(data) {
    return getRequest()(PutChangeAttendanceApi, {
        method: 'PUT',
        data,
    })
}

export async function GetChildListDetail(data) {
    return getRequest()(GetChildListDetailApi.replace('{groupId}', data.groupId), {
        method: 'GET',
        params: data,
    })
}

export async function GetListenRecord(data) {
    return getRequest()(GetListenRecordApi, {
        method: 'GET',
        params: data,
    })
}

export async function GetChildStatus(data) {
    return getRequest()(GetChildStatusApi, {
        method: 'GET',
        params: data,
    })
}

export async function GetCheckMusciStatus(data) {
    return getRequest()(GetCheckMusciStatusApi, {
        method: 'GET',
        params: data,
    })
}

export async function PostChangeClassStatus(data) {
    return getRequest()(PostChangeClassStatusApi, {
        method: 'POST',
        data,
    })
}

export async function StartClass(data) {
    return getRequest()(StartClassApi, {
        method: 'POST',
        data,
    })
}

export async function EndClass(data) {
    return getRequest()(EndClassApi, {
        method: 'POST',
        data,
    })
}

export async function ContinueClass(data) {
    return getRequest()(ContinueClassApi, {
        method: 'POST',
        data,
    })
}

export async function AddGroup(data) {
    return getRequest()(AddGroupApi, {
        method: 'POST',
        data,
    })
}

export async function PostEndTrain(id) {
    return getRequest()(PostEndTrainApi.replace('{groupTrainingPlanId}', id), {
        method: 'POST',
    })
}

export async function CheckState(data) {
    return getRequest()(PostCheckStateApi, {
        method: 'POST',
        data,
    })
}

export async function Done(data) {
    return getRequest()(PostDoneApi, {
        method: 'POST',
        data,
    })
}

export async function DoneNum(data) {
    return getRequest()(PostDoneNumApi, {
        method: 'POST',
        data,
    })
}

export async function GetFeedBack(data) {
    return getRequest()(GetFeedBackApi, {
        method: 'GET',
        params: data,
    })
}

export async function GetGroupCourseList(data) {
    return getRequest()(GetGroupCourseListApi, {
        method: 'GET',
        params: data,
    })
}

export async function PutFeedBack(data) {
    return getRequest()(PostFeedBackItemApi, {
        method: 'PUT',
        data,
    })
}

export async function PostFeedBack(data) {
    return getRequest()(PostFeedBackItemApi, {
        method: 'POST',
        data,
    })
}

export async function PutFeedBackTotal(data) {
    return getRequest()(PostFeedBackTotalApi, {
        method: 'PUT',
        data,
    })
}

export async function PostFeedBackTotal(data) {
    return getRequest()(PostFeedBackTotalApi, {
        method: 'POST',
        data,
    })
}

export async function PostEndCourse(data) {
    return getRequest()(EndCourseApi, {
        method: 'POST',
        data,
    })
}

export async function GetPlanCourseByNum(data) {
    return getRequest()(GetPlanCourseApi, {
        method: 'GET',
        params: data
    })
}

export async function GetPlanList(data) {
    return getRequest()(GetPlanListApi, {
        method: 'POST',
        data
    })
}

export async function CreateTrainingPlan(data) {
    return getRequest()(CreateTrainingPlanApi, {
        method: 'POST',
        data,
    })
}

export async function GetTrainPlanDetail(data) {
    return getRequest()(TrainPlanDetailApi.replace('{id}', data.id), {
        method: 'GET',
    })
}

export async function BeforeStartClass(data) {
    return getRequest()(BeforeStartClassApi, {
        method: 'GET',
        params: data,
    })
}

export async function StartCourse(data) {
    return getRequest()(StartCourseApi, {
        method: 'POST',
        data,
    })
}

export async function GetGroupList(data) {
    return getRequest()(GetGroupApi, {
        method: 'POST',
        data,
    })
}

export async function AssignTrainers(data) {
    return getRequest()(AssignTrainersApi, {
        method: 'POST',
        data
    })
}

export async function UpdateItem(data) {
    return getRequest()(UpdateItemApi, {
        method: 'POST',
        data
    })
}

export async function UpdateGroup(data) {
    return getRequest()(UpdateGroupApi, {
        method: 'PUT',
        data
    })
}

export async function UpdateChild(data) {
    return getRequest()(UpdateChildApi, {
        method: 'PUT',
        data
    })
}

export async function GetDetailGroup(data) {
    return getRequest()(GetGroupDetailApi.replace('{id}', data.id), {
        method: 'GET',
        params: data
    })
}

export async function PostRestart(data) {
    return getRequest()(PostRestartApi.replace('{groupTrainingPlanId}', data.groupTrainingPlanId), {
        method: 'POST',
    })
}
