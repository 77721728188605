import { ActivateEmail, CheckActivateToken } from 'services/account'
import { message } from 'antd'

export const SHOWTYPE = {
  Idle: 'idle',
  NewUser: 'newUser',
  ExistUser: 'existUser',
  UnknownError: 'error',
  ErrorLink: 'errorLink',
  UserAlreadyActive: 'userAlreadyActive',
}

export default {
  namespace: 'updateEmail',

  state: {
    showType: SHOWTYPE.Idle,
    token: '',
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },

  effects: {
    *check({ payload }, { call, put, take, select }) {
      const { token } = payload
      const res = yield call(CheckActivateToken, token)
      if (res.response.ok) {
        const { isNew } = res.data
        console.log(isNew)
        if (isNew) {
          yield put({
            type: 'updateState',
            payload: { showType: SHOWTYPE.NewUser, token },
          })
        } else {
          yield put({
            type: 'updateState',
            payload: { showType: SHOWTYPE.ExistUser },
          })
        }
        //message.success('账号激活成功')
      } else {
        const { error } = res.data
        const { code } = error
        message.error(error.message)
        let showType = null
        switch (code) {
          case 'ErrorActiveLink':
            showType = SHOWTYPE.ErrorLink
            break
          case 'UserAlreadyActive':
            showType = SHOWTYPE.UserAlreadyActive
            break
          default:
            showType = SHOWTYPE.UnknownError
            break
        }
        yield put({
          type: 'updateState',
          payload: { showType },
        })
      }
    },

    *activateUser({ payload }, { call, put, take, select }) {
      const { token } = yield select(state => state.updateEmail)
      const res = yield call(ActivateEmail, { token, ...payload })
      if (res.response.ok) {
        message.success('账号激活成功')
        yield put({
          type: 'updateState',
          payload: { showType: SHOWTYPE.ExistUser },
        })
      } else {
        const { error } = res.data
        message.error(error.message)
      }
    },
  },

  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(({ pathname, query }) => {
    //     if (pathname === '/updateEmail') {
    //       dispatch({ type: 'check', payload: query })
    //     }
    //   })
    // },
  },
}
