import React, { Fragment } from 'react'
import { Checkbox, Form, message, Radio, Input, Divider, Card, Button } from 'antd'
import styles from './index.less'
import { IntlManager } from 'utils/helper'
const FormItem = Form.Item;

class IlsMusicFeedBack extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            allData: props.allData,
            audioRecordPunch: props.audioRecordPunch,
            checkedData: [],
            loading: props.loading,
            loadingKey: props.loadingKey,
            musicHasDone: props.musicHasDone
        }
    }

    onSubmit = (values) => {
        const { allData } = this.state;
        const { onSubmit } = this.props
        const { userOfflineTrainingPlan, iLs } = allData || {}
        const { id } = userOfflineTrainingPlan || {}
        const { tracks } = iLs || {}
        const tracksArr = tracks.map(item => `${item.courseCode}-${item.code}`)

        const listenedList = values.musicFeedBack
        let notListenedList = []
        tracksArr.forEach(item => {
            if (listenedList.indexOf(item) === -1) {
                notListenedList.push(item)
            }
        })
        const parmas = {
            userPlanId: id,
            listenedList,
            notListenedList
        }
        onSubmit(parmas)
    }

    onchange = (value) => {
        const { allData, checkedData } = this.state;
        const { iLs } = allData || {}
        const { tracks } = iLs || {}
        let newCheckedData = []
        if (tracks && Array.isArray(tracks)) {
            let isOrderTracksFlag = true
            const tracksArr = tracks.map(item => `${item.courseCode}-${item.code}`)
            value.forEach((item, index) => {
                if (item !== tracksArr[index]) {
                    isOrderTracksFlag = false
                }
            })
            if (isOrderTracksFlag) {
                newCheckedData = value
            } else {
                if (value.length < checkedData.length) {
                    let index = 0
                    for (let i = 0; i < value.length; i++) {
                        if (value[i] !== checkedData[i]) {
                            index = i
                            break
                        }
                    }
                    newCheckedData = value.slice(0, index)
                } else {
                    message.warning('请按照顺序选择')
                    newCheckedData = checkedData
                }
            }
            this.setState({ checkedData: newCheckedData })
            return newCheckedData
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            allData: props.allData,
            audioRecordPunch: props.audioRecordPunch,
            loading: props.loading,
            loadingKey: props.loadingKey
        }
    }

    render() {
        const intl = IntlManager.MyIntl()
        const { allData, audioRecordPunch, loading, loadingKey, musicHasDone } = this.state;
        if (!allData) return <span></span>
        const { iLs, musicIsEdit } = allData || {}
        const { tracks } = iLs || {}
        const options = tracks && Array.isArray(tracks) && tracks.map(item => {
            return {
                label: `${item.courseCode}-${item.code}`,
                value: `${item.courseCode}-${item.code}`,
                checked: true
            }
        })

        return (
            <Card>
                {
                    allData.hasOwnProperty("musicIsEdit") ?
                        (
                            musicIsEdit ?
                                <Form
                                    onFinish={this.onSubmit}
                                    layout="vertical"
                                >
                                    <FormItem
                                        label={intl('musicPlaybackRecord')}
                                        name={'musicFeedBack'}
                                        initialValue={audioRecordPunch?.listenedList}
                                        getValueFromEvent={val => {
                                            return this.onchange(val)
                                        }}
                                        rules={[
                                            {
                                                required: true,
                                                message: intl('progressNotSelected')
                                            }
                                        ]}
                                    >
                                        <Checkbox.Group options={options} />
                                    </FormItem>
                                    <div className={styles.musicFeedbackButton}>
                                        <Button
                                            type='primary'
                                            htmlType="submit"
                                            loading={loading && loadingKey.indexOf('music') > -1}>
                                            {intl('submitOther')}
                                        </Button>
                                    </div>
                                </Form>
                                :
                                <div>
                                    <b>{intl('listened')}</b>
                                    {
                                        audioRecordPunch?.listenedList?.map((item, index) => {
                                            return (
                                                <span key={index}>
                                                    {index !== audioRecordPunch.listenedList.length - 1 ? `${item}，` : item}
                                                </span>
                                            )
                                        })
                                    }
                                </div>
                        ) :
                        (
                            musicHasDone ?
                                <div>
                                    <b>{intl('listened')}</b>
                                    {
                                        audioRecordPunch?.listenedList?.map((item, index) => {
                                            return (
                                                <span key={index}>
                                                    {index !== audioRecordPunch.listenedList.length - 1 ? `${item}，` : item}
                                                </span>
                                            )
                                        })
                                    }
                                </div>
                                :
                                <Form
                                    onFinish={this.onSubmit}
                                    layout="vertical"
                                >
                                    <FormItem
                                        label={intl('musicPlaybackRecord')}
                                        name={'musicFeedBack'}
                                        initialValue={null}
                                        getValueFromEvent={val => {
                                            return this.onchange(val)
                                        }}
                                        rules={[
                                            {
                                                required: true,
                                                message: intl('progressNotSelected')
                                            }
                                        ]}
                                    >
                                        <Checkbox.Group options={options} />
                                    </FormItem>
                                    <div className={styles.musicFeedbackButton}>
                                        <Button
                                            type='primary'
                                            htmlType="submit"
                                            loading={loading && loadingKey.indexOf('music') > -1}>
                                            {intl('submitOther')}
                                        </Button>
                                    </div>
                                </Form>
                        )
                }
            </Card>
        )
    }
}

export default IlsMusicFeedBack;