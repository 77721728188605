import getRequest from 'utils/request'
import { payBaseURL } from 'utils/config'

const PostQualificationApi = '/api/rocketAdmin/qualification'
const GetQualificationApi = '/api/rocketAdmin/qualification'
const DeleteQualificationApi = '/api/rocketAdmin/qualification/{id}'
const PutQualificationApi = '/api/rocketAdmin/qualification/{id}'
const PostPrepareTrainingModuleApi = '/api/rocketAdmin/trainingModule/prepareForUpdate'
const PostTrainingModuleApi = '/api/rocketAdmin/trainingModule'
const GetTrainingModuleApi = '/api/rocketAdmin/trainingModule'
const PutTrainingModuleApi = '/api/rocketAdmin/trainingModule/{id}'
const DeleteTrainingModuleApi = '/api/rocketAdmin/trainingModule/{id}'
const PutSortOrderMoveApi = '/api/rocketAdmin/trainingModule/{id}/move'
const GetTrainingModuleDetailApi = '/api/rocketAdmin/trainingModule/{id}'
const PutTrainingModuleCourseApi = '/api/rocketAdmin/trainingModule/course'
const PutCourseOrderApi = '/api/rocketAdmin/trainingModule/courseOrder'
const DeleteTrainingModuleCourseApi = '/api/rocketAdmin/trainingModule/course'
const GetTrainingCoursesByModuleApi = '/api/rocketAdmin/tenantTrainingModule/tenantModule'
const PostTrainingModuleResourceApi = '/api/rocketAdmin/tenantTrainingModule/trainingResource'
const PutTenantTrainingModuleApi = '/api/rocketAdmin/tenantTrainingModule/tenantTrainingModule'
const PostStartTenantTrainingModuleApi = '/api/rocketAdmin/tenantTrainingModule/start'
const PostEndTenantTrainingModuleApi = '/api/rocketAdmin/tenantTrainingModule/stop'
const GetTenantTrainerModuleListApi = '/api/rocketAdmin/tenantTrainer/moduleList'
const GetCanArrangeTrainerListApi = '/api/rocketAdmin/tenantTrainer/canArrangeTrainerList'
const PostArrangeTrainerApi = '/api/rocketAdmin/tenantTrainer/arrangeTraining'
const PostTrainerListApi = '/api/rocketAdmin/tenantTrainer/list'
const PostExportPostTrainerListApi = '/api/rocketAdmin/tenantTrainer/listExcel'
const GetAdminStaffListApi = '/api/business/user/staffList'
const GetStaffDetailApi = '/api/business/user/staffDetail/{staffId}'
const GetProfileApi = '/api/business/user/{id}/profile'
const GetUserInfoApi = '/api/business/user/{id}'
const GetTrainerModuleListApi = '/api/rocketAdmin/trainerTraining/trainerTrainingModuleList'
const PostModuleCourseCountApi = '/api/rocketAdmin/trainerTraining/moduleCoursePassCount'
const GetTrainerModuleDetailApi = '/api/rocketAdmin/trainerTraining/trainerTrainingModule'
const GetTrainingApplicationApi = '/api/rocketAdmin/userQuestionnaire/trainingApplication'
const PutQuestionnaireApi = '/api/rocketAdmin/userQuestionnaire/questionnaire'
const StartStudyApi = '/api/rocketAdmin/trainerTraining/start/{trainerTrainingId}'
const FinishCourseApi = '/api/rocketAdmin/trainerTraining/finishCourseDetail'
const CreateQuestionnaireApi = '/api/rocketAdmin/userQuestionnaire/{id}'
const PutUpdateQuestionnaireApi = '/api/rocketAdmin/trainerTraining/userCourseDetail'
const PostStopOrStartApi = '/api/rocketAdmin/trainerTraining/stopOrEnableTrainerTrainingModule/{trainerTrainingModuleId}'
const PostNewTrainerTrainingModuleApi = '/api/rocketAdmin/trainerTraining/renewTrainerTrainingModule'
const PostIssueCertificateApi = '/api/rocketAdmin/trainerTraining/issueCertificate'
const PostTrainingApplicationApi = '/api/rocketAdmin/userQuestionnaire/trainingApplication'
const PostUploadUserResumesApi = '/api/business/user/uploadResume'
const GetAppUserQuestionnarieApi = '/api/rocketAdmin/userQuestionnaire/userQuestionnaire'
const GetCertificateListApi = '/api/rocketAdmin/trainerTraining/certificateList'
const GetTrainerDetailApi = '/api/rocketAdmin/trainerTraining/trainerDetail'
const PostStopCertificateApi = '/api/rocketAdmin/trainerTraining/stopCertificate/{certificateId}'
const PutTrainerCertificateApi = '/api/rocketAdmin/trainerTraining/certificate'
const GetTrainerTrainingFeedbackApi = '/api/rocketAdmin/trainerTraining/feedBack'
const PostTrainerTrainingFeedbackApi = '/api/rocketAdmin/trainerTraining/feedback'
const GetTrainerSummaryListApi = '/api/rocketAdmin/trainerTraining/trainerSummaryList'
const GetSupervisorSSummaryAPi = '/api/rocketAdmin/trainerTraining/supervisorSSummaryList'
const PostSupervisorSummaryApi = '/api/rocketAdmin/userQuestionnaire/supervisorSSummary'
const PostTrainerSummaryApi = '/api/rocketAdmin/userQuestionnaire/trainerSummary'
const PutTrainerSummaryApi = '/api/rocketAdmin/trainerTraining/trainerSummary'
const PutSupervisorSummaryApi = '/api/rocketAdmin/trainerTraining/supervisorSSummary'
const PostSendCheckNumberCodeApi = '/api/business/user/sendCheckPhoneNumberCode'
const PostPayOrderApi = '/api/payment/payOrder'
const GetPayStatusApi = '/api/payment/{code}'
const RefreshPayStatusApi = '/api/payment/refresh/{code}'
const GetTrainingModuleQrCodeApi = '/api/rocketAdmin/QrCode/TrainingModule'
const PostQuestionListApi = '/api/rocketAdmin/questionnaire/questionnaires'
const PostRelatingClassApi = '/api/rocketAdmin/trainingModule/relatingExamination'
const DeleteCertApi = '/api/rocketAdmin/trainerTraining/certificate/{certificateId}'
const GetTrainingDetailApi = '/api/rocketAdmin/trainerTraining/courseDetail'
const GetTrainCenterListApi = '/api/rocketAdmin/trainerTraining'
const GetCourseListApi = '/api/rocketAdmin/trainerTraining/courseList'
const EditAmount = '/api/rocketAdmin/trainerTraining/trainerAmount'
const GetSupervisorListApi = '/api/business/user/supervisorList'
const GetChiefComplaintListApi = '/api/rocketAdmin/userQuestionnaire/chiefComplaintList'
const GetDiagnosticInformationListApi = '/api/rocketAdmin/userQuestionnaire/diagnosticInformationList'
const GetTrainingDetailExcelApi = '/api/rocketAdmin/trainerTraining/trainerDetailsExcel'
const PutTrainerRemarkApi = '/api/rocketAdmin/tenantTrainer/trainerTrainingModuleRemark/{trainerTrainingModuleId}'
const PostEndDateApi = '/api/rocketAdmin/trainerTraining/renewTrainerTrainingModuleEndDate'
const GetTrainingApi= '/api/rocketAdmin/userQuestionnaire/trainingApplicationRecords'
const PostDiagnosticInformationApi = '/api/rocketAdmin/userQuestionnaire/diagnosticInformation'
const PostChiefComplaintApi = '/api/rocketAdmin/userQuestionnaire/chiefComplaint'
const PutTrainingModuleInUseApi = '/api/rocketAdmin/trainingModule/inUse'
const GetSelectTenantTrainingModuleApi = '/api/rocketAdmin/tenantTrainingModule'
const GetPrepareForIssueCertificateApi = '/api/rocketAdmin/trainerTraining/prepareForIssueCertificate'
const GetCredentialsCodeApi = '/api/rocketAdmin/trainerTraining/credentialsCode'
const GetTrainingListApi = '/api/rocketAdmin/trainerTraining/trainingList'
const GetTrainerTrainingModuleDetailApi = '/api/rocketAdmin/trainerTraining/trainingDetail/{trainerTrainingModuleId}'
const GetTrainingAppointmentApi = '/api/rocketAdmin/trainingAppointment'
const DeleteTrainingAppointmentApi = '/api/rocketAdmin/trainingAppointment/{id}'
const PostTrainingAppointmentFeedbackApi = '/api/rocketAdmin/trainingAppointment/feedback'
const PostTrainingAppointmentCompleteApi = '/api/rocketAdmin/trainingAppointment/complete'
const TrainerTrainingSupervisionApi = '/api/rocketAdmin/trainerTrainingSupervision'
const DeleteTrainerTrainingSupervisionApi = '/api/rocketAdmin/trainerTrainingSupervision/{id}'
const PutSupervisorSSummaryApi = '/api/rocketAdmin/trainerTrainingSupervision/supervisorSSummary'
const PutTrainerTrainingSupervisionSummaryApi = '/api/rocketAdmin/trainerTrainingSupervision/trainerSummary'
const GetTrainerTrainingSupervisionDetailApi = '/api/rocketAdmin/trainerTrainingSupervision/moduleList/{trainerTrainingModuleId}'
const GetLatestPreparationApi = '/api/rocketAdmin/preparationTrainer/latestPreparation/{trainerId}'
const GetPracticalTestApi = '/api/rocketAdmin/trainerTrainingExamination/practicalTest/{trainerTrainingModuleId}'
const GetCertificateApi = '/api/rocketAdmin/trainerTraining/certificate/{trainerTrainingModuleId}'
const OralQuestionnaireApi = '/api/rocketAdmin/trainerTrainingExamination/oralQuestionnaire'
const PracticalQuestionnaireApi = '/api/rocketAdmin/trainerTrainingExamination/practicalQuestionnaire'
const GetTheoreticalTestWApi = '/api/rocketAdmin/trainerTrainingExamination/theoreticalTestW/{trainerTrainingModuleId}'
const GetTestListApi = '/api/rocketAdmin/trainerTrainingExamination/testList'
const PostPreparationTrainerApi = '/api/rocketAdmin/preparationTrainer/applyForTraining'

export async function PostPreparationTrainer(data) {
    return getRequest()(PostPreparationTrainerApi, {
        method: 'POST',
        data
    })
}

export async function GetTestList(data) {
    return getRequest()(GetTestListApi, {
        method: 'GET',
        params: data
    })
}

export async function GetTheoreticalTestW(data) {
    return getRequest()(GetTheoreticalTestWApi.replace('{trainerTrainingModuleId}', data.trainerTrainingModuleId), {
        method: 'GET',
        params: data
    })
}

export async function PutOralQuestionnaire(data) {
    return getRequest()(OralQuestionnaireApi, {
        method: 'PUT',
        data
    })
}

export async function PutPracticalQuestionnaire(data) {
    return getRequest()(PracticalQuestionnaireApi, {
        method: 'PUT',
        data
    })
}

export async function PostOralQuestionnaire(data) {
    return getRequest()(OralQuestionnaireApi, {
        method: 'POST',
        data
    })
}

export async function PostPracticalQuestionnaire(data) {
    return getRequest()(PracticalQuestionnaireApi, {
        method: 'POST',
        data
    })
}

export async function GetCertificate(data) {
    return getRequest()(GetCertificateApi.replace('{trainerTrainingModuleId}', data.trainerTrainingModuleId), {
        method: 'GET',
        params: data
    })
}

export async function GetPracticalTest(data) {
    return getRequest()(GetPracticalTestApi.replace('{trainerTrainingModuleId}', data.trainerTrainingModuleId), {
        method: 'GET',
        params: data
    })
}

export async function GetLatestPreparation(data) {
    return getRequest()(GetLatestPreparationApi.replace('{trainerId}', data.trainerId), {
        method: 'GET',
        params: data
    })
}

export async function GetTrainerTrainingSupervision(data) {
    return getRequest()(TrainerTrainingSupervisionApi, {
        method: 'GET',
        params: data
    })
}

export async function PostTrainerTrainingSupervision(data) {
    return getRequest()(TrainerTrainingSupervisionApi, {
        method: 'POST',
        data
    })
}

export async function DeleteTrainerTrainingSupervision(data) {
    return getRequest()(DeleteTrainerTrainingSupervisionApi.replace('{id}', data.id), {
        method: 'DELETE',
        params: data
    })
}

export async function DeleteTrainingAppointment(data) {
    return getRequest()(DeleteTrainingAppointmentApi.replace('{id}', data.id), {
        method: 'DELETE',
        params: data
    })
}

export async function PutSupervisorSSummary(data) {
    return getRequest()(PutSupervisorSSummaryApi, {
        method: 'PUT',
        data
    })
}


export async function PutTrainerTrainingSupervisionSummary(data) {
    return getRequest()(PutTrainerTrainingSupervisionSummaryApi, {
        method: 'PUT',
        data
    })
}

export async function GetTrainerTrainingSupervisionDetail(data) {
    return getRequest()(GetTrainerTrainingSupervisionDetailApi.replace('{trainerTrainingModuleId}', data.trainerTrainingModuleId), {
        method: 'GET',
        params: data
    })
}

export async function PutTrainingAppointment(data) {
    return getRequest()(GetTrainingAppointmentApi, {
        method: 'Put',
        data
    })
}

export async function PostTrainingAppointment(data) {
    return getRequest()(GetTrainingAppointmentApi, {
        method: 'POST',
        data
    })
}

export async function GetTrainingAppointment(data) {
    return getRequest()(GetTrainingAppointmentApi, {
        method: 'GET',
        params: data
    })
}

export async function PostTrainingAppointmentFeedback(data) {
    return getRequest()(PostTrainingAppointmentFeedbackApi, {
        method: 'POST',
        data
    })
}

export async function PostTrainingAppointmentComplete(data) {
    return getRequest()(PostTrainingAppointmentCompleteApi, {
        method: 'POST',
        data
    })
}

export async function GetTrainerTrainingModuleDetail(data) {
    return getRequest()(GetTrainerTrainingModuleDetailApi.replace('{trainerTrainingModuleId}', data.trainerTrainingModuleId), {
        method: 'GET',
        params: data
    })
}

export async function GetTrainingList(data) {
    return getRequest()(GetTrainingListApi, {
        method: 'GET',
        params: data
    })
}

export async function GetCredentialsCode(data) {
    return getRequest()(GetCredentialsCodeApi, {
        method: 'GET',
        params: data
    })
}

export async function GetPrepareForIssueCertificate(data) {
    return getRequest()(GetPrepareForIssueCertificateApi, {
        method: 'GET',
        params: data
    })
}

export async function PostSelectTenantTrainingModule(data) {
    return getRequest()(GetSelectTenantTrainingModuleApi, {
        method: 'POST',
        data
    })
}

export async function GetSelectTenantTrainingModule(data) {
    return getRequest()(GetSelectTenantTrainingModuleApi, {
        method: 'GET',
        params: data
    })
}

export async function PutTrainingModuleInUse(data) {
    return getRequest()(PutTrainingModuleInUseApi, {
        method: 'PUT',
        data
    })
}

export async function PostDiagnosticInformation(data) {
    return getRequest()(PostDiagnosticInformationApi, {
        method: 'POST',
        data
    })
}

export async function PostChiefComplaint(data) {
    return getRequest()(PostChiefComplaintApi, {
        method: 'POST',
        data
    })
}

export async function PostModuleCourseCount(data) {
    return getRequest()(PostModuleCourseCountApi, {
        method: 'POST',
        data
    })
}

export async function PostExportPostTrainerList(data) {
    return getRequest()(PostExportPostTrainerListApi, {
        reponseType: 'blob',
        method: 'POST',
        data,
    })
}

export async function PostEndDate(data) {
    return getRequest()(PostEndDateApi, {
        method: 'POST',
        data
    })
}

export async function PutTrainerRemark(data, params) {
    return getRequest()(PutTrainerRemarkApi.replace("{trainerTrainingModuleId}", params.id), {
        method: 'PUT',
        params: data
    })
}

export async function GetTrainingDetailExcel(data) {
    return getRequest()(GetTrainingDetailExcelApi, {
        method: 'GET',
        params: data
    })
}

export async function GetChiefComplaintList(data) {
    return getRequest()(GetChiefComplaintListApi, {
        method: 'GET',
        params: data
    })
}

export async function GetDiagnosticInformationList(data) {
    return getRequest()(GetDiagnosticInformationListApi, {
        method: 'GET',
        params: data
    })
}

export async function GetSupervisorList(data) {
    return getRequest()(GetSupervisorListApi, {
        method: 'GET',
        params: data
    })
}

export async function GetCourseList(data) {
    return getRequest()(GetCourseListApi, {
        method: 'GET',
        params: data,
    })
}

export async function GetTrainCenterList(data) {
    return getRequest()(GetTrainCenterListApi, {
        method: 'GET',
        params: data,
    })
}

export async function GetTrainingDetail(data) {
    return getRequest()(GetTrainingDetailApi, {
        method: 'GET',
        params: data,
    })
}

export async function PostQualification(data) {
    return getRequest()(PostQualificationApi, {
        method: 'POST',
        data,
    })
}


export function PostQuestionList(data) {
    return getRequest()(PostQuestionListApi, {
        method: 'POST',
        data,
    })
}

export async function PostRelatingClass(data) {
    return getRequest()(PostRelatingClassApi, {
        method: 'POST',
        data
    })
}

export async function DeleteCert(data) {
    return getRequest()(DeleteCertApi.replace("{certificateId}", data.certificateId), {
        method: 'DELETE',
    })
}

export async function PutAmount(data) {
    return getRequest()(EditAmount, {
        method: 'PUT',
        data,
    })
}

export async function GetQualification(data) {
    return getRequest()(GetQualificationApi, {
        method: 'GET',
        params: data
    })
}

export async function GetTrainingModuleQrCode(data) {
    return getRequest()(GetTrainingModuleQrCodeApi, {
        method: 'GET',
        params: data
    })
}

export async function GetUserInfo(data) {
    return getRequest()(GetUserInfoApi.replace("{id}", data.id), {
        method: 'GET',
        params: data
    })
}


export async function DeleteQualification(data) {
    return getRequest()(DeleteQualificationApi.replace('{id}', data.id), {
        method: 'DELETE',
        params: data
    })
}

export async function PutQualification(data) {
    return getRequest()(PutQualificationApi.replace('{id}', data.id), {
        method: 'PUT',
        data
    })
}

export async function PostPrepareTrainingModule(data) {
    return getRequest()(PostPrepareTrainingModuleApi, {
        method: 'POST',
        data,
    })
}

export async function PostTrainingModule(data) {
    return getRequest()(PostTrainingModuleApi, {
        method: 'POST',
        data,
    })
}

export async function GetTrainingModule(data) {
    return getRequest()(GetTrainingModuleApi, {
        method: 'GET',
        params: data
    })
}

export async function PutTrainingModule(data) {
    return getRequest()(PutTrainingModuleApi.replace('{id}', data.id), {
        method: 'PUT',
        data
    })
}

export async function DeleteTrainingModule(data) {
    return getRequest()(DeleteTrainingModuleApi.replace('{id}', data.id), {
        method: 'DELETE',
        params: data,
    })
}

export async function PutSortOrderMove(data) {
    return getRequest()(PutSortOrderMoveApi.replace('{id}', data.id), {
        method: 'PUT',
        params: data
    })
}

export async function GetTrainingModuleDetail(data) {
    return getRequest()(GetTrainingModuleDetailApi.replace('{id}', data.id), {
        method: 'GET',
        params: data
    })
}

export async function PutTrainingModuleCourse(data) {
    return getRequest()(PutTrainingModuleCourseApi, {
        method: 'PUT',
        data
    })
}

export async function PutCourseOrder(data) {
    return getRequest()(PutCourseOrderApi, {
        method: 'PUT',
        data
    })
}

export async function DeleteTrainingModuleCourse(data) {
    return getRequest()(DeleteTrainingModuleCourseApi, {
        method: 'DELETE',
        params: data,
    })
}

export async function GetTrainingCoursesByModule(data) {
    return getRequest()(GetTrainingCoursesByModuleApi, {
        method: 'GET',
        params: data
    })
}

export async function PostTrainingModuleResource(data) {
    return getRequest()(PostTrainingModuleResourceApi, {
        method: 'POST',
        data,
    })
}

export async function PutTenantTrainingModule(data) {
    return getRequest()(PutTenantTrainingModuleApi, {
        method: 'PUT',
        data
    })
}

export async function PostStartTenantTrainingModule(data) {
    return getRequest()(PostStartTenantTrainingModuleApi, {
        method: 'POST',
        data,
    })
}

export async function PostEndTenantTrainingModule(data) {
    return getRequest()(PostEndTenantTrainingModuleApi, {
        method: 'POST',
        data,
    })
}

export async function GetTenantTrainerModuleList(data) {
    return getRequest()(GetTenantTrainerModuleListApi, {
        method: 'GET',
        params: data
    })
}

export async function GetCanArrangeTrainerList(data) {
    return getRequest()(GetCanArrangeTrainerListApi, {
        method: 'GET',
        params: data
    })
}

export async function PostArrangeTrainer(data) {
    return getRequest()(PostArrangeTrainerApi, {
        method: 'POST',
        data,
    })
}

export async function GetTrainerList(data) {
    return getRequest()(PostTrainerListApi, {
        method: 'POST',
        data
    })
}

export async function GetAdminStaffList(data) {
    return getRequest()(GetAdminStaffListApi, {
        method: 'GET',
        params: data
    })
}

export async function StaffDetail(data) {
    return getRequest()(GetStaffDetailApi.replace('{staffId}', data.id), {
        method: 'GET'
    })
}

export async function GetProfile(data) {
    return getRequest()(GetProfileApi.replace('{id}', data.id), {
        method: 'GET',
        params: data
    })
}

export async function GetTrainerModuleList(data) {
    return getRequest()(GetTrainerModuleListApi, {
        method: 'GET',
        params: data
    })
}

export async function GetTrainerModuleDetail(data) {
    return getRequest()(GetTrainerModuleDetailApi, {
        method: 'GET',
        params: data
    })
}

export async function GetTrainingApplication(data) {
    return getRequest()(GetTrainingApplicationApi, {
        method: 'GET',
        params: data
    })
}

export async function PutQuestionnaire(data) {
    return getRequest()(PutQuestionnaireApi, {
        method: 'PUT',
        data
    })
}

export async function StartStudy(data) {
    return getRequest()(StartStudyApi.replace('{trainerTrainingId}', data.trainerTrainingId), {
        method: 'POST',
        data,
    })
}

export async function FinishCourse(data) {
    return getRequest()(FinishCourseApi, {
        method: 'POST',
        data,
    })
}

export async function GetQuestionnaire(data) {
    return getRequest()(CreateQuestionnaireApi.replace('{id}', data.id), {
        method: 'GET',
    })
}

export async function PutUpdateQuestionnaire(data) {
    return getRequest()(PutUpdateQuestionnaireApi, {
        method: 'PUT',
        data
    })
}

export async function PostNewTrainerTrainingModule(data) {
    return getRequest()(PostNewTrainerTrainingModuleApi, {
        method: 'POST',
        data,
    })
}

export async function PostStopOrStart(data) {
    return getRequest()(PostStopOrStartApi.replace('{trainerTrainingModuleId}', data.trainerTrainingModuleId), {
        method: 'POST',
        data,
    })
}

export async function PostIssueCertificate(data) {
    return getRequest()(PostIssueCertificateApi, {
        method: 'POST',
        data,
    })
}

export async function PostGetTrainingApplication(data) {
    return getRequest()(PostTrainingApplicationApi, {
        method: 'POST',
        data,
    })
}

export async function PostUploadUserResumes(data) {
    return getRequest()(PostUploadUserResumesApi, {
        method: 'POST',
        data,
    })
}

export async function GetAppUserQuestionnarie(data) {
    return getRequest()(GetAppUserQuestionnarieApi, {
        method: 'GET',
        params: data
    })
}

export async function GetCertificateList(data) {
    return getRequest()(GetCertificateListApi, {
        method: 'GET',
        params: data
    })
}

export async function GetTrainerDetail(data) {
    return getRequest()(GetTrainerDetailApi, {
        method: 'GET',
        params: data
    })
}

export async function PostStopCertificate(data) {
    return getRequest()(PostStopCertificateApi.replace('{certificateId}', data.certificateId), {
        method: 'POST',
        data,
    })
}

export async function PutTrainerCertificate(data) {
    return getRequest()(PutTrainerCertificateApi, {
        method: 'PUT',
        data
    })
}

export async function GetTrainerTrainingFeedback(data) {
    return getRequest()(GetTrainerTrainingFeedbackApi, {
        method: 'GET',
        params: data
    })
}

export async function PostTrainerTrainingFeedback(data) {
    return getRequest()(PostTrainerTrainingFeedbackApi, {
        method: 'POST',
        data,
    })
}

export async function GetTrainerSummaryList(data) {
    return getRequest()(GetTrainerSummaryListApi, {
        method: 'GET',
        params: data
    })
}

export async function PostSupervisorSummary(data) {
    return getRequest()(PostSupervisorSummaryApi, {
        method: 'POST',
        data,
    })
}

export async function PostTrainerSummary(data) {
    return getRequest()(PostTrainerSummaryApi, {
        method: 'POST',
        data,
    })
}

export async function PutTrainerSummary(data) {
    return getRequest()(PutTrainerSummaryApi, {
        method: 'PUT',
        data,
    })
}

export async function PutSupervisorSummary(data) {
    return getRequest()(PutSupervisorSummaryApi, {
        method: 'PUT',
        data,
    })
}

export async function GetSupervisorSSummary(data) {
    return getRequest()(GetSupervisorSSummaryAPi, {
        method: 'GET',
        params: data
    })
}

export async function PostSendCheckNumberCode(data) {
    return getRequest()(PostSendCheckNumberCodeApi, {
        method: 'POST',
        data,
    })
}

export async function PostPayOrder(data) {
    return getRequest({ myBaseURL: payBaseURL, credentials: false })(PostPayOrderApi, {
        method: 'POST',
        data,
    })
}

export async function GetPayStatus(data) {
    return getRequest({ myBaseURL: payBaseURL, credentials: false })(GetPayStatusApi.replace("{code}", data.code), {
        method: 'GET',
        params: data,
    })
}

export async function RefreshPayStatus(data) {
    return getRequest({ myBaseURL: payBaseURL, credentials: false })(RefreshPayStatusApi.replace("{code}", data.code), {
        method: 'PUT',
        data
    })
}