
import { Divider } from 'antd';
import style from './index.less';

const CardHead = ({ title, extra = <></> }) => {
    return (
        <div className={style.cardHead}>
            <div className={style.cardHeadTitle}>
                <span className={style.title}>
                    {title}
                </span>
                <span className={style.cardExtra}>
                    {extra}
                </span>
            </div>
            <Divider className={style.cardHeadDivider} />
        </div>
    )
}

export default CardHead;
