import React, { useState, useEffect } from 'react';
import { deepCopy, showErrorMsg } from 'cognitiveleap-core-us/utils/utils';
import { useIntl } from 'umi';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';
import { UpdateItem, CreateTrainingPlan } from 'services/group';

const trainContentMenu = (intl) => {
  return {
    Properties: [
      {
        EditorType: 'SingleWithSearch',
        Value: null,
        Setting: {
          DropdownOptions: [],
          Required: true,
        },
        ShowTitle: intl.formatMessage({ id: 'selectPlan'}),
        FormKey: 'trainingPlanV2s',
        Description: intl.formatMessage({ id: 'canNotEdit'}),
      },
    ],
  };
} 

const ConnectTrainPlan = ({
  type = 'other',
  currentItem,
  closeModal = () => {},
  openSelectChild = () => {},
  reloadPage = () => {},
}) => {

  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modalContent, setModalContent] = useState(trainContentMenu(intl));

  const getData = async () => {
    const resData = await UpdateItem({
      type: 'Null',
      id: currentItem.id,
    });
    if (resData.response.ok) {
      const { trainingPlanV2s } = resData.data;
      let emptyContent = deepCopy(trainContentMenu(intl));
      emptyContent.Properties[0].Setting.DropdownOptions = trainingPlanV2s.map(
        (item) => {
          return {
            Value: item.displayName,
            Id: item.id,
          };
        },
      );
      setModalContent(emptyContent);
      setVisible(true);
    } else {
      showErrorMsg(resData, intl);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const trainModalProps = {
    title: intl.formatMessage({ id: 'selectPlan'}),
    cancelText: intl.formatMessage({ id: 'later'}),
    visible,
    content: modalContent,
    loading: loading,
    onCancel() {
      closeModal();
      setVisible(false);
    },
    async onOk(value) {
      const { trainingPlanV2s } = value;
      setLoading(true);

      const res = await CreateTrainingPlan({
        groupId: currentItem.id,
        trainingPlanV2Id: trainingPlanV2s,
      });

      if (res.response.ok) {
        const { groupLinkUsers } = currentItem;
        type === 'add' && groupLinkUsers.length === 0 && openSelectChild();
        reloadPage();
      } else {
        showErrorMsg(res, intl);
      }
      closeModal()
      setVisible(false);
      setLoading(false);
    },
  };

  return <MagicFormModal {...trainModalProps} />;
};

export default ConnectTrainPlan;
