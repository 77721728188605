import React from 'react';
import { Spin } from 'antd';

const SpinCom = () => {
  return (
    <div style={{ width: '100%', textAlign: 'center', marginTop: '-10px' }}>
      <Spin />
    </div>
  );
};

export default React.memo(SpinCom);
