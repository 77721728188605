import getRequest from 'utils/request'

const AddToCourse = '/api/rocketAdmin/course/detail'
const PutToCourse = '/api/rocketAdmin/course/detail'
const EditCourseOrder = '/api/rocketAdmin/course/move/{courseId}'
const EditItemOrder = '/api/rocketAdmin/course/detailMove'
const DeleteItem = '/api/rocketAdmin/course/detail'
const PutPublishStatus = '/api/rocketAdmin/course/contentStatus'

export async function AddCourseItem(data) {
  return getRequest()(AddToCourse, {
    method: 'POST',
    data,
  })
}

export async function UpdateCourseItem(data) {
  return getRequest()(PutToCourse, {
    method: 'PUT',
    data,
  })
}

export async function EditSortOrder(courseId, newSortOrder) {
  return getRequest()(EditCourseOrder.replace('{courseId}', courseId), {
    method: 'PUT',
    params: { newSortOrder },
  })
}

export async function EditCourseItemSortOrder(data) {
  return getRequest()(EditItemOrder, {
    method: 'PUT',
    params: data,
  })
}

export async function PublishStatus(data) {
  return getRequest()(PutPublishStatus, {
    method: 'PUT',
    data,
  })
}

export async function DeleteCourseItem(data) {
  return getRequest()(DeleteItem, {
    method: 'DELETE',
    data,
  })
}
