import React, { useState, useEffect } from 'react';
import { useIntl } from 'umi';
import { Drawer, Tabs, Divider, Space, message } from 'antd';

import Source from './components/Source';
import Certificate from './components/Certificate';

import { getStaffUserCourses } from 'services/questionnaires';

import styles from './index.less';

const timeFormat = 'YYYY/MM/DD HH:mm';

const TrainWithClassDrawer = ({
  visible = false,
  userInfo = {},
  onCloseDraw = () => {},
}) => {
  const intl = useIntl();

  const { id, name } = userInfo || {};

  const getIntlMsg = (id) => intl.formatMessage({ id });

  const [data, setData] = useState({});

  const initData = async () => {
    const res = await getStaffUserCourses({
      id,
    });

    if (res.response.ok) {
      setData(res.data);
    } else {
      const {
        data: { error },
      } = res;
      message.warning(error.message);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  const {
    jobTitle,
    offlineCourseQuantity,
    onlineCourseQuantity,
    userAccount,
    workUnit,
  } = data || {};

  const onDrawerClose = () => {
    onCloseDraw();
  };

  const sourceProps = {
    userInfo,
    timeFormat,
    getIntlMsg,
  };

  const certificateProps = {
    userInfo,
    timeFormat,
    getIntlMsg,
  };

  return (
    <Drawer
      title={intl.formatMessage(
        { id: 'The Training and Classes of' },
        { name },
      )}
      width={800}
      placement="right"
      onClose={onDrawerClose}
      open={visible}
      className={styles.drawerPanel}
    >
      <Space>
        <div>
          {getIntlMsg('Offline Classes (hrs)')}: {offlineCourseQuantity}
        </div>
        <Divider type="vertical" />
        <div>
          {getIntlMsg('Online Classes (hrs)')}: {onlineCourseQuantity}
        </div>
        <Divider type="vertical" />
        <div>{userAccount}</div>
        {jobTitle && (
          <>
            <Divider type="vertical" />
            <div>{jobTitle}</div>
          </>
        )}
        {workUnit && (
          <>
            <Divider type="vertical" />
            <div>{workUnit}</div>
          </>
        )}
      </Space>
      <p />
      <Tabs
        type="card"
        defaultActiveKey="source"
        items={[
          {
            label: getIntlMsg('Supervision Score'),
            key: 'source',
            children: <Source {...sourceProps} />,
          },
          {
            label: getIntlMsg('Exams and Certificates'),
            key: 'certificate',
            children: <Certificate {...certificateProps} />,
          },
        ]}
      />
    </Drawer>
  );
};

export default TrainWithClassDrawer;
