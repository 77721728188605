import getRequest from 'utils/request'
import { getLocale } from 'umi'
import download from 'cognitiveleap-core-us/utils/download'

const LangOptions = {
  'en-US': 'en',
  'zh-CN': 'zh-Hans',
  'es-ES': 'es'
}

function getStr(str) {
  var result = str.match(/UTF-8.+/g)
  return result[0]
}

const PostTestRequestsPaged = '/api/vrclassroom/TestingCenter/TestRequestsPaged'
const GetSortedSubjectInfo = '/api/vrclassroom/TestingCenter/SubjectsPaged'
const AddSubject = '/api/vrclassroom/TestingCenter/Subject'
const updateSubjectInfo = '/api/vrclassroom/TestingCenter/Subject'
const PostStatistics = '/api/vrclassroom/TestingCenter/TestRequestStatistics'
const GetInfoById = '/api/vrclassroom/TestingCenter/TestRequestInfo'
const ChangeTestType = '/api/vrclassroom/TestingCenter/TestType'
const CaseCode = '/api/vrclassroom/TestingCenter/CaseCode'
const AddTest = '/api/vrclassroom/TestingCenter/TestRequest'
const GuardianPaged = '/api/vrclassroom/TestingCenter/GuardianPaged'
const SignConsentForm = '/api/vrclassroom/TestingCenter/ConsentFormSign'
const DeleteComment = '/api/vrclassroom/TestingCenter/DeleteComment'
const AddComment = '/api/vrclassroom/TestingCenter/AddComment'
const GetSubjectInfo = '/api/vrclassroom/TestingCenter/SubjectInfo'
const GetSystemInfo = '/api/vrclassroom/TestingCenter/SystemInfo'
const SearchGuardian = '/api/vrclassroom/TestingCenter/SearchGuardian'
const DeleteSubject = '/api/vrclassroom/TestingCenter/Subject'
const GetSearchContent = '/api/vrclassroom/TestingCenter/SearchName'
const GetDistinctContent = '/api/vrclassroom/TestingCenter/Instant'
const PostCenterTestExcelApi = '/api/vrclassroom/TestingCenter/TestRequestsExcel'
const PostSendEmail = '/api/vrclassroom/TestingCenter/SendReportToEmail'
const DownloadStatistic = '/api/vrclassroom/TestingCenter/TestRequestStatistics/Excel'
const PutNormalDataApi = '/api/vrclassroom/Admin/TestRequest/NormalData'
const ForEdit = '/api/vrclassroom/TestingCenter/prepareForEdit'

export async function GetStatistic(data) {
  return getRequest()(DownloadStatistic, {
    reponseType: 'blob',
    method: 'POST',
    data,
  }).then(res => {
    const { response } = res
    if (response.ok) {
      var filename = response.headers.get('Content-Disposition')
      filename = getStr(filename)
      const reportTitle = decodeURIComponent(filename).replace("UTF-8''", '')
      response.blob().then(blob => {
        download(blob, reportTitle)
      })
    }
  })
}

export async function PutNormalData(data) {
  return getRequest()(PutNormalDataApi, {
    method: 'PUT',
    params: data,
  })
}

export async function PostCenterTestExcel(urlData, bodyData) {
  return getRequest()(PostCenterTestExcelApi, {
    reponseType: 'blob',
    method: 'POST',
    params: urlData,
    data: bodyData,
  }).then(res => {
    const { response } = res
    if (response.ok) {
      var filename = response.headers.get('Content-Disposition')
      filename = getStr(filename)
      const reportTitle = decodeURIComponent(filename).replace("UTF-8''", '')
      response.blob().then(blob => {
        download(blob, reportTitle)
      })
    }
  })
}

export async function GetDistinct(searchContent, limit = 12) {
  return getRequest()(GetDistinctContent, {
    method: 'GET',
    params: { searchContent, limit },
  })
}

export async function GetSearch(searchField, limit = 12) {
  return getRequest()(GetSearchContent, {
    method: 'GET',
    params: { searchField, limit },
  })
}

export async function DeleteTestSubject(subjectId) {
  return getRequest()(DeleteSubject, {
    method: 'DELETE',
    params: {
      subjectId,
    },
  })
}

export async function GetSearchGuardian(searchField, limit = 10) {
  return getRequest()(SearchGuardian, {
    method: 'GET',
    params: {
      searchField,
      limit,
    },
  })
}

export async function GetProductList() {
  return getRequest()(GetSystemInfo, {
    method: 'GET',
  })
}

export async function TestRequestsPaged(queryData, bodyData) {
  return getRequest()(PostTestRequestsPaged, {
    method: 'POST',
    params: queryData,
    data: bodyData,
  })
}

export async function SendReportEmail(data) {
  const language = LangOptions[getLocale()]
  return getRequest()(PostSendEmail, {
    method: 'POST',
    data: {
      ...data,
      language,
    },
  })
}

export async function GetSubjectInfoById(subjectId) {
  return getRequest()(GetSubjectInfo, {
    method: 'GET',
    params: { subjectId },
  })
}

export async function DoctorAddComment(data) {
  return getRequest()(AddComment, {
    method: 'POST',
    data,
  })
}

export async function DeleteDocComment(commentId) {
  return getRequest()(DeleteComment, {
    method: 'DELETE',
    params: { commentId },
  })
}

export async function CheckConsentForm(testRequestId) {
  return getRequest()(SignConsentForm, {
    method: 'POST',
    params: { testRequestId },
  })
}

export async function GetSortedSubject(queryData, bodyData) {
  return getRequest()(GetSortedSubjectInfo, {
    method: 'POST',
    params: queryData,
    data: bodyData,
  })
}

export async function AddTestSubject(data) {
  return getRequest()(AddSubject, {
    method: 'POST',
    data,
  })
}

export async function UpdateTestSubject(subjectId, data) {
  return getRequest()(updateSubjectInfo, {
    method: 'PUT',
    params: { subjectId },
    data,
  })
}

export async function PostCenterStatistics(queryData, bodyData) {
  return getRequest()(PostStatistics, {
    method: 'POST',
    params: queryData,
    data: bodyData,
  })
}

export async function GetRequestInfoById(queryData) {
  return getRequest()(GetInfoById, {
    method: 'GET',
    params: queryData,
  })
}

export async function TestTypeChange(bodyData) {
  return getRequest()(ChangeTestType, {
    method: 'POST',
    data: bodyData,
  })
}

export async function GetCaseCode(queryData) {
  return getRequest()(CaseCode, {
    method: 'GET',
    params: queryData,
  })
}

export async function AddTestRequest(queryData, bodyData) {
  return getRequest()(AddTest, {
    method: 'POST',
    params: queryData,
    data: bodyData,
  })
}

export async function PostGuardianPaged(queryData, bodyData) {
  return getRequest()(GuardianPaged, {
    method: 'POST',
    params: queryData,
    data: bodyData,
  })
}

export async function GetForEdit(subjectId) {
  return getRequest()(ForEdit, {
    method: 'GET',
    params: { subjectId },
  })
}
