import React, { useState, useEffect } from 'react';
import { Input, Select, InputNumber, Modal, Form, message, Spin } from 'antd';
import { useIntl } from 'umi';
import { GetCenterSubjectList } from 'services/userManager';
import {
  GetAssessmentTimesSettingPrepare,
  PostAssessmentTimesSettingRecords,
} from 'services/userConfiguration';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const fetch = async (value, callback) => {
  if (!value) return;

  const res = await GetCenterSubjectList({
    skipCount: 0,
    maxResultCount: 999,
    status: 'Bind',
    filter: value,
  });

  if (res.response.ok) {
    const { items } = res?.data || {};
    callback(items);
  } else {
    const {
      data: { error },
    } = res;
    message.error(error.message);
  }
};

const AddAssessmentModal = ({
  visible = false,
  onCancel,
  reloadTable,
  currentItem
}) => {
  const intl = useIntl();

  const [form] = Form.useForm();

  const { remainingTimes, name, tagName } = currentItem || {};

  const [childList, setChildList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [selectType, setSelectType] = useState('add');
  const [selectValue, setSelectValue] = useState();
  const [loading, setLoading] = useState({
    formLoading: false,
    modalLoading: false,
  });

  const initData = async () => {
    setLoading({
      ...loading,
      formLoading: true,
    });
    const res = await GetAssessmentTimesSettingPrepare();

    if (res.response.ok) {
      const { tagList } = res?.data || {};
      setTagList(tagList);
    } else {
      const {
        data: { error },
      } = res;
      message.error(error.message);
    }

    setLoading({
      ...loading,
      formLoading: false,
    });
  };

  useEffect(() => {
    !(currentItem) && initData();
  }, []);

  const onFinish = async (value) => {
    setLoading({
      ...loading,
      modalLoading: true,
    });

    const { times } = value;

    let props;
    if (currentItem) {
      const { tag, subject } = currentItem;
      const { id: tagId } = tag || {};
      const { id: userId } = subject || {};

      props = {
        ...value,
        userId,
        tagId,
        times: selectType === 'reduce' ? -times : times,
      };
    } else {
      props = {
        ...value,
        times: selectType === 'reduce' ? -times : times,
      };
    }

    const res = await PostAssessmentTimesSettingRecords(props);

    if (res.response.ok) {
      message.success(intl.formatMessage({ id: 'submitted' }));
      onCancel();
      reloadTable();
    } else {
      const { error } = res.data;
      message.error(error.message);
    }

    setLoading({
      ...loading,
      modalLoading: false,
    });
  };

  const handleCancel = () => {
    onCancel();
  };

  const selectBefore = (
    <Select
      value={selectType}
      onChange={(value) => setSelectType(value)}
      options={[
        {
          value: 'add',
          label: '+',
        },
        {
          value: 'reduce',
          label: '-',
        },
      ]}
    />
  );

  const handleSearch = (newValue) => {
    if (newValue) {
      fetch(newValue, setChildList);
    } else {
      setChildList([]);
    }
  };

  const handleChange = (newValue) => {
    setSelectValue(newValue);
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'Add assessment count' })}
      open={visible}
      width={800}
      onOk={() => form.submit()}
      onCancel={handleCancel}
      confirmLoading={loading.modalLoading}
    >
      <Spin spinning={loading.formLoading}>
        <Form
          name="AddAssessmentForm"
          form={form}
          {...formItemLayout}
          onFinish={onFinish}
        >
          {currentItem && (
            <Form.Item label={intl.formatMessage({ id: 'Remaining Times' })}>
              <span>{remainingTimes ?? '-'}</span>
            </Form.Item>
          )}

          {currentItem ? (
            <Form.Item label={intl.formatMessage({ id: 'subjectName' })}>
              <span>{name ?? '-'}</span>
            </Form.Item>
          ) : (
            <Form.Item
              label={intl.formatMessage({ id: 'Select Child' })}
              name="userId"
              rules={[
                {
                  required: true,
                  message: `${intl.formatMessage({
                    id: 'Select Child',
                  })} ${intl.formatMessage({ id: 'isRequired' })}`,
                },
              ]}
            >
              <Select
                showSearch
                placeholder={intl.formatMessage({
                  id: 'Enter and search to select',
                })}
                value={selectValue}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={handleSearch}
                onChange={handleChange}
                notFoundContent={null}
                options={childList.map((item) => {
                  const { name, id } = item || {};

                  return {
                    value: id.toString(),
                    label: name,
                  };
                })}
              />
            </Form.Item>
          )}

          {currentItem ? (
            <Form.Item label={intl.formatMessage({ id: 'assessment' })}>
              <span>{tagName ?? '-'}</span>
            </Form.Item>
          ) : (
            <Form.Item
              label={intl.formatMessage({ id: 'assessment' })}
              name="tagId"
              rules={[
                {
                  required: true,
                  message: `${intl.formatMessage({
                    id: 'assessment',
                  })} ${intl.formatMessage({ id: 'isRequired' })}`,
                },
              ]}
            >
              <Select
                placeholder={intl.formatMessage({
                  id: 'Enter and search to select',
                })}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={tagList.map((item) => {
                  const { displayName, id } = item || {};

                  return {
                    value: id.toString(),
                    label: displayName,
                  };
                })}
              />
            </Form.Item>
          )}

          <Form.Item
            label={intl.formatMessage({ id: 'Amount of Assessment' })}
            name="times"
            rules={[
              {
                required: true,
                message: `${intl.formatMessage({
                  id: 'Amount of Assessment',
                })} ${intl.formatMessage({ id: 'isRequired' })}`,
              },
            ]}
          >
            <InputNumber addonBefore={selectBefore} min="0" />
          </Form.Item>

          <Form.Item
            label={intl.formatMessage({ id: 'comments' })}
            name="remark"
          >
            <Input />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AddAssessmentModal;
