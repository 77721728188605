import React, { useState } from 'react';
import { Modal, Checkbox, Button } from 'antd';
import SubjectInfoCard from 'components/SubjectInfoCard';
import { useIntl } from 'umi';
import { ExclamationCircleFilled, CloseCircleOutlined } from '@ant-design/icons';

import styles from './userList.less';

const ConfirmModal = ({ type, subjectInfo, visible, onCancel, onOk, loading }) => {
  const intl = useIntl();
  const [disable, setDisable] = useState(true);
  const onSubmit = () => {
    onOk();
  };

  const onQuit = () => {
    onCancel();
  };

  const handleOnchange = () => {
    setDisable(!disable);
  };

  const okStyle = {
    backgroundColor: '#FF3B30 ',
    color: '#fff',
    border: 'none',
    marginLeft: '30px',
  };

  const cancelStyle = {
    backgroundColor: '#fff ',
    color: '#262626',
    border: '1px solid #ccc',
    marginLeft: '30px',
  };

  const disableStyle = {
    backgroundColor: '#ff00008f',
    border: 'none',
    color: '#fff',
    marginLeft: '30px',
  };
  return (
    <Modal
      maskClosable={false}
      open={visible}
      footer={null}
      title={<span style={{fontSize: '14px'}}><b>{intl.formatMessage({ id: 'sureInfoAgain' })}</b></span>}
      confirmLoading={loading}
      zIndex={1000}
      width={730}
      onCancel={onQuit}
      className={styles.modalMain}
      afterClose={() => {
        setDisable(true);
      }}
      closeIcon={<CloseCircleOutlined />}
      destroyOnClose={true}
    >
      <SubjectInfoCard subjectInfo={subjectInfo} />
      <div
        className={styles.modalTips}
      >
        <ExclamationCircleFilled style={{fontSize: '28px', color:'#faad14'}}/>&nbsp;&nbsp;&nbsp;{intl.formatMessage({ id: type === 'edit' ? 'editChildSubmitTip' : 'subTip' })}
      </div>
      {/* <div className={styles.tips}>{intl.formatMessage({ id: 'subTipInfo' })}</div> */}
      {/* <Checkbox style={{ marginLeft: '300px' }} onChange={handleOnchange}>
        {intl.formatMessage({ id: 'risk' })}
      </Checkbox> */}
      <Button
        // disabled={disable}
        // style={disable ? disableStyle : okStyle}
        style={{ marginLeft: '50%' }}
        onClick={onSubmit}
        loading={loading}
        type='primary'
      >
        {intl.formatMessage({ id: type === 'edit' ? 'submit' : 'Confirm Add' })}
      </Button>
      <Button style={cancelStyle} onClick={onQuit}>
        {intl.formatMessage({ id: 'return' })}
      </Button>
    </Modal>
  );
};

export default ConfirmModal;
