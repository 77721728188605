module.exports = {
  scannedSubjects: 'scannedSubjects',
  currentSubject: 'currentSubject',
  trainingMaterialCourseId: 'tMCourse',
  trainingMaterialContentId: 'tMContent',
  trainingMaterialCache: 'trainingMaterialCache',
  musicRecord: 'musicRecord',
  currentPlanMusicRecord: 'currentPlanMusicRecord',
  prePlanInfo: 'prePlanInfo'
}
