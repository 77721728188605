  import { PlanUserInfo } from 'services/rocketService/TrainingPlan'
  
  export default {
    namespace: 'subjectSleep',
  
    state: {
      planInfo:null,
      list: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      }
    },
  
    reducers: {
      updateState(state, { payload }) {
        return {
          ...state,
          ...payload,
        }
      },
    },
  
    effects: {
  
      *loadList(_, { call, put, take, select }) {
        const { subjectInfo } = yield select(state => state.subjectDetail)
        const res = yield call(PlanUserInfo, {subjectId: subjectInfo.id, type: 'Sleep'})
        if(res.response.ok){
          yield put({
            type: 'updateState',
            payload: { planInfo: res.data },
          })
        }
      },
  
      *changeTable({ payload: data }, { call, put, select }) {
        const { pagination: originPage } = yield select(state => state.subjectSleep)
        const { pagination } = data
        yield put({ type: 'updateState', payload: { pagination: { ...originPage, ...pagination } } })
        yield put({ type: 'loadList' })
      },
    },
  
    subscriptions: {
      setup({ dispatch, history }) {
        // Warning: [app.unmodel] subscription should return unlistener function, check these subscriptions setup
        return ()=>{}
      },
    },
  }
  