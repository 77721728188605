import getRequest from 'utils/request'

const PostPostTrainingProgram = '/api/rocketAdmin/trainingProgram'
const GetGetTrainingProgramList = '/api/rocketAdmin/trainingProgram'
const GetGetTrainingProgram = '/api/rocketAdmin/trainingProgram/{id}'
const PutPutTrainingProgram = '/api/rocketAdmin/trainingProgram/{id}'
const DeleteDeleteTrainingProgram = '/api/rocketAdmin/trainingProgram/{id}'
const GetPrepareForEdit = '/api/rocketAdmin/trainingProgram/prepareForEdit'
const GetTrainingProgramDetail = '/api/rocketAdmin/trainingProgram/detail'

export async function PostTrainingProgram(data) {
  return getRequest()(PostPostTrainingProgram, {
    method: 'POST',
    data,
  })
}

export async function GetTrainingProgramList(data) {
  return getRequest()(GetGetTrainingProgramList, {
    method: 'GET',
    params: data,
  })
}

//{Id}
export async function TrainingProgramDetail(data) {
  return getRequest()(GetTrainingProgramDetail, {
    method: 'GET',
    params: data,
  })
}

export async function GetTrainingProgram(id) {
  return getRequest()(GetGetTrainingProgram.replace('{id}', id), {
    method: 'GET',
  })
}
export async function PutTrainingProgram(data, id) {
  return getRequest()(PutPutTrainingProgram.replace('{id}', id), {
    method: 'PUT',
    data,
  })
}

//{Id}
export async function PrepareForEdit(data) {
  return getRequest()(GetPrepareForEdit, {
    method: 'GET',
    params: data,
  })
}

export async function DeleteTrainingProgram(id) {
  return getRequest()(DeleteDeleteTrainingProgram.replace('{id}', id), {
    method: 'DELETE',
  })
}
