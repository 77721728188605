import React from 'react';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';

const ExpandedTable = ({ allData, getIntlMsg, timeFormat }) => {
  const { credentialExamDtos } = allData || {};

  const listSupport = {
    submitTime: {
      showText: getIntlMsg('submissionTime'),
      showType: 'Time',
      addtional: {
        format: timeFormat,
      },
    },
    type: {
      showText: getIntlMsg('Exam Type'),
      showType: 'Text',
      render: (text) => getIntlMsg(text),
    },
    score: {
      showText: getIntlMsg('Score'),
      showType: 'Text',
    },
    isMakeUp: {
      showText: getIntlMsg('Make-up Exam'),
      showType: 'Text',
    },
    submitTimes: {
      showText: getIntlMsg('OrderTimes'),
      showType: 'Text',
      // render: (text, record, index) => credentialExamDtos.length - index,
    },
    fillerUserName: {
      showText: getIntlMsg('Submitter'),
      showType: 'Text',
      render: (text) => text || 'Admin',
    },
  };

  const tableProps = {
    listData: credentialExamDtos.map((item) => {
      const { isMakeUp } = item || {};

      return {
        ...item,
        isMakeUp: getIntlMsg(isMakeUp ? 'yes' : 'no'),
      };
    }),
    listSupport,
  };

  return <MagicTable {...tableProps} />;
};

export default ExpandedTable;
