import React from 'react'
import { useIntl } from 'umi'
import { Modal, Button } from 'antd'

const SendToGuardian = ({ visible, onCancel, onOk, loading }) => {
    const intl = useIntl()

    return (
        <Modal
            title={<b>{intl.formatMessage({ id: 'sendToGuardian' })}</b>}
            open={visible}
            footer={null}
            onCancel={onCancel}
        >
            <div style={{ textAlign: 'center' }}>
                <div style={{fontWeight: 600}}>{intl.formatMessage({ id: 'sureSendReportGuardian' })}</div>
                <div style={{ marginTop: '5px' }}>{intl.formatMessage({ id: 'parentsCanInAPP' })}</div>
                <div style={{ marginTop: '30px' }}>
                    <Button onClick={onCancel} style={{ marginRight: '20px' }}>{intl.formatMessage({ id: 'cancel' })}</Button>
                    <Button type="primary" onClick={onOk} loading={loading}>{intl.formatMessage({ id: 'send' })}</Button>
                </div>
            </div>
        </Modal>
    )
}

export default SendToGuardian