import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { history, useIntl, getLocale, connect } from 'umi';
import { FileDoneOutlined, FileTextOutlined } from '@ant-design/icons';
import {
  Button,
  Avatar,
  Tabs,
  Space,
  Divider,
  Progress,
  Empty,
  Popover,
  Radio,
  Image,
  Modal,
  message,
  Spin,
  Alert
} from 'antd';
import { getActiveContentTime } from 'utils/utils';
import {
  GetFirstExam,
  GetAgainExam,
  GetExamResult,
  GetExamDetail,
} from 'services/exam';
import { GetPayStatus } from 'services/trainingSystem';
import QuestionnaireRecordDrawer from 'components/QuestionnaireRecordDrawer';
import classDefaultImgUrl from 'assets/classDefault.png';
import weChatpayUrl from 'assets/we_chatpay.png';
import iconFillUrl from 'assets/icon_fill.png';
import styles from '../index.less';

const { TabPane } = Tabs;
const isZh = getLocale() === 'zh-CN';

const LearnTab = ({
  dispatch,
  userInfo,
  roleType, // 自己定义的, 外部传入的 { centerAdmin："代表中心管理员看别人的数据", personal:　"看自己" , admin }
  tabkey,
  tabList,
  detail,
  isFininshed,
  onClickItem,
  onChangeTabs,
  showTrainingApplication,
  startStudy,
  trainerDetail,
  loading,
  goToPay,
  payUrl,
  payStatusPollingRes,
}) => {
  const intl = useIntl();

  const [payStyle, setPayStyle] = useState('WXPAY_NATIVE');
  const [examInfo, setExamInfo] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [currentName, setCurrentName] = useState(''); // 当前模块名字
  const [assesDrawerVisible, setAssesDrawerVisible] = useState(false); // 实操Drawer

  // const getExamInfo = async () => {
  //   const id = detail?.lastUserExaminationPaperId
  //     ? detail.lastUserExaminationPaperId
  //     : 0;
  //   if (id !== 0) {
  //     const res = await GetExamResult({ id });
  //     if (res.response.ok) {
  //       setExamInfo(res.data.data);
  //     }
  //   } else {
  //     setExamInfo({});
  //   }
  // };

  useEffect(() => {
    // getExamInfo();
    return () => {
      dispatch({ type: 'personalCenter/clearPayTimer' });
    };
  }, [detail]);

  let modelKeys = [];
  const {
    trainingModule,
    issued,
    startDate,
    endDate,
    status,
    trainer,
    tenant,
    trainingOrder,
  } = detail || {};
  const {
    courses,
    id,
    name: trainingModuleName,
    oralQuestionnaireId,
    practicalQuestionnaireId,
    examination,
    learningReminder = ''
  } = trainingModule || {};
  const { trainerCredentialses: trainerCredentialsesArr } = trainer || {};
  const newTrainerCredentialsesArr =
    trainerCredentialsesArr && trainerCredentialsesArr.length > 0
      ? JSON.parse(JSON.stringify(trainerCredentialsesArr))
      : null;
  newTrainerCredentialsesArr &&
    newTrainerCredentialsesArr.sort((a, b) => {
      const aTime = a.creationTime;
      const bTime = b.creationTime;
      return moment(aTime) < moment(bTime) ? 1 : -1;
    });
  const certificateInfo =
    newTrainerCredentialsesArr &&
    newTrainerCredentialsesArr.find(
      (item) => item.trainingModule && item.trainingModule.id === id,
    );
  const { credentialsCode, startTime, endTime } = certificateInfo || {};
  const { displayName } = tenant || {};

  const { trainerCredentialses } = trainerDetail || {};
  modelKeys =
    trainerCredentialses &&
    trainerCredentialses.map((item) => {
      const { trainingModule, endTime } = item || {};
      const { name } = trainingModule || {};
      if (moment(endTime).isSameOrAfter(moment())) return name;
      else return null;
    });

  const { data } = payStatusPollingRes || {};
  const { status: payStatus } = data || {};

  // 预计学习时长
  let timeConsuming = 0;
  // 所有课程的所有小节
  let contents =
    courses && courses.length > 0
      ? courses.map((item) => item.course && item.course.contents)
      : [];
  contents
    .flat(Infinity)
    .forEach((item) => (timeConsuming += item.timeConsuming));

  const finishCourse = () => {
    return (
      courses &&
      courses.length > 0 &&
      courses.every((item) => item.course.length === item.course.passCount)
    );
  };

  const { status: orderStatus, needPayPrice, code } = trainingOrder || {};

  const getOutMantleDom = () => {
    switch (roleType) {
      case 'admin':
      case 'centerAdmin':
        return (
          <div className={styles.detailInfoMantleDom}>
            <span className={styles.adminTitle}>培训申请表尚未填写</span>
          </div>
        );
      // case 'personal':
      //   return (
      //     <div className={styles.detailInfoMantleDom}>
      //       <Button
      //         type="primary"
      //         icon={<FileTextOutlined />}
      //         className={styles.personalBtn}
      //         onClick={showTrainingApplication}
      //       >
      //         {intl.formatMessage({ id: 'trainingApplicationForm' })}
      //       </Button>
      //     </div>
      //   );
    }
  };

  const getDomByPayStatus = () => {
    switch (payStatus) {
      case 'NotPay':
        return (
          <div className={styles.payUrl}>
            <div className={styles.payUrlLeft}>
              <Image src={payUrl} width={100} />
            </div>
            <div className={styles.payUrlRight}>
              <div className={styles.payUrlRightTop}>
                <img src={weChatpayUrl} />
                <span>{intl.formatMessage({ id: 'WechatScanPay' })}</span>
              </div>
              <div className={styles.payUrlRightBottom}>
                {intl.formatMessage({ id: 'amountPayable' })}
                <span>${needPayPrice}</span>
              </div>
            </div>
          </div>
        );
      case 'OrderClosed':
        return (
          <div className={styles.nopay}>
            {intl.formatMessage({ id: 'orderClosed' })}
          </div>
        );
      case 'Refunded':
        return (
          <div className={styles.nopay}>
            {intl.formatMessage({ id: 'orderRefunded' })}
          </div>
        );
      case 'Paid':
        return (
          <div className={styles.paid}>
            <div className={styles.paidLeft}>
              <img src={iconFillUrl} width={48} />
            </div>
            <div className={styles.paidRight}>
              <div className={styles.paidRightTop}>
                {intl.formatMessage(
                  { id: 'trainingBeenPaidSuccessfully' },
                  { trainingModuleName: trainingModuleName },
                )}
              </div>
              <div className={styles.paidRightBottom}>
                {intl.formatMessage({ id: 'jumpingToLearn' })}...
              </div>
            </div>
          </div>
        );
      default:
        return <span></span>;
    }
  };

  const getInMantleDom = () => {
    let inMantleDom = null;
    // 我要先去判断是否存在未支付的订单
    switch (orderStatus) {
      case 'NotPay':
        if (roleType === 'centerAdmin' || roleType === 'admin') {
          return (
            <div className={styles.nopay}>
              {intl.formatMessage({ id: 'unpaid' })}
            </div>
          );
        }
        return payUrl ? (
          payStatus ? (
            <div>{getDomByPayStatus(payStatus)}</div>
          ) : (
            <div className={styles.payUrl}>
              <div className={styles.payUrlLeft}>
                <Image src={payUrl} width={100} />
              </div>
              <div className={styles.payUrlRight}>
                <div className={styles.payUrlRightTop}>
                  <img src={weChatpayUrl} />
                  <span>{intl.formatMessage({ id: 'WechatScanPay' })}</span>
                </div>
                <div className={styles.payUrlRightBottom}>
                  {intl.formatMessage({ id: 'amountPayable' })}
                  <span>${needPayPrice}</span>
                </div>
              </div>
            </div>
          )
        ) : (
          <div className={styles.needPay}>
            <div>
              {intl.formatMessage(
                { id: 'pleasePayYourTraining' },
                { trainingModuleName },
              )}
              <span style={{ fontWeight: '600', fontSize: '20px' }}>
                ${needPayPrice}
              </span>
              {intl.formatMessage({ id: 'pleasePayYourTrainingTwo' })}
            </div>
            <div className={styles.needPayStyle}>
              {intl.formatMessage({ id: 'pleaseSelectPaymentMethod' })}
              <Radio.Group
                value={payStyle}
                onChange={(e) => setPayStyle(e.target.value)}
              >
                <Radio value={'WXPAY_NATIVE'}>
                  {intl.formatMessage({ id: 'Wechat' })}
                </Radio>
                {/* <Radio value={"ALIPAY_PC"}>支付宝</Radio> */}
              </Radio.Group>
            </div>
            <div className={styles.needPayBtn}>
              <Button
                type="primary"
                disabled={payStyle === 'ALIPAY_PC'}
                onClick={() => goToPay && goToPay({ code, payStyle })}
              >
                {intl.formatMessage({
                  id: payStyle === 'ALIPAY_PC' ? 'functionIsNotOpen' : 'pay',
                })}
              </Button>
            </div>
          </div>
        );
    }

    if (!inMantleDom) {
      switch (roleType) {
        case 'admin':
        case 'centerAdmin':
          if (
            status === 'NotStarted' ||
            status === 'Stopped' ||
            status === 'Expired'
          ) {
            inMantleDom = (
              <span className={styles.simpleInfoContentMantleAdminStop}>
                {
                  {
                    NotStarted: intl.formatMessage({ id: 'notStart' }),
                    Stopped: intl.formatMessage({ id: 'paused' }),
                    Expired: intl.formatMessage({ id: 'expired' }),
                  }[status]
                }
              </span>
            );
          }
        case 'personal':
          if (status === 'NotStarted') {
            inMantleDom = (
              <div className={styles.simpleInfoContentMantleNostart}>
                <Button
                  type="primary"
                  className={styles.simpleInfoContentMantleNostartBtn}
                  onClick={startStudy}
                >
                  {intl.formatMessage({ id: 'letStartLearning' })}
                </Button>
                <div className={styles.simpleInfoContentMantleNostartContent}>
                  {intl.formatMessage({ id: 'expectedLearningTime' })}：
                  {getActiveContentTime(timeConsuming, intl)}
                </div>
              </div>
            );
          } else if (status === 'Stopped' || status === 'Expired') {
            inMantleDom = (
              <div className={styles.simpleInfoContentMantleStop}>
                {status === 'Stopped'
                  ? intl.formatMessage({ id: 'paused' })
                  : intl.formatMessage({ id: 'expired' })}{' '}
                {intl.formatMessage({ id: 'continueTrainingSupervisor' })}
              </div>
            );
          } else {
            inMantleDom = <span></span>;
          }
      }
    }
    return inMantleDom;
  };

  // const showConfirm = async (type) => {
  //   setBtnLoading(true);
  //   let totalQuestionCount = 0,
  //     fraction = 0,
  //     paymentAmount;
  //   let againSource; // 补考通过分数
  //   let newId;
  //   let isPay = false; // 是否已经支付
  //   let code; // 考试结算单号
  //   const params = {
  //     trainerTrainingModuleId: tabkey,
  //   };

  //   const examDetailRes = await GetExamDetail({
  //     id: detail.trainingModule.examinationId,
  //   });

  //   if (!examDetailRes.response.ok) {
  //     const { message: errorMessage } = examDetailRes.data?.error || {
  //       message: intl.formatMessage({ id: 'testInformationFail' }),
  //     };
  //     message.error(errorMessage, 3);
  //     return;
  //   }

  //   const { duration, regulations, passingScore } = examDetailRes.data.data;
  //   regulations.forEach((item) => {
  //     totalQuestionCount += item.quantity;
  //     fraction += item.quantity * item.fraction;
  //   });

  //   if (detail.lastUserExaminationPaperId) {
  //     newId = detail.lastUserExaminationPaperId;
  //   }

  //   if (type !== 'first' && JSON.stringify(examInfo) !== '{}') {
  //     if (examInfo.makeUpExamination) {
  //       paymentAmount = examInfo.makeUpExamination.price;
  //       againSource = examInfo.makeUpExamination.passingScore;
  //     }
  //     code = examInfo.id;
  //     if (
  //       examInfo.status === 'NOT_STARTED' &&
  //       examInfo.type === 'MAKEUP' &&
  //       examInfo.makeUpExamination.price !== 0
  //     ) {
  //       // 说明时已经是补考创建的试卷
  //       const payRes = await GetPayStatus({ code });
  //       if (payRes && payRes.response && payRes.response.ok) {
  //         const { status } = payRes.data.data;
  //         if (status === 'Paid') {
  //           isPay = true;
  //         }
  //       } else {
  //         message.error(intl.formatMessage({ id: 'failedGetOrder' }));
  //       }
  //     } else if (examInfo.status === 'FAILED') {
  //       const againRes = await GetAgainExam(params);
  //       if (againRes.response.ok) {
  //         code = againRes.data.id;
  //         setExamInfo({
  //           ...examInfo,
  //           id: code,
  //           type: 'MAKEUP',
  //           status: 'NOT_STARTED',
  //           makeUpExamination: againRes.data.makeUpExamination,
  //         });
  //       } else {
  //         const { message: errorMessage } = examDetailRes.data?.error || {
  //           message: intl.formatMessage({ id: 'generateRetestPaperFail' }),
  //         };
  //         message.error(errorMessage, 3);
  //       }
  //     }
  //   }

  //   if (tabList && tabList.length > 0) {
  //     tabList.forEach((item) => {
  //       if (tabkey === item.id) {
  //         setCurrentName(item.trainingModule.displayName);
  //       }
  //     });
  //   }
  //   setBtnLoading(false);
  //   Modal.confirm({
  //     title: intl.formatMessage({ id: 'testInstructions' }),
  //     icon: '',
  //     width: 500,
  //     content:
  //       type === 'first' ? (
  //         <div className={styles.modelStyle}>
  //           <div className={styles.modelTitles}>
  //             <div>
  //               <div>{duration}</div>
  //               <div className={styles.describe}>
  //                 {intl.formatMessage({ id: 'examDuration' })}
  //               </div>
  //             </div>

  //             <div>
  //               <div>{totalQuestionCount}</div>
  //               <div className={styles.describe}>
  //                 {intl.formatMessage({ id: 'numberOfQuestions' })}
  //               </div>
  //             </div>

  //             <div>
  //               <div>{fraction}</div>
  //               <div className={styles.describe}>
  //                 {intl.formatMessage({ id: 'testScore' })}
  //               </div>
  //             </div>
  //           </div>
  //           <div>
  //             <ul>
  //               <li>
  //                 {intl.formatMessage(
  //                   { id: 'firstTimeExam' },
  //                   { name: currentName },
  //                 )}
  //               </li>
  //               <li>
  //                 {intl.formatMessage(
  //                   { id: 'examResults' },
  //                   { score: passingScore },
  //                 )}
  //               </li>
  //             </ul>
  //           </div>
  //         </div>
  //       ) : (
  //         <div className={styles.modelStyle}>
  //           <div className={styles.modelTitles}>
  //             <div>
  //               <div>{duration}</div>
  //               <div className={styles.describe}>
  //                 {intl.formatMessage({ id: 'examDuration' })}
  //               </div>
  //             </div>

  //             <div>
  //               <div>{totalQuestionCount}</div>
  //               <div className={styles.describe}>
  //                 {intl.formatMessage({ id: 'numberOfQuestions' })}
  //               </div>
  //             </div>

  //             <div>
  //               <div>{fraction}</div>
  //               <div className={styles.describe}>
  //                 {intl.formatMessage({ id: 'testScore' })}
  //               </div>
  //             </div>

  //             {/* <div>
  //                   <div>{paymentAmount}</div>
  //                   <div className={styles.describe}>费用（元）</div>
  //               </div> */}
  //           </div>
  //           <div>
  //             <ul>
  //               <li>
  //                 {intl.formatMessage(
  //                   { id: 'thisTimeExamRetakeFee' },
  //                   { name: currentName, price: paymentAmount },
  //                 )}
  //               </li>
  //               <li>
  //                 {intl.formatMessage(
  //                   { id: 'examResults' },
  //                   { score: againSource },
  //                 )}
  //               </li>
  //             </ul>
  //           </div>
  //         </div>
  //       ),
  //     okText: intl.formatMessage({
  //       id:
  //         type === 'first'
  //           ? 'joinNow'
  //           : isPay
  //           ? 'continueWithLastExam'
  //           : paymentAmount == 0
  //           ? 'takeExam'
  //           : 'pay',
  //     }),
  //     cancelText: intl.formatMessage({ id: 'wait' }),
  //     async onOk() {
  //       if (type === 'first') {
  //         if (JSON.stringify(examInfo) === '{}') {
  //           const firstRes = await GetFirstExam(params);
  //           if (firstRes.response.ok) {
  //             newId = firstRes.data.id;
  //           } else {
  //             const { message: errorMessage } = firstRes.data?.error || {
  //               message: intl.formatMessage({ id: 'failedGenerateExam' }),
  //             };
  //             message.error(errorMessage, 3);
  //             return;
  //           }
  //         }
  //         history.push({
  //           pathname: '/system/center/detail/examPanel',
  //           query: {
  //             trainerTrainingModuleId: newId,
  //           },
  //         });
  //       } else {
  //         if (examInfo.makeUpExamination.price === 0) {
  //           history.push({
  //             pathname: '/system/center/detail/examPanel',
  //             query: {
  //               trainerTrainingModuleId: code,
  //             },
  //           });
  //         } else {
  //           if (isPay) {
  //             history.push({
  //               pathname: '/system/center/detail/examPanel',
  //               query: {
  //                 trainerTrainingModuleId: code,
  //               },
  //             });
  //           } else {
  //             goToPay &&
  //               (await goToPay({
  //                 code,
  //                 payStyle,
  //                 showModal: 'show',
  //                 paymentAmount,
  //               }));
  //           }
  //         }
  //       }
  //     },
  //     onCancel() {
  //       console.log('Cancel');
  //     },
  //   });
  // };

  const getExamResult = async (type) => {
    let pathname = '/system/center/detail/examResult';
    if (type === 'admin') {
      pathname = '/system/manage/detail/examResult';
    }
    history.push({
      pathname,
      query:
        roleType !== 'personal'
          ? {
              TrainerTrainingModuleId: detail.id,
              TrainerId: detail.trainerId,
            }
          : { TrainerTrainingModuleId: detail.id },
    });
  };

  // const gotoExam = () => {
  //   history.push({
  //     pathname: '/system/center/detail/examPanel',
  //     query: {
  //       trainerTrainingModuleId: examInfo.id,
  //     },
  //   });
  // };

  const assesDrawerProps = {
    visible: assesDrawerVisible,
    TrainingModuleId: id,
    userInfo,
    onClose: () => {
      setAssesDrawerVisible(false);
    },
  };

  return (
    <div className={styles.detailInfo}>
      {tabList && tabList.length > 0 ? (
        <React.Fragment>
          <Tabs
            // tabBarExtraContent={
            //   <Button
            //     icon={<FileDoneOutlined />}
            //     onClick={showTrainingApplication}
            //   >
            //     {intl.formatMessage({ id: 'trainingApplicationForm' })}
            //   </Button>
            // }
            onChange={onChangeTabs}
            activeKey={tabkey}
            tabBarStyle={{ paddingRight: '10px' }}
          >
            {tabList.map((item) => {
              return (
                <TabPane
                  tab={item.trainingModule && item.trainingModule.displayName}
                  key={item.id}
                ></TabPane>
              );
            })}
          </Tabs>
          <Spin spinning={loading} size="large">
            {!loading ? (
              <div className={styles.simpleInfoContent}>
                {/* {issued && (
                  <div className={styles.simpleInfoContentTitle}>
                    <div className={styles.simpleInfoContentTitleTop}>
                      <Space split={<Divider type="vertical" />}>
                        <span className={styles.simpleInfoContentTitleTopItem}>
                          {intl.formatMessage({ id: 'certified' })}
                        </span>
                        <span className={styles.simpleInfoContentTitleTopItem}>
                          {intl.formatMessage({ id: 'certificateNumber' })}：
                          {credentialsCode || ''}
                        </span>
                        <span className={styles.simpleInfoContentTitleTopItem}>
                          {intl.formatMessage({ id: 'availablePeriod' })}：
                          {startTime
                            ? moment(startTime).format(
                                isZh ? 'YYYY/MM/DD' : 'MM/DD/YYYY',
                              )
                            : ''}{' '}
                          -{' '}
                          {endTime
                            ? moment(endTime).format(
                                isZh ? 'YYYY/MM/DD' : 'MM/DD/YYYY',
                              )
                            : ''}
                        </span>
                        {
                            credential && credential.url &&
                            <a onClick={() => downloadLongRangeImg(credential)} className={styles.simpleInfoContentTitleTopDownload}>下载证书</a>
                        }
                      </Space>
                    </div>
                  </div>
                )} */}
                <div className={styles.simpleInfoContentTitleBottom}>
                  <Space split={<Divider type="vertical" />}>
                    {endDate && (
                      <div>
                        {endDate
                          ? intl.formatMessage(
                              { id: 'expire' },
                              {
                                time: moment(endDate).format(
                                  isZh ? 'YYYY/MM/DD' : 'MM/DD/YYYY',
                                ),
                              },
                            )
                          : ''}
                      </div>
                    )}
                    {(startDate !== null || endDate !== null) && (
                      <div>
                        {intl.formatMessage({ id: 'learningPeriod' })}：
                        {startDate
                          ? moment(startDate).format(
                              isZh ? 'YYYY/MM/DD' : 'MM/DD/YYYY',
                            ) + ' -'
                          : ''}{' '}
                        {endDate
                          ? moment(endDate).format(
                              isZh ? 'YYYY/MM/DD' : 'MM/DD/YYYY',
                            )
                          : ''}
                      </div>
                    )}
                    <div>
                      {intl.formatMessage({ id: 'expectedLearningTime' })}{' '}
                      {getActiveContentTime(timeConsuming, intl)}
                    </div>
                    {roleType === 'admin' && tenant && (
                      <span className={styles.simpleInfoContentTitleTopItem}>
                        {intl.formatMessage({ id: 'trainCenter' })}：
                        {displayName || ''}
                      </span>
                    )}
                  </Space>
                  {/* {roleType === 'admin' && (
                    <Space>
                      {(oralQuestionnaireId || practicalQuestionnaireId) && (
                        <Button onClick={() => setAssesDrawerVisible(true)}>
                          考核记录
                        </Button>
                      )}
                    </Space>
                  )} */}
                  {/* {roleType === 'personal' &&
                    detail &&
                    status === 'Learning' && (
                      <Space>
                        {examInfo.status !== 'PASSED' &&
                          examInfo.status !== 'DOING' &&
                          examination && (
                            <div className={styles.btnStyle}>
                              {(examInfo.status === 'FAILED' ||
                                examInfo.status === 'END' ||
                                examInfo.type === 'MAKEUP') &&
                              detail.lastUserExaminationPaperId ? (
                                examInfo.makeUpExamination ? (
                                  examInfo.makeUpExamination.times !== 0 ||
                                  examInfo.status === 'NOT_STARTED' ? (
                                    <Button
                                      type="primary"
                                      loading={btnLoading}
                                      onClick={() => showConfirm('again')}
                                    >
                                      {intl.formatMessage({ id: 'retake' })}
                                    </Button>
                                  ) : (
                                    <Popover
                                      content={
                                        <div>
                                          {intl.formatMessage({
                                            id: 'retakeExamsNotTimes',
                                          })}
                                        </div>
                                      }
                                    >
                                      <Button disabled={true}>
                                        {intl.formatMessage({ id: 'retake' })}
                                      </Button>
                                    </Popover>
                                  )
                                ) : (
                                  <Popover
                                    content={
                                      <div>
                                        {intl.formatMessage({
                                          id: 'trainingDes',
                                        })}
                                      </div>
                                    }
                                  >
                                    <Button disabled={true}>
                                      {intl.formatMessage({ id: 'retake' })}
                                    </Button>
                                  </Popover>
                                )
                              ) : finishCourse() ? (
                                <Button
                                  type="primary"
                                  loading={btnLoading}
                                  onClick={() => showConfirm('first')}
                                >
                                  {intl.formatMessage({ id: 'joinExam' })}
                                </Button>
                              ) : (
                                <Popover
                                  content={
                                    <div>
                                      {intl.formatMessage(
                                        { id: 'completeFirst' },
                                        { name: currentName },
                                      )}
                                    </div>
                                  }
                                >
                                  <Button disabled={true}>
                                    {intl.formatMessage({ id: 'joinExam' })}
                                  </Button>
                                </Popover>
                              )}
                            </div>
                          )}
                        {detail.lastUserExaminationPaperId &&
                          examInfo.status === 'DOING' && (
                            <Button danger onClick={gotoExam}>
                              {intl.formatMessage({
                                id: 'continueWithLastExam',
                              })}
                            </Button>
                          )}
                        {detail.lastUserExaminationPaperId &&
                          (examInfo.status === 'FAILED' ||
                            examInfo.status === 'PASSED' ||
                            examInfo.type === 'MAKEUP') && (
                            <Button type="primary" onClick={getExamResult}>
                              {intl.formatMessage({ id: 'sesResults' })}
                            </Button>
                          )}
                      </Space>
                    )}
                  {roleType === 'admin' && detail && (
                    <div className={styles.btnStyle}>
                      {examInfo.status === 'PASSED' ||
                      examInfo.status === 'FAILED' ||
                      examInfo.type === 'MAKEUP' ? (
                        <Button
                          type="primary"
                          onClick={() => getExamResult('admin')}
                        >
                          {intl.formatMessage({ id: 'sesResults' })}
                        </Button>
                      ) : (
                        ''
                      )}
                    </div>
                  )}
                  {roleType === 'centerAdmin' && detail && (
                    <div className={styles.btnStyle}>
                      {examInfo.status === 'PASSED' ||
                      examInfo.status === 'FAILED' ||
                      examInfo.type === 'MAKEUP' ? (
                        <Button
                          type="primary"
                          onClick={() => getExamResult('centerAdmin')}
                        >
                          {intl.formatMessage({ id: 'sesResults' })}
                        </Button>
                      ) : (
                        ''
                      )}
                    </div>
                  )} */}
                </div>

                {learningReminder && (
                  <div style={{ margin: '0 20px 20px 20px' }}>
                    <Alert
                      message={learningReminder}
                      type="info"
                      showIcon
                      closable
                    />
                  </div>
                )}

                {courses && courses.length > 0 ? (
                  <div className={styles.simpleInfoContentDetail}>
                    {courses.map((item, index) => {
                      let questionnairesLen = 0;
                      const { course } = item || {};
                      const {
                        title,
                        subTitle,
                        headerImageUrl,
                        contents,
                        passCount,
                        length,
                      } = course || {};
                      const { url } = headerImageUrl || {};
                      let allTimeConsuming =
                        contents && contents.length > 0
                          ? contents.reduce(
                              (result, item) => result + item.timeConsuming,
                              0,
                            )
                          : 0;
                      contents &&
                        contents.length > 0 &&
                        contents.forEach((item) => {
                          const { questionnaires, userCourseDetail } =
                            item || {};
                          const { isFinish, userQuestionnaireId } =
                            userCourseDetail || {};
                          if (isFinish) {
                            if (userQuestionnaireId) questionnairesLen++;
                          } else {
                            if (questionnaires && questionnaires.length > 0)
                              questionnairesLen++;
                          }
                        });
                      return (
                        <div
                          className={styles.simpleInfoContentDetailItem}
                          key={index}
                          onClick={() => {
                            // 培训申请表未填写
                            if (isFininshed === null) {
                              message.warning({
                                key: 'Complete the training',
                                content: intl.formatMessage({ id: 'Complete the training' }),
                                duration: 2,
                                onClose: showTrainingApplication,
                              });
                            } else {
                              onClickItem(item)
                            }
                          }}
                        >
                          <div
                            className={styles.simpleInfoContentDetailItemLeft}
                          >
                            <Avatar
                              src={url || classDefaultImgUrl}
                              size={72}
                              shape="square"
                              className={styles.avatar}
                            />
                          </div>
                          <div
                            className={styles.simpleInfoContentDetailItemRight}
                          >
                            <div
                              className={
                                styles.simpleInfoContentDetailItemRightTitle
                              }
                            >
                              {title}
                            </div>
                            <div
                              className={
                                styles.simpleInfoContentDetailItemRightSubTitle
                              }
                            >
                              {subTitle}
                            </div>
                            <div
                              className={
                                styles.simpleInfoContentDetailItemRightSection
                              }
                            >
                              {intl.formatMessage(
                                { id: 'nSession' },
                                { num: length },
                              ) +
                                ' | ' +
                                intl.formatMessage(
                                  { id: 'nTest' },
                                  { num: questionnairesLen },
                                ) +
                                ' | ' +
                                getActiveContentTime(allTimeConsuming, intl)}
                            </div>
                            <div
                              className={
                                styles.simpleInfoContentDetailItemRightProgress
                              }
                            >
                              <Progress
                                percent={
                                  length > 0 ? (passCount / length) * 100 : 0
                                }
                                format={(percent) => `${passCount}/${length}`}
                                strokeColor={
                                  length > 0 && passCount === length
                                    ? '#3C84FF'
                                    : '#F96B4B'
                                }
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className={styles.simpleInfoContentDetailEmpty}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </div>
                )}
                {isFininshed && status && status !== 'Learning' && (
                  <div className={styles.simpleInfoContentMantle}>
                    {getInMantleDom()}
                  </div>
                )}
              </div>
            ) : (
              <div className={styles.loadingPage}></div>
            )}
            {isFininshed === null && roleType !== 'personal' && (
              <div className={styles.detailInfoMantle}>{getOutMantleDom()}</div>
            )}
          </Spin>
          {/* 口试Drawer */}
          {assesDrawerVisible && (
            <QuestionnaireRecordDrawer {...assesDrawerProps} />
          )}
        </React.Fragment>
      ) : (
        <div className={styles.detailInfoEmpty}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}
    </div>
  );
};

export default LearnTab;
