import getRequest from 'utils/request'

const newReminderTemplate = '/api/rocketAdmin/reminderTemplate'
const updateReminderTemplate = '/api/rocketAdmin/reminderTemplate/{id}'

export async function GetReminderTemplate() {
  return getRequest()(newReminderTemplate, {
    method: 'GET',
  })
}

export async function PostReminderTemplate(data) {
  return getRequest()(newReminderTemplate, {
    method: 'POST',
    data,
  })
}

export async function GetUpdateReminderTemplate(id) {
  return getRequest()(updateReminderTemplate.replace('{id}', id), {
    method: 'GET',
  })
}

export async function PutReminderTemplate(data, id) {
  return getRequest()(updateReminderTemplate.replace('{id}', id), {
    method: 'PUT',
    data,
  })
}

export async function DeleteReminderTemplate(id) {
  return getRequest()(updateReminderTemplate.replace('{id}', id), {
    method: 'DELETE',
  })
}
